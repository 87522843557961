import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgressWithLabel } from "../containers/LoadingContainer";
import {
  Card,
  Grid,
  Button,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { apiQuery } from "../libs/auth-config";
//import { apiQuery } from "../libs/auth-config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: theme.spacing(24),
  },
  card: {
    padding: theme.spacing(0),
    marginLeft: "50%",
    marginRight: "50%",
  },
  card_field: {
    marginBottom: theme.spacing(1),
  },
  grid: {
    paddingTop: theme.spacing(1),
    height: "74%",
  },
  progress: {
    marginTop: theme.spacing(1),
  },
}));

export default function AutomationExecution({ jobs, callback }) {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [jobStates, setJobStates] = useState({});
  const [reset, setReset] = useState(false);

  const startExecution = async (jobList) => {
    const progressPerJob = 100 / jobList.length;
    for (var i = 0; i < jobList.length; i++) {
      var job = jobList[i];
      var path = ""
      if(job.endsWith(" Groups")){
        path = "groups"
      }else{
        path = job.toLowerCase().replace(" ", "_")
      }
      provisionQuery(path, jobs[job], job, progressPerJob);

    }
  };

  const provisionQuery = (path, payload, key, progressPerJob) => {
    apiQuery("POST", `/automation/${path}`, payload)
      .then((data) => {
        setJobStates({
          ...jobStates,
          [key]: {
            state: data.status_code === 200 ? 1 : 2,
            code: data.status_code,
            message: data.message,
          },
        });
        setProgress(progress + progressPerJob);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    var states = {};
    const jobList = Object.keys(jobs);
    jobList.forEach((job) => {
      states[job] = { state: 0, code: 0, message: "" };
    });
    setJobStates(states);
    setProgress(0);
    startExecution(jobList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (progress === 100) setReset(true);
  }, [progress]);

  return (
    <Paper className={classes.root}>
      <Typography variant="h4">Live Updates</Typography>
      <div className={classes.progress}>
        <LinearProgressWithLabel value={progress} style={{ width: "100%" }} />
      </div>
      <Grid container className={classes.grid} justifyContent="center">
        {Object.keys(jobStates).length &&
          Object.keys(jobs).map((job) => {
            return (
              <Grid item lg={2}>
                <Card elevation={0}>
                  <div>
                    <Typography variant="h5" className={classes.card_field}>
                      {job}
                    </Typography>
                    <Typography
                      variant="subtitle"
                      className={
                        !jobStates[job].state
                          ? `loading_text ${classes.card_field}`
                          : classes.card_field
                      }
                    >
                      {!jobStates[job].state
                        ? "Running"
                        : jobStates[job].state === 1
                        ? "Complete"
                        : "Failed"}
                    </Typography>
                    {jobStates[job]?.state > 0 ? (
                      <Alert
                        severity={
                          jobStates[job].state === 1 ? "success" : "error"
                        }
                      >
                        {jobStates[job].code}: {jobStates[job].message}
                      </Alert>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={reset}
            onClick={() => callback()}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
