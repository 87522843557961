import { MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import _ from "lodash";
import { THEME } from "../constants";

let themes = [
  {
    name: "Late Shift",
    values: {
      portalPrimary: "#e07800",
      portalSecondary: "#f58438",
      sidebarColour: "#404040",
      colourScheme: "dark",
    },
  },
  {
    name: "Rustic",
    values: {
      portalPrimary: "#14532d",
      portalSecondary: "#f59e0b",
      sidebarColour: "#14532d",
      colourScheme: "light",
    },
  },
];

// Fix the theme list based on the portal we are on.

let defaultTheme = "";

// It's Giacom.
if (THEME === "affinity" || THEME === "dwp") {
  themes = [
    {
      name: "Giacom (Light)",
      values: {
        portalPrimary: "#67246a",
        portalSecondary: "#EEAF03",
        sidebarColour: "#67246a",
        colourScheme: "light",
      },
    },
    {
      name: "Giacom (Dark)",
      values: {
        portalPrimary: "#EEAF03",
        portalSecondary: "#7e22ce",
        sidebarColour: "#67246a",
        colourScheme: "dark",
      },
    },
  ].concat(themes);
  defaultTheme = "Giacom (Light)";
}
// It's DC.
else {
  themes = [
    {
      name: "DC (Light)",
      values: {
        portalPrimary: "#022f6b",
        portalSecondary: "#f29400",
        sidebarColour: "#022f6b",
        colourScheme: "light",
      },
    },
    {
      name: "DC (Dark)",
      values: {
        portalPrimary: "#f29400",
        portalSecondary: "#2563eb",
        sidebarColour: "#022f6b",
        colourScheme: "dark",
      },
    },
  ].concat(themes);
  defaultTheme = "DC (Light)";
}

const useStyles = makeStyles((theme) => ({
  themeSelect: {
    minWidth: 210,
  },
}));

const ThemePicker = ({ currentTheme, setTheme }) => {
  const classes = useStyles();
  // Store the name of the theme picked.
  const [themePicked, setThemePicked] = useState(defaultTheme);

  useEffect(() => {
    /*
     If the provided theme isn't a template set the theme
     picked to "custom", if it is, set it to the provided theme.
     This means default themes will be detected and not go to "custom".
    */
    let isCustomTheme = true;
    themes.forEach((theme) => {
      if (_.isEqual(theme.values, currentTheme)) {
        setThemePicked(theme.name);
        isCustomTheme = false;
      }
    });
    if (isCustomTheme) {
      setThemePicked("custom");
    }
  }, [currentTheme]);

  return (
    <TextField
      select
      label="Theme"
      variant="outlined"
      size="small"
      className={classes.themeSelect}
      onChange={(e) => {
        if (e.target.value !== "custom") {
          let presetTheme = themes.filter(
            (theme) => theme.name === e.target.value
          )[0].values;
          setTheme(Object.values(presetTheme));
        }
        setThemePicked(e.target.value);
      }}
      value={themePicked}
    >
      <MenuItem value="custom">Custom</MenuItem>
      {themes.map((theme) => (
        <MenuItem key={theme.name} value={theme.name}>
          {theme.name[0].toUpperCase() + theme.name.substring(1)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ThemePicker;
