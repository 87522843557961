import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
// import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    // margin: theme.spacing(1),
    // position: 'relative',
  },
  // buttonSuccess: {
  //   backgroundColor: green[500],
  //   '&:hover': {
  //     backgroundColor: green[700],
  //   },
  // },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CircularIntegration({
  text,
  onClick,
  is_loading,
  disabled = false,
  style,
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function handleButtonClick() {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
    onClick();
  }

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        className={buttonClassname}
        disabled={is_loading || disabled}
        onClick={handleButtonClick}
        style={style ? style : null}
      >
        {text}
      </Button>
      {/* is_loading && <CircularProgress size={24} className={classes.buttonProgress} /> */}
    </div>
  );
}
