import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUnixTime } from "date-fns";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import { acknowledgeAlert } from "../../actions/alerts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function AcknowledgeConfirmation({ alert, state, closeDialog }) {
  const [open, setOpen] = React.useState(state);
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [currentAlert, setAlert] = React.useState(alert);
  const [message, setMessage] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const thisalert = useSelector((state) => state.alerts.byId[alert.id]);

  useEffect(() => {
    if (state) {
      setOpen(state);
      setAlert(thisalert);
      console.log(thisalert);
    }
  }, [state, thisalert]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleClose = () => {
    setOpen(false);
    closeDialog();
    setAlert({
      id: "-",
      threshold: "-",
      alertValue: "-",
      dataPointName: "-",
      message: {
        subject: "Subject",
        body: "Message",
      },
    });
  };

  const changeDate = (e) => {
    setDate(e.target.value);
  };

  const submit = () => {
    var end = getUnixTime(new Date(date)) * 1000;
    console.log(end);
  };

  const checkValuesAreChanged = () => {
    if (date !== null && message !== null) return false;
    else return true;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Alert Information</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Summary" {...a11yProps(0)} />
                <Tab label="Acknowledge" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Grid container spacing={1}>
                  <Card className={classes.root}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="primary"
                        style={{ marginBottom: "20px" }}
                      >
                        Description
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h2"
                        style={{ marginBottom: "10px" }}
                      >
                        {currentAlert.message
                          ? currentAlert.message.subject
                          : "no message in alert"}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {currentAlert.message
                          ? currentAlert.message.body
                          : "no message in alert"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <TableContainer style={{ marginTop: "30px" }} component={Paper}>
                  <Table size="small" aria-label="recipients table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Threshold</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Datapoint</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{currentAlert.id}</TableCell>
                        <TableCell>{currentAlert.threshold}</TableCell>
                        <TableCell>{currentAlert.alertValue}</TableCell>
                        <TableCell>{currentAlert.dataPointName}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  style={{ height: "100px" }}
                >
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Acknowledgement Comment
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={null}
                    onChange={() => console.log("FEG")}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    labelWidth={180}
                    style={{ height: "100px" }}
                  />
                </FormControl>
                <Button
                  onClick={acknowledgeAlert(5)}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: "20px" }}
                >
                  Acknowledge
                </Button>
              </TabPanel>
              <TabPanel>
                <FormControl
                  fullWidth
                  className={classes.margin}
                  variant="outlined"
                  style={{ height: "100px", width: "500px" }}
                >
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Description
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={message}
                    multiline
                    onChange={(e) => setMessage(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    labelWidth={70}
                    style={{ height: "100px" }}
                  />
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item>
                    <form className={classes.container} noValidate>
                      <TextField
                        id="datetime-end"
                        label="End Time"
                        type="datetime-local"
                        defaultValue={date}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginTop: "17px" }}
                        onChange={changeDate}
                      />
                    </form>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={submit}
                      color="primary"
                      variant="contained"
                      style={{ marginTop: "20px" }}
                      disabled={checkValuesAreChanged()}
                    >
                      Sumbit
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            </SwipeableViews>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
