import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {
  Button,
  Card,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import ContainerHeader from "../components/ContainerHeader";
import { apiQuery } from "../libs/auth-config";
import DragAndDrop from "../components/DragAndDrop";
import { PREFIX } from "../constants";
import ThemePicker from "../components/ThemePicker";

const useStyles = makeStyles((theme) => ({
  gap: {
    marginTop: theme.spacing(1),
  },
  smallgap: {
    marginTop: 10,
  },
  nogap: {
    marginTop: theme.spacing(0),
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  colourButton: { minWidth: 210, marginRight: 20 },
  logoPreview: {
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
  },
  logoPreviewTooBig: {
    maxWidth: 230,
    maxHeight: 60,
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5,
  },
}));

export default function ThemeForm({
  state,
  branding,
  callback,
  saveCallback,
  loading,
  logo,
  portalLogo,
}) {
  const theme = useTheme();
  var themeColours = JSON.parse(branding.themeColours);
  const [reportPrimary, setReportPrimary] = useState(
    branding ? themeColours.reports.primary : theme.palette.primary.main
  );
  const [reportSecondary, setReportSecondary] = useState(
    branding ? themeColours.reports.secondary : theme.palette.secondary.main
  );
  const [portalPrimary, setPortalPrimary] = useState(
    branding ? themeColours.portal.primary : theme.palette.primary.main
  );
  const [portalSecondary, setPortalSecondary] = useState(
    branding ? themeColours.portal.secondary : theme.palette.secondary.main
  );
  // Toggle dark/light mode.
  const [colourScheme, setColourScheme] = useState(
    themeColours.portal.type ? themeColours.portal.type : "light"
  );
  // Choose sidebar colour.
  const [sidebarColour, setSidebarColour] = useState(
    themeColours.portal.sidebar ? themeColours.portal.sidebar : "red"
  );
  const [img, setImg] = useState(logo);
  const [portalImg, setPortalImg] = useState(portalLogo);
  const [subdomain, setSubdomain] = React.useState("");
  const [ccAddressees, setCCAddressees] = React.useState("");
  const email = useSelector((state) => state.userSession.email);
  const account_id = useSelector(
    (state) => state.userSession.properties.account_id
  );
  const classes = useStyles();
  const [didRequestFail, setDidRequestFail] = React.useState(null);
  const fields = [
    {
      id: "subdomain",
      label: "Subdomain",
      onChange: (value) => setSubdomain(value),
    },
    {
      id: "ccaddressees",
      label: "CC Addressees",
      onChange: (value) => setCCAddressees(value),
    },
  ];
  const [imageDimensions, setImageDimensions] = useState({});

  const handleReportSave = () => {
    saveCallback(
      {
        reports: { primary: reportPrimary, secondary: reportSecondary },
        portal: {
          primary: portalPrimary,
          secondary: portalSecondary,
          type: colourScheme,
          sidebar: sidebarColour,
        },
      },
      img,
      portalImg
    );
  };

  const checkValuesAreChanged = () =>
    img === logo &&
    portalImg === portalLogo &&
    (branding
      ? reportPrimary === themeColours.reports.primary &&
        reportSecondary === themeColours.reports.secondary &&
        portalPrimary === themeColours.portal.primary &&
        portalSecondary === themeColours.portal.secondary &&
        colourScheme === themeColours.portal.type &&
        sidebarColour === themeColours.portal.sidebar
      : reportPrimary === theme.palette.primary.main &&
        reportSecondary === theme.palette.secondary.main &&
        portalPrimary === theme.palette.primary.main &&
        portalSecondary === theme.palette.secondary.main &&
        sidebarColour === theme.palette.sidebar.main) &&
    colourScheme === theme.palette.type;

  const sendDomainRequest = async () => {
    const data = {
      requestEmail: email,
      accountId: account_id,
      domain: subdomain,
      CCAddresses: ccAddressees,
    };

    const response = await apiQuery("POST", "/domainrequest", data);
    if (response.status_code === 200) {
      setDidRequestFail(false);
    } else {
      setDidRequestFail(true);
    }
  };

  const handleColourButtonClick = (id) => {
    let input = document.getElementById(id);
    input.click();
  };

  const loadImage = (setImageDimensions, portalImg) => {
    const img = new Image();
    img.src = portalImg;

    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
    img.onerror = (err) => {
      setImageDimensions({
        height: 230,
        width: 60,
      });
    };
  };

  useEffect(() => {
    loadImage(setImageDimensions, portalImg);
  }, [portalImg]);

  return (
    <div style={{ paddingBottom: 20 }}>
      <ContainerHeader title={"Settings"} />
      <Card elevation={2} style={{ padding: 20, marginBottom: 20 }}>
        <Typography variant="h3">Portal Theming</Typography>
        <Grid container spacing={2} alignItems="top">
          <Grid item xl={5} lg={6} md={12} style={{ marginTop: "20px" }}>
            <Typography variant="body1" className={classes.gap}>
              Customise your portal by selecting from one of our premade themes
              or by creating your own. Choose the primary, secondary, and side
              bar colours, and specify whether dark mode will be applied.
            </Typography>
            <Typography variant="body1" className={classes.gap}>
              For best results, contrasting colours should be used.
            </Typography>
          </Grid>
          <Grid
            item
            style={{ display: "flex", gap: "12px", flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ThemePicker
                currentTheme={{
                  portalPrimary,
                  portalSecondary,
                  sidebarColour,
                  colourScheme,
                }}
                setTheme={([primary, secondary, sidebar, colourScheme]) => {
                  setPortalPrimary(primary);
                  setPortalSecondary(secondary);
                  setSidebarColour(sidebar);
                  setColourScheme(colourScheme);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                className={classes.colourButton}
                variant="contained"
                color="primary"
                onClick={() => handleColourButtonClick("t_pri")}
              >
                Select Primary Colour
              </Button>
              <input
                id="t_pri"
                onChange={(colour) => setPortalPrimary(colour.target.value)}
                type="color"
                value={portalPrimary}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                className={classes.colourButton}
                variant="contained"
                color="primary"
                onClick={() => handleColourButtonClick("t_sec")}
              >
                Select Secondary Colour
              </Button>
              <input
                type="color"
                id="t_sec"
                value={portalSecondary}
                onChange={(e) => setPortalSecondary(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                className={classes.colourButton}
                variant="contained"
                color="primary"
                onClick={() => handleColourButtonClick("t_sidebar")}
              >
                Sidebar Colour
              </Button>
              <input
                type="color"
                id="t_sidebar"
                value={sidebarColour}
                onChange={(e) => setSidebarColour(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={colourScheme === "dark"}
                    onChange={() =>
                      setColourScheme(
                        colourScheme === "dark" ? "light" : "dark"
                      )
                    }
                    name="checkedDarkmode"
                  />
                }
                label="Dark Mode"
              />
            </div>
          </Grid>
        </Grid>
      </Card>
      <Card
        elevation={2}
        style={{ padding: 20, marginTop: 20, marginBottom: 20 }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xl={5} lg={6} md={12}>
            <Typography variant="h3">Report Theming</Typography>
            <Typography variant="body1" className={classes.gap}>
              Choose colours used to theme the PDF reports for this account.
            </Typography>
            <Typography variant="body1" className={classes.gap}>
              The colours should not be similar as they will be used for
              background (primary) and text (secondary).
            </Typography>
          </Grid>
          <Grid item>
            <div
              style={{
                display: "flex",
                marginBottom: 35,
                alignItems: "center",
              }}
            >
              <Button
                className={classes.colourButton}
                variant="contained"
                color="primary"
                onClick={() => handleColourButtonClick("r_pri")}
              >
                Select Primary Colour
              </Button>
              <input
                type="color"
                id="r_pri"
                value={reportPrimary}
                onChange={(colour) => setReportPrimary(colour.target.value)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                className={classes.colourButton}
                variant="contained"
                color="primary"
                onClick={() => handleColourButtonClick("r_sec")}
              >
                Select Secondary Colour
              </Button>
              <input
                type="color"
                id="r_sec"
                value={reportSecondary}
                onChange={(colour) => setReportSecondary(colour.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </Card>
      <Card elevation={2} style={{ padding: 20, marginTop: 20 }}>
        <Typography variant="h3">Branded Domain Request</Typography>
        <div style={{ width: "100%" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.gap}
            style={{ width: "100%" }}
            sm={12}
            md={12}
            lg={8}
            xl={5}
          >
            <Alert severity="info" style={{ width: "100%" }}>
              Raise a request to have this account hosted on a branded LogicOne
              subdomain.
            </Alert>
            {fields.map((field) => (
              <Grid className={classes.gap} item lg={4} md={4} sm={12}>
                <TextField
                  key={field.id}
                  id={field.id}
                  label={field.label}
                  variant="outlined"
                  size="small"
                  placeholder={field.placeholder}
                  className={classes.textfield}
                  multiline
                  fullWidth
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </Grid>
            ))}
            <Grid item xl={3} lg={3} md={3} sm={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={sendDomainRequest}
                className={classes.gap}
                fullWidth
              >
                Send Request
              </Button>
            </Grid>
          </Grid>
        </div>

        {didRequestFail && (
          <Alert severity="error">
            "Error sending request, try later or contact support."
          </Alert>
        )}
        {didRequestFail !== null && !didRequestFail && (
          <Alert severity="success">Request sent successfully.</Alert>
        )}
      </Card>
      <Card elevation={2} style={{ padding: 20, marginTop: 20 }}>
        <Typography variant="h3">Company Logo</Typography>
        <Grid item xl={5} lg={6} md={12}>
          <Typography variant="body1" className={classes.gap}>
            Choose separate logos for the portal and the reports. These images
            should be of SVG or PNG format only.
          </Typography>
          <Typography variant="body1" className={classes.gap}>
            Portal Logo - This should be an image with a recommended resolution
            of 230x60px. The new logo will show upon session refresh.
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.gap}
          style={{ width: "100%" }}
          sm={12}
          md={12}
          lg={8}
          xl={5}
        >
          <Grid
            item
            style={{ display: "flex", height: "100%" }}
            alignItems="center"
          >
            <div
              style={{
                width: 165,
                height: 165,
                marginLeft: 10,
                marginBottom: 10,
                padding: 10,
                paddingTop: 15,
                border: "2px dashed",
                borderRadius: 7,
              }}
            >
              <DragAndDrop
                callback={(file) => setPortalImg(file)}
                content={
                  <>
                    <Typography className={classes.smallgap} variant="body2">
                      Drag {"&"} drop your logo
                    </Typography>
                    <Typography className={classes.smallgap} variant="body2">
                      OR
                    </Typography>
                    <Button
                      className={classes.smallgap}
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      Upload
                    </Button>
                  </>
                }
              />
            </div>
            <Card
              elevation={1}
              style={{
                width: 240,
                marginLeft: 30,
                height: 65,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                className={
                  imageDimensions.width > 230 || imageDimensions.height > 60
                    ? classes.logoPreviewTooBig
                    : classes.logoPreview
                }
                src={
                  typeof portalImg === "string"
                    ? `${portalImg}`
                    : URL.createObjectURL(portalImg)
                }
                onError={(e) =>
                  (e.target.src = `https://s3.eu-west-2.amazonaws.com/${PREFIX}.logic-one.io/brands/${PREFIX}_sidemenu_logo_default.png`)
                }
                //src={"https://s3.eu-west-2.amazonaws.com/dws.logic-one.io/brands/DWS+Logo+Test.png"}
                alt="Company Logo"
                //width={"100%"}
                //height={"100%"}
              />
            </Card>
          </Grid>
          <Grid item xl={12} lg={12} md={12}>
            <Typography variant="body1" className={classes.gap}>
              Report Logo - This should be a square image.
            </Typography>
          </Grid>
          <Grid
            item
            style={{ display: "flex", height: "100%" }}
            alignItems="center"
          >
            <div
              style={{
                width: 165,
                height: 165,
                marginLeft: 10,
                padding: 10,
                paddingTop: 15,
                border: "2px dashed",
                borderRadius: 7,
              }}
            >
              <DragAndDrop
                callback={(file) => setImg(file)}
                content={
                  <>
                    <Typography className={classes.smallgap} variant="body2">
                      Drag {"&"} drop your logo
                    </Typography>
                    <Typography className={classes.smallgap} variant="body2">
                      OR
                    </Typography>
                    <Button
                      className={classes.smallgap}
                      size="small"
                      variant="outlined"
                      color="primary"
                    >
                      Upload
                    </Button>
                  </>
                }
              />
            </div>
            <Card
              elevation={1}
              style={{
                width: 165,
                marginLeft: 30,
                height: 165,
              }}
            >
              <img
                style={{
                  padding: 20,
                }}
                src={typeof img === "string" ? img : URL.createObjectURL(img)}
                alt="Company Logo"
                width={"100%"}
                height={"100%"}
              />
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Button
        onClick={handleReportSave}
        variant="contained"
        color="primary"
        className={classes.gap}
        disabled={checkValuesAreChanged()}
      >
        Save
      </Button>
    </div>
  );
}
