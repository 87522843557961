import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Link,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  MenuItem,
} from "@material-ui/core";
import CSVReader from "react-csv-reader";
import CircularIntegration from "../CircularIntegration";

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  div: {
    width: "100%",
    minHeight: theme.spacing(8.5),
  },
  grid: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  item: {
    width: "100%",
  },
  centered: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
  },
}));

const initialState = [
  {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: "",
  },
];

export default function UserForm({ callback, data, saveFileName, roles }) {
  const classes = styles();

  //const [roleList, setRoleList] = React.useState([]);

  const [users, setUsers] = React.useState(initialState);

  var fields = [
    { id: "firstName", label: "First Name", value: users.firstName },
    { id: "lastName", label: "Last Name", value: users.lastName },
    { id: "email", label: "Email Address", value: users.email },
    { id: "phoneNumber", label: "Phone Number", value: users.phoneNumber },
    { id: "role", label: "Role", value: users.role },
  ];

  // Track whether user wants to add single or multiple users.
  const [addMultipleUsers, setAddMultipleUsers] = React.useState(false);

  useEffect(() => {
    if (data !== undefined) {
      setUsers(data);
    }
  }, [data]);

  const handleChange = (val, key) => {
    var usr = users[0];
    usr[key] = val;
    setUsers([usr]);
  };

  const rolesList = useMemo(() => {
    return roles.map((r) => r.name);
  }, [roles]);

  const validForm = () => {
    for (let i = 0; i < users.length; i++) {
      // If any missing values return false.
      if (Object.values(users[i]).some((field) => field === "")) {
        return false;
      }
      // Validate email.
      if (!users[i].email.includes("@") || !users[i].email.includes(".")) {
        return false;
      }
      if (!users[i].phoneNumber.includes("+")) {
        return false;
      }
      if (!rolesList.includes(users[i].role)) {
        return false;
      }
    }
    return true;
  };

  /*
   Split these into seperate functions so we can validate each field individually,
   then its actually clear what is wrong.
  */
  const validEmail = (email) => {
    return email.includes("@") && email.includes(".");
  };

  const validPhoneNumber = (phoneNumber) => {
    return phoneNumber.includes("+") && !phoneNumber.match(/^[A-Za-z]+$/);
  };

  const saveConfig = () => {
    callback(users, () => saveFileName(fileName));
  };

  const handleSwitch = (e) => {
    // Wipe the fields when they swap tab, tabs will load
    // based on what is saved anyway, this just prevents
    // whats in tab 1 from showing up in tab 2. Have to do this
    // deepcopy approach as we initialise the array with initialState
    // we need a new ID.
    setUsers(JSON.parse(JSON.stringify(initialState)));
    setAddMultipleUsers(e.target.checked);
  };

  const createUserObject = (row) => {
    const user = {
      firstName: row[0],
      lastName: row[1],
      email: row[2],
      phoneNumber: row[3],
      role: row[4],
    };
    return user;
  };

  /*
   Separate variable to hold the name of the CSV file, we only want this
   to get passed up if the user saves the configuration, otherwise the 
   most recent file upload will show in confirmation page even if it
   is not saved.
  */
  const [fileName, setFileName] = React.useState(null);

  const handleCSV = (data, fileInfo, files) => {
    setFileName(fileInfo.name);
    // Slice header.
    setUsers(data.slice(1).map((user) => createUserObject(user)));
  };

  const renderCSVTable = (userData) => {
    const rows = ["First Name", "Last Name", "Email", "Phone Number", "Role"];
    return (
      <TableContainer style={{ height: "100%", marginTop: "10px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {rows.map((row) => {
                return <TableCell>{row}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((usr) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {Object.keys(usr).map((field) => (
                  <TableCell align="center">{usr[field]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  var addUserForm;
  if (!addMultipleUsers) {
    addUserForm = (
      <>
        <Typography variant="body">
          Fill in the form to provision a single user.
        </Typography>
        <div className={classes.div}>
          <Grid
            container
            spacing={0}
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.grid}
          >
            {fields.map((field) => {
              // Only display validation errors if the field has been
              // filled in.
              var validField = true;
              if (users[0][field.id].length) {
                switch (field.id) {
                  case "phoneNumber":
                    validField = validPhoneNumber(users[0].phoneNumber);
                    break;
                  case "email":
                    validField = validEmail(users[0].email);
                    break;
                  default:
                    break;
                }
              }
              return (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={5}
                  xl={2}
                  className={classes.item}
                >
                  {field.id !== "role" ? (
                    <TextField
                      fullWidth
                      style={{ width: "100%" }}
                      error={!validField}
                      key={field.label}
                      label={field.label}
                      defaultValue={
                        data !== undefined && data.length === 1
                          ? data[0][field.id]
                          : null
                      }
                      placeholder={field.id === "phone" ? "(+44)" : ""}
                      onChange={(e) => handleChange(e.target.value, field.id)}
                      variant="outlined"
                      size="small"
                    />
                  ) : (
                    <TextField
                      label="Role"
                      placeholder="role"
                      fullWidth
                      key={field.label}
                      select
                      defaultValue={
                        data !== undefined && data.length === 1
                          ? data[0][field.id]
                          : null
                      }
                      onChange={(e) => handleChange(e.target.value, field.id)}
                      variant="outlined"
                      size="small"
                    >
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </div>
        <CircularIntegration
          style={{ marginLeft: "auto" }}
          text={"Save"}
          disabled={!validForm()}
          onClick={() => saveConfig()}
        />
      </>
    );
  } else {
    // Default the table to display the saved options
    // if the user has tabbed out and back in.
    var csvTable;
    if (JSON.stringify(users) !== JSON.stringify(initialState)) {
      // We reset users to length 1 when multiple switch is flicked,
      // so if a file has been uploaded then length > 1.
      csvTable = renderCSVTable(users);
    } else if (data !== undefined) {
      // User hasn't uploaded a file since tabbing in, however
      // they did upload one during the session, so render the last one they saved.
      csvTable = renderCSVTable(data);
    } else {
      // Default empty sheet.
      csvTable = renderCSVTable(initialState);
    }

    addUserForm = (
      <React.Fragment>
        <Typography variant="body" style={{ width: "100%", maxWidth: "500px" }}>
          Create multiple user accounts in one go by uploading a&nbsp;
          <Link
            style={{
              color: "#9f46d4",
              borderBottom: "1px solid",
              textDecoration: "none",
            }}
            href="https://masro-automation.s3.eu-west-2.amazonaws.com/example.csv"
          >
            CSV file.
          </Link>
        </Typography>
        {csvTable}
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          className={classes.grid}
        >
          <Grid item xs={6} sm={6} md={4} lg={2} style={{}}>
            <CircularIntegration
              text={"Save"}
              disabled={!validForm()}
              onClick={() => saveConfig()}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={2}
            xl={2}
            style={{
              maxWidth: "200px",
              alignSelf: "flex-end",
              marginBottom: "8px",
              marginRight: "13px",
            }}
          >
            <CSVReader
              inputStyle={{ width: "200px" }}
              onFileLoaded={(data, fileInfo, files) =>
                handleCSV(data, fileInfo, files)
              }
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" className={classes.root}>
            Users
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Switch onChange={(e) => handleSwitch(e)} />}
            label="Multiple"
          />
        </Grid>
      </Grid>
      {addUserForm}
    </>
  );
}
