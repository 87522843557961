import API from "@aws-amplify/api";

import * as queries from "../graphql/queries";
import { apiQuery } from "../libs/auth-config";

import { IS_EMBEDDED, PREFIX } from "../constants";
//import Configuration from "../config.json";
// import { scrollIntoView } from "react-select/dist/utils-2db2ca57.cjs.prod";

export const USER_SETTINGS_CREATE = "USER_SETTINGS_CREATE";
export const USER_SETTINGS_RECEIVE = "USER_SETTINGS_RECEIVE";
export const USER_SETTINGS_UPDATE = "USER_SETTINGS_UPDATE";

export const getUserSettings = (view_id) => async (dispatch) => {
  return dispatch(getUserSettingsThunk(view_id));
};

export const updateDeviceFilter = (filter) => async (dispatch) => {
  return dispatch({ type: "FILTER_UPDATE", payload: { filter: filter } });
};

export const updateAlertsFilter = (filter) => async (dispatch) => {
  return dispatch({
    type: "ALERTS_FILTER_UPDATE",
    payload: { filter: filter },
  });
};

export const getUserSettingsThunk = (view_id) => async (dispatch, getState) => {
  let username = getState().userSession.username;
  let settings,
    default_view = false;
  var items = [];
  if (!IS_EMBEDDED) {
    try {
      const response = await API.graphql({
        query: queries.listUserViewSettings,
        variables: { filter: { cognito_user_id: { eq: username } } },
        authToken: localStorage.getItem("jwt"),
      });
      items = response.data.listUserViewSettings.items;
    } catch (e) {
      items = [];
    }
  }

  const default_response = await API.graphql({
    query: queries.listUserViewSettings,
    variables: { filter: { cognito_user_id: { eq: "default" } } },
    authToken: localStorage.getItem("jwt"),
  });

  if (!items.length || items[0].saved_views === null) {
    default_view = true;
    settings = default_response.data.listUserViewSettings.items[0];
    settings.saved_views = JSON.parse(settings.saved_views);
    if (items.length) settings.id = items[0].id;
  } else {
    settings = items[0];
    var user_saved_views = JSON.parse(settings.saved_views);
    const default_views = JSON.parse(
      default_response.data.listUserViewSettings.items[0].saved_views
    );
    settings.saved_views = JSON.parse(settings.saved_views);
    settings.saved_views.DevicesTable.push(default_views.DevicesTable[0]);
    settings.saved_views.AlertsTable.push(default_views.AlertsTable[0]);
    const component_names = Object.keys(default_views);
    component_names.forEach((name) => {
      default_views[name].forEach((view) => {
        if (view.is_default === true || view.is_default === 1) {
          if (user_saved_views[name]) {
            user_saved_views[name].push(view);
          } else {
            user_saved_views[name] = [];
            user_saved_views[name].push(view);
          }
        }
      });
    });
    settings.saved_views = user_saved_views;
  }

  if (!IS_EMBEDDED) {
    try {
      // Always load user's dashboard settings and not the ones off the default.
      settings["dashboard_settings"] = items[0]["dashboard_settings"];
      // Also make a copy of last saved settings so that we know whether
      // to disable the save button or not.
      settings["saved_dashboard_settings"] = items[0]["dashboard_settings"];
    } catch {
      console.log("No Dashoard settings");
    }
  }

  return dispatch({
    type: USER_SETTINGS_RECEIVE,
    payload: settings,
    meta: { default_view: default_view, view_id: view_id },
  });
};

// const listUserViewSettings = () => async (dispatch) => {};

export const createUserSettings = (payload) => async (dispatch) => {
  await apiQuery("POST", "/views", payload)
    .then((res) =>
      res.status_code === 200
        ? dispatch(switchUserView(payload, payload.filters, payload.user_id))
        : console.log("no create")
    )
    .catch((e) => console.log(e));
};

export const updateUserSettings = (payload) => async (dispatch) => {
  payload.name = payload.view_name;
  await apiQuery("PUT", "/views", payload)
    .then((res) => {
      if (res.status_code === 200) {
        dispatch(switchUserView(payload, payload.filters, payload.user_id));
      } else console.log("no update");
    })
    .catch((e) => console.log(e));
};

export const deleteUserSettings = (payload) => async (dispatch) => {
  await apiQuery("DELETE", "/views", payload)
    .then(async (res) => {
      if (res.status_code === 200) await dispatch(getUserSettings());
      else console.log("no delete");
    })
    .catch((e) => console.log(e));
};

export const switchUserView = (view, filters, userId) => async (dispatch) => {
  // Only need to do this sometimes.
  if (view && filters && userId) {
    await apiQuery("PUT", "/views/preferences", { view, userId: userId });
  }
  await dispatch(getUserSettings(view.name)).then(() => {
    dispatch({
      type: "USER_VIEW_UPDATED",
      payload: view,
      meta: filters,
    });
  });
};

export const createAuditLog = (message) => (dispatch) => {
  return apiQuery("POST", "/auditlogs", { message: message })
    .then((res) =>
      dispatch({ type: "AUDIT_LOG_SUCCESS", payload: res.status_code })
    )
    .catch((e) => {
      console.log(e);
      dispatch({ type: "AUDIT_LOG_FAILURE" });
    });
};

export const updateDashboardSettings = (data) => (dispatch) => {
  dispatch({
    type: "UPDATE_DASHBOARD_PREFERENCES",
    payload: {
      data,
    },
  });
};

export const saveDashboardSettings = (data, user_id) => (dispatch) => {
  dispatch({
    type: "SAVE_DASHBOARD_PREFERENCES",
    payload: {
      data,
      user_id,
    },
  });
};

export const initGetPortalConfig = () => async (dispatch) => {
  await fetch(
    `https://s3.eu-west-2.amazonaws.com/${PREFIX}.logic-one.io/brands/config.json`
  ).then((data) => {
    data.text().then((text) => {
      dispatch({
        type: "PORTAL_CONFIGURATION",
        payload: JSON.parse(text),
      });
    });
  });
};
