/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createJobHistory = /* GraphQL */ `
  mutation CreateJobHistory($input: CreateJobHistoryInput!) {
    createJobHistory(input: $input) {
      id
      created
      dashGroupId
      devGroupId
      account_id
      output
      params
      email
      type
    }
  }
`;
export const updateJobHistory = /* GraphQL */ `
  mutation UpdateJobHistory($input: UpdateJobHistoryInput!) {
    updateJobHistory(input: $input) {
      id
      created
      dashGroupId
      devGroupId
      account_id
      output
      params
      email
      type
    }
  }
`;
export const deleteJobHistory = /* GraphQL */ `
  mutation DeleteJobHistory($input: DeleteJobHistoryInput!) {
    deleteJobHistory(input: $input) {
      id
      created
      dashGroupId
      devGroupId
      account_id
      output
      params
      email
      type
    }
  }
`;
export const createViewRoles = /* GraphQL */ `
  mutation CreateViewRoles($input: CreateViewRolesInput!) {
    createViewRoles(input: $input) {
      id
      Component
      Permissions
    }
  }
`;
export const updateViewRoles = /* GraphQL */ `
  mutation UpdateViewRoles($input: UpdateViewRolesInput!) {
    updateViewRoles(input: $input) {
      id
      Component
      Permissions
    }
  }
`;
export const deleteViewRoles = /* GraphQL */ `
  mutation DeleteViewRoles($input: DeleteViewRolesInput!) {
    deleteViewRoles(input: $input) {
      id
      Component
      Permissions
    }
  }
`;
export const createUserViewSettings = /* GraphQL */ `
  mutation CreateUserViewSettings($input: CreateUserViewSettingsInput!) {
    createUserViewSettings(input: $input) {
      id
      cognito_user_id
      component
      config
      view_name
      saved_views
      dashboard_settings
      selected_views
    }
  }
`;
export const updateUserViewSettings = /* GraphQL */ `
  mutation UpdateUserViewSettings($input: UpdateUserViewSettingsInput!) {
    updateUserViewSettings(input: $input) {
      id
      cognito_user_id
      component
      config
      view_name
      saved_views
      dashboard_settings
      selected_views
    }
  }
`;
export const deleteUserViewSettings = /* GraphQL */ `
  mutation DeleteUserViewSettings($input: DeleteUserViewSettingsInput!) {
    deleteUserViewSettings(input: $input) {
      id
      cognito_user_id
      component
      config
      view_name
      saved_views
      dashboard_settings
      selected_views
    }
  }
`;
export const createDevices = /* GraphQL */ `
  mutation CreateDevices($input: CreateDevicesInput!) {
    createDevices(input: $input) {
      id
      account_id
      system
      manual
      custom
      timestamp
    }
  }
`;
export const updateDevices = /* GraphQL */ `
  mutation UpdateDevices($input: UpdateDevicesInput!) {
    updateDevices(input: $input) {
      id
      account_id
      system
      manual
      custom
      timestamp
    }
  }
`;
export const deleteDevices = /* GraphQL */ `
  mutation DeleteDevices($input: DeleteDevicesInput!) {
    deleteDevices(input: $input) {
      id
      account_id
      system
      manual
      custom
      timestamp
    }
  }
`;
export const createReports = /* GraphQL */ `
  mutation CreateReports($input: CreateReportsInput!) {
    createReports(input: $input) {
      id
      account_id
      report_id
      results
      timestamp
      custom_columns
    }
  }
`;
export const updateReports = /* GraphQL */ `
  mutation UpdateReports($input: UpdateReportsInput!) {
    updateReports(input: $input) {
      id
      account_id
      report_id
      results
      timestamp
      custom_columns
    }
  }
`;
export const deleteReports = /* GraphQL */ `
  mutation DeleteReports($input: DeleteReportsInput!) {
    deleteReports(input: $input) {
      id
      account_id
      report_id
      results
      timestamp
      custom_columns
    }
  }
`;
export const createAutomation = /* GraphQL */ `
  mutation CreateAutomation($input: CreateAutomationInput!) {
    createAutomation(input: $input) {
      id
      job
      timestamp
      status_code
      message
      user
      params
    }
  }
`;
export const updateAutomation = /* GraphQL */ `
  mutation UpdateAutomation($input: UpdateAutomationInput!) {
    updateAutomation(input: $input) {
      id
      job
      timestamp
      status_code
      message
      user
      params
    }
  }
`;
export const deleteAutomation = /* GraphQL */ `
  mutation DeleteAutomation($input: DeleteAutomationInput!) {
    deleteAutomation(input: $input) {
      id
      job
      timestamp
      status_code
      message
      user
      params
    }
  }
`;
export const createBranding = /* GraphQL */ `
  mutation CreateBranding($input: CreateBrandingInput!) {
    createBranding(input: $input) {
      account_id
      name
      id
      themeColours
      logo_filename
      portal_logo_filename
      graphConfig
    }
  }
`;
export const updateBranding = /* GraphQL */ `
  mutation UpdateBranding($input: UpdateBrandingInput!) {
    updateBranding(input: $input) {
      account_id
      name
      id
      themeColours
      logo_filename
      portal_logo_filename
      graphConfig
    }
  }
`;
export const deleteBranding = /* GraphQL */ `
  mutation DeleteBranding($input: DeleteBrandingInput!) {
    deleteBranding(input: $input) {
      account_id
      name
      id
      themeColours
      logo_filename
      portal_logo_filename
      graphConfig
    }
  }
`;
export const createMinimalMonitoring = /* GraphQL */ `
  mutation CreateMinimalMonitoring($input: CreateMinimalMonitoringInput!) {
    createMinimalMonitoring(input: $input) {
      account_id
      id
      billing_reference
      dns_name
      timestamp
      issue
    }
  }
`;
export const updateMinimalMonitoring = /* GraphQL */ `
  mutation UpdateMinimalMonitoring($input: UpdateMinimalMonitoringInput!) {
    updateMinimalMonitoring(input: $input) {
      account_id
      id
      billing_reference
      dns_name
      timestamp
      issue
    }
  }
`;
export const deleteMinimalMonitoring = /* GraphQL */ `
  mutation DeleteMinimalMonitoring($input: DeleteMinimalMonitoringInput!) {
    deleteMinimalMonitoring(input: $input) {
      account_id
      id
      billing_reference
      dns_name
      timestamp
      issue
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      group_id
      id
      role
      Admin
      Alerts
      Automation
      Device
      Maintenance
      Masquerade
      Reports
      Services
      Settings
      Support
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      group_id
      id
      role
      Admin
      Alerts
      Automation
      Device
      Maintenance
      Masquerade
      Reports
      Services
      Settings
      Support
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      group_id
      id
      role
      Admin
      Alerts
      Automation
      Device
      Maintenance
      Masquerade
      Reports
      Services
      Settings
      Support
    }
  }
`;
