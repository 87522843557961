import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import API from "@aws-amplify/api";
import { useSelector } from "react-redux";
import { CAPABILITY_LEVELS, USER_POOL } from "../../constants";
import { apiQuery } from "../../libs/auth-config";
import PhoneNumberInput from "../PhoneNumberInput";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textfield: {
    width: theme.spacing(12),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  grid: {
    marginBottom: theme.spacing(1),
  },
  confirm: {
    width: theme.spacing(16),
  },
  dropdown: {
    width: theme.spacing(12),
    marginBottom: theme.spacing(2),
  },
}));

export default function UpdateUsers({
  state,
  userRole,
  users,
  callback,
  updateCallback,
}) {
  const [open, setOpen] = React.useState(state);
  const [updates, setUpdates] = React.useState(users);
  React.useEffect(() => {
    setUpdates(JSON.parse(JSON.stringify(users)));
  }, [users]);
  const [confirm, setConfirm] = React.useState("");
  const [running, setRunning] = React.useState(false);
  const classes = useStyles();
  const accountList = useSelector((state) => state.deviceGroups.accountGroups);

  const email = useSelector((state) => state.userSession.email) || "";
  const textfields = [
    {
      id: "firstName",
      label: "First Name",
      key: "given_name",
    },
    {
      id: "lastName",
      label: "Last Name",
      key: "family_name",
    },
  ];

  const permissions = useSelector((state) => state.roles.permissions);
  const canSeeAdmin = permissions.admin.roles <= CAPABILITY_LEVELS["manage"];

  let roles = useSelector((state) => state.roles.roles);
  if (!canSeeAdmin) {
    roles = roles.filter((r) => r.name !== "Admin");
  }

  React.useEffect(() => {
    setOpen(state);
  }, [state]);

  const handleClose = () => {
    callback();
    setOpen(false);
    setRunning(false);
    setConfirm("");
    setUpdates(JSON.parse(JSON.stringify(users)));
  };

  const submitUpdate = async () => {
    setRunning(true);
    let parsedUsers = [];
    updates.forEach((user) => {
      let usr = user;
      delete usr.sub;
      delete usr.email_verified;
      delete usr.phone_number_verified;
      delete usr.status;
      parsedUsers.push(usr);
    });

    var myInit = {
      userPoolId: USER_POOL,
      users: parsedUsers,
      user: email,
    };
    apiQuery("PUT", "/users", myInit)
      .then((response) => {
        updateCallback(response.status_code, response.message);
      })
      .catch((response) => console.log(response))
      .finally(() => {
        handleClose();
      });
  };

  const getLabel = (option) => {
    if (typeof option === "string") return option;
    else if (typeof option.name !== "undefined") return option.name;
    else return "";
  };

  const checkConfirm = () => {
    if (confirm.toLowerCase() === "confirm") return false;
    else return true;
  };

  const updateUserValue = (val, index, key) => {
    let tmp = [...updates];
    tmp[index][key] = val;
    setUpdates(tmp);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Update Users</DialogTitle>
        <DialogContent>
          <Typography color="primary" variant="h4" className={classes.section}>
            Please edit the attributes to update the user accounts.
          </Typography>
          {updates.map((usr, i) => {
            return (
              <Grid container spacing={2} className={classes.grid}>
                {textfields.map((field) => {
                  return (
                    <Grid key={field.id} item>
                      <TextField
                        id={field.id}
                        label={field.label}
                        variant="outlined"
                        size="small"
                        value={usr[field.key]}
                        className={classes.textfield}
                        onChange={(e) =>
                          updateUserValue(e.target.value, i, field.key)
                        }
                      />
                    </Grid>
                  );
                })}
                <Grid item>
                  <PhoneNumberInput
                    handlePhoneChange={(value) =>
                      updateUserValue("+" + value, i, "phone_number")
                    }
                    phone_number={usr["phone_number"]}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    options={accountList}
                    getOptionLabel={(option) => getLabel(option)}
                    value={usr["custom:account_id"]}
                    className={classes.textfield}
                    fullWidth
                    freeSolo
                    onChange={(_, v) => {
                      if (v)
                        updateUserValue(v.account_id, i, "custom:account_id");
                    }}
                    renderInput={(params) => (
                      <TextField
                        key={params.id}
                        {...params}
                        label="Default Account"
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item style={{ minWidth: 300 }}>
                  <Autocomplete
                    multiple
                    options={roles}
                    defaultValue={
                      usr && "custom:roles" in usr
                        ? usr["custom:roles"]
                            .split(",")
                            .filter((r) => r && r !== ",")
                            .map((role) => ({ name: role }))
                        : []
                    }
                    onChange={(_, v) =>
                      updateUserValue(
                        v.map((role) => role.name).join(","),
                        i,
                        "custom:roles"
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Role(s)"
                        fullWidth
                        placeholder=""
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            );
          })}
          <Box fontStyle="italic">
            <Typography
              color="secondary"
              variant="h5"
              className={classes.grid}
              component="div"
            >
              Please type 'confirm' in the box below.
            </Typography>
          </Box>
          <TextField
            autoFocus
            margin="dense"
            label="Update Confirmation"
            variant="outlined"
            onChange={(e) => setConfirm(e.target.value)}
            className={classes.confirm}
          />
        </DialogContent>
        <DialogActions>
          {running ? <CircularProgress /> : null}
          <Button
            onClick={submitUpdate}
            variant="contained"
            color="primary"
            disabled={checkConfirm() || running}
          >
            Update
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
