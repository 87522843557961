import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Box, Typography } from "@material-ui/core";
import LoadingButton from "../LoadingButton";
import { DEFAULT_ROLES } from "../../constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  text: {
    marginBottom: 15,
  },
}));

export default function RemovalConfirmation({
  open,
  loading,
  title,
  message,
  callBack,
  closesureCallback,
  children,
}) {
  const [state, setOpen] = React.useState(open);
  const [confirm, setConfirm] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    closesureCallback();
  };

  const submitRemoval = () => {
    setConfirm("");
    callBack();
  };

  let roleNameStart;
  let roleNameEnd;

  if (title === "Remove Role" && message) {
    roleNameStart = message.indexOf("'");
    roleNameEnd = message.indexOf("'", roleNameStart + 2);
  }

  const disableForDefaultRoles =
    title === "Remove Role" &&
    message &&
    DEFAULT_ROLES.some(
      (r) => message.slice(roleNameStart + 1, roleNameEnd) === r
    )
      ? true
      : false;

  return (
    <div>
      <Dialog
        open={state}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography color="primary" variant="h4" className={classes.text}>
            {message}
          </Typography>
          <Box fontStyle="italic">
            <Typography
              color="secondary"
              variant="h6"
              className={classes.text}
              component="div"
            >
              Please type 'confirm' in the box below.
            </Typography>
          </Box>
          <TextField
            autoFocus
            margin="dense"
            label="Removal Confirmation"
            fullWidth
            variant="outlined"
            onChange={(e) => setConfirm(e.target.value)}
            style={{ paddingBottom: "10px" }}
          />
          {children}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={submitRemoval}
            disabled={confirm !== "confirm" || disableForDefaultRoles}
            isLoading={loading}
            spinnerStyle={{
              height: "25px",
              width: "25px",
              marginRight: "9px",
            }}
          >
            Remove
          </LoadingButton>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
