import React, { useState, useEffect } from "react";
import CircularIntegration from "../CircularIntegration";
import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  div: {
    width: "100%",
    height: "79%",
  },
  grid: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  item: {
    width: "100%",
  },
}));

export default function RolesForm({
  data,
  saveConfig,
  deviceGroupOptions,
  dashboardGroupOptions,
  deviceGroupList,
  dashboardGroupList,
}) {
  const classes = styles();

  const [accountId, setAccountId] = useState(data?.accountId || "");
  const [customer, setCustomer] = useState(data?.customer || "");
  const [resellersResourceGroup, setResellersResourceGroup] = useState("");
  const [resellersDashboardGroup, setResellersDashboardGroup] = useState("");

  /*
   Only require inputs to save the form if device/dashboard
   group jobs are not being run.
  */
  const missingValues = () => {
    return (
      customer !== "" &&
      customer !== null &&
      (deviceGroupOptions !== undefined ||
        (resellersResourceGroup !== "" && resellersResourceGroup !== null)) &&
      (dashboardGroupOptions !== undefined ||
        (resellersDashboardGroup !== "" && resellersDashboardGroup !== null))
    );
  };

  useEffect(() => {
    // User cannot input data so set the form values manually.
    if (deviceGroupOptions !== undefined) {
      setResellersResourceGroup(deviceGroupOptions.customer);
    }
    if (dashboardGroupOptions !== undefined) {
      setResellersDashboardGroup(dashboardGroupOptions.customer);
    }
    if (deviceGroupOptions.customer) {
      setCustomer(deviceGroupOptions.customer);
    } else if (dashboardGroupOptions.customer) {
      setCustomer(dashboardGroupOptions.customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // need to set up action to get list of dashboard groups
  var deviceGroupField;
  if (deviceGroupOptions !== undefined) {
    // Disable field if we already know the device group.
    deviceGroupField = (
      <TextField
        key={1}
        disabled
        fullWidth
        style={{ width: "100%" }}
        value={deviceGroupOptions.customer}
        onChange={(e) => setResellersResourceGroup(e.target.value)}
        label="Device Group"
        variant="outlined"
        size="small"
      />
    );
  } else if (data !== undefined) {
    // If previously selected option then set it to the default.
    deviceGroupField = (
      <Autocomplete
        key={2}
        options={deviceGroupList}
        freeSolo
        defaultValue={data.deviceGroup}
        fullWidth
        onChange={(_, v) => setResellersResourceGroup(v)}
        renderInput={(params) => (
          <TextField
            key={params.key}
            {...params}
            label="Device Group"
            variant="outlined"
            size="small"
          />
        )}
      />
    );
  } else {
    // Default field, they need to enter value.
    deviceGroupField = (
      <Autocomplete
        key={2}
        options={data ? data.deviceGroup : deviceGroupList}
        freeSolo
        fullWidth
        onChange={(_, v) => setResellersResourceGroup(v)}
        renderInput={(params) => (
          <TextField
            key={params.key}
            {...params}
            label="Device Group"
            variant="outlined"
            size="small"
          />
        )}
      />
    );
  }

  var dashboardGroupField;
  if (dashboardGroupOptions !== undefined) {
    // Disable field if we already know the device group.
    dashboardGroupField = (
      <TextField
        key={2}
        disabled
        fullWidth
        style={{ width: "100%" }}
        value={dashboardGroupOptions.customer}
        onChange={(e) => setResellersDashboardGroup(e.target.value)}
        label="Dashboard Group"
        variant="outlined"
      />
    );
  } else {
    // Default field, they need to enter value.
    dashboardGroupField = (
      <Autocomplete
        key={2}
        options={data ? data.dashboardGroup : dashboardGroupList}
        freeSolo
        fullWidth
        style={{ width: "100%" }}
        onChange={(_, v) => setResellersDashboardGroup(v)}
        renderInput={(params) => (
          <TextField
            key={params.key}
            {...params}
            label="Dashboard Group"
            variant="outlined"
            size="small"
          />
        )}
      />
    );
  }

  const customerNameAlreadyFilledIn = deviceGroupOptions.customer
    ? deviceGroupOptions.customer
    : dashboardGroupOptions.customer
    ? dashboardGroupOptions.customer
    : false;

  return (
    <>
      <Typography variant="h4" className={classes.root}>
        Roles
      </Typography>
      <Typography variant="body" className={classes.root}>
        Create role to grant customer permission to view groups and respond to
        alerts.
      </Typography>
      <div className={classes.div}>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item xs={6} sm={6} md={4} lg={7} className={classes.item}>
            <TextField
              label="Customer"
              variant="outlined"
              fullWidth
              disabled={customerNameAlreadyFilledIn}
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={7} className={classes.item}>
            <TextField
              label="Account ID"
              variant="outlined"
              fullWidth
              disabled={data !== undefined}
              value={accountId}
              onChange={(e) => setAccountId(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={7} className={classes.item}>
            {deviceGroupField}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={7} className={classes.item}>
            {dashboardGroupField}
          </Grid>
        </Grid>
      </div>
      <CircularIntegration
        style={{ marginLeft: "auto" }}
        text={"Save"}
        disabled={!missingValues()}
        onClick={() =>
          saveConfig({
            customer,
            accountId,
            resellersResourceGroup,
            resellersDashboardGroup,
          })
        }
      />
    </>
  );
}
