import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Box, CardHeader, Grid, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import { saveToLS } from "../libs/utils";

const useStyles = makeStyles(() => ({
  card: {
    // marginBottom: "20px",
    // display: 'flex',
    // alignItems: 'center'
  },
  title: {
    textAlign: "left",
    //"border-color": "red",
    "border-left": "10px solid",
    //"border": "3px solid",
    //"display": "inline-block",
    maxWidth: "250px",
    alignItems: "left",
  },
  tileHeader: {
    marginLeft: "0px",
    textAlign: "left",
    fontWeight: "bold",
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    wordBreak: "break-all",
    marginBottom: "-5px",
    lineClamp: "1",
  },
  tileHeaderDetailsOff: {
    marginLeft: "0px",
    textAlign: "left",
    fontWeight: "bold",
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    wordBreak: "break-word",
    marginBottom: "-5px",
    lineClamp: "3",
  },
}));

export default function SummaryTile({
  tile,
  noicon,
  tileSize,
  textSize,
  details,
  convertTileSize,
  groupBy,
}) {
  const classes = useStyles();

  const tileHeight = convertTileSize(tileSize, "height");
  const tileWidth = convertTileSize(tileSize, "width");
  const bodySize = convertTileSize(textSize, "bodySize");
  const titleSize = convertTileSize(textSize, "titleSize");

  // Split the identifier string into k,v pairs.
  let values = tile.title.split("+"); // delimited by + symbol.
  let gBy;
  if (typeof groupBy === "string") {
    gBy = [groupBy];
  } else {
    gBy = groupBy;
  }
  let groupingCriteria = {};
  gBy.forEach((field, index) => (groupingCriteria[field] = values[index]));

  return (
    <Grid
      item
      style={{
        flexGrow: 1,
        flexShrink: 0,
        maxWidth: `${tileWidth}px`,
      }}
    >
      <Link
        style={{
          textDecoration: "none",
        }}
        to={"/services"}
        onClick={() => {
          saveToLS("search_term", "");
          saveToLS("Filter", tile.title);
          saveToLS("groupBy", gBy.join("+"));
        }}
      >
        <Card
          display="static"
          elevation={3}
          onClick={() => tile.handleClick && tile.handleClick()}
          className={classes.title}
          fullWidth
          style={{
            height: "100%",
            minHeight: `${tileHeight}px`,
            width: "500px",
            maxWidth: `${tileWidth}px`,
            borderColor: tile.borderColor,
          }}
        >
          <CardHeader
            //className={classes.tmp}
            //action={<AlertsIcon needsColour status={tile.status} />}
            title={
              <Box>
                {Object.keys(groupingCriteria).map((k) =>
                  groupingCriteria[k] ? (
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          {k}: {groupingCriteria[k]}
                        </Typography>
                      }
                    >
                      <Typography
                        className={
                          details
                            ? classes.tileHeader
                            : classes.tileHeaderDetailsOff
                        }
                        style={{
                          fontSize: `${titleSize}rem`,
                        }}
                      >
                        {groupingCriteria[k]}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <></>
                  )
                )}
              </Box>
            }
            style={{ margin: "-10px", display: "flex" }}
          />

          <Grid
            container
            direction="row"
            style={{ paddingBottom: 8, paddingLeft: 6 }}
          >
            {Object.entries(tile.info).map((field) => (
              <Grid item>
                <Typography
                  color="textSecondary"
                  style={{
                    marginRight: "3px",
                    fontSize: `${bodySize}rem`,
                  }}
                >
                  {`${field[0]}: ${field[1]}`}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Link>
    </Grid>
  );
}
