import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import LoadingButton from "../LoadingButton";

export default function SaveUserView({
  state,
  defaultView,
  viewName,
  handleSave,
  handleClose,
  userViewIsSaving,
}) {
  const [name, setName] = React.useState(defaultView ? "" : viewName);
  useEffect(() => {
    if (defaultView) setName("");
    else setName(viewName);
  }, [defaultView, viewName, state]);

  return (
    <Dialog
      open={state}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">Create or Update View</DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 20 }} variant="body2">
          {defaultView
            ? "Enter the name of the view you would like to save."
            : `Enter a new view name to create a new view, otherwise update the current '${viewName}' view.`}
        </Typography>
        <TextField
          id="view_name_tf"
          label="View name"
          variant="outlined"
          size="small"
          value={name}
          onChange={(event) => setName(event.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          isLoading={userViewIsSaving}
          onClick={() => handleSave(name)}
          spinnerStyle={{ height: "25px", width: "25px", marginRight: "8px" }}
        >
          {name === viewName && !defaultView ? "Update" : "Create"}
        </LoadingButton>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
