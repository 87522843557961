import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import DashboardIcon from "@material-ui/icons/Dashboard";
import StorageIcon from "@material-ui/icons/Storage";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { apiQuery } from "../../libs/auth-config";

const styles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  formControl: {
    width: 300,
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
  },
};

const additionOptions = ["Device Groups", "Dashboard Groups", "Website Groups"];

export default function FullConfiguration({ callback }) {
  const classes = styles();
  const theme = useTheme();

  const [portal, setPortal] = React.useState("splc");
  const [customer, setCustomer] = React.useState(null);
  const [additions, setAdditions] = React.useState([]);
  const [layoutOpen, setLayoutOpen] = React.useState(false);
  const [layout, setLayout] = React.useState("standard");
  const [rFullPath, setRPath] = React.useState("");
  const [dFullPath, setDPath] = React.useState("");

  var tmp = [];
  for (var i = 0; i < additionOptions.length; i++) {
    var obj = { value: additionOptions[i], label: additionOptions[i] };
    tmp.push(obj);
  }

  const checkValuesAreChanged = () => {
    if (customer !== null && customer !== "" && additions.length > 0) {
      if (layout === "standard") return false;
      else {
        if (rFullPath.length > 0 && dFullPath.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  const runJob = () => {
    const data = {
      account: portal,
      customer: customer,
      additions: additions,
      layout: layout,
      devPath: rFullPath,
      dashPath: dFullPath,
    };

    apiQuery("POST", "/fullConfiguration", data)
      .then((resp) => callback(resp))
      .catch((e) => console.log(e));

    return data;
    // fire to api
  };

  return (
    <Box height={window.screen.height - 268}>
      <Card>
        <CardContent classes={{ root: classes.root }}>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="panel" focused>
                LogicMonitor Panel
              </InputLabel>
              <Select
                startAdornment={
                  <InputAdornment position="start">
                    <DesktopWindowsIcon />
                  </InputAdornment>
                }
                labelId="panel"
                id="panel"
                value={portal}
                onChange={(e) => setPortal(e.target.value)}
                labelWidth={125}
              >
                <MenuItem value={"sandy"}>Sandy</MenuItem>
                <MenuItem value={"dws"}>DWS</MenuItem>
                <MenuItem value={"splc"}>SPLC</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="customer" focused>
                Customer Name
              </InputLabel>
              <OutlinedInput
                id="customer"
                labelId="customer"
                value={customer}
                required
                onChange={(e) => setCustomer(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                }
                labelWidth={103}
                multiline
              />
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="additions" focused>
                What Would You Like to Add?
              </InputLabel>
              <Select
                labelId="additions"
                labelWidth={185}
                onChange={(e) => setAdditions(e.target.value)}
                multiple
                value={additions}
                startAdornment={
                  <InputAdornment position="start">
                    <AddToPhotosIcon />
                  </InputAdornment>
                }
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {additionOptions.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    style={getStyles(item, additions, theme)}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="layout" focused>
                Choose Layout
              </InputLabel>
              <Select
                labelId="layout"
                open={layoutOpen}
                required
                onOpen={() => setLayoutOpen(true)}
                onClose={() => setLayoutOpen(false)}
                value={layout}
                onChange={(e) => setLayout(e.target.value)}
                labelWidth={100}
                startAdornment={
                  <InputAdornment position="start">
                    <DashboardIcon />
                  </InputAdornment>
                }
              >
                <MenuItem value={"standard"}>Standard</MenuItem>
                <MenuItem value={"custom"}>Custom</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {layout === "custom" ? (
            <React.Fragment>
              <Box textAlign="center">
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="rPath" focused>
                    Full Path for Device Folder Creation
                  </InputLabel>
                  <OutlinedInput
                    labelId="rPath"
                    value={rFullPath}
                    required
                    onChange={(e) => setRPath(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <StorageIcon />
                      </InputAdornment>
                    }
                    labelWidth={222}
                    multiline
                    placeholder="FOLDER1/FOLDER2"
                  />
                </FormControl>
              </Box>
              <Box textAlign="center">
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="dPath" focused>
                    Full Path for Dashboard Folder Creation
                  </InputLabel>
                  <OutlinedInput
                    labelId="dPath"
                    value={dFullPath}
                    required
                    onChange={(e) => setDPath(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <AssessmentIcon />
                      </InputAdornment>
                    }
                    labelWidth={248}
                    multiline
                    placeholder="FOLDER1/FOLDER2"
                  />
                </FormControl>
              </Box>
            </React.Fragment>
          ) : null}
          <Box textAlign="center">
            <Button
              className={classes.formControl}
              color="primary"
              variant="contained"
              disabled={checkValuesAreChanged()}
              onClick={runJob}
            >
              Run Job
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
