import React, { Component } from "react";
import { connect } from "react-redux";
import { apiQuery } from "../libs/auth-config";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { sub } from "date-fns";
import CustomDatatable from "../components/CustomDatatable";
import ContainerHeader from "../components/ContainerHeader";
import { IconButton, Tooltip } from "@material-ui/core";
import TimeRangeDialog from "../components/dialogs/TimeRangeDialog";
import { epochToTimestamp } from "../libs/utils";
import TuneIcon from "@material-ui/icons/Tune";
import RefreshIcon from "@material-ui/icons/Refresh";

const menuItems = [
  "Today",
  "24 Hours",
  "Week",
  "30 Days",
  "This Calendar Month",
  "Last Calendar Month",
  "Year",
  "Custom",
];

const columns = {
  timestamp: {
    label: "Timestamp",
    sort: true,
    customBodyRender: (val) => epochToTimestamp(val),
  },
  user: {
    label: "User",
    sort: false,
  },
  message: {
    label: "Information",
    sort: false,
  },
};

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "",
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    paddingRight: "50px",
    alignItems: "center",
    top: "30px",
  },
  dateSelection: {
    position: "relative",
  },
  dateGap: {
    marginBottom: 10,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  singleDropdown: {
    width: "500px",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttons: {
    width: 120,
  },
});

class AuditLogsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      dateType: "24_Hours",
      startDate: sub(new Date(), { days: 1 }),
      endDate: new Date(),
      dialogVisible: false,
    };
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  getAuditLogs(start, end) {
    var startEpoch;
    var endEpoch;
    if (start === undefined && end === undefined) {
      endEpoch = Math.trunc(new Date().getTime() / 1000);
      startEpoch = Math.trunc(sub(new Date(), { days: 1 }).getTime() / 1000);
    } else {
      startEpoch = Math.trunc(start.getTime() / 1000);
      endEpoch = Math.trunc(end.getTime() / 1000);
    }
    this.setState({ loading: true, data: [] });

    apiQuery("GET", "/auditlogs?start=" + startEpoch + "&end=" + endEpoch, "")
      .then((response) => {
        try {
          this.setState({ data: response.data });
        } catch {
          console.log("FAILED FIND ME");
        }
      })
      .catch((e) => console.log("Unexpected Error: \n" + e))
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.props.setParentTitle("Audit Logs");
    this.getAuditLogs();
  }

  CustomToolbar = () => (
    <>
      <Tooltip disableFocusListener title="Options">
        <IconButton
          color="primary"
          onClick={() => this.setState({ dialogVisible: true })}
        >
          <TuneIcon />
        </IconButton>
      </Tooltip>
      <Tooltip disableFocusListener title="Refresh">
        <IconButton
          color="primary"
          onClick={() =>
            this.getAuditLogs(
              this.state.startDate,
              this.state.dateType !== "Custom" ? new Date() : this.state.endDate
            )
          }
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  callbackClose = (refresh) => {
    if (refresh) {
      this.getAuditLogs(
        this.state.startDate,
        this.state.dateType !== "Custom" ? new Date() : this.state.endDate
      );
    }
    this.setState({ dialogVisible: false });
  };

  render() {
    const { dialogVisible, data, loading, startDate, endDate, dateType } =
      this.state;
    return (
      <>
        <TimeRangeDialog
          visible={dialogVisible}
          callbackClose={this.callbackClose}
          //callbackClose={() => this.setState({ dialogVisible: false })}
          setStartDate={(date) => this.setState({ startDate: date })}
          setEndDate={(date) => this.setState({ endDate: date })}
          setDateType={(date) => this.setState({ dateType: date })}
          startDate={startDate}
          endDate={endDate}
          menuItems={menuItems}
          disabled={false}
          extraConfig={false}
          dialogTitle={"Audit Log Time Range"}
          timeRangeDisabled={false}
        />
        <ContainerHeader title={"Audit Logs"} />
        <CustomDatatable
          viewComponent={"AuditLogsTable"}
          customPage
          propMap={columns}
          rows={data}
          loading={loading}
          sortProp={"timestamp"}
          download={"Audit Log - " + dateType.replaceAll("_", " ")}
          customToolbar={this.CustomToolbar()}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const enhance = compose(withStyles(styles), connect(mapStateToProps));
export default enhance(AuditLogsContainer);
