import React from "react";
import EmailIcon from "@material-ui/icons/Email";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import WarningIcon from "@material-ui/icons/Warning";
import { useTheme } from "@material-ui/styles";

export default function AlertMethodIcon({ type, needsColour }) {
  const theme = useTheme();
  let themedColour = theme.palette.action.active;
  var icon, colour;
  if (type === "EMAIL") {
    colour = needsColour ? (colour = "red") : (colour = themedColour);
    icon = <EmailIcon style={{ color: colour }} />;
  } else if (type === "SMS") {
    colour = needsColour ? (colour = "red") : (colour = themedColour);
    icon = <WarningIcon style={{ color: colour }} />;
  } else if (type === "PAGER") {
    colour = needsColour ? (colour = "#fc0") : (colour = themedColour);
    icon = <WarningIcon style={{ color: colour }} />;
  } else {
    colour = needsColour ? (colour = "green") : (colour = themedColour);
    icon = <DoneOutlineIcon style={{ color: colour }} />;
  }
  return icon;
}
