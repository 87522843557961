import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { HAS_RESELLERS } from "../constants";
import { CAPABILITY_LEVELS, IS_EMBEDDED } from "../constants";

let defaultPermissions = {
  alerts: CAPABILITY_LEVELS["none"],
  customProperties: CAPABILITY_LEVELS["none"],
  diagnostics: CAPABILITY_LEVELS["none"],
  metrics: CAPABILITY_LEVELS["none"],
  raiseTicket: CAPABILITY_LEVELS["none"],
  system: CAPABILITY_LEVELS["none"],
  systemProperties: CAPABILITY_LEVELS["none"],
};

const useRelevantPermissions = () => {
  /*
    Filter permissions for the device group that user is current viewing.
  */
  const deviceId = parseInt(useParams()?.id);

  const Device = useSelector((state) => state.devices.byId[deviceId]);

  const permissions = useSelector((state) => state.roles.permissions);

  const accountGroups = useSelector(
    (state) => state.deviceGroups.accountGroups
  );

  const rootFolderId = useSelector(
    (state) => state.ui_settings.portal_config.rootFolderId
  );

  if (!deviceId || !accountGroups.length) {
    return false;
  }

  // Embedded has the same permissions for every device and also doesn't
  // fetch subgroup data presently.
  if (IS_EMBEDDED) {
    return {
      ...permissions,
      services: { ...permissions.services[0], id: deviceId },
    };
  }

  let { Reseller, parentId } = Device;

  let resellerGroup = accountGroups.find((group) => group.name === Reseller);

  let servicesPermissions = permissions.services;

  let rootPerms = servicesPermissions.find(
    (permission) => permission.id === rootFolderId
  );
  let resellerPerms = HAS_RESELLERS
    ? servicesPermissions.find(
        (permission) => permission.id === resellerGroup.id
      )
    : null;
  let customerPerms = servicesPermissions.find(
    (permission) => permission.id === parentId
  );

  let servicesPerms = rootPerms
    ? rootPerms
    : resellerPerms
    ? resellerPerms
    : customerPerms
    ? customerPerms
    : defaultPermissions;

  return { ...permissions, services: servicesPerms };
};

export default useRelevantPermissions;
