import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button, Card } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Avatar from "@material-ui/core/Avatar";
import Auth from "@aws-amplify/auth";
import { USER_POOL } from "../constants";
import { Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import "react-phone-input-2/lib/material.css";
import { apiQuery } from "../libs/auth-config";
import PhoneNumberInput from "../components/PhoneNumberInput";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // float: "left",
      // margin: theme.spacing(1),
      // width: "70%",
    },
    padding: theme.spacing(1),
    paddingTop: 0,
  },
  // button: {
  //   marginTop: theme.spacing(1),
  //   width: "25ch",
  //   fontSize: "20px",
  //   float: "left",
  // },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "50px",
  },
  countryList: {
    ...theme.typography.body1,
  },
}));

export default function UserAccountSettings() {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [update, setUpdate] = useState({
    given_name: "",
    family_name: "",
    phone_number: "",
  });
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [updateResult, setUpdateResult] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handlePhoneChange = (value) => {
    setUpdate({ ...update, phone_number: value });
    validatePhone({ name: "phone_number", value: value });
  };

  const handleChange = (event) => {
    if (event.target === undefined) return;
    const name = event.target.name;
    const value = event.target.value;
    setUpdate({ ...update, [name]: value });
    checkDisabled(event.target);
  };

  const updateUserInfo = () => {
    setLoading(true);
    var myInit = {
      user: user.email,
      userPoolId: USER_POOL,
      users: [
        {
          "custom:account_id": user["custom:account_id"],
          "custom:role": user["custom:role"],
          "custom:superuser": user["custom:superuser"],
          email: user.email,
          family_name: update.family_name,
          given_name: update.given_name,
          phone_number: "+" + update.phone_number,
        },
      ],
    };
    apiQuery("PUT", "/users", myInit).then((response) => {
      setUpdateResult([response.status_code, response.message]);
      setOpen(true);
      setDisabled(true);
      setLoading(false);
      Auth.currentUserInfo().then((user) => {
        setUser(user.attributes);
      });
    });
  };

  useEffect(() => {
    Auth.currentUserInfo().then((user) => {
      setUser(user.attributes);
      setUpdate({
        given_name: user.attributes.given_name,
        family_name: user.attributes.family_name,
        phone_number: user.attributes.phone_number.replace(/\+/g, ""),
      });
      setLoading(false);
    });
  }, []);

  const validatePhone = (target) => {
    user[target.name] === "+" + target.value || target.value.length < 11
      ? setDisabled(true)
      : setDisabled(false);
  };

  const checkDisabled = (target) => {
    user[target.name] === target.value || target.value === ""
      ? setDisabled(true)
      : setDisabled(false);
  };

  const checkSeverity = () => {
    var isError = "";
    updateResult[0] === 200 ? (isError = "success") : (isError = "error");
    return isError;
  };

  return (
    <div>
      <Avatar
        alt={user.given_name}
        src="/broken-image.jpg"
        className={classes.avatar}
      >
        {user.given_name &&
          user.given_name.charAt(0) + user.family_name.charAt(0)}
      </Avatar>
      <h1 style={{ textAlign: "center" }}>
        Welcome, {user.given_name} {user.family_name}
      </h1>
      <h2 style={{ textAlign: "center" }}>{user.email}</h2>
      <br />
      <Card>
        <form className={classes.root} noValidate autoComplete="off">
          <h2>Basic Info</h2>
          {/*}  <h2>First Name - {user.given_name}</h2> */}
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <TextField
                name="given_name"
                onChange={handleChange}
                id="given_name_tf"
                label="Given Name(s)"
                value={update.given_name}
                variant="outlined"
                size="small"
                fullWidth
                helperText={
                  update.given_name.length < 1 && "Given name cannot be blank."
                }
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>

            <Grid item xs={8}>
              {/* }<h2>Last Name - {user.family_name}</h2> */}
              <TextField
                onChange={handleChange}
                name="family_name"
                id="family_name_tf"
                label="Family Name"
                value={update.family_name || ""}
                variant="outlined"
                size="small"
                fullWidth
                helperText={
                  update.family_name.length < 1 &&
                  "Family name cannot be blank."
                }
              />
            </Grid>
            <Grid item xs={2}></Grid>

            <Grid item xs={2}></Grid>

            <Grid item xs={8}>
              <PhoneNumberInput
                style={{ width: "100%" }}
                phone_number={update.phone_number}
                handlePhoneChange={handlePhoneChange}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={updateUserInfo}
                disabled={disabled}
                startIcon={<SaveIcon />}
              >
                Update
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          {loading && <CircularProgress color="primary" />}
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={checkSeverity()}>
              {updateResult[updateResult.length - 1]}
            </Alert>
          </Snackbar>
        </form>
      </Card>
    </div>
  );
}
