import { CircularProgress, Grid } from "@material-ui/core";
import FilterRow from "./FilterRow";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  filterPropertiesForAutocomplete,
  filterAddOperator,
  deleteFilter,
  filterEditor,
} from "../libs/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
    width: 400,
    minWidth: 400,
  },
  popover: {
    marginTop: 20,
  },
  operator: {
    width: "168px",
  },
  additional_props: {
    "& .MuiAutocomplete-endAdornment": {
      right: 0,
    },
  },
}));

const ReportFilterSettings = ({ reportFilters, setReportFilters }) => {
  const classes = useStyles();

  const filterProps = filterPropertiesForAutocomplete(
    useSelector((state) => state.devices.deviceProperties.perProp)
  );

  // Its still loading probably.
  if (!reportFilters) {
    return <CircularProgress />;
  }

  return (
    <Grid
      container
      className={"test"}
      alignItems="center"
      direction="column"
      spacing={1}
    >
      {reportFilters.map((filter, position) => (
        <Grid item>
          {Array.isArray(filter) ? (
            filter.map((statement, arrayPosition) => (
              <FilterRow
                classes={classes}
                filterProps={filterProps}
                info={statement}
                position={position}
                arrayPosition={arrayPosition}
                filterEditor={(item, value, position, arrayPosition) =>
                  setReportFilters(
                    filterEditor(
                      reportFilters,
                      item,
                      value,
                      position,
                      arrayPosition
                    )
                  )
                }
                deleteFilter={(verb, position) =>
                  setReportFilters(deleteFilter(reportFilters, verb, position))
                }
                filterAddOperator={(verb, position) =>
                  setReportFilters(
                    filterAddOperator(reportFilters, verb, position)
                  )
                }
              />
            ))
          ) : (
            <FilterRow
              classes={classes}
              info={filter}
              filterProps={filterProps}
              position={position}
              arrayPosition={null}
              filterEditor={(item, value, position, arrayPosition) =>
                setReportFilters(
                  filterEditor(
                    reportFilters,
                    item,
                    value,
                    position,
                    arrayPosition
                  )
                )
              }
              deleteFilter={(verb, position) =>
                setReportFilters(deleteFilter(reportFilters, verb, position))
              }
              filterAddOperator={(verb, position) =>
                setReportFilters(
                  filterAddOperator(reportFilters, verb, position)
                )
              }
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default ReportFilterSettings;
