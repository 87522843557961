export const APIURL = process.env.REACT_APP_API_HOSTNAME;
export const IS_EMBEDDED = process.env.REACT_APP_IS_EMBEDDED;
export const THEME = process.env.REACT_APP_THEME;
export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const GATEWAY_KEY = process.env.REACT_APP_GATEWAY_KEY;
export const USER_POOL = process.env.REACT_APP_USER_POOL_ID;
export const PREFIX = process.env.REACT_APP_PREFIX;
export const HAS_RESELLERS =
  process.env.REACT_APP_HAS_RESELLERS === "true" ? true : false;
export const STAGE = process.env.REACT_APP_STAGE || "testing";
export const APPSYNC_URL = process.env.REACT_APP_APPSYNC_URL;
export const JOBS = [
  "Device Group",
  "Dashboard Group",
  "Website Group",
  "Report Group",
  "Dashboards",
  "Roles",
  "User",
];
export const WARNING_COLOR = "#fc0";
export const ERROR_COLOR = "#ff8c00";
export const CRITICAL_COLOR = "red";
export const LIVE_COLOR = "green";

export const CAPABILITY_LEVELS = {
  manage: 1,
  acknowledge: 2,
  view: 3,
  none: 4,
};

export const DEFAULT_ROLES = ["Admin", "Embedded"];
