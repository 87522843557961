import React from "react";
import {
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Typography,
} from "@material-ui/core";
import ifTypes from "../data/ifTypes.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export default class SnmpInterfaceTable extends React.Component {
  formatFieldNames = (k, v) => {
    /* Convert OID values to human-readable information. */
    if (k === "Status") {
      switch (v) {
        case "1":
          return "Up (1)";
        case "2":
          return "Down (2)";
        case "3":
          return "Testing (3)";
        case "4":
          return "Unknown (4)";
        case "5":
          return "Dormant (4)";
        case "6":
          return "Not Present (6)";
        case "7":
          return "Lower-Layer Down (7)";
        default:
          return `Unknown (${v})`;
      }
    }

    // Use giant lookup table in file to find out the type.
    if (k === "Type") {
      if (v in Object.keys(ifTypes)) {
        return ifTypes[v];
      }
    }

    // Alot of stuff has no alias, so just return N/A instead.
    if (k === "Alias") {
      switch (v) {
        case "":
          return "N/A";
        default:
          return v;
      }
    }

    return v;
  };

  renderRows() {
    if (this.props.errorFetchingData) {
      return (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell colSpan={5} style={{ alignContent: "center" }}>
            <div className={this.props.classes.interfaceTableErrorContainer}>
              <ErrorOutlineIcon
                color="error"
                style={{ marginRight: "5px", verticalAlign: "middle" }}
              />
              <Typography
                align="center"
                variant="string"
                style={{ verticalAlign: "middle" }}
              >
                Could not gather interface data, please try again.
              </Typography>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    // Show spinner if data is still loading.
    if (this.props.interfaces.length === 0) {
      return (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <Typography
              variant="span"
              style={{ display: "inline-block", marginTop: "10px" }}
            >
              <CircularProgress style={{ color: "#67246A" }} />
            </Typography>
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      );
    }

    return this.props.interfaces.map((i, index) => (
      <TableRow
        hover
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        {Object.keys(i).map((field) => {
          return (
            <TableCell
              align="center"
              className={this.props.classes.snmpTableCell}
            >
              <Typography variant="p">
                {this.formatFieldNames(field, i[field])}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
    ));
  }

  render() {
    return (
      <>
        <Paper elevation={2}>
          <TableContainer className={this.props.classes.snmpTableHeader}>
            <Table stickyHeader style={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow>
                  {["Interface", "Name", "Type", "Status", "Alias"].map((k) => {
                    return (
                      <TableCell
                        className={this.props.classes.snmpTableHeaderCell}
                      >
                        <Typography
                          style={{ fontWeight: 550 }}
                          variant="span"
                        >{`${k}`}</Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>{this.renderRows()}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  }
}
