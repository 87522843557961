import React from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // version 1.x
import Grid from "@material-ui/core/Grid";
import { MenuItem } from "@material-ui/core";
import { sub } from "date-fns";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dateTime: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  timeSelector: {
    marginBottom: theme.spacing(1),
  },
}));

export default function TimeRangeDialog({
  visible,
  callbackClose,
  setStartDate,
  setEndDate,
  startDate,
  setDateType,
  endDate,
  menuItems,
  disabled,
  extraConfig,
  dialogTitle,
  timeRangeDisabled,
}) {
  const classes = useStyles();
  const [selected, setSelect] = React.useState("24 Hours");
  const [pickerDisabled, setPickerDisabled] = React.useState(true);

  const setTime = (e) => {
    setSelect(e);
    if (e === "Custom") {
      setPickerDisabled(false);
    } else {
      setPickerDisabled(true);
    }
    var startDate;
    var endDate;
    //var currentDate = new Date();
    if (e === "24 Hours") {
      setStartDate(sub(new Date(), { days: 1 }));
      setEndDate(new Date());
      setDateType(e);
    } else if (e === "Today") {
      startDate = new Date();
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      setStartDate(startDate);
      setEndDate(new Date());
      setDateType(e.replaceAll(" ", "_"));
    } else if (e === "Week") {
      setStartDate(sub(new Date(), { days: 7 }));
      setEndDate(new Date());
      setDateType(e);
    } else if (e === "30 Days") {
      setStartDate(sub(new Date(), { days: 30 }));
      setEndDate(new Date());
      setDateType(e.replaceAll(" ", "_"));
    } else if (e === "This Calendar Month") {
      startDate = new Date();
      startDate.setDate(1);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      console.log(startDate);
      setStartDate(startDate);
      setEndDate(new Date());
      setDateType(e.replaceAll(" ", "_"));
    } else if (e === "Last Calendar Month") {
      endDate = new Date();
      endDate.setDate(0);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      startDate = new Date();
      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      setStartDate(startDate);
      setEndDate(endDate);
      setDateType(e.replaceAll(" ", "_"));
    } else if (e === "Year") {
      setStartDate(sub(new Date(), { days: 365 }));
      setEndDate(new Date());
      setDateType(e);
    } else if (e === "Custom") {
      setDateType(e);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={visible}
        onClose={() => callbackClose(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {extraConfig ? extraConfig : null}
            <Grid container alignItems="center" justifyContent={"center"}>
              <Grid item xs={12}>
                <TextField
                  label="Select Time"
                  id="timeselection"
                  value={selected}
                  select
                  variant="outlined"
                  size="small"
                  disabled={timeRangeDisabled}
                  className={classes.timeSelector}
                  required
                  onChange={(e) => setTime(e.target.value)}
                  fullWidth
                >
                  {menuItems.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                container
                direction={"rows"}
                alignItems="center"
                maxWidth="xs"
                justifyContent={"center"}
              >
                <Grid item xs>
                  <DateTimePicker
                    value={startDate}
                    maxWidth="xs"
                    format="do MMM HH:mm"
                    disabled={pickerDisabled || timeRangeDisabled}
                    className={classes.dateTime}
                    label="Start"
                    size="small"
                    onChange={(date) => {
                      setStartDate(date);
                      setDateType("Custom");
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <DateTimePicker
                    value={endDate}
                    maxWidth="xs"
                    format="do MMM HH:mm"
                    label="End"
                    disabled={pickerDisabled || timeRangeDisabled}
                    className={classes.dateTime}
                    size="small"
                    onChange={(date) => {
                      setEndDate(date);
                      setDateType("Custom");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => callbackClose(true)}
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
