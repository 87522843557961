import { apiQuery } from "../libs/auth-config";

export function selectAutomationFormOption(inputId, formSection, value) {
  return {
    type: "SELECT_AUTOMATION_FORM_OPTION",
    payload: {
      inputId: inputId,
      formSection: formSection,
      value: value,
    },
  };
}

export function changeAccordionTabStatus(tabName, expanded) {
  return {
    type: "CLICK_ACCORDION_TAB",
    payload: {
      tabName: tabName,
      expanded: expanded,
    },
  };
}

export const GROUP_TEMPLATE_REQUEST = "REQUEST_GROUP_TEMPLATE";
export const GROUP_TEMPLATE_RECEIVE = "RECEIVE_GROUP_TEMPLATE";
export const GROUP_TEMPLATE_FAILURE = "FAILURE_GROUP_TEMPLATE";

export const getGroupTemplates = (type) => async (dispatch) => {
  dispatch({
    type: GROUP_TEMPLATE_REQUEST.replace(
      "GROUP",
      `${type.toUpperCase()}_GROUP`
    ),
  });
  apiQuery("GET", "/templates/groups", { type: type })
    .then((data) => {
      dispatch({
        type: GROUP_TEMPLATE_RECEIVE.replace(
          "GROUP",
          `${type.toUpperCase()}_GROUP`
        ),
        payload: data.data,
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({
        type: GROUP_TEMPLATE_FAILURE.replace(
          "GROUP",
          `${type.toUpperCase()}_GROUP`
        ),
      });
    });
};

export const DASHBOARD_TEMPLATE_REQUEST = "REQUEST_DASHBOARD_TEMPLATE";
export const DASHBOARD_TEMPLATE_RECEIVE = "RECEIVE_DASHBOARD_TEMPLATE";
export const DASHBOARD_TEMPLATE_FAILURE = "FAILURE_DASHBOARD_TEMPLATE";

export const getDashboardTemplates = () => async (dispatch) => {
  dispatch({ type: DASHBOARD_TEMPLATE_REQUEST });
  apiQuery("GET", "/templates/dashboards")
    .then((data) => {
      dispatch({
        type: DASHBOARD_TEMPLATE_RECEIVE,
        payload: data.data,
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: DASHBOARD_TEMPLATE_FAILURE });
    });
};

export const getDashboardInformation = async (id) => {
  const result = apiQuery("GET", "/templates/dashboards/dashboard", { id: id })
    .then((data) => {
      return data.data;
    })
    .catch((e) => console.log(e));
  return result;
};

export const getGroupStructure = async (type, structure) => {
  const result = apiQuery("GET", "/templates/groups/group", {
    type: type,
    name: structure,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => console.log(e));
  return result;
};

// Jobs:

export const createDeviceGroups = async (
  customer,
  structure,
  resellersResourceGroupName
) => {
  const result = apiQuery("POST", "/automation/device_groups", {
    customer,
    resellersResourceGroupName,
    structure,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return result;
};

export const createDashboardGroups = async (
  customer,
  structure,
  resellersResourceGroup,
  resellersDashboardGroup
) => {
  const result = apiQuery("POST", "/automation/dashboard_groups", {
    customer,
    resellersDashboardGroup,
    resellersResourceGroup,
    structure,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return result;
};

export const createWebsiteGroups = async (customer, topLevel) => {
  const result = apiQuery("POST", "/automation/website_groups", {
    customer,
    topLevel,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return result;
};

export const createReportGroups = async (customer) => {
  const result = apiQuery("POST", "/automation/create_report_groups", {
    customer,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return result;
};

export const createNetworkDashboard = async (
  customer,
  reseller,
  device,
  dashboard
) => {
  const result = apiQuery("POST", "/automation/dashboard", {
    customer,
    reseller,
    device,
    dashboard,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return result;
};

export const createUserRoles = async (
  customer,
  resellersResourceGroup,
  resellersDashboardGroup
) => {
  const result = apiQuery("POST", "/automation/user_roles", {
    customer,
    resellersResourceGroup,
    resellersDashboardGroup,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return result;
};

export const createUsers = async (users) => {
  const result = apiQuery("POST", "/automation/users", {
    users,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
  return result;
};

export const provisionQuery = async (path, payload) => {
  return await apiQuery("POST", `/automation/${path}`, payload);
};
