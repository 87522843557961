import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import ExpandedIcon from "@material-ui/icons/ExpandLess";
import DescriptionIcon from "@material-ui/icons/Description";
import Help from "@material-ui/icons/Help";
import Toolbar from "@material-ui/core/Toolbar";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import NotificationsIcon from "@material-ui/icons/Notifications";
import BuildIcon from "@material-ui/icons/Build";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DnsIcon from "@material-ui/icons/Dns";
import CodeIcon from "@material-ui/icons/Code";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import menuItems from "../data/menuItems";
import { Settings } from "@material-ui/icons";
import Visible from "../components/Visible";
import { PREFIX } from "../constants";
import { CAPABILITY_LEVELS } from "../constants";

const useStyles = makeStyles((theme) => ({
  drawer: (props) => ({
    width: props.width,
    flexShrink: 0,
  }),
  drawerPaper: (props) => ({
    backgroundColor: theme.palette.sidebar.main,
    width: props.width,
  }),
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  icons: {
    color: "white !important",
  },
  menuClass: {
    backgroundColor: theme.palette.sidebar.main,
  },
  expandedItem: {
    backgroundColor: theme.palette.sidebar.main,
    brightness: "70%",
  },
}));

const portalLogoTooBig = {
  maxWidth: 230,
  maxHeight: 60,
  marginTop: 5,
  marginLeft: 5,
  marginRight: 5,
};

const portalLogoTooSmall = {
  marginTop: 5,
  marginLeft: 5,
  marginRight: 5,
};

const loadImage = (setImageDimensions, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setImageDimensions({
      height: img.height,
      width: img.width,
    });
  };
  img.onerror = (err) => {
    setImageDimensions({
      height: 230,
      width: 60,
    });
  };
};

export default function SideMenu(props) {
  const { drawer, drawerPaper, menuClass } = useStyles(props);
  const { is_loading, open } = props;
  const account_id = useSelector(
    (state) => state.userSession.properties.account_id
  );
  const [imageDimensions, setImageDimensions] = useState({});
  const imageUrl = `https://s3.eu-west-2.amazonaws.com/${PREFIX}.logic-one.io/brands/${account_id}_PORTAL`;

  useEffect(() => {
    loadImage(setImageDimensions, imageUrl);
  }, [imageUrl]);

  const usersPermissions = useSelector((state) => state.roles.permissions);

  const renderItems = () => {
    let items = [];

    menuItems.data.forEach((item) => {
      // Handle the admin section.
      if ("children" in item) {
        let itemCopy = JSON.parse(JSON.stringify(item));

        let childrenVisible = [];

        item.children.forEach((child) => {
          let permissionSplit = child.permission.split(".");
          if (permissionSplit.length === 1) {
            if (
              usersPermissions[child.permission] <= CAPABILITY_LEVELS["view"]
            ) {
              childrenVisible.push(child);
            }
          } else {
            if (
              usersPermissions[permissionSplit[0]][permissionSplit[1]] <=
              CAPABILITY_LEVELS["view"]
            ) {
              childrenVisible.push(child);
            }
          }
        });

        if (childrenVisible.length) {
          itemCopy.children = childrenVisible;
          items.push(itemCopy);
        }
      } else if (item.permission in usersPermissions) {
        if (usersPermissions[item.permission] <= CAPABILITY_LEVELS["view"]) {
          items.push(item);
        }
      }

      // Just allow it if no permissions set.
      else {
        items.push(item);
      }
    });

    return items;
  };

  return (
    <Drawer
      anchor="left"
      color="primary"
      className={drawer}
      open={open}
      variant="persistent"
      classes={{
        paper: drawerPaper,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={
            imageDimensions.width > 230 || imageDimensions.height > 60
              ? portalLogoTooBig
              : portalLogoTooSmall
          }
          src={imageUrl}
          alt="CompanyLogo"
          onError={(e) =>
            (e.target.src = `https://s3.eu-west-2.amazonaws.com/${PREFIX}.logic-one.io/brands/${PREFIX}_sidemenu_logo_default.png`)
          }
        />
      </div>
      <div
        className={menuClass}
        style={{
          color: "white",
          filter: "brightness(90%)",
        }}
      >
        <List>
          {!is_loading &&
            renderItems().map((item) => (
              <ListItemLink
                key={item.name}
                to={"/"}
                item={item}
                primary="Summary"
                icon={item.icon}
                menu_prop_map={props.dynamic_children[item.name]}
              />
            ))}
          {usersPermissions.support <= CAPABILITY_LEVELS["view"] && (
            <ListItem
              dense
              button
              onClick={() => window.open("http://docs.logic-one.io/")}
            >
              <ListItemIcon>
                <Help style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Help & Support" />
            </ListItem>
          )}
        </List>
      </div>
      <Divider />

      <div style={{ marginTop: "auto" }}>
        <Toolbar>
          <img
            src={
              // logo_url ||
              "https://logic-one.s3.eu-west-2.amazonaws.com/public/LogicOne.svg"
            }
            alt="LogicOne"
            height="28"
          />
        </Toolbar>
      </div>
    </Drawer>
  );
}

function ListItemLink(props) {
  const { item, icon, padding, expanded } = props;
  const { icons, expandedItem } = useStyles(props);
  const [open, setOpen] = React.useState(false);
  // const visible = useContext(RbacContext)[item.name];
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={item.url} {...itemProps} innerRef={ref} />
      )),
    [item.url]
  );

  if (item.children) {
    return (
      <Visible component={item.component}>
        <ListItem dense={true} onClick={() => setOpen(!open)} button>
          {icon ? (
            <ListItemIcon>
              <MenuItemIcon color="white" icon={icon} />
            </ListItemIcon>
          ) : null}
          <ListItemText primary={item.name} />
          {open ? <ExpandedIcon /> : <ExpandIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {item.children.map((item) => {
            return (
              <ListItemLink
                key={item.name}
                to={"/"}
                dense={true}
                item={item}
                primary="Summary"
                padding={padding ? padding + 20 : 20}
                component={item.component}
                icon
                expanded
              />
            );
          })}
        </Collapse>
        <Divider />
      </Visible>
    );
  } else if (item.dynamic_children) {
    return (
      <React.Fragment>
        <ListItem dense={true} onClick={() => setOpen(!open)} button>
          {icon ? (
            <ListItemIcon>
              <MenuItemIcon color="white" icon={icon} />
            </ListItemIcon>
          ) : null}
          <ListItemText primary={item.name} />
          {open ? <ExpandedIcon /> : <ExpandIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {props.menu_prop_map.map((prop) => {
            return (
              <ListItemLink
                key={prop}
                to={"/" + prop.toLowerCase}
                dense={true}
                item={{ name: prop, url: "/services/" + prop.toLowerCase() }}
                primary="Summary"
                padding={padding ? padding + 20 : 20}
                // component={item}
                icon
                expanded
              />
            );
          })}
        </Collapse>
      </React.Fragment>
    );
  } else {
    return (
      <Visible component={item.component}>
        <ListItem
          style={{
            filter: expanded ? "brightness(80%)" : "brightness(100%)",
          }}
          className={expandedItem}
          dense={true}
          button
          divider
          component={renderLink}
        >
          {icon ? (
            <ListItemIcon className={icons}>
              <MenuItemIcon color="white" icon={icon} />
            </ListItemIcon>
          ) : null}
          {/* style={{"paddingLeft": padding + "px"}} */}
          <ListItemText primary={item.name} />
        </ListItem>
      </Visible>
    );
  }
}

ListItemLink.propTypes = {
  // item: PropTypes.element,
  icon: PropTypes.string,
  primary: PropTypes.string.isRequired,
  // to: PropTypes.string.isRequired,
};

function MenuItemIcon(props) {
  const { icon, color } = props;

  var Icon = <DashboardIcon small style={{ color: color }} />;
  switch (icon) {
    case "Dashboard":
      return Icon;
    case "Alerts":
      return <NotificationsIcon style={{ color: color }} />;
    case "Maintenance":
      return <BuildIcon style={{ color: color }} />;
    case "Services":
      return <AccountTreeIcon style={{ color: color }} />;
    case "Reports":
      return <DescriptionIcon style={{ color: color }} />;
    case "Expanded":
      return <ExpandedIcon style={{ color: color }} />;
    case "Device":
      return <DnsIcon style={{ color: color }} />;
    case "Automation":
      return <CodeIcon style={{ color: color }} />;
    case "Settings":
      return <Settings style={{ color: color }} />;
    default:
      return <div style={{ paddingLeft: "56px" }}></div>;
  }
}
