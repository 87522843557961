import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DateTimePicker } from "@material-ui/pickers";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  operator: {
    width: "168px",
  },
  additional_props: {
    "& .MuiAutocomplete-endAdornment": {
      right: 0,
    },
  },
}));

const FilterRow = ({
  filterProps,
  filterEditor,
  info,
  position,
  arrayPosition,
  setInitialDate,
  filterAddOperator,
  deleteFilter,
}) => {
  const classes = useStyles();
  const filterRow = (
    <Grid
      container
      direction={"row"}
      alignItems="center"
      maxWidth="xs"
      justifyContent={"center"}
      style={{ gap: "10px" }}
    >
      <Grid item>
        <Autocomplete
          options={filterProps.sort((a, b) => a.group.localeCompare(b.group))}
          getOptionLabel={(option) => option.name}
          groupBy={(option) => option.group}
          value={{ name: info["property"] }}
          sx={{ width: 300 }}
          onChange={(_, v) => {
            if (v === null) {
              v = { name: "" };
            }
            if (v["name"] === "Alert Began") {
              filterEditor("operator", null, position, arrayPosition);
            }
            // Clear timestamp field if they change to a field with string input.
            if (v["name"] !== "Alert Began" && Number.isInteger(info.value)) {
              filterEditor("value", null, position, arrayPosition);
            }
            filterEditor("property", v["name"], position, arrayPosition);
          }}
          className={classes.additional_props}
          fullWidth
          renderInput={(params) => (
            <TextField
              key={params}
              {...params}
              label="Property"
              style={{ width: 160 }}
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item>
        {info["property"] !== "Alert Began" ? (
          <TextField
            //labelId="filter-operator-selector"
            select
            variant="outlined"
            id={"filter-operator-selector-" + position}
            value={info["operator"]}
            className={classes.operator}
            label="Operator"
            fullWidth
            size="small"
            onChange={(event) => {
              filterEditor(
                "operator",
                event.target.value,
                position,
                arrayPosition
              );
            }}
          >
            <MenuItem value={"equals"}>is</MenuItem>
            <MenuItem value={"not equals"}>is not</MenuItem>
            <MenuItem value={"contains"}>contains</MenuItem>
            <MenuItem value={"not contains"}>does not contain</MenuItem>
          </TextField>
        ) : (
          <TextField
            select
            variant="outlined"
            id={"filter-operator-selector-" + position}
            value={info["operator"]}
            className={classes.operator}
            label="Operator"
            fullWidth
            size="small"
            onChange={(event) => {
              filterEditor(
                "operator",
                event.target.value,
                position,
                arrayPosition
              );
            }}
          >
            <MenuItem value={"before"}>before</MenuItem>
            <MenuItem value={"after"}>after</MenuItem>
          </TextField>
        )}
      </Grid>
      <Grid item>
        {info["property"] === "Status" ? (
          <TextField
            //labelId="filter-operator-selector"
            select
            variant="outlined"
            id={"filter-operator-selector-" + position}
            value={info["value"]}
            className={classes.operator}
            label="Value"
            //fullWidth
            size="small"
            onChange={(event) => {
              filterEditor(
                "value",
                event.target.value,
                position,
                arrayPosition
              );
            }}
          >
            <MenuItem value={"critical"}>Critical</MenuItem>
            <MenuItem value={"error"}>Error</MenuItem>
            <MenuItem value={"warn"}>Warning</MenuItem>
            <MenuItem value={"live"}>Live</MenuItem>
          </TextField>
        ) : info["property"] === "Alert Began" ? (
          <DateTimePicker
            fullWidth
            variant="inline"
            inputVariant="outlined"
            id="date-picker-start"
            label="Date"
            value={
              new Date(info["value"]).getTime() ||
              setInitialDate(position, arrayPosition)
            }
            ampm={false}
            onChange={(v) => {
              filterEditor("value", v, position, arrayPosition);
            }}
            size="small"
          />
        ) : (
          <TextField
            variant="outlined"
            id={"value-operator-" + position}
            label="Value"
            size="small"
            className={classes.operator}
            value={info["value"]}
            onChange={(event) =>
              filterEditor("value", event.target.value, position, arrayPosition)
            }
          />
        )}
      </Grid>
    </Grid>
  );

  return arrayPosition !== null ? (
    <div class="mediator-container">
      <Grid
        item
        container
        spacing={1}
        direction={"row"}
        alignItems="center"
        maxWidth="xs"
        style={{ paddingLeft: "5px" }}
        //justifyContent={"center"}
      >
        {arrayPosition !== 0 ? (
          <Grid item xl={1} lg={1}>
            <Typography color="primary">OR</Typography>
          </Grid>
        ) : null}
        <Grid item>{filterRow}</Grid>
        {arrayPosition === 0 ? (
          <>
            <Grid item>
              <Button
                id={"or-button-" + position}
                variant="contained"
                color="primary"
                onClick={() => filterAddOperator("AND", position)}
              >
                {"AND"}
              </Button>
            </Grid>
            <Grid item>
              <Button
                id={"or-button-" + position}
                variant="contained"
                color="primary"
                onClick={() => filterAddOperator("OR", position)}
              >
                {"OR"}
              </Button>
            </Grid>{" "}
          </>
        ) : null}
        <Grid item>
          <Tooltip disableFocusListener title="Delete">
            <IconButton
              onClick={() => deleteFilter(position, arrayPosition)}
              color="primary"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div class="mediator-container">
      <Grid
        item
        container
        spacing={1}
        direction={"row"}
        alignItems="center"
        maxWidth="xs"
      >
        <Grid item>{filterRow}</Grid>
        <Grid item>
          <Button
            id={"and-button-" + position}
            variant="contained"
            color="primary"
            onClick={() => filterAddOperator("AND", position)}
          >
            {"AND"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            id={"or-button-" + position}
            variant="contained"
            color="primary"
            onClick={() => filterAddOperator("OR", position)}
          >
            {"OR"}
          </Button>
        </Grid>
        <Grid item>
          <Tooltip
            disableFocusListener
            title={<Typography variant="body2">Delete Filter</Typography>}
          >
            <IconButton
              onClick={() => deleteFilter(position, null)}
              color="primary"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </div>
  );
};

export default FilterRow;
