import convertArrayToObject from "../libs/utils";

const severityMap = {
  4: "Critical",
  3: "Error",
  2: "Warning",
};

export default function alerts(
  state = {
    alerts: [],
    recipient_group: null,
    byId: [],
    byDeviceId: [],
    received: false,
  },
  action
) {
  switch (action.type) {
    case "ALERTS_SUCCESS":
      var newPayload = filterAllAlerts(action.payload, action.meta);
      return Object.assign({}, state, {
        alerts: newPayload,
        byId: convertArrayToObject(newPayload, "id"),
        byDeviceId: mapInstanceIdsToDeviceIds(newPayload),
        byStatus: groupAlertsByStatus(newPayload),
        acknowledgedAlertIds: acknowledgedAlertIds(newPayload),
        received: true,
      });
    case "IDV_ALERT_SUCCESS":
      return Object.assign({}, state, {
        byId: storeAlertMessage(action.payload, state.byId),
      });
    case "RECIPIENT_GROUPS_SUCCESS":
      return Object.assign({}, state, {
        recipient_group: action.payload[0],
      });
    default:
      return state;
  }
}

function filterAllAlerts(alerts, accountId) {
  var newPayload = [];

  for (var i = 0; i < alerts.length; i++) {
    // console.log(severityMap[alerts[i].severity]);
    alerts[i].severityLabel = severityMap[alerts[i].severity];
    if (alerts[i].rule !== "PingLossPercent Emails") {
      newPayload.push(alerts[i]);
    }
  }

  return newPayload;
}

function groupAlertsByStatus(alerts) {
  var byStatus = [];

  alerts.forEach((alert) => {
    if (!byStatus[severityMap[alert.severity]])
      byStatus[severityMap[alert.severity]] = [];
    byStatus[severityMap[alert.severity]].push(alert.id);
    // mappings[item.monitorObjectId].push(item.id)
  });
  return Object.assign({}, byStatus);
}

function acknowledgedAlertIds(alerts) {
  var acknowledgedIds = [];
  alerts.forEach((alert) => {
    // if (!acknowledgedIds[alert.rule]) byStatus[alert.rule] = [];
    alert.acked && acknowledgedIds.push(alert.id);
    // mappings[item.monitorObjectId].push(item.id)
  });
  return acknowledgedIds;
}

function storeAlertMessage(alert, byId) {
  byId[alert.id].message = alert.detailMessage;

  var y = Object.assign({}, byId);

  return y;
}

function mapInstanceIdsToDeviceIds(array) {
  var mappings = {};
  array.forEach((item) => {
    mappings[item.monitorObjectId] = mappings[item.monitorObjectId] || [];
    mappings[item.monitorObjectId].push(item.id);
  });
  return mappings;
}
