import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Container from "@material-ui/core/Container";
import { epochToTimestampWithYears } from "../libs/utils";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { acknowledgeAlert } from "../actions/alerts";
import { getAlertInformation } from "../actions/alerts";
import {
  CircularProgress,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";

import Visible from "../components/Visible";
// import { RbacContext } from "./LoadingContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  alertContainer: {
    width: "95%",
    display: "flex",
  },
  card: {
    width: "100%",
  },
  table: {
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  row: {
    cursor: "pointer",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AlertRow({ account_id, row }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState("summary");
  const [message, setMessage] = React.useState(null);
  const [acked, setAcked] = React.useState(row.acked);
  // const perms = useContext(RbacContext).Alerts.Acknowledge;
  let history = useHistory();
  const Device = useSelector(
    (state) => state.devices.byId[row.monitorObjectId]
  );

  useEffect(() => {
    dispatch(getAlertInformation(account_id, row.id));
  }, [dispatch, row.id, account_id]);
  var groups = [];

  row.monitorObjectGroups.forEach((value, index) => {
    groups.push(value.name);
  });

  const radioChange = (e) => {
    setTab(e.target.value);
  };

  const acknowledge = (id) => {
    setAcked(true);
    dispatch(
      acknowledgeAlert(
        row["Account ID"],
        id,
        {
          billing_ref: row["Billing Reference"],
          severity: row.severityLabel,
          instance: row.instanceName,
          datapoint: row.dataPointName,
          value: row.alertValue,
        },
        message
      )
    );
  };

  const LinkToDevice = (event) => {
    if (row && row.monitorObjectId && Device) {
      history.push("/device/" + row.monitorObjectId, { Device: Device });
    }
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell colSpan={8}>
          <Container style={{ padding: 20, paddingTop: 10 }} maxWidth={false}>
            {!row.message ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Grid container spacing={2} alignItems="flex-start">
                      <Grid item>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          value={tab}
                          onChange={radioChange}
                          style={{ paddingBottom: "10px" }}
                        >
                          <FormControlLabel
                            value={"summary"}
                            control={<Radio color="primary" />}
                            label="Summary"
                            labelPlacement="start"
                          />
                          <FormControlLabel
                            value={"info"}
                            control={<Radio color="primary" />}
                            label="Information"
                            labelPlacement="start"
                          />
                          <Visible
                            permissionNeeded="alerts"
                            capabilityNeeded="acknowledge"
                          >
                            <FormControlLabel
                              value={"acknowledge"}
                              control={<Radio color="primary" />}
                              label="Acknowledge"
                              labelPlacement="start"
                            />
                          </Visible>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                  {!acked && tab === "acknowledge" ? (
                    <Grid item>
                      {/* {perms.manage && ( */}
                      <Button
                        onClick={() => acknowledge(row.id)}
                        color="primary"
                        fullWidth
                        variant="contained"
                      >
                        Acknowledge
                      </Button>
                      {/* )} */}
                    </Grid>
                  ) : null}
                </Grid>

                {tab === "summary" ? (
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <Typography
                        className={classes.title}
                        color="primary"
                        style={{ marginBottom: "10px" }}
                      >
                        {row.message
                          ? row.message.subject
                          : "No message in alert."}
                      </Typography>
                      <ul>
                        {row.message ? (
                          row.message.body
                            .replace(". ", "\n")
                            .replace("\n\n", "\n")
                            .split("\n")
                            .map((line, index) => {
                              if (line.length !== 0) {
                                return (
                                  <li key={index}>
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      style={{ paddingBottom: "5px" }}
                                    >
                                      {line}
                                    </Typography>
                                  </li>
                                );
                              } else return null;
                            })
                        ) : (
                          <li>
                            <Typography variant="h5" component="h2">
                              No message in alert.
                            </Typography>
                          </li>
                        )}
                      </ul>
                    </Grid>
                    <Grid item xs style={{ position: "relative" }}>
                      <Tooltip
                        style={{
                          position: "absolute",
                          right: 0,
                          bottom: 0,
                          margin: "38px",
                        }}
                        title="Go To Device"
                      >
                        <IconButton onClick={LinkToDevice}>
                          <LaunchIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : tab === "info" ? (
                  <TableContainer>
                    <Table size="small" aria-label="recipients table">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Threshold</TableCell>
                          <TableCell>Value</TableCell>
                          <TableCell>DataSource</TableCell>
                          <TableCell>Datapoint</TableCell>
                          <TableCell>Groups</TableCell>
                          <TableCell>Escalation</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.threshold}</TableCell>
                          <TableCell>{row.alertValue}</TableCell>
                          <TableCell>{row.resourceTemplateName}</TableCell>
                          <TableCell>{row.dataPointName}</TableCell>
                          <TableCell>{groups.join(", ")}</TableCell>
                          <TableCell>{row.chain}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : !acked && !row.acked ? (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ height: "100px" }}
                  >
                    <TextField
                      value={undefined}
                      variant="outlined"
                      onChange={(e) => setMessage(e.target.value)}
                      label="Acknowledgement Comment"
                      multiline
                      fullWidth
                      minRows={4}
                      placeholder={"Full Name & Description"}
                    />
                  </FormControl>
                ) : row.acked ? (
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item xs={10}>
                      <Grid container spacing={3}>
                        <Grid item>
                          <Typography
                            className={classes.title}
                            color="primary"
                            style={{ marginBottom: "10px" }}
                          >
                            This alert has been acknowledged.
                          </Typography>

                          {row.ackComment ? (
                            <ul>
                              <li>
                                <Typography
                                  variant="h5"
                                  component="h2"
                                  style={{ paddingBottom: "5px" }}
                                >
                                  Acknowledged by: {row.ackedBy}
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  variant="h5"
                                  component="h2"
                                  style={{ paddingBottom: "5px" }}
                                >
                                  Time of acknowledgement:{" "}
                                  {epochToTimestampWithYears(row.ackedEpoch)}
                                </Typography>
                              </li>
                              <li>
                                <Typography
                                  variant="h5"
                                  component="h2"
                                  style={{ paddingBottom: "5px" }}
                                >
                                  Comment left by user: {row.ackComment}
                                </Typography>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li>
                                <Typography variant="h5" component="h2">
                                  No acknowledgement comment in alert.
                                </Typography>
                              </li>
                            </ul>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}
          </Container>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
