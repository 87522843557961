import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { withStyles, createStyles, alpha } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import DevicesContainer from "./DevicesContainer";
import DashboardContainer from "./DashboardContainer";
import GraphsContainer from "./GraphsContainer";
import DeviceGroupContainer from "./DeviceGroupContainer";
import AlertsContainer from "./AlertsContainer";
import MaintenanceContainer from "./MaintenanceContainer";
import AuditLogsContainer from "./AuditLogsContainer";
import SummaryStats from "./SummaryStats";
import LoginContainer from "./LoginContainer";
// import CustomerDeviceContainer from "./CustomerDeviceContainer";
import ProvisionContainer from "./ProvisionContainer";
import UserAccountSettings from "./UserAccountSettings";
import PanelSettingsContainer from "./PanelSettingsContainer";
import SideMenu from "./SideMenu";
import { initRequestSession, fetchJWT, logoutUser } from "../actions/session";
import { initRequestAlerts } from "../actions/alerts";
import { initDeviceSearch } from "../actions/devices";
import { initRequestAllDeviceGroups } from "../actions/devicegroup";
import { initRequestReports } from "../actions/reports";
import RunReportContainer from "./RunReportContainer";
import ScheduleReportsContainer from "./ScheduleReportsContainer";
import UserAccessContainer from "./UserAccessContainer";
import AccountAccessContainer from "./AccountAccessContainer";
import TopNavContainer from "./TopNavContainer";
import AutomationContainer from "./AutomationContainer";
import RunJobContainer from "./RunJobContainer";
import { PrivateRoute } from "./PrivateRoute";
import CircuitsContainer from "./CircuitsContainer";
import RolesContainer from "./RolesContainer";
import { CAPABILITY_LEVELS } from "../constants";

const drawerWidth = 240;

const useStyles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
    },
    main: {
      width: "100%",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(6),
      padding: theme.spacing(2),
      maxWidth: "100%",
      height: "100%",
    },
    mainExpand: {
      padding: theme.spacing(2),
      maxWidth: "100%",
      height: "100%",
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      width: "100%",
      overflow: "auto",
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: "100%",
      overflow: "auto",
    },
    masquerade_form: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(2),
      marginLeft: 400,
      width: "100%",
      [theme.breakpoints.up("sm")]: {},
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      margin: "auto",
      width: "100%",
      float: "right",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  });

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      loading: true,
      is_offline: false,
      logged_in: false,
      open: true,
      data_loaded: false,
      wan_group_dash_id: null,
      signOutElement: null,
      masqueradeDialogVisible: false,
      global_search_term: "",
      mainExpand: false,
      hasPortalLogo: false,
      sideMenuHidden: false,
    };
  }

  componentDidMount() {
    document.addEventListener("fullscreenchange", () => this.escapeHatch());
    document.addEventListener("webkitfullscreenchange", () =>
      this.escapeHatch()
    );
  }

  async initialDataLoad() {
    await this.props.initDeviceSearch();
    await this.props.initRequestAlerts();
    await this.props.initRequestReports();

    this.setState({ loading: false });
  }

  async checkExistingSession() {
    const jwt_token = localStorage.getItem("jwt");
    if (!jwt_token) {
      try {
        const session = await Auth.currentSession();
        if (session) {
          console.log(session);
          localStorage.setItem("jwt", session.idToken.jwtToken);
          this.setState({ is_offline: false, logged_in: true });
        }
      } catch (error) {
        console.log("error finding a session: ", error);
        this.setState({ logged_in: false });
      }
    }
    if (jwt_token) {
      const session = await this.props.initRequestSession();
      if (session.type && session.type !== "SESSION_FAILURE") {
        this.setState({ is_offline: false, logged_in: true });
      } else {
        console.log("problem loading session from token");
        localStorage.removeItem("jwt");
      }
    }

    this.setState({ loading: false });
  }

  toggleDrawer() {
    this.setState({ open: !this.state.open });
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  handleMenu(event) {
    this.setState({ signOutElement: event.currentTarget });
  }

  handleClose() {
    this.setState({ signOutElement: null });
  }

  handleMainExpand = () => {
    this.state.mainExpand ? this.closeFullscreen() : this.openFullscreen();
  };

  openFullscreen() {
    var elem = document.documentElement;
    this.setState({ mainExpand: !this.state.mainExpand });
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
      this.setState({ mainExpand: !this.state.mainExpand });
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    this.setState({ mainExpand: !this.state.mainExpand });
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }

  escapeHatch() {
    const fullscreen = this.state.mainExpand;
    let listen = null;
    if (document.exitFullscreen) {
      listen =
        fullscreen && !document.fullscreenElement
          ? this.setState({ mainExpand: !this.state.mainExpand })
          : null;
    } else {
      listen =
        fullscreen && !document.webkitIsFullScreen
          ? this.setState({ mainExpand: !this.state.mainExpand })
          : null;
    }
    return listen;
  }

  async performSearch(event) {
    console.log(`searching for.. ${this.state.global_search_term}`);
    event && event.preventDefault();
  }

  render() {
    const { classes } = this.props;

    return (
      <Router>
        <div
          className={classes.root}
          style={{
            height: "100%",
            position: "absolute",
            left: "0px",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <CssBaseline />
          {this.state.mainExpand ? null : (
            <TopNavContainer
              logged_in={this.props.logged_in}
              open={this.state.open}
              toggleDrawer={() => this.toggleDrawer()}
            />
          )}
          {this.state.mainExpand ? null : (
            <SideMenu
              open={this.state.open}
              width={drawerWidth}
              dynamic_children={{ Services: this.props.view_names }}
              closedrawer={() => this.handleDrawerClose()}
              authenticated={this.props.logged_in}
              portal_logo={this.state.hasPortalLogo}
            />
          )}
          <main
            style={{ marginLeft: this.state.mainExpand && "0px" }}
            className={this.state.open ? classes.contentShift : classes.content}
          >
            {/* e            <div className={classes.drawerHeader} /> */}
            <Container
              maxWidth="lg"
              className={
                this.state.mainExpand ? classes.mainExpand : classes.container
              }
            >
              <Route
                exact
                path="/"
                render={(props) => {
                  return this.props.permissions.dashboard >
                    CAPABILITY_LEVELS["view"] ? (
                    <DevicesContainer
                      key={props.match.params.device_type}
                      {...props}
                      setParentTitle={(title) => this.setTitle(title)}
                    />
                  ) : (
                    <SummaryStats
                      {...props}
                      setParentTitle={(title) => this.setTitle(title)}
                      mainExpand={this.state.mainExpand}
                      expandMain={() => this.handleMainExpand()}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/services/:device_type"
                render={(props) => (
                  <DevicesContainer
                    key={props.match.params.device_type}
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                exact
                path="/services"
                render={(props) => (
                  <DevicesContainer
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/dashboard"
                render={(props) => (
                  <DashboardContainer
                    {...props}
                    wan_group_dash_id={this.state.wan_group_dash_id}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/dashboard/:id"
                render={(props) => (
                  <DashboardContainer
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/device/:id"
                render={(props) => (
                  <CircuitsContainer
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/graphs/:id"
                render={(props) => (
                  <GraphsContainer
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/groups/:id"
                render={(props) => (
                  <DeviceGroupContainer
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/summary"
                render={(props) => (
                  <SummaryStats
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/login"
                render={(props) => (
                  <LoginContainer
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/profile"
                render={(props) => (
                  <UserAccountSettings
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />

              <Route
                exact
                path="/automation"
                render={(props) => (
                  <AutomationContainer
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <Route
                path="/automation/:id"
                render={(props) => (
                  <RunJobContainer
                    {...props}
                    setParentTitle={(title) => this.setTitle(title)}
                  />
                )}
              />
              <PrivateRoute
                keys={["Reports", "Report Builder"]}
                path="/reports/:id"
              >
                <RunReportContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute
                keys={["Reports", "Report Builder"]}
                path="/reports"
              >
                <RunReportContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute
                keys={["Reports", "Scheduled Reports"]}
                path="/scheduledreports/"
              >
                <ScheduleReportsContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute keys={["Alerts"]} path="/alerts">
                <AlertsContainer
                  windowRef={this.props.windowRef}
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute keys={["Maintenance"]} path="/maintenance">
                <MaintenanceContainer />
              </PrivateRoute>
              <PrivateRoute keys={["Admin", "Provisioning"]} path="/provision">
                <ProvisionContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute keys={["Admin", "User Access"]} path="/users">
                <UserAccessContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute keys={["Admin", "Account Access"]} path="/accounts">
                <AccountAccessContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute keys={["Admin", "Roles"]} path="/roles">
                <RolesContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute keys={["Admin", "Audit Logs"]} path="/auditlogs">
                <AuditLogsContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <PrivateRoute keys={["Settings"]} path="/admin/settings">
                <PanelSettingsContainer
                  setParentTitle={(title) => this.setTitle(title)}
                />
              </PrivateRoute>
              <Redirect to={"/"} />
            </Container>
          </main>
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = {
  initRequestSession,
  fetchJWT,
  logoutUser,
  initRequestAllDeviceGroups,
  initDeviceSearch,
  initRequestAlerts,
  initRequestReports,
};

const mapStateToProps = (state) => {
  const { logged_in, is_superuser } = state.userSession;
  const { byViewName } = state.ui_settings.user_views;
  const { permissions } = state.roles;

  var view_names = Object.keys(byViewName);

  return {
    is_superuser,
    logged_in,
    view_names,
    permissions,
  };
};

const enhance = compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AppContainer);
