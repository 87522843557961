import React from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";

const LoadingButton = ({
  startIcon,
  endIcon,
  children,
  isLoading = false,
  onClick = () => {},
  size = "medium",
  variant = "contained",
  disabled = false,
  color = "primary",
  spinnerStyle = {
    height: "25px",
    width: "25px",
    marginRight: "7px",
  },
  className = "",
}) => {
  return (
    <Button
      onClick={onClick}
      size={size}
      className={className}
      variant={variant}
      color={color}
      startIcon={startIcon && !isLoading}
      disabled={disabled || isLoading}
      endIcon={endIcon}
    >
      {isLoading && <CircularProgress style={spinnerStyle} />}
      {children}
    </Button>
  );
};

export default LoadingButton;
