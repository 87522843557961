import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import getUnixTime from "date-fns/getUnixTime";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PREFIX } from "../constants";

import FullScreenGraph from "../components/dialogs/FullScreenGraph";
import { epochToTimestamp } from "../libs/utils";

const styles = (theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
    progress: {
      justifyContent: "center",
    },
    root: {
      // paddingTop: "20px",
      flexGrow: 1,
      "& .MuiTableHead-root": {
        backgroundColor: "lightgray",
      },
      "& .MuiTableCell-head": {
        // color: "white",
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    graphText: {
      color:
        theme.palette.type === "dark" ? theme.palette.text.primary : "gray",
    },
  });

class GraphsContainer extends Component {
  abortController = new AbortController();

  constructor(props) {
    super(props);
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    this.state = {
      state: "Graphs",
      is_hidden: true,
      dialog_is_hidden: true,
      //loadingWidgets: true,
      rangesChanged: false,
      report_error: false,
      fromDate: yesterday,
      fromEpoch: getUnixTime(yesterday),
      toDate: today,
      toEpoch: getUnixTime(today),
      isGraphOpen: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    //this.updateGraphRanges();
    // this.setState({ loadingWidgets: true });
    // // this.props.setParentTitle('Device Info')
    // var dashboard_id =
    //   this.props.Device["aur.device_dashboard_id"] ||
    //   this.props.Device.rmdashid;
    // console.log("Getting graphs for dashboard: " + dashboard_id);
    // if (dashboard_id && dashboard_id !== 0) {
    //   // this.getDashboard(this.props.Device.device_report_id)
    //   this.setState();
    //   // this.updateGraphRanges();
    // } else {
    //   this.setState({ loadingWidgets: false, report_error: true });
    // }
    // this.setState({ rangesChanged: false });
  }

  componentWillUnmount() {
    // this.abortController.abort();
  }

  setDateRangeValues() {
    var today = new Date();
    var yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

    this.handleFromDateChange(yesterday);
    this.handleToDateChange(today);
  }

  handleFromDateChange = (date) => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
      hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      fromDate: date,
      fromEpoch: getUnixTime(date),
    });
  };

  handleToDateChange = (date) => {
    var hasChanged = false;
    if (this.state.fromEpoch !== getUnixTime(date)) {
      hasChanged = true;
    }
    this.setState({
      rangesChanged: hasChanged,
      toDate: date,
      toEpoch: getUnixTime(date),
    });
  };

  handleTicketButtonClick = (bool) => {
    this.setState({ dialog_is_hidden: bool });
  };

  render() {
    var type = "";
    const { data, classes, bigGraph, theme } = this.props;
    const fontColor =
      theme.palette.type === "dark" ? theme.palette.text.primary : "gray";

    data.forEach((widget) => {
      if (widget.type === "graph") {
        if (
          widget.title ===
            "DSL Line Characteristics-ATM0-adsl-DSL Sync Speeds" &&
          PREFIX === "dc"
        ) {
          type = "DSL";
        } else if (
          widget.title ===
            "FTTC Line Characteristics-ATM0-adsl-FTTC Sync Speeds" &&
          PREFIX === "dc"
        ) {
          type = "FTTC";
        } else if (widget.title === "WAN utilization (Gi0/0/0)") {
          type = "Ethernet";
        } else if (
          widget.title === "Bandwidth Utilisation" &&
          PREFIX === "dws"
        ) {
          type = "DSL/FTTC";
        }
      }
    });

    const dashboard_orders = {
      dws: {
        "DSL/FTTC": [
          "Bandwidth Utilisation",
          "Ping Latency",
          "Traffic - In",
          "Traffic - Out",
          "Operating Status",
          "Drops / Errors",
        ],
        //"FTTC":["Bandwidth Utilisation","Ping Latency","Traffic - In","Traffic - Out", "Operating Status","Drops / Errors"],
        Ethernet: [
          "WAN utilization (Gi0/0/0)",
          "Ping latency",
          "Traffic - In",
          "Traffic - Out",
          "Operating Status",
          "Drops / Errors",
        ],
      },
      dc: {
        DSL: [
          "Traffic - In",
          "Traffic - Out",
          "Availability",
          "Ping Latency",
          "Discards",
          "CPU Utilisation",
          "DSL Line Characteristics-ATM0-adsl-DSL Sync Speeds",
          "DSL Line Characteristics-ATM0-adsl-DSL Attenuation",
          "DSL Line Characteristics-ATM0-adsl-DSL Line SNR",
        ],
        FTTC: [
          "Traffic - In",
          "Traffic - Out",
          "Availability",
          "Ping Latency",
          "Discards",
          "CPU Utilisation",
          "FTTC Line Characteristics-ATM0-adsl-FTTC Sync Speeds",
          "FTTC Line Characteristics-ATM0-adsl-FTTC Attenuation",
          "FTTC Line Characteristics-ATM0-adsl-FTTC Line SNR",
        ],
        Ethernet: [
          "WAN utilization (Gi0/0/0)",
          "Throughput",
          "Ping latency",
          "Packet Transmission",
          "Operating Status",
          "Drops / Errors",
          "Memory",
          "CPU",
          "Temperature",
          "DNS Status",
          "NTP Status",
        ],
      },
    };

    if (type) {
      data.sort(function (a, b) {
        return (
          dashboard_orders[PREFIX][type].indexOf(a.title) -
          dashboard_orders[PREFIX][type].indexOf(b.title)
        );
      });
    }
    // var widgets = data || graphsById; // just to check it works, if so rename all widgets to graphs
    if (this.props.report_error) {
      return (
        <Paper justifyContent="center" style={{ marginTop: "20px" }}>
          <Typography color="error" gutterBottom>
            This device does not have an associated dashboard. Contact support.
          </Typography>
        </Paper>
      );
    }

    return (
      <div className={classes.root}>
        {this.props.isUpdating ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <>
            <div className="row">
              <Grid container spacing={2} justifyContent="center">
                {data.map((widget, keyIndex) => {
                  let rows = [];
                  let cols = {
                    time: {
                      label: "Time",
                    },
                  };
                  widget.lines.forEach(
                    (dp) => (cols[dp.label] = { label: dp.label })
                  );
                  widget.timestamps.forEach((timestamp, i) => {
                    let tmp = { time: epochToTimestamp(timestamp) };
                    widget.lines.forEach((dp) => (tmp[dp.label] = dp.data[i]));
                    rows.push(tmp);
                  });
                  rows = rows.reverse();
                  if (widget.type === "graph") {
                    var graph = widget;
                    var noData = false;
                    var data = { datasets: [] };
                    var options = {
                      title: { text: graph.title, display: false },
                      maintainAspectRatio: true,
                      scales: {
                        xAxes: [
                          {
                            type: "time",
                            time: {
                              displayFormats: {
                                hour: "hA",
                              },
                            },
                            display: true,
                            ticks: {
                              // reverse: true,
                              autoSkip: true,
                              maxTicksLimit: 7,
                              fontColor: fontColor,
                            },
                            gridLines: { display: false },
                            // distribution: 'linear'
                          },
                        ],
                        yAxes: [
                          {
                            scaleLabel: {
                              display: true,
                              labelString: graph.verticalLabel,
                              fontColor: fontColor,
                            },
                            ticks: {
                              fontColor: fontColor,
                            },
                            gridLines: {
                              color: theme.palette.divider,
                              zeroLineColor: theme.palette.divider,
                            },
                          },
                        ],
                      },
                      tooltips: {
                        mode: "index",
                        intersect: false,
                      },
                      hover: {
                        mode: "index",
                        intersect: false,
                      },
                      legend: {
                        display: false,
                      },
                    };
                    // Override date format if range is over 5 days.
                    if (
                      this.props.toEpoch - this.props.fromEpoch >
                      60 * 60 * 24 * 5
                    ) {
                      options.scales.xAxes[0].time.displayFormats.hour =
                        "MMM D";
                    }

                    if (
                      graph.title.includes("FTTC") ||
                      graph.title.includes("DSL")
                    ) {
                      for (var i = 0; i < graph.lines.length; i++) {
                        if (!graph.lines[i].min && !graph.lines[i].max)
                          noData = true;
                        else noData = false;
                      }
                    }
                    if (graph.lines && !noData) {
                      graph.lines.map(function (line, index) {
                        var data_points = [];
                        graph.lines[index].data.map(function (
                          value,
                          line_index
                        ) {
                          if (line.legend !== "BandwidthUsed") {
                            data_points.push({
                              x: graph.timestamps[line_index],
                              y: line.data[line_index],
                            });
                          }
                          return null;
                        });

                        var color = hexAToRGBA("#" + graph.lines[index].color);
                        data.datasets.push({
                          data: data_points,
                          label: graph.lines[index].legend,
                          backgroundColor: color,
                          borderColor: color,
                          fill: false,
                          borderWidth: 1,
                          pointRadius: 0,
                        });
                        return null;
                      });
                    }
                    // style={{position: 'relative', height: '30vh', width: '40vw'}}
                    if (!noData) {
                      return (
                        <Grid
                          key={keyIndex}
                          item
                          xs={12}
                          sm={12}
                          md={bigGraph ? 12 : 6}
                          lg={bigGraph ? 12 : 6}
                        >
                          <Paper className={classes.root}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div />
                              <Typography
                                variant="body"
                                style={{
                                  paddingTop: 12,
                                  paddingLeft: 35,
                                  fontWeight: 12,
                                }}
                                className={classes.graphText}
                              >
                                <b>{graph.title}</b>
                              </Typography>
                              <FullScreenGraph
                                isOpen={this.isGraphOpen}
                                data={data}
                                options={options}
                                propMap={cols}
                                rows={rows}
                                title={`${this.props.Device["Billing Reference"]} - ${graph.title}`}
                                onOpen={() => this.props.onFullScreen()}
                                onClose={() => this.props.exitFullScreen()}
                              />
                            </div>
                            <div>
                              <Line data={data} options={options} />
                            </div>
                          </Paper>
                        </Grid>
                      );
                    }
                  }
                  return null;
                })}
              </Grid>
            </div>
          </>
        )}
      </div>
    );
  }
}

function hexAToRGBA(h) {
  let r = 0,
    g = 0,
    b = 0,
    a = 1;

  if (h.length === 5) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];
    a = "0x" + h[4] + h[4];
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
    // a = "0x" + h[7] + h[8];
  }
  // a = +(a / 255).toFixed(3);

  return "rgba(" + +r + "," + +g + "," + +b + "," + a + ")";
}

function mapStateToProps(state, ownProps) {
  const { account_id } = state.userSession.properties;
  return { account_id };
}

const mapDispatchToProps = {};

const enhance = compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(GraphsContainer);
