import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Auth, Hub } from "aws-amplify";
import { ThemeProvider } from "@material-ui/core/styles";

import AWSLoginContainer from "./AWSLoginContainer";
import LoadingContainer from "./LoadingContainer";
import theme_config from "../libs/themes";
import { getLogo, getBranding } from "../actions/reports";

import {
  initRequestSession,
  fetchCognitoUser,
  getViewRoleConfiguration,
  fetchJWT,
} from "../actions/session";

import { getUserSettings } from "../actions/ui_settings";

const drawerWidth = 240;

const useStyles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      overflow: "auto",
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  });

class AWSAuthContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      loading: true,
      is_offline: false,
      logged_in: false,
      open: false,
      wan_group_dash_id: null,
      branding: null,
    };
  }

  componentDidMount() {
    Hub.listen("auth", this.updateLoggedStatus);
    // this.updateLoggedStatus();
    // if (localStorage.getItem("jwt")) {
    this.checkExistingSession();
    // }
    //   this.setState({ logged_in: true, loading: false })
    // } else {
    //   this.setState({ logged_in: false, loading: false })
    // }
  }

  componentWillUnmount() {
    Hub.remove("auth");
  }

  updateLoggedStatus = async (data) => {
    try {
      // updating users account_id
      // const user = await Auth.currentAuthenticatedUser()
      // const result = await Auth.updateUserAttributes(
      //     user,
      //     { "custom:account_id": "RHF06013"
      // })
      // console.log(result);
      if (data.payload.event === "signIn") {
        const session = await Auth.currentSession();
        if (session) {
          localStorage.setItem("jwt", session.idToken.jwtToken);
          //const user = await Auth.currentAuthenticatedUser();
          //console.log(user.attributes);
          // this.checkExistingSession();

          this.props.fetchCognitoUser().then(() => {
            this.getBranding();
            this.props.getLogo(this.props.properties.account_id);
            this.props.getViewRoleConfiguration();
            this.props.getUserSettings(this.props.view_id);
          });

          this.setState({ is_offline: false, logged_in: true });
        } else {
          this.setState({ logged_in: false });
        }
      }
    } catch (error) {
      // console.log('error finding a session: ', error);
    }

    // if (localStorage.getItem('jwt')) {
    //   this.setState({ is_offline: false, logged_in: true })
    // }

    // this.setState({ loading: false })

    // const username = await this.getCurrentUsername()
    // let newLoggedStatus = false;
    // if (username) newLoggedStatus = true;
    // this.setState({ isLogged: newLoggedStatus });
  };

  async checkExistingSession() {
    try {
      // updating users account_id
      // const user = await Auth.currentAuthenticatedUser()
      // const result = await Auth.updateUserAttributes(
      //     user,
      //     { "custom:account_id": "RHF06013"
      // })
      // console.log(result);

      const session = await Auth.currentSession();
      if (session) {
        localStorage.setItem("jwt", session.idToken.jwtToken);
        // const user = await Auth.currentAuthenticatedUser();
        // console.log(user.attributes);
        // this.checkExistingSession();

        this.props.fetchCognitoUser().then(() => {
          this.getBranding();
          this.props.getLogo(this.props.properties.account_id);
          this.props.getViewRoleConfiguration();
          this.props.getUserSettings(this.props.view_id);
        });

        this.setState({ is_offline: false, logged_in: true });
      } else {
        this.setState({ logged_in: false });
      }
    } catch (error) {
      // console.log('error finding a session: ', error);
    }
    // const jwt_token = localStorage.getItem("jwt");
    // if (!jwt_token) {
    // }
    // if (jwt_token) {
    //   // console.log('initRequestSession in AWSAuthContainer')
    //   const session = await this.props.initRequestSession();
    //   if (session.type && session.type !== "SESSION_FAILURE") {
    //     this.setState({ is_offline: false, logged_in: true });
    //   } else {
    //     localStorage.removeItem("jwt");
    //   }
    // }
    //
    // this.setState({ loading: false });
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  async getBranding() {
    this.props
      .getBranding(this.props.properties.account_id)
      .then((data) => {
        // if (data === undefined) {
        //   this.props.getBranding("default");
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { properties, branding } = this.props;
    // if (this.state.loading === true) {
    //   return (
    //     <Grid container justifyContent="center" style={{ paddingTop: '200px' }}>
    //       <CircularProgress size={24}  />
    //       <Typography>Logging in..</Typography>
    //     </Grid>
    //   )
    // } else
    var body;
    if (this.state.logged_in === true && properties) {
      body = <LoadingContainer />;
    } else {
      body = (
        <AWSLoginContainer setParentTitle={(title) => this.setTitle(title)} />
      );
    }
    return (
      // replace this with a redirect to aws login?
      <ThemeProvider theme={theme_config(branding)}>{body}</ThemeProvider>
      // body
    );
  }
}

const mapDispatchToProps = {
  initRequestSession,
  fetchJWT,
  fetchCognitoUser,
  getViewRoleConfiguration,
  getUserSettings,
  getBranding,
  getLogo,
};

const mapStateToProps = (state) => {
  var { logged_in, properties, branding, view_id } = state.userSession;
  // devices: state.devices.byId,
  return {
    properties,
    logged_in,
    branding,
    view_id,
  };
};

const enhance = compose(
  withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AWSAuthContainer);
