import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  MenuItem,
  TextField,
  Typography,
  Button,
  Paper,
  InputAdornment,
  Checkbox,
  Menu,
  CircularProgress,
  IconButton,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { IS_EMBEDDED } from "../constants";
import { createUserSettings, updateUserSettings } from "../actions/ui_settings";
import { makeStyles, styled, alpha } from "@material-ui/core/styles";
import { CloudDownload, KeyboardArrowDown, Search } from "@material-ui/icons";
import { checkBillingRef, epochToTimestamp } from "../libs/utils";
import SavedViewsMenu from "./dialogs/SavedViewsMenu";
import { Autocomplete } from "@material-ui/lab";
import SaveUserView from "./dialogs/SaveUserView";
import { filterPropertiesForAutocomplete } from "../libs/utils";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiToolbar-root": {
      display: "block",
    },
    "& .MuiTablePagination-toolbar": {
      display: "flex",
    },
    "& .MuiToolbar-gutters": {
      paddingRight: 0,
      "@media (min-width: 600px)": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    "& .MuiTable-root": {
      overflowY: "hidden",
    },
    "& .MuiIconButton-root": {
      padding: 8,
    },
    "& .MuiCheckbox-root": {
      display: "block",
    },
    "& .hideElement": {
      display: "none",
    },

    paddingBottom: 20,
  },
  additional_props: {
    "& .MuiAutocomplete-endAdornment": {
      right: 0,
    },
  },
  saved_views_button: {
    "white-space": "nowrap",
    padding: "7px",
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 300,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      width: 230,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        //marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const common_columns = [
  /* Device Props */
  "Status",
  "Site Name",
  "Postcode",
  "Billing Reference",
  "IP Range",
  "Circuit Reference",
  "Device Type",
  "Circuit Size",
  "Circuit Provider",
  /* Alert Props */
  "Severity",
  "Alert Began",
  "Instance",
];

export default function CustomDatatable({
  propMap,
  rows,
  title,
  sortProp,
  expandableRows,
  rowClickCallback,
  selectableRows,
  rowSelectionCallback,
  setRows,
  filter,
  draggableColumns,
  selectColumns,
  isRowSelectable,
  viewComponent,
  user_views,
  user_id,
  loading,
  download,
  customToolbar,
  search_term,
  searchCallback,
  customPage,
  elevation,
  goToPage,
  selectedRow,
  view_name,
  editableColumns,
  defaultView,
  renderAddMaintenanceButton,
  renderAddReportButton,
  FilterSettings = null,
  updateFilterCallback = null,
  deviceFilter = null,
  allowMultipleViews,
}) {
  let tableSettings = viewComponent.split("Table")[0].toLowerCase();
  const search_table_settings = useSelector(
    (state) => state.ui_settings[`${tableSettings}_search_table`]
  );
  const [rowsPerPage, setRowsPerPage] = useState(search_table_settings.rows);
  const default_view = user_views
    ? user_views.filter((view) => view.name === view_name)[0]
    : null;
  const default_columns = default_view ? default_view.columns : null;
  const initial_props = useSelector(
    (state) => state.devices.deviceProperties.perProp
  );
  const [properties, setProperties] = useState(
    filterPropertiesForAutocomplete(initial_props).filter(
      (prop) => !common_columns.includes(prop.name)
    )
  );
  const [columnOrder, setColumnOrder] = useState(
    default_columns ? default_columns.map((_, i) => i) : [...Array(8).keys()]
  );

  const [page, setPage] = useState(customPage ? search_table_settings.page : 0);
  const [prop_search, setPropSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState(search_term);
  const [saveDialog, setSaveDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const open = Boolean(anchorEl);
  const classes = styles();
  const dispatch = useDispatch();

  // Apply default columns, if not get the standard ones
  const setColumnsState = () =>
    default_columns
      ? addColumnDynamicProps(default_columns)
      : // ? default_columns.map((col) =>
        //     col.name === "Alert Status"
        //       ? // Could do with fixing this, hacky way to display status
        //         getBaseColumns({ "Alert Status": propMap["Alert Status"] })[0]
        //       : col
        //   )
        getBaseColumns(propMap);

  if (rows.length <= rowsPerPage && page !== 0 && !loading) {
    setPage(0);
  }

  // Restart the column order if the view changes
  useEffect(() => {
    if (default_columns) {
      setColumnOrder(default_columns.map((_, i) => i));
      setProperties(
        filterPropertiesForAutocomplete(initial_props).filter(
          (prop) =>
            !common_columns.includes(prop.name) &&
            !default_columns.filter((col) => col.name === prop.name).length
        )
      );
    } else {
      setColumnOrder(Object.keys(propMap).map((_, i) => i));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view_name]);

  // Sets custom page
  useEffect(() => {
    if (goToPage) setPage(goToPage);
  }, [goToPage]);

  // If view changes, update columns
  useEffect(() => {
    setColumns(setColumnsState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view_name]);

  // Assign a custom ID on the original download button so we can use the onClick
  useEffect(() => {
    if (download) {
      for (let element of document.getElementsByClassName(
        "MuiButtonBase-root MuiIconButton-root"
      ))
        if (element.getAttribute("data-testid") === "DownloadCSV-iconButton") {
          element.className = "hideElement";
          element.setAttribute("id", "originalDownload");
        }
    }
  }, [download]);

  // If theres a set number of rows, hide bottom pagination
  useEffect(() => {
    if (
      setRows &&
      document.getElementsByClassName("MuiTablePagination-input").length
    ) {
      for (let element of document.getElementsByClassName(
        "MuiTablePagination-caption"
      ))
        if (element.innerHTML === "Rows per page:")
          element.className = "hideElement";
      document
        .getElementsByClassName("MuiTablePagination-input")
        .item(0).className = "hideElement";
    }
  }, [setRows]);

  useEffect(() => {
    if (search_term) setSearchTerm(search_term);
  }, [search_term]);

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleClick = (row) => rowClickCallback(row, searchTerm);

  const handleColumnPositionChange = (order) => {
    if (order !== columnOrder) {
      setColumnOrder(order);
    }
    const newColumns = order.map((i) => columns[i]);
    // setColumns(newColumns);
    if (!IS_EMBEDDED && view_name !== "All Services")
      saveUserView(view_name, newColumns);
  };

  const handleViewColumnsChange = (changedColumn) => {
    const newColumns = columns.map((col) => ({
      ...col,
      options: {
        ...col.options,
        display:
          col.name === changedColumn
            ? toggleDisplayStatus(col.options.display)
            : col.options.display,
      },
    }));
    setColumns(newColumns);
    if (!IS_EMBEDDED && view_name !== "All Services") {
      saveUserView(view_name, newColumns);
    }
  };

  // Toggle status for string and bool types.
  const toggleDisplayStatus = (display) => {
    if (display === "false" || display === false) return true;
    if (display === "true" || display === true) return false;
  };

  const updateRowsExpanded = (allRowsExpanded) =>
    setRowsExpanded(allRowsExpanded.map((row, index) => row.dataIndex));

  const [userViewIsSaving, setUserViewIsSaving] = useState(false);

  const [saveIsInFlight, setSaveIsInFlight] = useState(false);

  const saveUserView = async (save_name, newColumns) => {
    setUserViewIsSaving(true);
    var updateFilter = deviceFilter || [];
    if (deviceFilter && deviceFilter.length === 1) {
      if (
        deviceFilter[0]["property"] === "" &&
        deviceFilter[0]["value"] === "" &&
        deviceFilter[0]["operator"] === null
      ) {
        updateFilter = [];
      }
    }
    const payload = {
      table: viewComponent,
      view_name: save_name,
      user_id: user_id,
      columns: newColumns,
      filters: updateFilter,
    };

    if (!defaultView || user_id !== "default")
      await dispatch(updateUserSettings(payload)).then(() => {
        setUserViewIsSaving(false);
        setSaveDialog(false);
      });
    else {
      setSaveIsInFlight(true);
      await dispatch(createUserSettings(payload)).then(() => {
        setUserViewIsSaving(false);
        setSaveDialog(false);
      });
      setSaveIsInFlight(false);
    }
  };

  let tableName = viewComponent.split("Table")[0].toUpperCase();

  const rowsPerPageAction = (row_count) => ({
    type: `${tableName}_SEARCH_TABLE_ROWS_CHANGE`,
    payload: { rowsPerPage: row_count },
  });
  const pageAction = (page) => ({
    type: `${tableName}_SEARCH_TABLE_PAGE_CHANGE`,
    payload: { page: page },
  });

  const getBaseColumns = (propMap) => {
    const cols = Object.keys(propMap).map((key) => ({
      name: key,
      label: propMap[key].label || key,
      options: {
        display: propMap[key].display || true, //  === "excluded" ? false : true,
        //   filter: propMap[key].filter,
        //   filterType: "custom",
        //   filterOptions: {
        //     logic: (item, filters) => {
        //       if (filters.length) {
        //         return !filters.includes(item);
        //       }
        //       return false;
        //     },
        //     display: CustomFilterControl,
        //   },
        sortDirection: propMap[key].sortDirection,
        sortCompare: propMap[key].sortFunction,
        customBodyRender: propMap[key].customBodyRender,
        customBodyRenderLite: propMap[key].customBodyRenderLite,
      },
    }));
    return cols;
  };

  const addColumnDynamicProps = (columns) => {
    columns.forEach((column, index) => {
      var name = column.name;
      if (propMap[name] && propMap[name]["customBodyRender"]) {
        // replace column with propMap version.
        var prop = getBaseColumns({ [name]: propMap[name] });
        // use column display value rather than propMap.
        prop[0].options.display = column.options.display;
        columns[index] = prop[0];
      }
    });
    return columns;
  };

  const addToColumnList = (prop) => {
    const newColumns = [
      ...columns,
      ...[{ name: prop, label: prop, options: { display: true } }],
    ];
    setColumnOrder([...columnOrder, newColumns.length - 1]);
    setColumns(newColumns);
    setPropSearch("");
    setProperties(properties.filter((entry) => entry.name !== prop));
    if (!IS_EMBEDDED && view_name !== "All Services")
      saveUserView(view_name, newColumns);
  };

  const removeCustomColumn = (col) => {
    const newColumns = columns.filter(
      (view_col) => view_col.label !== col.label
    );
    setColumns(newColumns);
    setColumnOrder(newColumns.map((_, i) => i));
    const initial_prop = initial_props.filter(
      (prop) => prop.name === col.label
    )[0];

    setProperties(
      [...properties, ...[initial_prop]].sort((a, b) =>
        a.group > b.group ? 1 : b.group > a.group ? -1 : 0
      )
    );
    if (!IS_EMBEDDED && view_name !== "All Services")
      saveUserView(view_name, newColumns);
  };

  const CustomToolbar = () => (
    <>
      <Paper
        style={{
          width: "100%",
          padding: 10,
          ...(title && {
            paddingRight: 5,
            paddingTop: 5,
          }),
          display: "flex",
          justifyContent: "center",
          marginBottom: 10,
        }}
        elevation={1}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {title && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {customToolbar}
              <Typography gutterBottom style={{ paddingTop: 5 }}>
                <b>{title}</b>
              </Typography>
              <div />
            </div>
          )}
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
            }}
          >
            <TextField
              value={searchTerm}
              //style={{ height: "100px"}}
              variant="outlined"
              size="small"
              placeholder={"Search"}
              InputLabelProps={{
                style: { height: "10px" },
              }}
              InputProps={{
                style: { height: "36.5px" },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
              onChange={(e) => {
                setSearchTerm(e.target.value);
                if (searchCallback) searchCallback(e.target.value);
              }}
            />
            {renderAddMaintenanceButton && renderAddMaintenanceButton()}
            {download && (
              <div style={{ marginLeft: 10 }}>
                <Tooltip title="Download CSV">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      document.getElementById("originalDownload").click()
                    }
                  >
                    <CloudDownload />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        {editableColumns && allowMultipleViews && !IS_EMBEDDED && (
          <div style={{ marginLeft: 10 }}>
            <SavedViewsMenu
              user_views={user_views}
              view_name={view_name}
              viewComponent={viewComponent}
              user_id={user_id}
              callback={updateFilterCallback}
              saveViewCallback={() => setSaveDialog(true)}
              allowMultipleViews
            />
          </div>
        )}
        {FilterSettings && (
          <div style={{ marginLeft: 10 }}>{FilterSettings}</div>
        )}
        {!title && customToolbar}
        {(selectColumns || user_views) && (
          <div style={{ marginLeft: 10 }}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="outlined"
              color="primary"
              size="medium"
              disableElevation
              onClick={handleMenuClick}
              endIcon={<KeyboardArrowDown />}
            >
              Columns
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              {columns
                .filter((col) => col.options.display !== "excluded")
                .map((col) => (
                  <MenuItem
                    key={col.name}
                    disableRipple
                    style={{ width: "100%", position: "flex" }}
                  >
                    <Checkbox
                      disableRipple
                      disabled={saveIsInFlight}
                      checked={
                        col.options.display === true ||
                        col.options.display === "true"
                      }
                      onClick={() => handleViewColumnsChange(col.name)}
                    />
                    <ListItemText style={{ paddingLeft: 24 }}>
                      {col.label}
                    </ListItemText>
                    {!common_columns.includes(col.label) && (
                      <ListItemIcon>
                        <IconButton
                          color="primary"
                          onClick={() => removeCustomColumn(col)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItemIcon>
                    )}
                  </MenuItem>
                ))}
              {editableColumns && (
                <MenuItem
                  // onClick={() => setEditColumnsDialogOpen(true)}
                  disableRipple
                  onKeyDown={(e) => e.stopPropagation()}
                  style={{ width: "100%" }}
                >
                  <Autocomplete
                    disabled={saveIsInFlight}
                    options={properties || [{ name: "Loading properties..." }]}
                    getOptionLabel={(option) => option.name}
                    groupBy={(option) => option.group}
                    inputValue={prop_search}
                    onChange={(_, v) => v && addToColumnList(v.name)}
                    className={classes.additional_props}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        key={params}
                        {...params}
                        placeholder={"Add Property..."}
                        fullWidth
                        onChange={(e) => setPropSearch(e.target.value)}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // inputProps={{
                        //   ...params.inputProps,
                        //   onChange(event) {
                        //     // val.function(event.target.value)
                        //     return params.inputProps.onChange(event);
                        //   },
                        // }}
                      />
                    )}
                  />
                </MenuItem>
              )}
            </StyledMenu>
          </div>
        )}
        {filter && (
          <div style={{ marginLeft: 10 }}>
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleMenuClick}
              endIcon={<KeyboardArrowDown />}
            >
              Filter
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 18,
                  paddingRight: 16,
                  paddingTop: 7,
                  paddingBottom: 5,
                }}
              >
                <Typography
                  variant="button"
                  style={{ marginTop: 6, marginRight: 10 }}
                >
                  Filters
                </Typography>
                <Button variant="contained" color="primary" disableElevation>
                  Reset
                </Button>
              </div>
              {columns.map(
                (col) =>
                  col.options.display && (
                    <MenuItem key={col.name} disableRipple>
                      <TextField
                        select
                        fullWidth
                        label={col.label}
                        variant={"outlined"}
                        disabled={!rows.length}
                        size={"small"}
                      >
                        {[
                          ...new Map(
                            rows.map((check) => [check[col.name], check])
                          ).values(),
                        ].map(
                          (row) =>
                            row[col.name] !== undefined && (
                              <MenuItem value={row[col.name]}>
                                {col.label === "Alert Began"
                                  ? epochToTimestamp(row[col.name])
                                  : col.label === "Billing Reference"
                                  ? checkBillingRef(row[col.name])
                                  : row[col.name]}
                              </MenuItem>
                            )
                        )}
                      </TextField>
                    </MenuItem>
                  )
              )}
            </StyledMenu>
          </div>
        )}
      </Paper>
    </>
  );

  const [columns, setColumns] = useState(() =>
    setColumnsState(default_columns)
  );

  useEffect(() => {
    if (!default_columns) {
      setColumns(setColumnsState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propMap]);

  return (
    <div className={classes.root}>
      <SaveUserView
        state={saveDialog}
        viewName={view_name}
        defaultView={view_name === "All Services"}
        handleSave={(save_name) => saveUserView(save_name, columns)}
        handleClose={() => setSaveDialog(false)}
        userViewIsSaving={userViewIsSaving}
      />
      <MUIDataTable
        data={rows}
        columns={columns}
        options={{
          customToolbar: CustomToolbar,
          download: download ? true : false,
          downloadOptions: download && {
            filename: download,
            separator: ",",
            filterOptions: {
              useDisplayedColumnsOnly: true,
              useDisplayedRowsOnly: true,
            },
          },
          elevation: elevation || 2,
          print: false,
          responsive: "standard",
          filter: false,
          sort: true,
          search: false,
          searchText: searchTerm,
          customSearchRender: () => null,
          draggableColumns: { enabled: draggableColumns },
          sortOrder: sortProp && { name: sortProp, direction: "desc" },
          rowsPerPage: setRows || rowsPerPage,
          page: page,
          rowHover: true,
          viewColumns: false,
          selectableRows: selectableRows || "none",
          selectableRowsHeader: false,
          selectToolbarPlacement: "none",
          isRowSelectable: isRowSelectable,
          //columnOrder: viewComponent && columnOrder,
          columnOrder: columnOrder
            ? viewComponent
            : viewComponent && columnOrder,

          onRowSelectionChange: (_, all) =>
            rowSelectionCallback(all.map((row) => rows[row.dataIndex])),
          onRowClick: (_, rowMeta) =>
            rowClickCallback &&
            !expandableRows &&
            handleClick(rows[rowMeta.dataIndex]),
          onColumnOrderChange: (order) => handleColumnPositionChange(order),
          onViewColumnsChange: (changedColumn, action) =>
            handleViewColumnsChange(changedColumn, action),
          onChangePage: (page) => {
            setPage(page);
            dispatch(pageAction(page));
          },
          onChangeRowsPerPage: (rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
            dispatch(rowsPerPageAction(rowsPerPage));
          },
          setTableProps: () => ({ size: "small" }),
          expandableRows: expandableRows,
          expandableRowsHeader: expandableRows,
          expandableRowsOnClick: expandableRows,
          isRowExpandable: (dataIndex, expandedRows) =>
            !(
              expandedRows.data.length > 4 &&
              expandedRows.data.filter((d) => d.dataIndex === dataIndex)
                .length === 0
            ),
          renderExpandableRow: (_, indexData) =>
            expandableRows ? expandableRows(rows[indexData.dataIndex]) : null,
          rowsExpanded: rowsExpanded,
          onRowExpansionChange: (_, allRowsExpanded) =>
            updateRowsExpanded(allRowsExpanded),
          textLabels: {
            body: {
              noMatch: loading ? (
                <CircularProgress />
              ) : (
                "Sorry, there is no data to display"
              ),
            },
          },
          setRowProps: selectedRow
            ? (selected) => {
                if (selected[0] === rows[selectedRow].time)
                  return { style: { background: "lightgray" } };
              }
            : () => {
                if (viewComponent === "DevicesTable") {
                  return { style: { cursor: "pointer" } };
                }
              },
        }}
      />
    </div>
  );
}
