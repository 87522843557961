import React from "react";
import { Line } from "react-chartjs-2";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import { Collapse, Tooltip } from "@material-ui/core";
import CustomDatatable from "../CustomDatatable";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      overflow: "hidden",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function FullScreenGraph(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isOpen);
  const [rawData, setRawData] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  // const scrollRef = useRef(null);

  // useEffect(() => {
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollIntoView({ behaviour: "smooth" });
  //   }
  // }, [rawData]);

  const handleClickOpen = () => {
    setOpen(true);
    props.onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleRawDataClick = () => {
    setRawData(!rawData);
    window.scrollY = 1000;
  };

  const handleGraphClick = (index) => {
    setSelectedIndex(index);
    setRawData(true);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        className={classes.root}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {props.options.title.text}
        </DialogTitle>
        <DialogContent>
          <Paper className={classes.root}>
            <div>
              <Line
                data={props.data}
                options={{
                  ...props.options,
                  onClick: (_, elm) =>
                    elm.length && handleGraphClick(elm[0]._index),
                }}
              />
            </div>
          </Paper>
          <div
            style={{
              margin: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="Raw Data" onClick={handleRawDataClick}>
              {rawData ? <ArrowUpward /> : <ArrowDownward />}
            </Tooltip>
          </div>
          <Collapse in={rawData}>
            <CustomDatatable
              viewComponent={"GraphTable"}
              customPage
              propMap={props.propMap}
              rows={props.rows}
              setRows={10}
              elevation={1}
              goToPage={
                selectedIndex === null
                  ? 0
                  : Math.floor((props.rows.length - 1 - selectedIndex) / 10)
              }
              selectedRow={props.rows.length - selectedIndex - 1}
              download={props.title}
            />
            <div />
          </Collapse>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton aria-label="delete" color="primary" onClick={handleClickOpen}>
        <ZoomOutMapIcon />
      </IconButton>
    </React.Fragment>
  );
}
