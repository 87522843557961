import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField, Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  "@global": {
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
  },
  root: {
    marginBottom: theme.spacing(1),
  },
  div: {
    width: "100%",
    height: theme.spacing(16.3),
  },
  grid: {
    height: "100%",
    paddingTop: theme.spacing(1),
  },
  item: {
    height: "100%",
  },
  button: {
    width: "10%",
  },
  centered: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
  },
}));

export default function FullCustomerForm({ callback }) {
  const classes = styles();
  const [customer, setCustomer] = React.useState("");

  const handleChange = (e) => {
    setCustomer(e.target.value);
  };

  const saveConfig = () => callback(customer);

  return (
    <>
      <Typography variant="h4" className={classes.root}>
        Full Customer
      </Typography>
      <Typography variant="body">
        Enter the new customer name to begin the full provisioning process.
      </Typography>
      <div className={classes.centered}>
        <TextField
          label="Customer"
          style={{ margin: "auto" }}
          value={customer}
          variant="outlined"
          size="small"
          onChange={handleChange}
        />
      </div>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={!customer}
            onClick={() => saveConfig()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
