import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Grid, Typography } from "@material-ui/core";
import { PREFIX } from "../../constants";
import { TableContainer } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  text: {
    marginBottom: 10,
    marginTop: 10,
  },
}));
export default function AddAccountIds({
  open,
  accounts,
  circleState,
  callBack,
  startUpdate,
}) {
  const [state, setOpen] = React.useState(open);
  const [circle, setCircle] = React.useState(false);
  const [confirm, setConfirm] = React.useState("");
  const [updates, setUpdates] = React.useState([]);
  const classes = useStyles();
  React.useEffect(() => {
    setOpen(open);
    setCircle(circleState);
    setUpdates(accounts);
  }, [open, accounts, circleState]);
  const handleClose = () => {
    setConfirm("");
    setOpen(false);
    callBack();
  };
  const checkValuesAreChanged = () => {
    var setter = false;
    if (confirm === "confirm") {
      updates.forEach((val) => {
        if (!val.accId) {
          setter = true;
        }
      });
    } else setter = true;
    return setter;
  };
  const submitUpdate = () => {
    setConfirm("");
    startUpdate(updates);
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={state}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Account IDs</DialogTitle>
        <DialogContent>
          <Typography color="primary" variant="h4" className={classes.text}>
            You need to add the account IDs for the following
            {PREFIX === "dws" ? " resellers" : " customers"} before activating
            their LogicOne accounts.
          </Typography>
          <Typography color="primary" variant="h4" className={classes.text}>
            Please ensure each ID is correct before starting the update.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.demo}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {PREFIX === "dws" ? "Reseller" : "Customer"}
                        </TableCell>
                        <TableCell>Account ID</TableCell>
                      </TableRow>
                      {updates.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            <TextField
                              margin="dense"
                              id={"account_tf_" + i}
                              onChange={(e) => {
                                let tmpArray = [...updates];
                                let tmp = { ...row };
                                tmp.accId = e.target.value;
                                if (!tmpArray.length) {
                                  tmpArray.push(tmp);
                                } else {
                                  for (let i = 0; i < tmpArray.length; i++) {
                                    if (tmpArray[i].id === tmp.id) {
                                      tmpArray[i] = tmp;
                                      break;
                                    }
                                  }
                                }
                                setUpdates(tmpArray);
                              }}
                              fullWidth
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
          <Box fontStyle="italic">
            <Typography
              color="secondary"
              variant="h5"
              className={classes.text}
              component="div"
            >
              Please type 'confirm' in the box below.
            </Typography>
          </Box>
          <TextField
            autoFocus
            margin="dense"
            label="Update Confirmation"
            fullWidth
            variant="outlined"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
          />
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <Button
                onClick={submitUpdate}
                variant="contained"
                color="primary"
                disabled={checkValuesAreChanged()}
              >
                Next
              </Button>
            </Grid>
            {circle ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
