import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box, CircularProgress, Grid } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  root: {
    width: 800,
    height: 300,
    overflowY: "scroll",
  },
}));

function getColour(value) {
  if (value === 200) return { color: "#5CD15C" };
  else if (value === 419) return { color: "#FDC703" };
  else if (value === 500) return { color: "#FF2D00" };
  else return;
}

export default function JobConsole({ running, response }) {
  const classes = styles();
  let output = [];

  if (response) {
    const breakdown = response.breakdown;
    var breakdowns = [];
    output = response.output.split("\n");
    for (const [key] of Object.entries(breakdown))
      breakdowns.push({ name: key, value: breakdown[key] });
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container alignItems="flex-start">
          <Grid container item xs={4} justifyContent="flex-start">
            {running ? <CircularProgress size={20} /> : null}
          </Grid>

          <Grid container item xs={4} justifyContent="center">
            <Typography
              variant="h3"
              color="primary"
              align="center"
              style={{ marginBottom: 5 }}
            >
              Live Feedback
            </Typography>
          </Grid>

          <Grid item xs={4}>
            {/* Intentionally empty */}
          </Grid>
        </Grid>
        {response ? (
          <React.Fragment>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item>
                <Typography variant="h4" color="primary">
                  Status:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" style={getColour(response.overall)}>
                  {response.overall}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              color="primary"
              style={{ marginBottom: 5 }}
            >
              Unique ID: {response.params.id}
            </Typography>

            <Card variant="outlined" style={{ backgroundColor: "#e8e8e8" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  color="primary"
                  align="center"
                  style={{ marginBottom: 5 }}
                >
                  Automation Breakdown
                </Typography>
                <Box width="100%">
                  <Grid container alignItems="flex-end">
                    {breakdowns.map((val) => {
                      return (
                        <Grid item xs>
                          <Grid
                            container
                            alignItems="flex-end"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" color="primary">
                                {val.name.charAt(0).toUpperCase() +
                                  val.name.slice(1)}
                                :
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="subtitle2"
                                style={getColour(val.value)}
                              >
                                {val.value}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </CardContent>
            </Card>
            <Card variant="outlined" style={{ backgroundColor: "#e8e8e8" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  color="primary"
                  align="center"
                  style={{ marginBottom: 5 }}
                >
                  Output
                </Typography>
                {output.map((val, i) => {
                  let subtitle = false;
                  if (val.includes("...*")) subtitle = true;

                  return (
                    <Typography
                      variant={subtitle ? "subtitle2" : "body2"}
                      color={subtitle ? "primary" : "initial"}
                      style={
                        subtitle
                          ? i > 2
                            ? { marginBottom: 10, marginTop: 10 }
                            : { marginBottom: 10 }
                          : null
                      }
                    >
                      {subtitle ? val.substring(1, val.length - 1) : val}{" "}
                      {subtitle ? <br /> : null}
                    </Typography>
                  );
                })}
              </CardContent>
            </Card>
          </React.Fragment>
        ) : null}
      </CardContent>
    </Card>
  );
}
