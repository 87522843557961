import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { fromUnixTime, format } from "date-fns";
import { animateScroll as scroll } from "react-scroll";
import { withRouter } from "react-router";

import {
  // initDeviceSearch,
  listDevicesThenAlerts,
  resetDeviceSearch,
} from "../actions/devices";
import { initRequestWidgetData } from "../actions/dashboard";
import { initRequestAlerts } from "../actions/alerts";
import CustomDatatable from "../components/CustomDatatable";
import ContainerHeader from "../components/ContainerHeader";
import { getFromLS, saveToLS } from "../libs/utils";
import { getPropMap } from "../libs/deviceprops";
import FilterSettings from "../components/FilterSettings";
import { updateDeviceFilter } from "../actions/ui_settings";
import { operatorFilter } from "../libs/utils";

const styles = (theme) =>
  createStyles({
    textField: {},
    sticky: {
      position: "-webkit-sticky" /* Safari */,
      // eslint-disable-next-line
      position: "sticky",
      top: 0,
    },
    grid: {
      marginBottom: theme.spacing(2),
    },
    searchPanel: {
      display: "none",
      marginBottom: theme.spacing(1),
    },
    form: { padding: theme.spacing(2) },
  });

class DevicesContainer extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      search_term: "",
      search_open: false,
      loading_search: false,
      loadingWidgets: false,
      device_type: null,
      deviceFilter:
        this.props.deviceFilter.length.length === 0
          ? [{ property: "", operator: null, value: "" }]
          : this.props.deviceFilter,
      filtersIncludingLS: this.props.deviceFilter,
      filterValid: this.props.deviceFilter.length === 0 ? false : true,
      searchOutput: this.props.searchResults,
    };
  }

  componentDidMount() {
    scroll.scrollToTop();
    this.props.setParentTitle(this.props.match.params.device_type);

    const params = new URLSearchParams(this.props.location.search);
    var search = params.get("search_term") || "";
    if (search) this.updateSearchTerm(search);
    else this.updateSearchTerm(this.props.search_term);
    var filter;
    if (getFromLS("groupBy") && getFromLS("groupBy") !== "") {
      filter = [...this.props.deviceFilter];
      if (
        filter.length === 1 &&
        filter[0]["property"] === "" &&
        filter[0]["operator"] === null &&
        filter[0]["value"] === ""
      ) {
        filter = getFromLS("groupBy")
          .split("+")
          .map((field, idx) => ({
            property: field,
            operator: "equals",
            value: getFromLS("Filter").split("+")[idx],
          }));
      } else {
        let f = getFromLS("groupBy")
          .split("+")
          .map((field, idx) => ({
            property: field,
            operator: "equals",
            value: getFromLS("Filter").split("+")[idx],
          }));
        filter = filter.concat(f);
      }
      this.setState({ deviceFilter: filter, filtersIncludingLS: filter });
      this.updateFilterOutput(filter, true);
    } else {
      filter =
        this.props.deviceFilter.length === 0
          ? [{ property: "", operator: null, value: "" }]
          : this.props.deviceFilter;

      this.setState({ deviceFilter: filter });

      this.updateFilterOutput(filter, true);
    }
  }

  componentWillUnmount() {
    saveToLS("groupBy", "");
  }

  async performSearch(event) {
    event && event.preventDefault();
    this.setState({ loading_search: true });

    await this.props.listDevicesThenAlerts(this.props.account_id);

    this.setState({ loading_search: false });
  }

  resetSearch() {
    this.setState({
      search_term: "",
      loading_search: true,
      search_open: false,
    });
    this.props.resetDeviceSearch();
    this.setState({ loading_search: false });
  }

  updateDeviceFilter = (filter) => {
    var filterValid = true;
    var key;
    for (const item of filter) {
      if (Array.isArray(item)) {
        for (const obj of item) {
          for (key in obj) {
            if (obj[key] === null || obj[key] === "") {
              filterValid = false;
            }
          }
        }
      } else {
        for (key in item) {
          if (item[key] === null || item[key] === "") {
            filterValid = false;
          }
        }
      }
    }
    if (filter.length === 0) {
      filter = [{ property: "", operator: null, value: "" }];
      filterValid = true;
    }
    if (filter.length === 1) {
      if (
        filter[0]["property"] === "" &&
        filter[0]["value"] === "" &&
        filter[0]["operator"] === null
      ) {
        filterValid = true;
      }
    }
    this.setState({
      deviceFilter: filter,
      filterValid: filterValid,
    });
  };

  updateFilterOutput = (filter, searchBoolean = false) => {
    filter = filter || this.state.deviceFilter;
    this.updateDeviceFilter(filter);
    if (!searchBoolean) {
      this.props.updateDeviceFilter(filter);
    }
    var devicesOutput = operatorFilter(
      this.props.searchResults,
      filter || this.state.deviceFilter,
      this.state.filterValid
    );
    this.setState({ searchOutput: devicesOutput, filtersIncludingLS: filter });
  };

  updateSearchTerm(input_text) {
    var searchOpen = false;
    if (input_text) {
      searchOpen = true;
    }
    this.setState({ search_term: input_text, search_open: searchOpen });
  }

  handleRowClick = async (row, search_term) => {
    this.setState({ loadingWidgets: "true" });
    row
      ? this.props.history.push("/device/" + row.id, {
          Device: row,
          device_type: this.props.match.params.device_type,
        })
      : console.log(row);
    saveToLS("search_term", search_term);
  };

  epochToTimestamp(epoch) {
    var date = fromUnixTime(epoch);
    return format(date, "HH:mm:ss dd/MM/yy");
  }

  getFilterSettings = () => {
    return (
      <React.Fragment>
        <FilterSettings
          deviceProperties={this.props.deviceProperties}
          deviceFilter={this.state.deviceFilter}
          savedFilters={this.state.filtersIncludingLS}
          updateDeviceFilter={this.updateDeviceFilter}
          updateFilterOutput={this.updateFilterOutput}
          filterValid={this.state.filterValid}
          viewName={this.props.selectedViews.DevicesTable || "default"}
          byViewName={this.props.byViewName}
          user_id={this.props.user_id}
          viewComponent={"DevicesTable"}
          showSave={false}
        />
      </React.Fragment>
    );
  };

  render() {
    const {
      classes,
      searchResults,
      user_id,
      user_views,
      defaultView,
      deviceFilter,
      selectedViews,
    } = this.props;
    const identifier = this.props.portal_config.identifier;
    const propMap = getPropMap(identifier);

    const common_columns = [
      "Status",
      "Site Name",
      "Postcode",
      "Billing Reference",
      "IP Range",
      "Circuit Reference",
      "Device Type",
      "Circuit Provider Reference",
      "Provider Name",
      "Circuit Size",
    ];

    return (
      <React.Fragment>
        {searchResults ? (
          <>
            <ContainerHeader
              title={
                getFromLS("groupBy") && getFromLS("groupBy") !== ""
                  ? "Search..."
                  : selectedViews.DevicesTable
                  ? selectedViews.DevicesTable.charAt(0).toUpperCase() +
                    selectedViews.DevicesTable.slice(1)
                  : "All Services"
              }
            />
            <CustomDatatable
              propMap={propMap}
              rows={this.state.searchOutput}
              rowClickCallback={this.handleRowClick}
              sortProp={"Alert Status"}
              viewComponent={"DevicesTable"}
              user_views={user_views}
              user_id={user_id || "default"}
              searchCallback={(text) => saveToLS("search_term", text)}
              draggableColumns
              customPage
              defaultView={defaultView}
              view_name={selectedViews.DevicesTable || "All Services"}
              editableColumns
              FilterSettings={this.getFilterSettings()}
              updateFilterCallback={(event) => this.updateFilterOutput(event)}
              deviceFilter={deviceFilter}
              allowMultipleViews
              common_columns={common_columns}
              search_term={getFromLS("search_term")}
            />
            {/*
            <ServicesTableContainer
              device_type={this.props.match.params.device_type}
            />
            */}
          </>
        ) : (
          <CircularProgress className={classes.progress} />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { byDeviceId } = state.alerts;
  const { byId } = state.devices;
  const { account_id } = state.userSession.properties;
  const { search_term } = state.deviceSearch;
  const { view_id } = state.userSession;
  const user_views = state.ui_settings.user_views.byComponent.DevicesTable;
  const { user_id, portal_config } = state.ui_settings;
  const { allProperties } = state.devices.deviceProperties;
  const { deviceFilter, defaultView, byViewName, selectedViews } =
    state.ui_settings.user_views;

  var searchResults = [];

  if (byId) {
    Object.keys(byId).forEach((id) => {
      searchResults.push(byId[id]);
    });
  }

  // @todo change the table to render from object keyed by ids and then change that push
  // above to make an object of keyed elements.
  return {
    byDeviceId,
    deviceProperties: allProperties,
    searchResults,
    account_id,
    search_term,
    view_id,
    user_id,
    user_views,
    deviceFilter,
    defaultView,
    byViewName,
    selectedViews,
    portal_config,
  };
}

const mapDispatchToProps = {
  // initDeviceSearch,
  resetDeviceSearch,
  initRequestWidgetData,
  initRequestAlerts,
  listDevicesThenAlerts,
  updateDeviceFilter,
};

const enhance = compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DevicesContainer);
