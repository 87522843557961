import Amplify, { API, Storage } from "aws-amplify";
import { GATEWAY_KEY, STAGE, PREFIX } from "../constants";

import awsconfig from "../aws-config";

// const awsConfig = {
//     API : {
//         endpoints: [
//             {
//                 name: 'Automation',
//                 endpoint: GATEWAY_URL
//             }
//         ]
//     },
//     Storage: {
//         AWSS3: {
//             bucket: 'logic-one',
//             region: 'eu-west-2'
//         }
//     }
// };

function configureAmplify() {
  Amplify.configure(awsconfig);
}

export async function apiQuery(
  verb,
  endpoint,
  data,
  async,
  override = 0,
  auth
) {
  configureAmplify();

  var myInit = {
    headers: {
      "X-Api-Key": GATEWAY_KEY,
      Authorization: "Bearer " + localStorage.getItem("jwt"),
      "Content-Type": "application/json",
    },
    response: false,
  };

  endpoint = "/" + STAGE + endpoint;

  if (async) myInit.headers.InvocationType = "Event";
  if (auth) myInit.headers.Authorization = "Basic " + auth;

  if ((verb === "GET" || verb === "DELETE") && !override)
    myInit.queryStringParameters = data;
  else myInit.body = data;

  return new Promise((resolve, reject) => {
    switch (verb) {
      case "GET":
        API.get("Automation", endpoint, myInit)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
      case "POST":
        API.post("Automation", endpoint, myInit)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
      case "PATCH":
        API.patch("Automation", endpoint, myInit)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
      case "PUT":
        API.put("Automation", endpoint, myInit)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
      case "DELETE":
        API.del("Automation", endpoint, myInit)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
        break;
      default:
        reject(400);
        break;
    }
  });
}

export async function getProvisionHistory() {
  const key = PREFIX.toUpperCase() + "/JobHistory.json";
  configureAmplify();

  return new Promise((resolve, reject) => {
    Storage.get(key, { level: "public", download: true })
      .then((string) => {
        string.Body.text()
          .then((text) => {
            resolve(JSON.parse(text));
          })
          .catch((e) => reject(e));
      })
      .catch((e) => reject(e));
  });
}
