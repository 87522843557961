import { now } from "moment";
import { RSAA } from "redux-api-middleware";
import { APIURL } from "../constants";
import { apiQuery } from "../libs/auth-config";

export const DEVICE_DASHBOARD_REQUEST = "DEVICE_DASHBOARD_REQUEST";
export const DEVICE_DASHBOARD_SUCCESS = "DEVICE_DASHBOARD_SUCCESS";
export const DEVICE_DASHBOARD_FAILURE = "DEVICE_DASHBOARD_FAILURE";

export function initRequestDashboard(wan_dash_id, range) {
  var range_params = range
    ? "start_epoch=" + range.from + "&end_epoch=" + range.to
    : "";
  var url =
    APIURL +
    "/api/v1/dashboards/dashboard/" +
    wan_dash_id +
    "/widgets/graphs?" +
    range_params;
  console.log(url);
  return {
    [RSAA]: {
      endpoint: url,
      method: "GET",
      types: [
        {
          type: DEVICE_DASHBOARD_REQUEST,
          // meta: { 'goods': goods }
        },
        DEVICE_DASHBOARD_SUCCESS,
        DEVICE_DASHBOARD_FAILURE,
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    },
  };
}

export const DEVICE_WIDGETS_REQUEST = "DEVICE_WIDGETS_REQUEST";
export const DEVICE_WIDGETS_SUCCESS = "DEVICE_WIDGETS_SUCCESS";
export const DEVICE_WIDGETS_FAILURE = "DEVICE_WIDGETS_FAILURE";

export const retrieve_graph_data =
  (account_id, dash_id, range) => (dispatch) => {
    dispatch({ type: DEVICE_WIDGETS_REQUEST });
    apiQuery("GET", "/dashboards/dashboard/data", {
      account_id: account_id,
      dash_id: dash_id,
      start: range.from,
      end: range.to,
    })
      .then((res) =>
        dispatch({
          type: DEVICE_WIDGETS_SUCCESS,
          payload: res.data,
        })
      )
      .catch((e) => {
        dispatch({
          type: DEVICE_WIDGETS_FAILURE,
        });
        console.log(e);
      });
  };

export const DASHBOARD_WIDGET_REQUEST = "DASHBOARD_WIDGET_REQUEST";
export const DASHBOARD_WIDGET_SUCCESS = "DASHBOARD_WIDGET_SUCCESS";
export const DASHBOARD_WIDGET_FAILURE = "DASHBOARD_WIDGET_FAILURE";

export const initRequestWidgetData = (id, account_id) => (dispatch) => {
  dispatch({
    type: DASHBOARD_WIDGET_REQUEST,
  });
  apiQuery("GET", "/dashboards/dashboard/data", {
    account_id: account_id,
    dash_id: id,
    start: now() - 86400000,
    end: now(),
  })
    .then((data) => {
      dispatch({
        type: DASHBOARD_WIDGET_SUCCESS,
        payload: data.data,
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: DASHBOARD_WIDGET_FAILURE });
    });
};

export const REPORT_DASHBOARD_REQUEST = "REPORT_DASHBOARD_REQUEST";
export const REPORT_DASHBOARD_SUCCESS = "REPORT_DASHBOARD_SUCCESS";
export const REPORT_DASHBOARD_FAILURE = "REPORT_DASHBOARD_FAILURE";

export function getDashboardFromReportId(report_id) {
  if (!report_id) {
    return;
  }
  return {
    [RSAA]: {
      endpoint: APIURL + "/api/v1/reports/report/" + report_id + "/dashboard",
      method: "GET",
      types: [
        {
          type: REPORT_DASHBOARD_REQUEST,
          // meta: { 'goods': goods }
        },
        REPORT_DASHBOARD_SUCCESS,
        REPORT_DASHBOARD_FAILURE,
      ],
      headers: {
        // "Authorization": "Basic dWtzcGFya3lAZ21haWwuY29tOnJhcmhvdi1kdXFoQXEtZmlxdmE1",
        // "Content-Type": "application/json",
      },
      // credentials: 'include'
    },
  };
}

export const getGraphConfig = (account_id) => (dispatch) => {
  dispatch({ type: "GRAPH_CONFIG_REQUEST" });
  return apiQuery("PUT", "/dashboards/graphs", {
    account_id: account_id,
  })
    .then((resp) =>
      dispatch({ type: "GRAPH_CONFIG_RETRIEVED", payload: resp.data })
    )
    .catch((e) => {
      console.log(e);
      dispatch({ type: "GRAPH_CONFIG_FAILED" });
    });
};

export const saveGraphConfig = (account_id, name, layout) => {
  return apiQuery("PUT", "/dashboards/graphs", {
    account_id: account_id,
    name: name,
    layout: layout,
  })
    .then((resp) => resp.data)
    .catch((e) => console.log(e));
};
