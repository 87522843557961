import React, { Component } from "react";
import { connect } from "react-redux";
import SummaryStat from "../components/SummaryStat";
import SummaryDashboard from "../components/summaryTileDashoard";
import PageRefreshInput from "../components/PageRefreshInput";
import FilterSettings from "../components/FilterSettings";
import _ from "lodash";
import { IS_EMBEDDED } from "../constants";
import { dispatchChaining, listDevicesThenAlerts } from "../actions/devices";
import { createUserSettings, updateUserSettings } from "../actions/ui_settings";
import {
  Card,
  Chip,
  TextField,
  FormControl,
  Button,
  Tooltip,
  Zoom,
  Switch,
  Grid,
  Slider,
  FormControlLabel,
  IconButton,
  Typography,
  InputAdornment,
  Popover,
  MenuItem,
} from "@material-ui/core";
import {
  Fullscreen,
  FullscreenExit,
  AddCircleOutline,
  RemoveCircleOutline,
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignCenter,
} from "@material-ui/icons/";
import { filterPropertiesForAutocomplete, operatorFilter } from "../libs/utils";
import { CRITICAL_COLOR, ERROR_COLOR, WARNING_COLOR } from "../constants";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { Search, KeyboardArrowDown } from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import SavedViewsMenu from "../components/dialogs/SavedViewsMenu";
import SaveUserView from "../components/dialogs/SaveUserView";
import {
  updateDeviceFilter,
  updateDashboardSettings,
  saveDashboardSettings,
} from "../actions/ui_settings";
import { apiQuery } from "../libs/auth-config";
import { removeNewlines } from "../libs/utils";

// import { initRequestSDTs } from '../actions/maintenance'

class SummaryStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      bySiteTiles: {},
      search_term: "",
      selectedSeverities: ["Critical", "Error", "Warning", "Live"],
      disabledTooltip: false,
      tileSize: parseInt(props.savedDashboardSettings.tileSize),
      textSize: parseInt(props.savedDashboardSettings.textSize),
      sortBy: props.savedDashboardSettings.sortBy || "severity",
      filterOn: "none",
      groupTilesBy:
        props.savedDashboardSettings.groupBy === undefined
          ? "Customer"
          : props.savedDashboardSettings.groupBy,
      filterValid: this.props.deviceFilter.length === 0 ? false : true,
      viewDetailsState: props.savedDashboardSettings.details,
      detailsChecked: true,
      alignTiles: props.savedDashboardSettings.alignment,
      magnifyingGlassRef: React.createRef(),
      savedSettings: {},
      saveUserViewDialog: false,
      deviceFilter:
        this.props.deviceFilter.length === 0
          ? [{ property: "", operator: null, value: "" }]
          : this.props.deviceFilter,
      //anchorEl: React.useState<HTMLButtonElement | null>(null),
      filterProps: filterPropertiesForAutocomplete(props.perProp)
        .filter(
          (prop) =>
            this.getDashboardGroupByProperties().includes(prop.name) ||
            prop.group === "Custom"
        )
        .sort((a, b) => (a.group > b.group ? 1 : b.group > a.group ? -1 : 0)),
    };
    this.emptyFilterCheck = this.emptyFilterCheck.bind(this);
  }

  componentDidMount() {
    this.props.setParentTitle("Summary");
    this.emptyFilterCheck();
    this.gatherStatusTiles();
    this.updateDeviceFilter(this.state.deviceFilter, true);
  }

  getDashboardGroupByProperties() {
    return this.props.dashboard_groupby_properties
      ? this.props.dashboard_groupby_properties
      : [];
  }

  emptyFilterCheck() {
    var emptyCatch;
    const viewName = this.props.selectedViews.DevicesTable || "All Services";

    if (this.props.byViewName[viewName]["filters"].length === 0) {
      emptyCatch = [{ property: "", operator: null, value: "" }];
    } else {
      emptyCatch = this.props.byViewName[viewName]["filters"];
    }
    this.setState({
      deviceFilter: emptyCatch,
    });
  }

  /*componentDidUpdate(prevProps) {
    this.updateDeviceFilter(this.props.deviceFilter)
  }*/

  componentWillUnmount() {
    // Save the most recent dashboard settings locally in the store.
    let response = this.props.mainExpand
      ? this.props.expandMain(this.props.mainExpand)
      : null;
    return response;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.tileSize !== this.state.tileSize ||
      prevState.alignTiles !== this.state.alignTiles ||
      prevState.viewDetailsState !== this.state.viewDetailsState ||
      prevState.groupTilesBy !== this.state.groupTilesBy ||
      prevState.textSize !== this.state.textSize ||
      prevState.sortBy !== this.state.sortBy
    ) {
      this.saveDashboardSettings();
    }

    if (prevProps.deviceFilter !== this.props.deviceFilter) {
      this.gatherStatusTiles();
    }
  }

  getDevicesThenAlerts() {
    this.gatherStatusTiles();
    if (this.props.account_id.includes("_SUPER")) {
      return this.props.dispatchChaining(this.props.account_id);
    } else {
      return this.props.listDevicesThenAlerts(this.props.account_id);
    }
  }

  closeGroupBy = () => {
    this.setState({ anchorEl: undefined });
  };

  closeSaveDialog = () => {
    this.setState({ saveUserViewDialog: false });
  };

  openSaveDialog = () => {
    this.setState({ saveUserViewDialog: true });
  };

  saveUserView(save_name) {
    var { deviceFilter, user_id, currentColumns } = this.props;
    var updateFilter = deviceFilter;
    if (deviceFilter.length === 1) {
      if (
        deviceFilter[0]["property"] === "" &&
        deviceFilter[0]["value"] === "" &&
        deviceFilter[0]["operator"] === null
      ) {
        updateFilter = [];
      }
    }
    const payload = {
      table: "DevicesTable",
      view_name: save_name,
      user_id: user_id,
      filters: updateFilter,
    };

    if (currentColumns) {
      payload["columns"] = currentColumns;
    }

    if (user_id !== "default") this.props.updateUserSettings(payload);
    else this.props.createUserSettings(payload);
  }

  gatherStatusTiles(groupBy = this.state.groupTilesBy, filter = null) {
    var { alerts, devicesArray } = this.props;
    var bySiteTiles = {};
    if (!devicesArray) return;

    var filterInUse = filter || this.props.deviceFilter;

    let filteredDevices = operatorFilter(devicesArray, filterInUse);
    let deviceGroupings = this.groupDevices(filteredDevices, groupBy);

    for (const [site, element] of Object.entries(deviceGroupings)) {
      let alertInfo = {
        Severity: "Live",
        boxColor: "green",
        AlertTotal: 0,
        devices: element,
        SeverityInt: 0,
      };
      if (site) {
        bySiteTiles[site] = alertInfo;
      } else {
        bySiteTiles["undefined"] = alertInfo;
      }
    }

    // Shift everything without a site name into undefined.
    if (deviceGroupings[""] !== undefined) {
      // Push to devices array if exists.
      if (bySiteTiles["undefined"] !== undefined) {
        deviceGroupings[""].forEach((device) => {
          if (!bySiteTiles["undefined"].devices.includes(device)) {
            bySiteTiles["undefined"].devices.push(device);
          }
        });
      }
    }

    for (const element of Object.values(alerts)) {
      for (const [siteKey, siteElement] of Object.entries(bySiteTiles)) {
        if (siteElement["devices"].indexOf(element["monitorObjectId"]) > -1) {
          if (
            element["severityLabel"] === "Warning" &&
            bySiteTiles[siteKey]["Severity"] !== "Critical" &&
            bySiteTiles[siteKey]["Severity"] !== "Error"
          ) {
            bySiteTiles[siteKey]["Severity"] = element["severityLabel"];
            bySiteTiles[siteKey]["boxColor"] = WARNING_COLOR;
            bySiteTiles[siteKey]["SeverityInt"] = element["severity"];
          } else if (
            element["severityLabel"] === "Error" &&
            bySiteTiles[siteKey]["Severity"] !== "Critical"
          ) {
            bySiteTiles[siteKey]["Severity"] = element["severityLabel"];
            bySiteTiles[siteKey]["boxColor"] = ERROR_COLOR;
            bySiteTiles[siteKey]["SeverityInt"] = element["severity"];
          } else {
            bySiteTiles[siteKey]["Severity"] = element["severityLabel"];
            bySiteTiles[siteKey]["boxColor"] = CRITICAL_COLOR;
            bySiteTiles[siteKey]["SeverityInt"] = element["severity"];
          }
          bySiteTiles[siteKey]["AlertTotal"] += 1;
        }
      }
    }
    var sorted = {};
    let sortedKeys = [];

    if (this.state.sortBy === "ascending") {
      sortedKeys = Object.keys(bySiteTiles).sort((a, b) => a.localeCompare(b));
    } else if (this.state.sortBy === "descending") {
      sortedKeys = Object.keys(bySiteTiles)
        .sort((a, b) => a.localeCompare(b))
        .reverse();
    } else if (this.state.sortBy === "severity") {
      // Group by severity, then reverse so most severe comes first,
      // alphabetically sort each group, then flatten into one long ordered list.
      let severityLevels = _.groupBy(
        Object.keys(bySiteTiles),
        (k) => bySiteTiles[k].SeverityInt
      );
      sortedKeys = _.flatten(
        Object.keys(severityLevels)
          .reverse()
          .map((sl) => {
            return severityLevels[sl].sort((a, b) => a.localeCompare(b));
          })
      );
    }

    sortedKeys.forEach((key) => {
      sorted[`${key === "undefined" || "" ? "Ungrouped" : key} `] =
        bySiteTiles[key];
    });

    this.setState({ bySiteTiles: sorted });
  }

  groupDevices(devices, groupings) {
    // Concatenate the values of each of the desired keys to create
    // an identifier for each device.
    let g;
    if (typeof groupings === "string") {
      g = [groupings];
    } else {
      g = groupings;
    }

    const grouped = _.groupBy(devices, (device) => {
      //return g.map((field) => device[field]).join("+");
      return g.map((field) => removeNewlines(device[field])).join("+");
    });

    // Just get device ID's for each key.
    Object.keys(grouped).map(
      (k) => (grouped[k] = grouped[k].map((device) => device.id))
    );
    return grouped;
  }

  updateSearchTerm(search) {
    this.setState({ search_term: search });
  }

  updateDeviceFilter = (filter, filterBoolean = false) => {
    let filterCopy = JSON.parse(JSON.stringify(filter));
    var filterValid = true;
    var key;
    for (const item of filterCopy) {
      if (Array.isArray(item)) {
        for (const obj of item) {
          for (key in obj) {
            if (obj[key] === null || obj[key] === "") {
              filterValid = false;
            }
          }
        }
      } else {
        for (key in item) {
          if (item[key] === null || item[key] === "") {
            filterValid = false;
          }
        }
      }
      //this.gatherStatusTiles()
    }
    if (filterCopy.length === 0) {
      filterCopy = [{ property: "", operator: null, value: "" }];
      filterValid = true;
    }
    if (filterCopy.length === 1) {
      if (
        filterCopy[0]["property"] === "" &&
        filterCopy[0]["value"] === "" &&
        filterCopy[0]["operator"] === null
      ) {
        filterValid = true;
      }
    }
    /* Don't save filters unless user hits "Apply".
    if (!filterBoolean) {
      this.props.updateDeviceFilter(filterCopy);
    }
    */
    this.setState({ deviceFilter: filterCopy, filterValid: filterValid });
  };

  formatDashboardData = () => {
    let detailsState = this.state.viewDetailsState;
    if (typeof this.state.viewDetailsState === "string") {
      detailsState = this.state.viewDetailsState === "true";
    }
    return {
      textSize: this.state.textSize,
      tileSize: this.state.tileSize,
      details: detailsState,
      alignment: this.state.alignTiles,
      groupBy: this.state.groupTilesBy,
      sortBy: this.state.sortBy,
    };
  };

  updateFilterOutput = () => {
    this.props.updateDeviceFilter(this.state.deviceFilter);
  };

  updateSeverityFilter = (value) => {
    this.setState({ selectedSeverities: value });
  };

  fullscreenPassback = () => {
    this.props.expandMain(this.props.mainExpand);
    this.setState({ disabledTooltip: false });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleSliderChange = (event, newValue) => {
    this.setState({ tileSize: newValue });
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  increaseTileSize = () => {
    if (this.state.tileSize < 8) {
      this.setState({ tileSize: this.state.tileSize + 1 });
    }
  };

  decreaseTileSize = () => {
    if (this.state.tileSize > 2) {
      this.setState({ tileSize: this.state.tileSize - 1 });
    }
  };

  handleTextSizeChange = (event, newValue) => {
    this.setState({ textSize: newValue });
  };

  increaseTextSize = () => {
    if (this.state.textSize <= 8) {
      this.setState({ textSize: this.state.textSize + 1 });
    }
  };

  decreaseTextSize = () => {
    if (this.state.textSize > 2) {
      this.setState({ textSize: this.state.textSize - 1 });
    }
  };

  toggleTileResizer = () => {
    this.setState({ openResizeIconsWindow: !this.state.openResizeIconsWindow });
  };
  //bySiteTiles = this.gatherStatusTiles(devices,alerts)

  handleAlignmentChange = (a) => {
    if (a != null) {
      this.setState({ alignTiles: a });
    }
  };

  saveDashboardSettings = async () => {
    // Embedded user's can't save dashboard settings
    // since they don't have accounts.
    const data = this.formatDashboardData();
    if (!IS_EMBEDDED) {
      apiQuery("PUT", "/views/dashboards/settings", data).then((res) => {
        this.props.saveDashboardSettings(data, res.data.id);
        this.gatherStatusTiles();
      });
    } else {
      this.props.saveDashboardSettings(data, "default");
      this.gatherStatusTiles();
    }
  };

  render() {
    const {
      devices,
      byStatus,
      account_id,
      deviceProperties,
      view_id,
      user_id,
      user_views,
      defaultView,
      byViewName,
    } = this.props;
    if (!byStatus) {
      return null;
    }
    const open = Boolean(this.state.anchorEl);
    //this.setState({ deviceFilter: byViewName['Connectivity']['filters']})

    //const groupByOptions = ["Customer", "Postcode"];

    var bySiteTilesState = this.state.bySiteTiles;
    var bySiteTiles = {};
    Object.keys(bySiteTilesState).forEach((tile) => {
      if (
        tile.toLowerCase().includes(this.state.search_term.toLowerCase()) &&
        (this.state.selectedSeverities.includes(
          bySiteTilesState[tile]["Severity"]
        ) ||
          this.state.selectedSeverities.length === 4)
      ) {
        bySiteTiles[tile] = bySiteTilesState[tile];
      }
    });

    var live_services = devices ? Object.keys(devices).length : "N/A";
    var tiles = [
      {
        title: "Live Services",
        count: live_services,
        link: "/services/connectivity",
      },
      {
        title: "Critical Services",
        count: byStatus["Critical"] ? byStatus["Critical"].length : 0,
        link: "/alerts?filter=Critical",
        status: "Critical",
      },
      {
        title: "Services in Error",
        count: byStatus["Error"] ? byStatus["Error"].length : 0,
        link: "/alerts?filter=Error",
        status: "Error",
      },
      {
        title: "Services in Warning",
        count: byStatus["Warning"] ? byStatus["Warning"].length : 0,
        link: "/alerts?filter=Warning",
        status: "Warning",
      },
    ];

    return (
      <div>
        <div style={{ float: "right" }}>
          <SaveUserView
            state={this.state.saveUserViewDialog}
            viewName={view_id}
            defaultView={view_id === "All Services"}
            handleSave={(save_name) => {
              this.saveUserView(save_name);
              this.closeSaveDialog();
            }}
            handleClose={() => this.closeSaveDialog()}
          />
        </div>
        <div>
          <Grid container spacing={1} sx={{ pt: 2 }}>
            {tiles.map((tile, index) => {
              return <SummaryStat key={index} tile={tile} />;
            })}
          </Grid>
        </div>
        <div style={{ height: "16px" }}></div>
        {!account_id.includes("_SUPER") && (
          <Card
            elevation={1}
            style={{ textAlign: "center", overflow: "hidden" }}
          >
            <form
              onSubmit={(event) => this.handleSubmit(event)}
              id="searchForm"
              style={{
                padding: "20px",
                paddingTop: "25px",
                display: "inline-block",
                width: "90%",
              }}
            >
              <Grid
                container
                spacing={1}
                justifyContent="space-evenly"
                alignContent="center"
              >
                <Grid item xs>
                  <FormControl style={{ display: "grid" }}>
                    <TextField
                      id="searchInput"
                      variant="outlined"
                      style={{
                        minWidth: "30px",
                        width: "inherit",
                        //marginTop: "-1.5px",
                      }}
                      placeholder="Search"
                      value={this.state.search_term}
                      autoComplete="off"
                      size="small"
                      onChange={(event) =>
                        this.updateSearchTerm(event.target.value)
                      }
                      InputProps={{
                        style: { height: "36.5px" },
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  {!IS_EMBEDDED && (
                    <SavedViewsMenu
                      user_views={user_views}
                      user_id={user_id}
                      view_name={
                        this.props.selectedViews.DevicesTable || "All Services"
                      }
                      viewComponent={"DevicesTable"}
                      callback={(filter) => {
                        this.updateDeviceFilter(filter);
                        this.gatherStatusTiles(this.state.groupByTiles, filter);
                      }}
                      saveViewCallback={() => this.openSaveDialog()}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={(event) => this.handleMenuClick(event)}
                    endIcon={<KeyboardArrowDown />}
                  >
                    Group By
                  </Button>
                  <Popover
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.closeGroupBy}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    style={{ marginTop: "95px" }}
                  >
                    <Grid container>
                      <Grid item style={{ padding: "24px" }}>
                        <Autocomplete
                          multiple
                          options={this.state.filterProps.filter(
                            (prop) =>
                              !this.state.groupTilesBy.includes(prop.name)
                          )}
                          getOptionLabel={(option) => option.name}
                          groupBy={(option) => option.group}
                          onChange={(_, v) => {
                            if (v.length) {
                              this.setState({
                                groupTilesBy: v.map((val) =>
                                  val.name ? val.name : val
                                ),
                              });
                            }
                          }}
                          value={
                            typeof this.state.groupTilesBy === "string"
                              ? [this.state.groupTilesBy]
                              : this.state.groupTilesBy
                          }
                          disableClearable
                          fullWidth
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              key={params}
                              {...params}
                              label="Property"
                              style={{ width: 200 }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>
                <Grid item>
                  <FilterSettings
                    viewComponent="DevicesTable"
                    deviceProperties={deviceProperties}
                    deviceFilter={this.state.deviceFilter}
                    savedFilters={this.props.deviceFilter}
                    updateDeviceFilter={this.updateDeviceFilter}
                    updateFilterOutput={this.updateFilterOutput}
                    filterValid={this.state.filterValid}
                    user_id={user_id}
                    defaultView={defaultView}
                    byViewName={byViewName}
                    viewName={
                      this.props.selectedViews.DevicesTable || "default"
                    }
                    showSave={true}
                  />
                </Grid>
                {/*<Grid item xs={2}>
                  <SeveritySelect
                    severities={names}
                    callback={this.updateSeverityFilter}
                    selectedSeverities={this.state.selectedSeverities}
                    />
                </Grid>*/}
                <Grid style={{ display: "none", width: "0px" }}>
                  <PageRefreshInput
                    receivedAt={this.props.receivedAt}
                    refreshFunction={() => this.getDevicesThenAlerts()}
                    seconds={60}
                  />
                </Grid>
                {/*<Grid item>
                    <Button>Search</Button>
                </Grid>*/}
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    label="Resize Icons"
                    style={{ height: "36.5px" }}
                    onClick={(e) => {
                      this.setState({ magnifyingGlassRef: e.currentTarget });
                      this.toggleTileResizer();
                    }}
                  >
                    <SettingsIcon />
                  </Button>
                  <Popover
                    open={this.state.openResizeIconsWindow}
                    onClose={this.toggleTileResizer}
                    anchorEl={this.state.magnifyingGlassRef}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    style={{ marginTop: "20px" }}
                    disableRestoreFocus
                  >
                    <Grid
                      container
                      direction="column"
                      style={{
                        alignItems: "center",
                        paddingTop: "25px",
                        gap: "15px",
                        paddingBottom: "25px",
                      }}
                    >
                      <Grid item>
                        <Typography variant="h4">Dashboard Options</Typography>
                      </Grid>
                      <Grid item>
                        <Typography align="center">Sort By:</Typography>
                        <TextField
                          style={{ width: 250, marginTop: 10 }}
                          variant="outlined"
                          size="small"
                          value={this.state.sortBy}
                          select
                          onChange={(e) =>
                            this.setState({ sortBy: e.target.value })
                          }
                        >
                          <MenuItem key={1} value="ascending">
                            Ascending
                          </MenuItem>
                          <MenuItem key={1} value="descending">
                            Descending
                          </MenuItem>
                          <MenuItem key={2} value="severity">
                            Severity
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item style={{ marginBottom: "-10px" }}>
                        <Typography>Tile Size:</Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          justifyContent="center"
                          style={{ width: "320px" }}
                        >
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={this.decreaseTileSize}
                              style={{ marginRight: "3px" }}
                            >
                              <RemoveCircleOutline fontSize="small" />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Slider
                              style={{ paddingBottom: "7px", width: "180px" }}
                              defaultValue={2}
                              aria-labelledby="Tile Size"
                              value={this.state.tileSize}
                              onChange={this.handleSliderChange}
                              min={2}
                              max={8}
                              track={false}
                            />
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={this.increaseTileSize}
                              style={{ marginLeft: "3px" }}
                            >
                              <AddCircleOutline fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ marginBottom: "-10px" }}>
                        <Typography>Text Size:</Typography>
                      </Grid>
                      <Grid item style={{ marginBottom: "-10px" }}>
                        <Grid
                          container
                          justifyContent="center"
                          style={{ width: "320px" }}
                        >
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={this.decreaseTextSize}
                              style={{ marginRight: "3px" }}
                            >
                              <RemoveCircleOutline fontSize="small" />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <Slider
                              style={{ paddingBottom: "7px", width: "180px" }}
                              defaultValue={2}
                              aria-labelledby="Text Size"
                              value={this.state.textSize}
                              onChange={this.handleTextSizeChange}
                              min={2}
                              max={8}
                              track={false}
                            />
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={this.increaseTextSize}
                              style={{ marginLeft: "3px" }}
                            >
                              <AddCircleOutline fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item style={{ alignContent: "center" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              color="primary"
                              checked={this.state.viewDetailsState}
                              onClick={() => {
                                this.setState({
                                  detailsChecked: !this.state.detailsChecked,
                                  settingsSaved: false,
                                });
                              }}
                              onChange={() => {
                                this.setState({
                                  viewDetailsState:
                                    !this.state.viewDetailsState,
                                  settingsSaved: false,
                                });
                              }}
                            />
                          }
                          label={
                            <Typography style={{ marginBottom: "8px" }}>
                              Details:
                            </Typography>
                          }
                          labelPlacement="top"
                        />
                      </Grid>
                      <Grid item>
                        <Typography>Alignment:</Typography>
                      </Grid>
                      <Grid item>
                        <ToggleButtonGroup
                          exclusive
                          style={{ height: "37px" }}
                          value={this.state.alignTiles}
                          onChange={(_, v) => this.handleAlignmentChange(v)}
                        >
                          <ToggleButton value="flex-start">
                            <FormatAlignLeft />
                          </ToggleButton>
                          <ToggleButton value="center">
                            <FormatAlignCenter />
                          </ToggleButton>
                          <ToggleButton value="flex-end">
                            <FormatAlignRight />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>
                <Grid item>
                  <Tooltip
                    TransitionComponent={Zoom}
                    open={this.state.disabledTooltip}
                    disableHoverListener
                    onMouseEnter={() =>
                      this.setState({ disabledTooltip: true })
                    }
                    onMouseLeave={() =>
                      this.setState({ disabledTooltip: false })
                    }
                    title={
                      this.props.mainExpand ? (
                        <Typography variant="body2">
                          Disables Wallboard Mode
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          Enables Wallboard Mode
                        </Typography>
                      )
                    }
                  >
                    <Button
                      size="large"
                      onClick={this.fullscreenPassback}
                      variant="outlined"
                      color="primary"
                      style={{ height: "36.5px", width: "20px" }}
                    >
                      {this.props.mainExpand ? (
                        <FullscreenExit fontSize="large" />
                      ) : (
                        <Fullscreen fontSize="large" />
                      )}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
            {/* This is a very disturbing sort and needs to be fixed!!*/}
            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                alignContent: "center",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container spacing={1}></Grid>
                </Grid>
                <Grid item xs={7}>
                  <Grid
                    container
                    spacing={0}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  ></Grid>
                </Grid>
              </Grid>
            </div>
            <SummaryDashboard
              details={this.state.viewDetailsState}
              bySiteTiles={bySiteTiles}
              textSize={this.state.textSize}
              tileSize={this.state.tileSize}
              alignTiles={this.state.alignTiles}
              groupBy={this.state.groupTilesBy}
              sortBy={this.state.sortBy}
            />
            {/*<Card style={{height:800, overflow: 'auto'}} elevation={0}>
          <Grid container spacing={1} style={{ padding: "20px" }}>
            {Object.keys(bySiteTiles).map((tile) => {
                var description =
                  "Devices:" +
                  bySiteTiles[tile]["devices"].length +
                  " Alerts:" +
                  bySiteTiles[tile]["AlertTotal"];

                var tmpTile = {
                  title: tile,
                  description: description,
                  link: "/services/connectivity?search_term=" + tile,
                  status: bySiteTiles[tile]["Severity"],
                  borderColor: bySiteTiles[tile]["boxColor"],
                };
                return <SummaryTile key={tile} tile={tmpTile} />;
            })}
          </Grid>
          </Card>*/}
          </Card>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { byId, loading, devices } = state.devices;
  const { allProperties, perProp } = state.devices.deviceProperties;
  const { byStatus, alerts } = state.alerts;
  const { deviceGroupItems } = state.deviceGroups;
  const { account_id } = state.userSession.properties;
  const { user_id } = state.ui_settings;
  const { view_id } = state.userSession;
  const user_views = state.ui_settings.user_views.byComponent.DevicesTable;
  const { byViewName, deviceFilter, defaultView, selectedViews } =
    state.ui_settings.user_views;
  const { dashboard_groupby_properties } = state.ui_settings.portal_config;

  var currentColumns;
  try {
    currentColumns = byViewName[view_id || "All Services"]["columns"];
  } catch (e) {
    currentColumns = [];
  }
  return {
    perProp: perProp,
    devices: byId,
    deviceProperties: allProperties,
    devicesArray: devices,
    loading,
    byStatus,
    deviceGroupItems,
    alerts,
    account_id,
    view_id,
    user_id,
    byViewName,
    user_views,
    deviceFilter,
    defaultView,
    currentColumns,
    savedDashboardSettings: state["ui_settings"]["saved_dashboard_settings"],
    selectedViews,
    dashboard_groupby_properties,
  };
}

const mapDispatchToProps = {
  dispatchChaining,
  listDevicesThenAlerts,
  updateDeviceFilter,
  updateDashboardSettings,
  saveDashboardSettings,
  createUserSettings,
  updateUserSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryStats);
