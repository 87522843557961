import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { apiQuery } from "../../libs/auth-config";

import {
  Box,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
//import API from "@aws-amplify/api";
import { USER_POOL } from "../../constants";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  text: {
    marginBottom: 10,
  },
}));

function generate(element, name) {
  return React.cloneElement(element, { key: name });
}

export default function PasswordReset({
  state,
  users,
  callback,
  updateCallback,
}) {
  const [open, setOpen] = React.useState(state);
  const [confirm, setConfirm] = React.useState("");
  const [running, setRunning] = React.useState(false);
  const classes = useStyles();
  const email = useSelector((state) => state.userSession.email) || "";

  React.useEffect(() => {
    setOpen(state);
  }, [state]);

  const handleClose = () => {
    callback();
    setOpen(false);
    setRunning(false);
  };

  const submitPasswordReset = async () => {
    setRunning(true);
    var myInit = {
      userPoolId: USER_POOL,
      users: users,
      user: email,
      permanent: false,
    };

    apiQuery("PUT", "/users/password", myInit)
      .then((response) => {
        updateCallback(response.status_code, response.message);
      })
      .catch((response) => console.log(response))
      .finally(() => {
        handleClose();
      });
  };

  const checkConfirm = () => {
    if (confirm.toLowerCase() === "confirm") return false;
    else return true;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Password Reset</DialogTitle>
        <DialogContent>
          <Typography color="primary" variant="h4">
            The following users will have their password reset..
          </Typography>

          <List dense={true} className={classes.section}>
            {users.map((usr) =>
              generate(
                <ListItem>
                  <ListItemText
                    primary={usr.given_name + " " + usr.family_name}
                    secondary={usr.email}
                  />
                </ListItem>,
                usr.email
              )
            )}
          </List>
          <Box fontStyle="italic">
            <Typography
              color="secondary"
              variant="h5"
              className={classes.text}
              component="div"
            >
              Please type 'confirm' in the box below.
            </Typography>
          </Box>
          <TextField
            autoFocus
            margin="dense"
            label="Password Reset Confirmation"
            fullWidth
            variant="outlined"
            onChange={(e) => setConfirm(e.target.value)}
            style={{ paddingBottom: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          {running ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          <Button
            onClick={submitPasswordReset}
            variant="contained"
            color="primary"
            disabled={checkConfirm() || running}
          >
            Reset
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
