export default function automation(
  state = {
    templates: {
      device: {
        groups: [],
        devices: [],
      },
      dashboard: {
        groups: [],
        dashboards: [],
      },
    },
  },
  action
) {
  switch (action.type) {
    case "SELECT_AUTOMATION_FORM_OPTION": {
      // Store the value of that form input.
      const newState = { ...state };
      newState.formOptions[action.payload.formSection][action.payload.inputId] =
        action.payload.value;
      return newState;
    }
    case "CLICK_ACCORDION_TAB": {
      // If we closed the tab, remove it from open tabs.
      if (!action.payload.expanded) {
        return {
          ...state,
          openTabs: state.openTabs.filter(
            (tabName) => tabName !== action.payload.tabName
          ),
        };
      }
      // Prevent duplicates being added to state.
      if (action.payload.expanded in state.openTabs) {
        return state;
      }
      // Otherwise, add it if not a duplicate.
      return {
        ...state,
        openTabs: [...state.openTabs, action.payload.tabName],
      };
    }
    case "RECEIVE_DASH_GROUP_TEMPLATE": {
      return Object.assign({}, state, {
        ...state,
        templates: {
          ...state.templates,
          dashboard: {
            ...state.templates.dashboard,
            groups: action.payload,
          },
        },
      });
    }
    case "RECEIVE_DEVICE_GROUP_TEMPLATE": {
      return Object.assign({}, state, {
        ...state,
        templates: {
          ...state.templates,
          device: {
            ...state.templates.device,
            groups: action.payload,
          },
        },
      });
    }
    case "RECEIVE_DASHBOARD_TEMPLATE": {
      return Object.assign({}, state, {
        ...state,
        templates: {
          ...state.templates,
          dashboard: {
            ...state.templates.device,
            dashboards: action.payload,
          },
        },
      });
    }
    default:
      return state;
  }
}
