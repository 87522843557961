import React from "react";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AlertMethodIcon from "../AlertMethodIcon";
import {
  initRequestRecipientGroupById,
  initSaveRecipients,
  saveThresholdConfig,
} from "../../actions/alerts";
import { Box, CircularProgress, Tab, Tabs } from "@material-ui/core";
import ThresholdConfiguration from "../ThresholdConfiguration";
import Visible from "../Visible";
import { emailChecker } from "../../libs/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function ConfigureAlerts({
  account_id,
  recipient_group,
  manage,
}) {
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [emailInput, setEmail] = React.useState("");
  const [buttonState, setButtonState] = React.useState(true);
  const [saveState, setSaveState] = React.useState(false);
  const [updatedThresholds, setUpdatedThresholds] = React.useState([]);
  const dispatch = useDispatch();

  const setRecipientEmail = (event) => {
    setEmail(event.target.value);
  };

  const isInvalidEmailAddress = (address) =>
    recipient_group &&
    (!address ||
      !emailChecker(address) ||
      recipient_group.recipients.filter((item) => item.addr === address)
        .length);

  const updateRecipient = async (type, removal) => {
    let tmp = recipient_group;
    if (type === "add" && !isInvalidEmailAddress(emailInput)) {
      tmp = {
        ...tmp,
        recipients: [
          ...tmp.recipients,
          {
            type: "ARBITRARY",
            method: "EMAIL",
            addr: emailInput,
            contact: "",
          },
        ],
      };
      setEmail("");
    } else if (type === "remove") {
      recipient_group.recipients.forEach((val, i) => {
        if (val === removal) tmp.recipients.splice(i, 1);
      });
    } else {
      console.log("email address is not valid");
    }

    await dispatch(
      initSaveRecipients(account_id, recipient_group.id, tmp, type)
    );
    setTimeout(() => {
      dispatch(initRequestRecipientGroupById(account_id));
    }, 500);
  };

  const handleClickOpen = () => {
    dispatch(initRequestRecipientGroupById(account_id));

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (_, newTab) => {
    setTab(newTab);
  };

  const updateThreshold = (threshold, device) => {
    setButtonState(false);
    if (device) {
      let newState = [...updatedThresholds];
      for (let i = 0; i < newState.length; i++) {
        if (threshold.dataPointId === newState[i].dataPointId) {
          newState[i] = threshold;
          return setUpdatedThresholds(newState);
        }
      }
      setUpdatedThresholds([...newState, threshold]);
    } else setUpdatedThresholds(threshold);
  };

  const startExection = async (values, device) => {
    if (device) {
      for (let i = 0; i < updatedThresholds.length; i++) {
        await saveThresholdConfig({
          ...values,
          ...(i && {
            info: null,
          }),
          dp_id: updatedThresholds[i].dataPointId,
          data: updatedThresholds[i],
        });
      }
    } else {
      await saveThresholdConfig({
        ...values,
        data: {
          dpConfig: updatedThresholds,
        },
      });
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <div>
      <div>
        <Visible component={"ConfigureAlertsButton"}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Configure Alerts
          </Button>
        </Visible>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Alert Configuration</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <Tabs
            value={tab}
            variant="fullWidth"
            indicatorColor="primary"
            onChange={handleTabChange}
          >
            <Tab label="Recipients" {...a11yProps(0)} />
            <Tab label="Custom Alerts" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <TableContainer
              style={{ marginBottom: "20px" }}
              component={Paper}
              elevation={0}
            >
              <Table size="small" aria-label="recipients table">
                <TableHead>
                  <TableRow>
                    <TableCell>Method</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!recipient_group ||
                  recipient_group.recipients.length === 0 ? (
                    <TableRow>
                      <TableCell>No email addresses configured.</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  ) : (
                    recipient_group.recipients.map((item) => (
                      <TableRow key={item.addr}>
                        <TableCell>
                          <AlertMethodIcon
                            style={{ color: "red" }}
                            type={item.method}
                          />
                        </TableCell>
                        <TableCell>{item.addr}</TableCell>

                        {/* {manage && ( */}
                        <TableCell>
                          <IconButton
                            onClick={() => updateRecipient("remove", item)}
                            aria-label="delete"
                            disabled={recipient_group.recipients.length < 2}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                        {/* )} */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* {manage && ( */}
            <TextField
              error={isInvalidEmailAddress(emailInput)}
              autoFocus
              margin="dense"
              id="name"
              value={emailInput}
              label="Add Recipient Email"
              type="email"
              fullWidth
              variant="outlined"
              onChange={setRecipientEmail}
            />
            {/* )} */}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <ThresholdConfiguration
              account_id={account_id}
              setButtonState={(val) => setButtonState(val)}
              startExecution={(val, device) => startExection(val, device)}
              savePrompt={saveState}
              saveUpdates={(val, device) => updateThreshold(val, device)}
              resetUpdateValues={() => setUpdatedThresholds([])}
              resetSaveState={() => setSaveState(false)}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          {!tab ? (
            <Button
              onClick={() => updateRecipient("add")}
              variant="contained"
              color="primary"
              disabled={isInvalidEmailAddress(emailInput)}
            >
              Add
            </Button>
          ) : (
            //&& manage
            <>
              {saveState && <CircularProgress />}
              <Button
                onClick={() => setSaveState(true)}
                disabled={buttonState}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </>
          )}

          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
