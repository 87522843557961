import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Box, Card, Divider, Grid, Paper, Typography } from "@material-ui/core";
//import { ChevronRight } from "@material-ui/icons";
import { jobsList } from "../containers/AutomationContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: theme.spacing(24),
  },
  card: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    width: theme.spacing(17),
    height: theme.spacing(8.5),
  },
  grid: {
    padding: theme.spacing(1),
    height: "100%",
  },
  dotdotdot: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-all",
    overflow: "hidden",
  },
}));

export default function AutomationSummary({
  jobs,
  backCallback,
  continueCallback,
  csv,
}) {
  const ref = useRef();
  const classes = useStyles();

  const handleBack = () => {
    backCallback();
  };

  const handleContinue = () => {
    continueCallback();
  };

  /*
   This stops error from trying to render an array of 
   multiple users into the summary.
  */
  const renderFormFields = (jobOptions, field) => {
    if (!Array.isArray(jobOptions)) {
      var fieldOption;
      // Prevents error if you have a 1 letter long string.
      if (jobOptions[field].length > 1) {
        if(typeof jobOptions[field] === "string"){
        fieldOption =
          jobOptions[field].charAt(0).toUpperCase() +
          jobOptions[field].slice(1);}
      } else {
        fieldOption =
          typeof jobOptions[field] === "number"
            ? jobOptions[field]
            : field === "device" || field === "structure"
            ? jobOptions[field].name
            : jobOptions[field].charAt(0).toUpperCase();
      }
      if(typeof jobOptions[field] === "string"){
      return (
        <Box className={classes.dotdotdot}>
          <Typography component="div" variant="body2">
            <Box display="inline" fontWeight="fontWeightBold">
              {field.charAt(0).toUpperCase() + field.slice(1) + ": "}
            </Box>
            {fieldOption}
          </Typography>
        </Box>
      );}
    } else {
      return <div>{csv}</div>;
    }
  };

  return (
    <Paper className={classes.root}>
      <div ref={ref} style={{ overflow: "hidden", height: "89%" }}>
        <Grid
          container
          spacing={1}
          className={classes.grid}
          justifyContent="center"
        >
          {jobsList.map((job) =>
            jobs[job] !== undefined ? (
              <Grid item>
                <Card elevation={4} className={classes.card}>
                  <Typography variant="h6" style={{ marginBottom: 3 }}>
                    {job}
                  </Typography>
                  <Divider style={{ marginBottom: 5 }} />
                  {Object.keys(jobs[job]).map((field) => {
                    return renderFormFields(jobs[job], field);
                  })}
                </Card>
              </Grid>
            ) : null
          )}
        </Grid>
      </div>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleBack}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleContinue}>
            Execute
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
