import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withTheme } from "@material-ui/core/styles";
import { PREFIX } from "../constants";
import { saveBranding } from "../actions/reports";
import ThemeForm from "./ThemeForm";
import { createAuditLog } from "../actions/ui_settings";

class PanelSettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      brandingLoading: false,
      branding: {
        primary: this.props.theme.palette.primary.main,
        secondary: this.props.theme.palette.secondary.main,
      },
      img: `https://s3.eu-west-2.amazonaws.com/${PREFIX}.logic-one.io/brands/${
        props.branding ? props.branding.logo_filename : "default"
      }`,
      portalImg: `https://s3.eu-west-2.amazonaws.com/${PREFIX}.logic-one.io/brands/${
        props.branding
          ? props.branding.portal_logo_filename
          : `${PREFIX}_sidemenu_logo_default.png`
      }`,
    };
  }

  componentDidMount() {
    // this.getBranding();
  }

  // async getBranding() {
  //   getBranding(this.props.account_id)
  //     .then((data) => {
  //       console.log(data);
  //       Storage.get(data.account_id, {
  //         bucket: `${PREFIX}.logic-one.io`,
  //       })
  //         .then((image) => {
  //           console.log(image);
  //           this.setState({ branding: data, img: image });
  //         })
  //         .catch((e) => console.log(e));
  //     })
  //     .catch(() => {
  //       console.log("no logo so fallback to defaults");
  //       getBranding("default").then((data) => {
  //         console.log(data);
  //         this.setState({ branding: data });
  //       });
  //     });
  // }

  // Trigger mutation via appsync (if id is included it will update instead of create)
  // Then upload the image to S3 using dynamo ID
  async saveBranding(themeColours, img, portalImg) {
    // let extension = img.name.split(".").pop();
    var account_id = this.props.account_id;
    this.setState({ brandingLoading: true });
    this.props.saveBranding(
      {
        account_id: account_id,
        name: this.props.name,
        themeColours: JSON.stringify(themeColours),
        id: this.props.branding.id,
        // for now just set filename to the account_id
        logo_filename:
          typeof img === "string" && img.endsWith("default")
            ? "default"
            : account_id,
        portal_logo_filename:
          typeof portalImg === "string" &&
          portalImg.endsWith(`${PREFIX}_sidemenu_logo_default.png`)
            ? `${PREFIX}_sidemenu_logo_default.png`
            : `${account_id}_PORTAL`,
      },
      img,
      portalImg
    );

    this.props.createAuditLog(`Updated branding for ${this.props.account_id}.`);
    // ...(this.props.branding.id !== "default" && {
    //   id: this.props.branding.id,
    // }),
    // })
    // .then((data) => {
    //   console.log(data);
    //   this.state.branding.img !== img
    // Storage.put(this.props.account_id, img, {
    //   bucket: `${PREFIX}.logic-one.io`,
    // });
    // .then(() =>
    //           this.setState({
    //             branding: {
    //               ...data,
    //               img: URL.createObjectURL(img),
    //             },
    //             brandingLoading: false,
    //           })
    //         )
    //         .catch((e) => console.error(e))
    //     : this.setState({ brandingLoading: false });
    // });
  }

  render() {
    return (
      <ThemeForm
        saveCallback={(themeColours, img, portalImg) => {
          this.saveBranding(themeColours, img, portalImg);
        }}
        branding={this.props.branding}
        logo={this.state.img}
        portalLogo={this.state.portalImg}
      />
    );
  }
}

const mapDispatchToProps = {
  saveBranding,
  createAuditLog,
};

function mapStateToProps(state) {
  const { account_id } = state.userSession.properties;
  const { branding, logo_url } = state.userSession;
  const { name } = state.deviceGroups.deviceGroupItems.parent;
  return {
    account_id,
    name,
    branding,
    logo_url,
  };
}

// export default connect(mapStateToProps)(withTheme(PanelSettingsContainer));

const enhance = compose(
  // withStyles(useStyles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(withTheme(PanelSettingsContainer));

//
// const enhance = compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   withTheme
// );
