import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const DeviceGroupAutocomplete = (props) => {
  const classes = useStyles();
  const account_id = useSelector(
    (state) => state.userSession.properties.account_id
  );
  let placeholder;
  if (props.deviceGroups !== undefined) {
    for (let group of props.deviceGroups) {
      if (group.account_id === account_id) {
        placeholder = group.name + " (" + account_id + ")";
        break;
      }
    }
  }
  const getLabel = (option) => {
    var label = "";
    if (typeof option === "string") label = option;
    else if (typeof option.name !== "undefined") label = option.name;

    if (label !== "" && label !== "All Accounts") {
      if (option.account_id) label = label + " (" + option.account_id + ")";
    }

    return label;
  };

  return (
    <Autocomplete
      options={props.deviceGroups || [{ name: "Loading accounts..." }]}
      getOptionLabel={(option) => getLabel(option)}
      getOptionDisabled={(option) => option.has_key === false}
      getOptionSelected={(option, value) => option.value === value.value}
      onChange={(_, v) => props.onChange(v)}
      renderInput={(params) => (
        <TextField
          key={params.id}
          {...params}
          placeholder={placeholder}
          className={classes.textField}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            onChange(event) {
              // val.function(event.target.value)
              return params.inputProps.onChange(event);
            },
          }}
        />
      )}
    />
  );
};

DeviceGroupAutocomplete.propTypes = {
  deviceGroups: PropTypes.array,
};

export default DeviceGroupAutocomplete;
