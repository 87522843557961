import { apiQuery } from "../libs/auth-config";

export const FETCH_USER_PERMISSIONS = "FETCH_USER_PERMISSIONS";
export const FETCH_ROLES = "FETCH_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

export const getUserPermissions = () => async (dispatch) => {
  await apiQuery("GET", "/rbac/permissions/me").then((res) => {
    dispatch({
      type: FETCH_USER_PERMISSIONS,
      payload: res.data,
    });
  });
};

export const fetchRoles = () => async (dispatch) => {
  await apiQuery("GET", "/rbac/roles").then((res) => {
    dispatch({
      type: FETCH_ROLES,
      payload: res.data,
    });
  });
};

export const addRole = (data) => async (dispatch) => {
  await apiQuery("POST", "/rbac/roles", data).then((res) => {
    dispatch({
      type: CREATE_ROLE,
      payload: data,
    });
  });
};

export const updateRole = (data) => async (dispatch) => {
  return await apiQuery("PUT", `/rbac/roles?name=${data.name}`, data).then(
    (res) => {
      dispatch({
        type: UPDATE_ROLE,
        payload: data,
      });
    }
  );
};

export const deleteRole = (roleName) => async (dispatch) => {
  await apiQuery("DELETE", `/rbac/roles?name=${roleName}`).then((res) => {
    dispatch({
      type: DELETE_ROLE,
      payload: {
        name: roleName,
      },
    });
  });
};
