import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  ListSubheader,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { apiQuery } from "../libs/auth-config";
import { Typography } from "@material-ui/core";
import PopoutWindow from "../containers/PopoutWindow";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";

const styles = createStyles((theme) => ({
  root: {
    "& .MuiTableContainer-root": {
      width: "100%",
    },
  },
  cmdLineOutputBox: {
    marginTop: "15px",
    display: "flex",
    border: "1px solid #d9d7d7",
    borderRadius: "2px",
    overflowX: "auto",
    margin: "10px",
  },
  cmdLineOutputPre: {
    marginTop: "15px",
    paddingLeft: "30px",
    whiteSpace: "pre-wrap",
  },
  advancedDiagnosticsContainer: {
    margin: "15px",
    marginLeft: "0px",
    marginRight: "0px",
    padding: "32px",
    alignItems: "center",
    width: "100%",
  },
  commandContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    maxWidth: "80%",
    width: "100%",
  },
  commandSelect: {
    width: "100%",
    maxWidth: "300px",
    margin: "0px",
    marginTop: "3px",
  },
  commandOptions: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    gap: "15px",
    width: "80%",
  },
}));

class OidAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val: this.props.generalOids[0],
    };
  }

  getOidDesc = () => {
    const currentOid = this.props.oidSuggestions.filter(
      (oid) => oid.name === this.state.val
    );
    return currentOid.length ? currentOid[0].Description : "";
  };

  componentDidMount() {
    this.setState({ val: this.props.generalOids[0].name });
  }

  componentDidUpdate() {
    const previousOidNames = this.props.generalOids.map((oid) => oid.name);
    if (!previousOidNames.includes(this.state.val)) {
      this.setState({ val: this.props.generalOids[0].name });
    }
  }

  render() {
    return (
      <>
        <Grid container direction="column" style={{ gap: "20px" }}>
          <Grid item>
            <FormControl
              style={{ width: "100%", maxWidth: "300px" }}
              size="small"
            >
              <InputLabel
                htmlFor="oid-select"
                style={{ marginLeft: "15px", marginTop: "-5px" }}
              >
                OID
              </InputLabel>
              <Select
                displayEmpty
                id="oid-select"
                label="OID"
                variant="outlined"
                value={this.state.val}
                onInputChange={(e) => {
                  this.props.setOid(e.target.value);
                  this.setState({ val: e.target.value });
                  this.forceUpdate();
                }}
                onChange={(e) => {
                  this.props.setOid(e.target.value);
                  this.setState({ val: e.target.value });
                  this.forceUpdate();
                }}
              >
                <ListSubheader style={{ marginLeft: "7px", color: "grey" }}>
                  General
                </ListSubheader>
                {this.props.generalOids.map((o) => {
                  return <MenuItem value={o.name}>{o.name}</MenuItem>;
                })}
                <ListSubheader style={{ marginLeft: "7px", color: "grey" }}>
                  {this.props.vendorOids.length
                    ? this.props.deviceVendor
                    : null}
                </ListSubheader>
                {this.props.vendorOids.map((o) => {
                  return <MenuItem value={o.name}>{o.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="span" style={{ overflowWrap: "normal" }}>
              {this.getOidDesc()}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
}

class SnmpwalkTool extends React.Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
    this.state = {
      oid: "Name",
      command: null,
      data: null,
      snmpVersion: this.props.defaultSnmpVersion,
      customSnmpCredentials: false,
      snmpCommunityString: null,
      oidSuggestions: [
        {
          category: "General",
          name: "Loading OIDs...",
          Description: "",
          methods: ["snmpget", "snmpwalk"],
        },
      ],
      auth: null,
      authToken: null,
      priv: null,
      privToken: null,
    };
  }

  componentDidMount() {
    apiQuery("GET", "/devices/snmp/oids", {
      dnsName: this.props.device["DNS Name"],
      account_id: this.props.accountId,
    })
      .then((res) => {
        this.setState({ oidSuggestions: res.data });
        this.forceUpdate();
      })
      .catch((err) => {
        this.setState({ oidSuggestions: ["Error fetching OID list."] });
      });
  }

  handleClick = () => {
    if (this.validateForm()) {
      this.setState({ data: "Loading..." });
      apiQuery("POST", "/devices/snmp/oid", {
        dnsName: this.props.device["DNS Name"],
        account_id: this.props.accountId,
        oid: this.state.oid,
        command: this.state.command,
        snmpVersion: this.state.snmpVersion,
        customSnmpCredentials: this.state.customSnmpCredentials,
        snmpCommunityString: this.state.snmpCommunityString,
        auth: null,
        authToken: null,
        priv: null,
        privToken: null,
      })
        .then((res) => {
          this.setState({ data: res.data });
        })
        .catch((err) => {
          this.setState({ data: "Error running command." });
        });
    }
  };

  validateForm = () => {
    if (this.state.command === "snmpdiagnose") {
      return true;
    }
    return !(!this.state.oid || this.state.command === null);
  };

  colorSearchButton = () => {
    return this.validateForm() ? "primary" : "";
  };

  setCommand = (c) => {
    this.setState({ command: c });
  };

  setOid = (o) => {
    this.setState({ oid: o });
  };

  componentWillUnmount() {
    this.props.onCloseFunction();
  }

  renderWalkData = () => {
    return (
      <>
        <Divider style={{ width: "100%" }} />
        <Typography variant="h4" align="center" style={{ margin: "20px" }}>
          Output
        </Typography>
        <Box className={this.props.classes.cmdLineOutputBox}>
          <pre
            className={this.props.cmdLineOutputPre}
            style={{ marginLeft: "10px" }}
          >
            <Typography component="p">{this.state.data}</Typography>
          </pre>
        </Box>
      </>
    );
  };

  renderCommandOptions = () => {
    /* 
     Render a certain set of command options based on what debug command
     is selected by the user.
    */
    // Autocomplete used by both Get and Walk for user to submit OID.
    var generalOids = this.state.oidSuggestions;
    var vendorOids = [];
    if (
      this.state.command !== "snmpdiagnose" &&
      this.state.oidSuggestions.length
    ) {
      var oidCategories = Array.from(
        new Set(this.state.oidSuggestions.map((oid) => oid.category))
      );
      var deviceVendor = oidCategories.filter(
        (category) => category !== "General"
      )[0];
      generalOids = this.state.oidSuggestions
        .filter((oid) => oid.category === "General")
        .filter((oid) => oid.methods.includes(this.state.command));
      vendorOids = this.state.oidSuggestions
        .filter((oid) => oid.category !== "General")
        .filter((oid) => oid.methods.includes(this.state.command));
    }

    let oidAutoComplete = (
      <OidAutoComplete
        generalOids={generalOids}
        vendorOids={vendorOids}
        deviceVendor={deviceVendor}
        oidSuggestions={this.state.oidSuggestions}
        setOid={this.setOid}
      />
    );

    // Let the user select which version of SNMP is used on the Get/Walk.
    const snmpVersionSelect = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          select
          label="SNMP Version"
          variant="outlined"
          defaultValue={this.state.snmpVersion.toUpperCase()}
          onChange={(e) => this.setState({ snmpVersion: e.target.value })}
          size="small"
          style={{ width: "100%", maxWidth: "300px", marginTop: "20px" }}
        >
          <MenuItem value="V2C">V2C</MenuItem>
          <MenuItem value="V3">V3</MenuItem>
        </TextField>
      </div>
    );

    const snmpCredentialSwitch = (
      <FormControlLabel
        style={{ marginTop: "20px" }}
        control={
          <Switch
            color="primary"
            defaultChecked={this.state.customSnmpCredentials}
            onChange={(_) =>
              this.setState({
                customSnmpCredentials: !this.state.customSnmpCredentials,
              })
            }
          />
        }
        label="Use Custom SNMP Credentials"
      />
    );

    /* Different SNMP versions require different credentials. */
    var snmpPasswordFields;
    if (this.state.snmpVersion === "V2C") {
      snmpPasswordFields = (
        <TextField
          variant="outlined"
          type="password"
          value={this.state.snmpCommunityString}
          onChange={(e) =>
            this.setState({ snmpCommunityString: e.target.value })
          }
          style={{
            width: "100%",
            maxWidth: "300px",
          }}
          label="Community String"
          size="small"
        />
      );
    } else if (this.state.snmpVersion === "V3") {
      snmpPasswordFields = (
        <Grid container direction="column" style={{ gap: "10px" }}>
          <Grid item>
            <TextField
              variant="outlined"
              value={this.state.auth}
              onChange={(e) => this.setState({ auth: e.target.value })}
              style={{
                width: "100%",
                maxWidth: "300px",
              }}
              label="Authentication"
              size="small"
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              type="password"
              value={this.state.authtoken}
              onChange={(e) => this.setState({ authToken: e.target.value })}
              style={{
                width: "100%",
                maxWidth: "300px",
              }}
              label="Authentication Token"
              size="small"
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              value={this.state.priv}
              onChange={(e) => this.setState({ priv: e.target.value })}
              style={{
                width: "100%",
                maxWidth: "300px",
              }}
              label="Privacy"
              size="small"
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              type="password"
              value={this.state.privToken}
              onChange={(e) => this.setState({ privToken: e.target.value })}
              style={{
                width: "100%",
                maxWidth: "300px",
              }}
              label="Privacy Token"
              size="small"
            />
          </Grid>
        </Grid>
      );
    }

    if (!this.state.command) {
      return <></>;
    }
    if (this.state.command === "snmpget" || this.state.command === "snmpwalk") {
      return (
        <>
          <Typography variant="h4" align="center">
            Options
          </Typography>
          <Grid
            container
            justifyContent="center"
            style={{ alignContent: "center", gap: "10px" }}
            direction="column"
          >
            <Grid item>{oidAutoComplete}</Grid>
            <Grid item>{snmpVersionSelect}</Grid>
            <Grid item>{snmpCredentialSwitch}</Grid>
            <Grid item>
              {this.state.customSnmpCredentials ? snmpPasswordFields : <></>}
            </Grid>
          </Grid>
        </>
      );
    } else if (this.state.command === "snmpdiagnose") {
      return (
        <>
          <Typography variant="h4" align="center">
            Options
          </Typography>
          <Grid
            container
            justifyContent="center"
            style={{ alignContent: "center", gap: "10px" }}
            direction="column"
          >
            <Grid item style={{ width: "100%", maxWidth: "300px" }}>
              {snmpVersionSelect}
            </Grid>
            <Grid item>{snmpCredentialSwitch}</Grid>
            <Grid item>
              {this.state.customSnmpCredentials ? snmpPasswordFields : <></>}
            </Grid>
          </Grid>
        </>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <PopoutWindow height={800} width={1200} top={200} left={200}>
        <Grid
          component={Paper}
          style={{ width: "90%", margin: "0 auto", marginTop: "20px" }}
        >
          <Grid
            container
            justifyContent="center"
            direction="column"
            spacing={2}
            className={classes.advancedDiagnosticsContainer}
          >
            <Grid item>
              <Typography variant="h3">Advanced Diagnostics</Typography>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Divider />
            </Grid>
            <Grid item className={classes.commandContainer}>
              <TextField
                select
                label="Command"
                variant="outlined"
                onChange={(e) => {
                  this.setState({ command: e.target.value });
                }}
                size="small"
                className={classes.commandSelect}
              >
                <MenuItem value="snmpwalk">snmpwalk</MenuItem>
                <MenuItem value="snmpget">snmpget</MenuItem>
                <MenuItem value="snmpdiagnose">snmpdiagnose</MenuItem>
              </TextField>
              <Button
                color={this.colorSearchButton()}
                variant="contained"
                onClick={this.handleClick}
                size="large"
                style={{ display: "flex" }}
              >
                Run
              </Button>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Divider />
            </Grid>
            <Grid item className={classes.commandOptions}>
              {this.renderCommandOptions()}
            </Grid>
            <Grid item style={{ width: "100%" }}>
              {this.state.data !== null ? this.renderWalkData() : <></>}
            </Grid>
          </Grid>
        </Grid>
      </PopoutWindow>
    );
  }
}

const enhance = compose(withStyles(styles), connect());
export default enhance(SnmpwalkTool);
