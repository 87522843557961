import { API, Storage } from "aws-amplify";
import { RSAA } from "redux-api-middleware";
import { APIURL, PREFIX, STAGE } from "../constants";
import { apiQuery } from "../libs/auth-config";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

export const REPORTS_REQUEST = "REPORTS_REQUEST";
export const REPORTS_RECEIVE = "REPORTS_RECEIVE";
export const REPORTS_FAILURE = "REPORTS_FAILURE";

export function initRequestReports() {
  var url = APIURL + "/api/v1/reports";
  return {
    [RSAA]: {
      endpoint: url,
      method: "GET",
      types: [
        {
          type: REPORTS_REQUEST,
        },
        REPORTS_RECEIVE,
        REPORTS_FAILURE,
      ],
      headers: {},
    },
  };
}

const appStage = STAGE === "testing" ? "DEV" : "";

export const REPORT_TEMPLATE_REQUEST = "REPORT_TEMPLATE_REQUEST";
export const REPORT_TEMPLATE_RECEIVE = "REPORT_TEMPLATE_RECEIVE";
export const REPORT_TEMPLATE_FAILURE = "REPORT_TEMPLATE_FAILURE";

export const initRequestReportTemplates = (account_id) => (dispatch) => {
  dispatch({ type: REPORT_TEMPLATE_REQUEST });
  apiQuery("GET", "/reports/templates", {
    account_id: account_id,
    stage: appStage,
  })
    .then((data) => {
      dispatch({
        type: REPORT_TEMPLATE_RECEIVE,
        payload: data.data,
      });
    })
    .catch((e) => {
      dispatch({ type: REPORT_TEMPLATE_FAILURE });
      console.log(e);
    });
};

export const REPORT_GROUPS_REQUEST = "REPORT_GROUPS_REQUEST";
export const REPORT_GROUPS_RECEIVE = "REPORT_GROUPS_RECEIVE";
export const REPORT_GROUPS_FAILURE = "REPORT_GROUPS_FAILURE";

export function initRequestReportGroups() {
  var url = APIURL + "/api/v1/report_groups";
  return {
    [RSAA]: {
      endpoint: url,
      method: "GET",
      types: [
        {
          type: REPORT_GROUPS_REQUEST,
        },
        REPORT_GROUPS_RECEIVE,
        REPORT_GROUPS_FAILURE,
      ],
      headers: {},
    },
  };
}

export const REPORT_EXECUTION_REQUEST = "REPORT_EXECUTION_REQUEST";
export const REPORT_EXECUTION_RECEIVE = "REPORT_EXECUTION_RECEIVE";
export const REPORT_EXECUTION_FAILURE = "REPORT_EXECUTION_FAILURE";

export const runReport = (id, account_id) => (dispatch) => {
  dispatch({
    type: REPORT_EXECUTION_REQUEST,
  });
  apiQuery("POST", "/reports/report", {
    account_id: account_id,
    id: id,
  })
    .then((data) => {
      dispatch({
        type: REPORT_EXECUTION_RECEIVE,
        payload: data.data,
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: REPORT_EXECUTION_FAILURE });
    });
};

export const customReport =
  (
    unique_id,
    id,
    account_id,
    data,
    format,
    emails,
    range,
    name,
    columns,
    reports_needed,
    filters
  ) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      apiQuery("POST", "/reports/report/custom", {
        account_id: account_id,
        data: data,
        report_id: id,
        id: unique_id,
        report_format: format,
        ...(emails && {
          recipients: emails,
        }),
        range: range,
        report_name: name,
        custom_columns: columns,
        reports_needed: reports_needed,
        filters: filters,
      })
        .then((data) => {
          if (data.status_code === 200 && format === "PDF")
            dispatch({
              type: "PDF_REPORT_SUCCESS",
              payload: {
                report_name: name,
                range: range,
                account_id: account_id,
              },
            });
          return resolve(data.status_code);
        })
        .catch((e) => {
          console.log(e);
          return reject(0);
        });
    });
  };

export const removeReport = async (accId, name, onDemand) =>
  apiQuery("DELETE", "/reports/scheduled", {
    account_id: accId,
    report_name: name,
    on_demand: onDemand,
  })
    .then((response) => response.message)
    .catch((e) => console.log(e));

export function getLogo(account_id) {
  return async function getLogoThunk(dispatch, getState) {
    Storage.get(account_id, {
      bucket: `${PREFIX}.logic-one.io`,
    })
      .then((image) => {
        dispatch({
          type: "GET_LOGO_SUCCESS",
          payload: image,
        });
      })
      .catch((e) => console.log(e));
  };
}

export function getBranding(account_id) {
  return async function getBrandingThunk(dispatch, getState) {
    API.graphql({
      query: queries.getBranding,
      variables: { account_id: account_id },
      authToken: localStorage.getItem("jwt"),
    })
      .then((data) => {
        // data.data.getBranding
        if (data.data.getBranding) {
          dispatch({
            type: "GET_BRANDING_SUCCESS",
            payload: data.data.getBranding,
          });
        } else {
          API.graphql({
            query: queries.getBranding,
            variables: { account_id: "default" },
            authToken: localStorage.getItem("jwt"),
          })
            .then((data) => {
              // data.data.getBranding
              if (data.data.getBranding) {
                dispatch({
                  type: "GET_BRANDING_SUCCESS",
                  payload: data.data.getBranding,
                });
              }
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };
}

export function saveBranding(input, img, portalImg) {
  return async function saveBrandingThunk(dispatch, getState) {
    var type = input.id && input.id !== "default" ? "update" : "create";
    type === "create" && delete input["id"];
    API.graphql({
      query:
        type === "update" ? mutations.updateBranding : mutations.createBranding,
      variables: { input: input },
      authToken: localStorage.getItem("jwt"),
    }).then((data) => {
      // data.data.getBranding
      dispatch({
        type: "SAVE_BRANDING_SUCCESS",
        payload: input.id ? data.data.updateBranding : data.data.createBranding,
      });
    });
    if (img && typeof img !== "string") {
      Storage.put(input.account_id, img, {
        bucket: `${PREFIX}.logic-one.io`,
      }).then((data) => {
        dispatch({
          type: "GET_LOGO_SUCCESS",
          payload: URL.createObjectURL(img),
        });
      });
    }
    if (portalImg && typeof portalImg !== "string") {
      Storage.put(`${input.account_id}_PORTAL`, portalImg, {
        bucket: `${PREFIX}.logic-one.io`,
      }).then((data) => {
        dispatch({
          type: "GET_PORTAL_LOGO_SUCCESS",
          payload: URL.createObjectURL(portalImg),
        });
      });
    }
  };
}
