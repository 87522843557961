import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Storage } from "aws-amplify";
import { PREFIX } from "../../constants";
import ProviderDropdown from "../ProviderDropdown";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "auto",
  },
  summary: {
    padding: theme.spacing(1, 2),
    paddingRight: theme.spacing(1),
    width: "100%",
  },
  formControl: {
    marginBottom: "7%",
  },
}));

export default function OptionalProperties({
  state,
  closeDialog,
  setProperties,
  existingValues,
  initialValues,
}) {
  const provisioningConfig =
    useSelector((state) => state.ui_settings.portal_config["provisioning"]) ||
    {};

  const [open, setOpen] = React.useState(state);
  const [ip_range, setIpRange] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [hardware, setHardware] = React.useState("");
  const [vlan, setVLAN] = React.useState("");
  const [is_broadband, setBroadband] = React.useState("");
  const [provider_name, setProvider] = React.useState("");
  const [dist_router, setDistRouter] = React.useState("");
  const [bearer_reference, setBearerRef] = React.useState("");
  const [dist_interface, setDistInterface] = React.useState("");
  const [circuit_type, setCirType] = React.useState("");
  const [netmask, setNetmask] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [gateway_ip, setGatewayIp] = React.useState("");
  const [first_ip, setFirstIp] = React.useState("");
  const [last_ip, setLastIp] = React.useState("");
  const [dns_ip1, setDnsIp1] = React.useState("");
  const [dns_ip2, setDnsIp2] = React.useState("");
  const [dc, setDc] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [providerList, setProviderList] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (state) {
      setOpen(state);
      if (existingValues.length) updateValues(existingValues);
      else updateValues(initialValues);
    }
  }, [state, existingValues, initialValues]);

  useEffect(() => {
    if (providerList.length === 0) {
      getProviderList();
    }
  });

  const updateValues = (values) => {
    for (var i = 0; i < values.length; i++) {
      var tmpName = values[i].name.split("aur.")[1];
      var value = values[i].value;
      switch (tmpName) {
        case "ip_range":
          setIpRange(value);
          break;
        case "address":
          setAddress(value);
          break;
        case "hardware":
          setHardware(value);
          break;
        case "vlan":
          setVLAN(value);
          break;
        case "is_broadband":
          setBroadband(value);
          break;
        case "provider_name":
          setProvider({ name: value });
          break;
        case "dist_router":
          setDistRouter(value);
          break;
        case "bearer_reference":
          setBearerRef(value);
          break;
        case "dist_interface":
          setDistInterface(value);
          break;
        case "circuit_type":
          setCirType(value);
          break;
        case "netmask":
          setNetmask(value);
          break;
        case "description":
          setDescription(value);
          break;
        case "gateway_ip":
          setGatewayIp(value);
          break;
        case "first_ip":
          setFirstIp(value);
          break;
        case "last_ip":
          setLastIp(value);
          break;
        case "dns_ip1":
          setDnsIp1(value);
          break;
        case "dns_ip2":
          setDnsIp2(value);
          break;
        case "dc":
          setDc(value);
          break;
        case "location":
          setLocation(value);
          break;
        default:
          break;
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    closeDialog();
  };

  const optionalProps = [
    {
      name: "IP Range",
      value: ip_range,
      function: (val) => setIpRange(val),
    },
    { name: "VLAN", value: vlan, function: (val) => setVLAN(val) },
    {
      name: "Broadband?",
      value: is_broadband,
      function: (val) => setBroadband(val),
    },
    {
      name: "Provider Name",
      value: provider_name,
      function: (val) => setProvider(val),
    },
    {
      name: "Distribution Router IP",
      value: dist_router,
      function: (val) => setDistRouter(val),
    },
    {
      name: "Bearer Reference",
      value: bearer_reference,
      function: (val) => setBearerRef(val),
    },
    {
      name: "Distribution Interface",
      value: dist_interface,
      function: (val) => setDistInterface(val),
    },
    {
      name: "Netmask",
      value: netmask,
      function: (val) => setNetmask(val),
    },
    {
      name: "Gateway IP",
      value: gateway_ip,
      function: (val) => setGatewayIp(val),
    },
    {
      name: "First IP",
      value: first_ip,
      function: (val) => setFirstIp(val),
    },
    {
      name: "Last IP",
      value: last_ip,
      function: (val) => setLastIp(val),
    },
    {
      name: "DNS IP 1",
      value: dns_ip1,
      function: (val) => setDnsIp1(val),
    },
    {
      name: "DNS IP 2",
      value: dns_ip2,
      function: (val) => setDnsIp2(val),
    },
    {
      name: "Domain Controller",
      value: dc,
      function: (val) => setDc(val),
    },
    {
      name: "Circuit Type",
      value: circuit_type,
      function: (val) => setCirType(val),
    },
    {
      name: "Hardware",
      value: hardware,
      function: (val) => setHardware(val),
    },
    {
      name: "Address",
      value: address,
      function: (val) => setAddress(val),
      rowHeight: 3,
    },
    {
      name: "Location",
      value: location,
      function: (val) => setLocation(val),
      rowHeight: 3,
    },
    {
      name: "Description",
      value: description,
      function: (val) => setDescription(val),
      rowHeight: 3,
    },
  ];

  const confirmProperties = () => {
    var data = {
      ip_range: ip_range,
      hardware: hardware,
      address: address,
      vlan: vlan,
      is_broadband: is_broadband,
      provider_name: provider_name.name,
      dist_router: dist_router,
      bearer_reference: bearer_reference,
      dist_interface: dist_interface,
      circuit_type: circuit_type,
      netmask: netmask,
      description: description,
      gateway_ip: gateway_ip,
      first_ip: first_ip,
      last_ip: last_ip,
      dns_ip1: dns_ip1,
      dns_ip2: dns_ip2,
      dc: dc,
      location: location,
    };

    setProperties(data);
    handleClose();
  };

  async function getProviderList() {
    await Storage.get(`ProviderList.csv`, {
      download: true,
      bucket: `${PREFIX}.logic-one.io`,
    }).then((data) => {
      data.Body.text().then((text) => {
        const csvArray = text.replace(/(\r\n|\n|\r)/gm, ",").split(",");
        const csvMap = csvArray.map((provider) => ({ name: provider }));
        setProviderList(csvMap);
      });
    });
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        style={{ overflowX: "hidden" }}
      >
        <DialogTitle id="form-dialog-title">
          Apply Additional Properties
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            //direction="row"
            spacing={1}
            justifyContent="center"
          >
            {optionalProps.map((prop) => {
              return (
                <Grid item key={prop.name} xs={6} sm={6} md={4} lg={3}>
                  {prop.name === "Provider Name" ? (
                    <ProviderDropdown
                      options={providerList}
                      value={prop.value}
                      onChange={(_, v) => {
                        prop.function(v);
                      }}
                    />
                  ) : prop.name in provisioningConfig &&
                    provisioningConfig[prop.name]["type"] === "select" ? (
                    <Autocomplete
                      id={prop.name}
                      options={provisioningConfig[prop.name]["options"]}
                      getOptionLabel={(option) => option || ""}
                      //getOptionSelected={(option, value) => option.value === value.value}
                      value={prop.value}
                      fullWidth
                      freeSolo={true}
                      onChange={(_, v) => {
                        prop.function(v);
                      }}
                      renderInput={(params) => (
                        <TextField
                          key={params.id}
                          {...params}
                          label={prop.name}
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      id={prop.name}
                      label={prop.name}
                      variant="outlined"
                      size="small"
                      value={prop.value}
                      onChange={(e) => prop.function(e.target.value)}
                      multiline
                      fullWidth
                      rows={prop.rowHeight || 1}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirmProperties}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
