export default function dashboard(
  state = {
    widgets: [],
    graphs: [],
    wan_dash_id: null,
  },
  action
) {
  switch (action.type) {
    case "DEVICE_SEARCH_REQUEST":
      return Object.assign({}, state, {});
    case "DEVICE_DASHBOARD_REQUEST":
      return Object.assign({}, state, {
        graphs: [],
      });
    case "DEVICE_DASHBOARD_SUCCESS":
      return Object.assign({}, state, {
        widgets: action.payload.graphs_by_id,
        wan_dash_id: action.payload.wan_dash_id,
      });
    case "DEVICE_WIDGETS_SUCCESS":
      // const dashboard_id = action.payload.wan_dash_id;
      return Object.assign({}, state, {
        widgets: action.payload,
      });
    case "DEVICE_WIDGETS_FAILURE":
      return Object.assign({}, state, {
        widgets: [],
      });

    default:
      return state;
  }
}
