import React from "react";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";
// import { withAuthenticator } from 'aws-amplify-react';
// import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { IS_EMBEDDED } from "./constants";
import awsconfig from "./aws-config";
import configureStore from "./configureStore";
import AuthContainer from "./containers/AuthContainer";
import AWSAuthContainer from "./containers/AWSAuthContainer";

Amplify.configure(awsconfig);

var initialState;
const store = configureStore(initialState);

export default function App() {
  return (
    // <ThemeProvider theme={theme}>
    <Provider store={store}>
      {IS_EMBEDDED ? <AuthContainer /> : <AWSAuthContainer />}
    </Provider>
    // </ThemeProvider>
  );
}

// export default withAuthenticator(App, {includeGreetings: true});
