import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import MasqueradeUserContainer from "../../containers/MasqueradeUserContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  textfield: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(15),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
}));

export default function MasqueradeDialog({
  visible,
  callback,
  updateCallback,
}) {
  // const [open, setOpen] = React.useState(visible);
  const classes = useStyles();

  // useEffect(() => {
  //   if (visible) {
  //     setOpen(visible);
  //   }
  // }, [visible]);

  const handleClose = () => {
    callback();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={visible}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Switch Account</DialogTitle>
        <DialogContent>
          <Typography color="primary" className={classes.section}>
            Select below the account you wish to masquerade as:
          </Typography>
          <MasqueradeUserContainer />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
