import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";

// import { acknowledgeAlert} from '../actions/alerts'

import AlertRow from "./AlertRow";
import AcknowledgeDialog from "../components/dialogs/AcknowledgeAlert";
import AlertsTableToolbar from "../components/AlertsTableToolbar";
import MUIDataTable from "mui-datatables";
import { epochToTimestamp } from "../libs/utils";
// import { useHistory } from "react-router-dom";
import {
  CRITICAL_COLOR,
  LIVE_COLOR,
  ERROR_COLOR,
  WARNING_COLOR,
} from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

export default function DeviceAlertsTable({ items, title, useExpandableRows }) {
  const [dialogState, setDialogState] = React.useState(false);
  // const devicesById = useSelector((state) => state.devices.byId);
  const classes = useStyles();
  const [cleared, showCleared] = React.useState(false);
  const [rowsExpanded, setRowsExpanded] = React.useState([]);
  // let history = useHistory();

  const [alertRow] = React.useState({
    id: "-",
    threshold: "-",
    alertValue: "-",
    dataPointName: "-",
    message: {
      subject: "Subject",
      body: "Message",
    },
  });

  var rows = [];
  items.forEach((item, i) => {
    if (cleared === true || (cleared === false && item.cleared === false)) {
      rows.push(item);
    }
  });

  function closeDialog() {
    setDialogState(false);
  }

  // const linkToDevice = (event, id) => {
  //   history.push("/device/" + id);
  //   event.stopPropagation();
  // };

  const updateRowsExpanded = (allRowsExpanded) => {
    var rowsExpanded = [];
    allRowsExpanded.forEach((value, index) => {
      rowsExpanded.push(index);
    });
    setRowsExpanded(rowsExpanded); //simple value
  };

  // const handleClick = (rowID) => {
  //   console.log(rowID);
  //   dispatch(getAlertInformation(rowID));
  // };

  const toggleCleared = () => {
    showCleared(!cleared);
  };

  const columns = [
    {
      name: "cleared",
      label: "cleared?",
      options: {
        display: "excluded",
        // filter: true,
      },
    },
    {
      name: "severity",
      label: "Severity",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val, tableMeta) => {
          var cleared = tableMeta.rowData[0];
          if (val === 4) {
            return (
              <Typography
                style={{ color: cleared ? LIVE_COLOR : CRITICAL_COLOR }}
              >
                Critical
              </Typography>
            );
          } else if (val === 3) {
            return (
              <Typography style={{ color: cleared ? LIVE_COLOR : ERROR_COLOR }}>
                Error
              </Typography>
            );
          } else if (val === 2) {
            return (
              <Typography
                style={{ color: cleared ? LIVE_COLOR : WARNING_COLOR }}
              >
                Warning
              </Typography>
            );
          } else {
            return <Typography style={{ color: LIVE_COLOR }}>OK</Typography>;
          }
        },
      },
    },
    {
      name: "startEpoch",
      label: "Alert Began",
      options: {
        filter: true,
        customBodyRender: (val) => {
          val = epochToTimestamp(val);
          return val;
        },
      },
    },
    {
      name: "endEpoch",
      label: "Alert End",
      options: {
        filter: true,
        customBodyRender: (val) => {
          val = epochToTimestamp(val);
          return val;
        },
      },
    },
    {
      name: "instanceName",
      label: "Instance",
      options: {
        filter: true,
      },
    },
    {
      name: "alertValue",
      label: "Value",
      options: {
        filter: true,
      },
    },
    {
      name: "threshold",
      label: "Threshold",
      options: {
        filter: true,
      },
    },
  ];

  return (
    <>
      <div className={classes.root}>
        <AcknowledgeDialog
          alert={alertRow}
          state={dialogState}
          closeDialog={closeDialog}
        />
        <MUIDataTable
          title="Alerts"
          columns={columns}
          data={rows}
          options={{
            download: false,
            elevation: 1,
            print: false,
            responsive: "standard",
            filter: false,
            sort: true,
            search: true,
            selectableRows: "none",
            rowHover: true,
            setTableProps: () => ({
              size: "small",
            }),
            viewColumns: false,
            expandableRows: false,
            expandableRowsHeader: false,
            expandableRowsOnClick: false,
            // onRowClick: (_, rowData) => {
            //   console.log("clicked on row...");
            //   handleClick(rows[rowData["rowIndex"]]["id"]);
            // },
            // isRowExpandable: (dataIndex, expandedRows) => {
            //   if (
            //     expandedRows.data.length > 4 &&
            //     expandedRows.data.filter((d) => d.dataIndex === dataIndex)
            //       .length === 0
            //   ) {
            //     return false;
            //   } else {
            //     return true;
            //   }
            // },
            renderExpandableRow: (data, indexData) => {
              var row = rows[indexData["rowIndex"]];
              return <AlertRow key={row.id} row={row} state={true}></AlertRow>;
            },
            rowsExpanded: rowsExpanded,
            onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
              updateRowsExpanded(allRowsExpanded);
            },
            customToolbar: () => {
              return <AlertsTableToolbar toggleCleared={toggleCleared} />;
            },
          }}
          // see https://github.com/gregnb/mui-datatables/tree/2.15.0
          // not all options supported by the Design System out of the box
        />
      </div>
    </>
  );
}
