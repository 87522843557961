import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Visible from "../Visible";
import { getUnixTime } from "date-fns";
import { addMaintenance } from "../../actions/maintenance";
import { Autocomplete } from "@material-ui/lab";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { checkOptionLabel } from "../../libs/utils";
// import { RbacContext } from "../../containers/LoadingContainer";

const useStyles = makeStyles((theme) => ({
  gap: {
    marginBottom: theme.spacing(1),
  },
  formControl: { width: "100%", marginBottom: theme.spacing(1.5) },
}));

export default function AddMaintenance({ devices, callback, account_id }) {
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState(null);
  const [devs, setDevices] = React.useState([]);
  const [startTime, setStartTime] = React.useState(undefined);
  const [endTime, setEndTime] = React.useState(null);
  const [type, setType] = React.useState("DeviceSDT");
  //const [reduxProp, setReduxProp] = React.useState("label");
  const [targetSelection, setArray] = React.useState(devices);
  const dispatch = useDispatch();
  const classes = useStyles();
  const identifier =
    useSelector(
      (state) =>
        state.ui_settings.portal_config.planned_maintenance_identifier ||
        state.ui_settings.portal_config.device_identifier
    ) || "Billing Reference";
  // const manage = useContext(RbacContext).Maintenance.manage;

  const setSDTComment = (event) => {
    setComment(event.target.value);
    checkValuesAreChanged();
  };

  const addSDT = async () => {
    var end = getUnixTime(new Date(endTime)) * 1000;
    var start = getUnixTime(new Date(startTime)) * 1000;

    for (var i = 0; i < devs.length; i++) {
      await dispatch(
        addMaintenance(
          account_id,
          devs[i].id,
          devs[i][identifier],
          start,
          end,
          comment,
          type
        )
      );
    }
    setTimeout(() => callback(), 300);
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setStartTime(null);
    setEndTime(null);
    setComment(null);
    setDevices([]);
    setType("DeviceSDT");
    //setReduxProp("label");
    setArray(devices);
    setOpen(false);
  };
  /*  FOR ADDING DEVICE GROUP SDT
  const handleTypeChange = (event) => {
    setType(event.target.value);
    setId(null)
    if(event.target.value === 'DeviceGroupSDT') {
      setReduxProp("name")
      setArray(groups)
    } else {
      setReduxProp("label")
      setArray(devices)
    }
  };
*/

  const changeStartDate = (val) => {
    var start = getUnixTime(new Date(val)) * 1000;
    var end = getUnixTime(new Date(endTime)) * 1000;
    if (start < end || !endTime) {
      setStartTime(val);
      checkValuesAreChanged();
    }
  };

  const changeEndDate = (val) => {
    var end = getUnixTime(new Date(val)) * 1000;
    var start = getUnixTime(new Date(startTime)) * 1000;
    if (end > start || !startTime) {
      setEndTime(val);
      checkValuesAreChanged();
    }
  };

  const checkValuesAreChanged = () => {
    if (endTime !== null && startTime !== null && devs.length) return false;
    else return true;
  };

  return (
    <Visible component={"AddMaintenanceButton"}>
      <div>
        {/* {manage && ( */}
        <Button
          variant="outlined"
          color="primary"
          // className={buttonClassname}
          // disabled={is_loading}
          onClick={handleClickOpen}
          style={{
            height: "36.5px",
            minWidth: "45px",
            padding: "0px",
            marginLeft: "10px",
          }}
        >
          <Tooltip
            title={<Typography variant="body2">Add Maintenance</Typography>}
          >
            <AddIcon />
          </Tooltip>
        </Button>
        {/* )} */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Schedule Planned Maintenance
          </DialogTitle>
          <DialogContent>
            {/*
            <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="sdt-type-label">SDT Type</InputLabel>
              <Select
                labelId="sdt-type-label"
                id="sdt-type"
                value={type}
                onChange={handleTypeChange}
                label="SDT Type"
              >
                <MenuItem value={"DeviceSDT"}>Device</MenuItem>
                <MenuItem value={"DeviceGroupSDT"}>Device</MenuItem>
              </Select>
            </FormControl>
            */}
            <FormControl className={`${classes.formControl} ${classes.gap}`}>
              <Autocomplete
                multiple
                options={targetSelection}
                onChange={(_, v) => setDevices(v)}
                filterSelectedOptions
                getOptionLabel={(option) =>
                  checkOptionLabel(option, identifier)
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    key={params.id}
                    {...params}
                    label={identifier}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <Grid container spacing={1} className={classes.gap}>
              <Grid item>
                <TextField
                  id="datetime-start"
                  type="datetime-local"
                  className={classes.textField}
                  value={startTime}
                  variant="outlined"
                  label="Start"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => changeStartDate(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="datetime-end"
                  type="datetime-local"
                  className={classes.textField}
                  value={endTime}
                  variant="outlined"
                  label="End"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => changeEndDate(e.target.value)}
                />
              </Grid>
            </Grid>

            <TextField
              id="name"
              label="Add Comment"
              type="email"
              fullWidth
              multiline
              minRows={3}
              variant="outlined"
              onChange={setSDTComment}
              placeholder="FULL NAME & DESCRIPTION"
              className={classes.gap}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={addSDT}
              variant="contained"
              color="primary"
              disabled={checkValuesAreChanged()}
            >
              Add
            </Button>
            <Button onClick={handleClose} variant="contained">
              Close
            </Button>
            {/*
          <Button onClick={saveRecipients} color="primary">
            Save
          </Button>
          */}
          </DialogActions>
        </Dialog>
      </div>
    </Visible>
  );
}
