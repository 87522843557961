/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getJobHistory = /* GraphQL */ `
  query GetJobHistory($id: String!) {
    getJobHistory(id: $id) {
      id
      created
      dashGroupId
      devGroupId
      account_id
      output
      params
      email
      type
    }
  }
`;
export const listJobHistories = /* GraphQL */ `
  query ListJobHistories(
    $filter: TableJobHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        created
        dashGroupId
        devGroupId
        account_id
        output
        params
        email
        type
      }
      nextToken
    }
  }
`;
export const getViewRoles = /* GraphQL */ `
  query GetViewRoles($id: String!) {
    getViewRoles(id: $id) {
      id
      Component
      Permissions
    }
  }
`;
export const listViewRoles = /* GraphQL */ `
  query ListViewRoles(
    $filter: TableViewRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listViewRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Component
        Permissions
      }
      nextToken
    }
  }
`;
export const getUserViewSettings = /* GraphQL */ `
  query GetUserViewSettings($id: String!) {
    getUserViewSettings(id: $id) {
      id
      cognito_user_id
      component
      config
      view_name
      saved_views
      dashboard_settings
      selected_views
    }
  }
`;
export const listUserViewSettings = /* GraphQL */ `
  query ListUserViewSettings(
    $filter: TableUserViewSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserViewSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognito_user_id
        component
        config
        view_name
        saved_views
        dashboard_settings
        selected_views
      }
      nextToken
    }
  }
`;
export const queryUserViewSettingsByCognitoUserId = /* GraphQL */ `
  query QueryUserViewSettingsByCognitoUserId(
    $cognito_user_id: String!
    $first: Int
    $after: String
  ) {
    queryUserViewSettingsByCognitoUserId(
      cognito_user_id: $cognito_user_id
      first: $first
      after: $after
    ) {
      items {
        id
        cognito_user_id
        component
        config
        view_name
        saved_views
        dashboard_settings
        selected_views
      }
      nextToken
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices($id: String!) {
    getDevices(id: $id) {
      id
      account_id
      system
      manual
      custom
      timestamp
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $account_id: String
    $filter: TableDevicesFilterInput
    $count: Int
    $nextToken: String
  ) {
    listDevices(
      account_id: $account_id
      filter: $filter
      count: $count
      nextToken: $nextToken
    ) {
      items {
        id
        account_id
        system
        manual
        custom
        timestamp
      }
      nextToken
    }
  }
`;
export const listDevicesByAccountId = /* GraphQL */ `
  query ListDevicesByAccountId(
    $account_id: String!
    $filter: TableResellerDevicesFilterInput
    $count: Int
    $nextToken: String
  ) {
    listDevicesByAccountId(
      account_id: $account_id
      filter: $filter
      count: $count
      nextToken: $nextToken
    ) {
      items {
        account_id
        id
        system
        manual
        custom
        timestamp
      }
      nextToken
    }
  }
`;
export const getReports = /* GraphQL */ `
  query GetReports($id: String!, $account_id: String!) {
    getReports(id: $id, account_id: $account_id) {
      id
      account_id
      report_id
      results
      timestamp
      custom_columns
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: TableReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        account_id
        report_id
        results
        timestamp
        custom_columns
      }
      nextToken
    }
  }
`;
export const getAutomation = /* GraphQL */ `
  query GetAutomation($job: String!, $id: String!) {
    getAutomation(job: $job, id: $id) {
      id
      job
      timestamp
      status_code
      message
      user
      params
    }
  }
`;
export const listAutomations = /* GraphQL */ `
  query ListAutomations(
    $filter: TableAutomationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAutomations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        job
        timestamp
        status_code
        message
        user
        params
      }
      nextToken
    }
  }
`;
export const getDwsScheduledReports = /* GraphQL */ `
  query GetDwsScheduledReports($account_id: String!) {
    getDwsScheduledReports(account_id: $account_id) {
      account_id
      report_name
      receipient
      report_template
      data
      schedule
      time_range
    }
  }
`;
export const listDwsScheduledReports = /* GraphQL */ `
  query ListDwsScheduledReports(
    $account_id: String!
    $filter: TableDwsScheduledReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDwsScheduledReports(
      account_id: $account_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        account_id
        report_name
        receipient
        report_template
        data
        schedule
        time_range
      }
      nextToken
    }
  }
`;
export const getBranding = /* GraphQL */ `
  query GetBranding($account_id: String!) {
    getBranding(account_id: $account_id) {
      account_id
      name
      id
      themeColours
      logo_filename
      portal_logo_filename
      graphConfig
    }
  }
`;
export const listBrandings = /* GraphQL */ `
  query ListBrandings(
    $filter: TableBrandingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account_id
        name
        id
        themeColours
        logo_filename
        portal_logo_filename
        graphConfig
      }
      nextToken
    }
  }
`;
export const getMinimalMonitoring = /* GraphQL */ `
  query GetMinimalMonitoring($account_id: String!, $id: Int!) {
    getMinimalMonitoring(account_id: $account_id, id: $id) {
      account_id
      id
      billing_reference
      dns_name
      timestamp
      issue
    }
  }
`;
export const listMinimalMonitorings = /* GraphQL */ `
  query ListMinimalMonitorings(
    $filter: TableMinimalMonitoringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMinimalMonitorings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        account_id
        id
        billing_reference
        dns_name
        timestamp
        issue
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($group_id: String!, $id: String!) {
    getRole(group_id: $group_id, id: $id) {
      group_id
      id
      role
      Admin
      Alerts
      Automation
      Device
      Maintenance
      Masquerade
      Reports
      Services
      Settings
      Support
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: TableRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        group_id
        id
        role
        Admin
        Alerts
        Automation
        Device
        Maintenance
        Masquerade
        Reports
        Services
        Settings
        Support
      }
      nextToken
    }
  }
`;
