export default function reports(
  state = {
    items: [],
    results: {},
    pdfEpochs: {},
  },
  action
) {
  switch (action.type) {
    case "REPORTS_REQUEST":
      return Object.assign({}, state, {});
    case "REPORTS_RECEIVE":
      return Object.assign({}, state, {
        items: action.payload,
      });
    case "REPORTS_FAILURE":
      return Object.assign({}, state, {});
    case "REPORT_TEMPLATE_REQUEST":
      return Object.assign({}, state, {});
    case "REPORT_TEMPLATE_RECEIVE":
      return Object.assign({}, state, {
        items: action.payload,
      });
    case "REPORT_TEMPLATE_FAILURE":
      return Object.assign({}, state, {});
    case "REPORT_GROUPS_RECEIVE":
      return Object.assign({}, state, {
        groups: action.payload,
      });
    case "REPORT_EXECUTION_RECEIVE": {
      if (action.payload === "Unable to Load Result - Report too large") return;
      else
        return Object.assign({}, state, {
          results: storeReport(
            action.payload,
            state.results,
            action.meta.time_period,
            action.meta.timestamp,
            action.meta.custom_columns,
            action.meta.id
          ),
        });
    }
    case "PDF_REPORT_SUCCESS":
      return Object.assign({}, state, {
        pdfEpochs: storePdfEpoch(state.pdfEpochs, action.payload),
      });
    case "REPORT_DOWNLOAD":
      return Object.assign({}, state, {
        results: {
          ...state.results,
          [action.meta.type]: {
            ...state.results[action.meta.type],
            [action.meta.time_range]: {
              ...state.results[action.meta.type][action.meta.time_range],
              downloaded: true,
            },
          },
        },
      });
    default:
      return state;
  }
}

function storeReport(result, obj, time_period, timestamp, custom_columns, id) {
  var i, j, y, row, reportTime; // from removed
  var results = result.split("\n");
  result = "";
  var description = results[2].replace("Description:,", "");
  description = description.replaceAll('"', "");
  var descriptionSplit = description.split("||");
  var reportType = descriptionSplit[0],
    reportRows = descriptionSplit[1],
    datapoints = descriptionSplit[2],
    measurements = descriptionSplit[3],
    urlEndpoint = descriptionSplit[4];
  reportRows = reportRows.split(",");
  datapoints = datapoints.split(",");
  measurements = measurements.split(",");

  const headerReplacements = {
    max: ":Max",
    average: ":Avg",
    changePercent: ":Change(%)",
    changeDelta: ":Change(Delta)",
    sum: ":Sum",
    min: ":Min",
    start: ":Start",
    end: ":End",
  };

  // timeDiff = (to-from)/86400000

  //The if statement for inventory is a temp fix
  if (reportType === "Host Inventory") {
    reportTime = "24_Hours";
  } else {
    reportTime = time_period;
    // if(timeDiff === 1)
    //   reportTime = 'day'
    // else if(timeDiff === 7)
    //   reportTime = 'week'
    // else if(timeDiff === 30)
    //   reportTime = 'month'
    // else if(timeDiff === 365)
    //   reportTime = 'year'
    // else
    //   reportTime = 'custom'
  }

  var deviceDataFinal = [];

  for (i = 5; i < results.length - 1; i += datapoints.length) {
    row = results[i].split(",");
    var deviceData = { Device: row[0] };
    if (datapoints[0] !== " ") {
      for (j = 0; j < datapoints.length; j++) {
        //var measurementDict = {};
        for (var k = 0; k < measurements.length; k++) {
          var measurement = measurements[k];
          var rowToUse = results[i + j].split(",");
          //measurementDict[measurement] = rowToUse[1 + k + reportRows.length];
          deviceData[datapoints[j] + headerReplacements[measurement]] =
            rowToUse[1 + k + reportRows.length];
        }
        //deviceData[datapoints[j]] = measurementDict;
      }
    } else {
      for (var l = 0; l < reportRows.length; l++) {
        deviceData[reportRows[l]] = row[l + 1];
      }
    }
    deviceDataFinal.push(deviceData);
  }
  if (typeof obj[urlEndpoint] == "undefined") {
    obj[urlEndpoint] = {
      deviceSearch: [],
    };
  }
  if (typeof obj[urlEndpoint][reportTime] == "undefined") {
    obj[urlEndpoint][reportTime] = {
      timestamp: 0,
      results: [],
    };
  }

  var allData = [];
  for (var deviceEntry in deviceDataFinal) {
    var emptyFlag = 0;
    for (var deviceEntryData in deviceDataFinal[deviceEntry]) {
      if (deviceEntryData !== "device") {
        if (deviceDataFinal[deviceEntry][deviceEntryData] !== "") {
          emptyFlag += 1;
        }
      }
    }
    if (emptyFlag !== 0) {
      allData.push(deviceDataFinal[deviceEntry]);
    }
  }
  deviceDataFinal = allData;

  obj[urlEndpoint][reportTime].results = deviceDataFinal;
  obj[urlEndpoint][reportTime].timestamp = timestamp;
  obj[urlEndpoint][reportTime].custom_columns = custom_columns;
  obj[urlEndpoint][reportTime].id = id;
  obj[urlEndpoint][reportTime].downloaded = false;

  y = Object.assign({}, obj);

  return y;
}

function storePdfEpoch(epochs, payload) {
  const acc = epochs[payload.account_id] || {};
  const name = acc[payload.report_name] || {};
  return {
    ...epochs,
    [payload.account_id]: {
      ...acc,
      [payload.report_name]: {
        ...name,
        [payload.range]: Date.now(),
      },
    },
  };
}
