import { Typography } from "@material-ui/core";
import {
  AllDeviceProps,
  ConnectivityProps,
  FirewallProps,
  SwitchProps,
} from "../data/ServiceProps";

export const propGroups = {
  Customer: [
    "Account ID",
    "Billing Reference",
    "Customer",
    "Postcode",
    "Address",
  ],
  Circuit: [
    "Provider Name",
    "Circuit Reference Provider",
    "Circuit Reference",
    "Circuit Type",
    "Circuit Size",
    "Bearer Reference",
    "Bearer Size",
    "Distribution Router",
    "Distribution Interface",
    "Distribution",
  ],
  Device: [
    "Date Added",
    "Description",
    "Chassis Model",
    "Hardware",
    "DNS Name",
    "IP Range",
    "Gateway IP",
    "Netmask",
  ],
  Network: [
    "Domain Controller",
    "Loopback IP",
    "First IP",
    "Last IP",
    "DNS IP 1",
    "DNS IP 2",
    "VLAN",
  ],
};

function getDeviceProps(device_type) {
  var deviceProps = AllDeviceProps;
  if (device_type === "connectivity") {
    deviceProps = ConnectivityProps;
  } else if (device_type === "firewalls") {
    deviceProps = FirewallProps;
  } else if (device_type === "switches") {
    deviceProps = SwitchProps;
  } else {
    deviceProps = ConnectivityProps;
  }
  return deviceProps;
}

const checkBillingRef = (ref) =>
  typeof ref !== "undefined"
    ? ref.includes("DR-")
      ? ref.replace("DR-", "")
      : ref
    : null;

export function getPropMap(identifier) {
  const baseProps = {
    id: { display: "excluded", filter: false },
    "Alert Status": {
      label: "Status",
      filter: true,
      display: true,
      customBodyRender: (val) =>
        !val ? (
          <Typography style={{ color: "green" }}>OK</Typography>
        ) : val.includes("critical") ? (
          <Typography style={{ color: "red" }}>Critical</Typography>
        ) : val.includes("error") ? (
          <Typography style={{ color: "orange" }}>Error</Typography>
        ) : val.includes("warn") ? (
          <Typography style={{ color: "#F4B545" }}>Warning</Typography>
        ) : (
          <Typography style={{ color: "green" }}>OK</Typography>
        ),

      sortFunction: (order) => (obj1, obj2) => {
        /* Assign priorities to criticalities to be able to sort */
        if (obj1.data === undefined) console.log(obj1);
        let val1, val2;
        if (obj1.data.includes("critical")) {
          val1 = 0;
        } else if (obj1.data.includes("error")) {
          val1 = 1;
        } else if (obj1.data.includes("warning")) {
          val1 = 2;
        } else {
          val1 = 3;
        }
        if (obj2.data.includes("critical")) {
          val2 = 0;
        } else if (obj2.data.includes("error")) {
          val2 = 1;
        } else if (obj2.data.includes("warning")) {
          val2 = 2;
        } else {
          val2 = 3;
        }
        return (val1 - val2) * (order === "asc" ? -1 : 1);
      },
    },
    Customer: { label: "Site Name", display: true, filter: true },
    Postcode: { display: true, filter: false },
    [identifier]: {
      label: identifier,
      display: true,
      filter: false,
      customBodyRender: (val) => checkBillingRef(val),
    },
    "Circuit Reference": {
      display: true,
      filter: false,
    },
    "Device Type": { display: true, filter: true },
    "Circuit Reference Provider": {
      label: "Circuit Provider",
      display: true,
      filter: true,
    },
    "Circuit Size": {
      display: true,
      filter: true,
      customBodyRender: (val) => {
        let newUnit = "";
        try {
          val = val.toLowerCase();
        } catch {
          return "";
        }

        if (val.includes("mb")) newUnit = "Mb";
        else if (val.includes("gb")) newUnit = "Gb";
        else if (val.length === 1) return val + "Gb";
        else if (val.length > 1) return val + "Mb";
        else return val;

        return val.replace(/\D/g, "") + newUnit;
      },
    },
  };
  return baseProps;
}

export function getSummaryProps(device_type) {
  var deviceProps = getDeviceProps(device_type);

  return deviceProps.summary_props;
}
