import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import {
  initRequestRecipientGroupById,
  acknowledgeAlert,
} from "../actions/alerts";
import { getAlertInformation } from "../actions/alerts";
import ConfigureAlerts from "../components/dialogs/ConfigureAlerts";
import { Typography } from "@material-ui/core";
import { epochToTimestamp } from "../libs/utils";
import CustomDatatable from "../components/CustomDatatable";
import ContainerHeader from "../components/ContainerHeader";
import AlertRow from "./AlertRow";
import FilterSettings from "../components/FilterSettings";
import { operatorFilter } from "../libs/utils";
import { updateAlertsFilter } from "../actions/ui_settings";
import Visible from "../components/Visible";

class AlertsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      alertData: [],
      alertsFilter:
        this.props.alertsFilter.length === 0
          ? [{ property: "", operator: null, value: "" }]
          : this.props.alertsFilter,
      filterValid: this.props.alertsFilter.length === 0 ? false : true,
      searchOutput: this.props.searchResults,
    };
    this.propMap = {
      "Alert Status": {
        label: "Status",
        display: "excluded",
      },
      cleared: {
        label: "cleared?",
        display: "excluded",
      },
      severityLabel: {
        label: "Severity",
        filter: true,
        sort: true,
        customBodyRender: (val, tableMeta) => {
          return val === "Critical" ? (
            <Typography
              style={{ color: tableMeta.rowData[0] ? "green" : "red" }}
            >
              Critical
            </Typography>
          ) : val === "Error" ? (
            <Typography style={{ color: "orange" }}>Error</Typography>
          ) : val === "Warning" ? (
            <Typography style={{ color: "#F4B545" }}>Warning</Typography>
          ) : (
            <Typography style={{ color: "green" }}>{val}</Typography>
          );
        },
      },
      startEpoch: {
        label: "Alert Began",
        filter: true,
        sortDirection: "desc",
        customBodyRender: (val) => epochToTimestamp(val),
      },
      "Billing Reference": {
        label: "Billing Reference",
        filter: true,
        // customBodyRender: (val) => checkBillingRef(val),
      },
      Customer: {
        label: "Site Name",
        filter: true,
      },
      Postcode: {
        filter: true,
      },
      "Circuit Reference": {
        // monitorObjectId: {
        label: "Circuit Reference",
        filter: true,
        // customBodyRender: (val) =>
        //   this.props.byId[val] ? (
        //     <Link
        //       onClick={(event) =>
        //         this.LinkToDevice(event, this.props.byId[val])
        //       }
        //     >
        //       {this.props.byId[val]["Circuit Reference"]
        //         ? this.props.byId[val]["Circuit Reference"]
        //         : "Unknown"}
        //     </Link>
        //   ) : null,
      },
      instanceName: {
        label: "Instance",
        filter: true,
      },
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    var filter = params.get("filter") || "";
    this.filterAlerts(filter);
    // Auth.currentUserInfo().then(usr => {
    //   if (usr.attributes['custom:account_id'] !== 'DWS_SUPER') this.setState({ access: 1, loading: false })
    // }).catch(e => console.log(e))
    //this.props.dispatch(initRequestAlerts())
    this.updateFilterOutput(filter, true);
  }

  /* NEXT IS TO TAKE NEWDEVICESINFO AND MERGE IT WITH THE ALERTS INFO - THESE HAVE ALREADY BEEN MATCHED ON LINE 40. ONCE THEY HAVE BEEN MERGED YOU CAN THEN TAKE THIS INFO AND USE IT ON ALERTSTABLE. */

  handleButtonClick() {
    this.props.dispatch(initRequestRecipientGroupById(this.props.account_id));
  }

  acknowledgeAlert() {
    this.props.dispatch(acknowledgeAlert(this.props.alert_id));
  }

  getAlertInfo(id) {
    this.props.dispatch(getAlertInformation(id));
  }

  filterAlerts(type) {
    this.setState({ filter: type });
  }

  LinkToDevice = (event, Device) => {
    if (Device && Device.id) {
      this.props.history.push("/device/" + Device.id, { Device: Device });
    }
    event.stopPropagation();
  };

  updateAlertsFilter = (filter) => {
    var filterValid = true;
    var key;
    for (const item of filter) {
      if (Array.isArray(item)) {
        for (const obj of item) {
          for (key in obj) {
            if (obj[key] === null || obj[key] === "") {
              filterValid = false;
            }
          }
        }
      } else {
        for (key in item) {
          if (item[key] === null || item[key] === "") {
            filterValid = false;
          }
        }
      }
      //this.gatherStatusTiles()
    }
    if (filter.length === 0) {
      filter = [{ property: "", operator: null, value: "" }];
      filterValid = true;
    }
    if (filter.length === 1) {
      if (
        filter[0]["property"] === "" &&
        filter[0]["value"] === "" &&
        filter[0]["operator"] === null
      ) {
        filterValid = true;
      }
    }
    this.setState({ alertsFilter: filter, filterValid: filterValid });
  };

  updateFilterOutput = (filter, searchBoolean = false) => {
    filter = this.state.alertsFilter;
    this.updateAlertsFilter(filter);
    if (!searchBoolean) {
      this.props.updateAlertsFilter(filter);
    }

    let alerts = [];
    this.props.finalData.forEach((alert) => {
      let device = this.props.searchResults.find(
        (device) => device["Circuit Reference"]
      );
      alerts.push({ ...device, ...alert });
    });

    var devicesOutput = operatorFilter(
      alerts,
      filter || this.state.alertsFilter,
      this.state.filterValid
    );
    this.setState({ searchOutput: devicesOutput });
  };

  render() {
    const {
      alerts,
      account_id,
      finalData,
      recipient_group,
      user_views,
      user_id,
      defaultView,
      alertsFilter,
    } = this.props;

    const severityMap = {
      4: "Critical",
      3: "Error",
      2: "Warning",
    };

    var filteredAlerts = [];
    if (this.state.filter && this.state.filter !== "Acknowledged") {
      finalData.forEach((alert) => {
        if (severityMap[alert.severity] === this.state.filter) {
          filteredAlerts.push(alert);
        }
      });
    } else if (this.state.filter === "Acknowledged") {
      finalData.forEach((alert) => {
        if (alert.acked) {
          filteredAlerts.push(alert);
        }
      });
    } else {
      filteredAlerts = finalData;
    }

    // Merge filter output with filtered alerts.
    let filteredAlertData = [];
    filteredAlerts.forEach((i) => {
      this.state.searchOutput.forEach((j) => {
        if (String(i["monitorObjectId"]) === String(j["monitorObjectId"])) {
          filteredAlertData.push({ ...j, ...i });
        }
      });
    });
    filteredAlertData = _.uniqBy(filteredAlertData, (a) => a.id);

    const common_columns = [
      "Severity",
      "Alert Began",
      "Billing Reference",
      "Site Name",
      "Postcode",
      "Circuit Reference",
      "Instance",
    ];

    return (
      <>
        <ContainerHeader title={"Alerts"} />
        <Grid
          container
          justifyContent="space-between"
          style={{ marginBottom: 20 }}
        >
          <Grid item>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item>
                <Button
                  variant={!this.state.filter ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => this.filterAlerts(null)}
                >
                  ({alerts.alerts ? alerts.alerts.length : 0}) Show All
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={
                    this.state.filter === "Critical" ? "contained" : "outlined"
                  }
                  color="primary"
                  onClick={() => this.filterAlerts("Critical")}
                >
                  (
                  {alerts.byStatus["Critical"]
                    ? alerts.byStatus["Critical"].length
                    : 0}
                  ) Critical
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={
                    this.state.filter === "Error" ? "contained" : "outlined"
                  }
                  color="primary"
                  onClick={() => this.filterAlerts("Error")}
                >
                  (
                  {alerts.byStatus["Error"]
                    ? alerts.byStatus["Error"].length
                    : 0}
                  ) Error
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={
                    this.state.filter === "Warning" ? "contained" : "outlined"
                  }
                  color="primary"
                  onClick={() => this.filterAlerts("Warning")}
                >
                  (
                  {alerts.byStatus["Warning"]
                    ? alerts.byStatus["Warning"].length
                    : 0}
                  ) Warning
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={
                    this.state.filter === "Acknowledged"
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  onClick={() => this.filterAlerts("Acknowledged")}
                >
                  ({alerts.acknowledgedAlertIds.length}) Acknowledged
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {!account_id.includes("_SUPER") ? (
            // <RbacContext.Consumer>
            //   {(perms) =>
            //     perms.Alerts["Configure Alerts"].view && (
            <Grid item>
              <Visible permissionNeeded="alerts" capabilityNeeded="manage">
                <ConfigureAlerts
                  account_id={account_id}
                  recipient_group={recipient_group}
                  // manage={perms.Alerts["Configure Alerts"].manage}
                />
              </Visible>
            </Grid>
          ) : null}
        </Grid>
        <CustomDatatable
          account_id={account_id}
          propMap={this.propMap}
          rows={filteredAlertData}
          expandableRows={(row) => (
            <AlertRow account_id={account_id} key={row.id} row={row} />
          )}
          viewComponent={"AlertsTable"}
          user_id={user_id || "default"}
          draggableColumns
          customPage
          loading={false} // Data will be prefetched.
          view_name={this.props.selectedViews.AlertsTable || "default"}
          defaultView={defaultView}
          user_views={user_views}
          editableColumns
          FilterSettings={
            <FilterSettings
              deviceProperties={this.props.deviceProperties}
              deviceFilter={this.state.alertsFilter}
              savedFilters={this.props.alertsFilter}
              updateDeviceFilter={this.updateAlertsFilter}
              updateFilterOutput={this.updateFilterOutput}
              filterValid={this.state.filterValid}
              viewName={this.props.selectedViews.AlertsTable || "default"}
              viewComponent={"AlertsTable"}
              byViewName={this.props.byViewName}
              user_id={this.props.user_id}
              showSave={false}
            />
          }
          deviceFilter={alertsFilter}
          updateFilterCallback={(event) => this.updateFilterOutput(event)}
          //sortProp={"startEpoch"}
          common_columns={common_columns}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { alerts } = state;
  const { account_id } = state.userSession.properties;
  const { is_superuser, role } = state.userSession;
  const { recipient_group } = state.alerts;
  const { byId } = state.devices;
  const user_views = state.ui_settings.user_views.byComponent.AlertsTable;
  const user_id = state.ui_settings.user_id;
  const { alertsFilter, defaultView, byViewName, selectedViews } =
    state.ui_settings.user_views;
  const { allProperties } = state.devices.deviceProperties;
  var alertsInfo = alerts.alerts || [];
  let devicesInfo = byId || [];
  let finalData = [];
  for (var alertKey in alertsInfo) {
    var alertId = alertsInfo[alertKey].monitorObjectId;
    let newDevicesInfo = devicesInfo[alertId];
    let mergedData = { ...newDevicesInfo, ...alertsInfo[alertKey] };
    finalData.push(mergedData);
  }

  var searchResults = [];
  if (byId) {
    Object.keys(byId).forEach((id) => {
      searchResults.push(byId[id]);
    });
  }

  return {
    alerts,
    account_id,
    is_superuser,
    byId,
    finalData,
    recipient_group,
    role,
    alertsFilter,
    defaultView,
    byViewName,
    selectedViews,
    user_views,
    searchResults,
    deviceProperties: allProperties,
    user_id,
  };
}

const mapDispatchToProps = {
  updateAlertsFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);
