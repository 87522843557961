import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const ProviderDropdown = ({ options, value, onChange }) => {
  return (
    <Autocomplete
      id={"Provider Name"}
      options={options}
      getOptionLabel={(option) => option.name || ""}
      getOptionSelected={(option, value) => option.value === value.value}
      value={value}
      fullWidth
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          key={params.id}
          {...params}
          label={"Provider Name"}
          variant="outlined"
          size="small"
        />
      )}
    />
  );
};

export default ProviderDropdown;
