/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateJobHistory = /* GraphQL */ `
  subscription OnCreateJobHistory {
    onCreateJobHistory {
      id
      created
      dashGroupId
      devGroupId
      account_id
      output
      params
      email
      type
    }
  }
`;
export const onUpdateJobHistory = /* GraphQL */ `
  subscription OnUpdateJobHistory {
    onUpdateJobHistory {
      id
      created
      dashGroupId
      devGroupId
      account_id
      output
      params
      email
      type
    }
  }
`;
export const onDeleteJobHistory = /* GraphQL */ `
  subscription OnDeleteJobHistory {
    onDeleteJobHistory {
      id
      created
      dashGroupId
      devGroupId
      account_id
      output
      params
      email
      type
    }
  }
`;
export const onCreateViewRoles = /* GraphQL */ `
  subscription OnCreateViewRoles(
    $id: String
    $Component: String
    $Permissions: AWSJSON
  ) {
    onCreateViewRoles(
      id: $id
      Component: $Component
      Permissions: $Permissions
    ) {
      id
      Component
      Permissions
    }
  }
`;
export const onUpdateViewRoles = /* GraphQL */ `
  subscription OnUpdateViewRoles(
    $id: String
    $Component: String
    $Permissions: AWSJSON
  ) {
    onUpdateViewRoles(
      id: $id
      Component: $Component
      Permissions: $Permissions
    ) {
      id
      Component
      Permissions
    }
  }
`;
export const onDeleteViewRoles = /* GraphQL */ `
  subscription OnDeleteViewRoles(
    $id: String
    $Component: String
    $Permissions: AWSJSON
  ) {
    onDeleteViewRoles(
      id: $id
      Component: $Component
      Permissions: $Permissions
    ) {
      id
      Component
      Permissions
    }
  }
`;
export const onCreateUserViewSettings = /* GraphQL */ `
  subscription OnCreateUserViewSettings($id: String) {
    onCreateUserViewSettings(id: $id) {
      id
      cognito_user_id
      component
      config
      view_name
      saved_views
      dashboard_settings
      selected_views
    }
  }
`;
export const onUpdateUserViewSettings = /* GraphQL */ `
  subscription OnUpdateUserViewSettings($id: String) {
    onUpdateUserViewSettings(id: $id) {
      id
      cognito_user_id
      component
      config
      view_name
      saved_views
      dashboard_settings
      selected_views
    }
  }
`;
export const onDeleteUserViewSettings = /* GraphQL */ `
  subscription OnDeleteUserViewSettings($id: String) {
    onDeleteUserViewSettings(id: $id) {
      id
      cognito_user_id
      component
      config
      view_name
      saved_views
      dashboard_settings
      selected_views
    }
  }
`;
export const onCreateDevices = /* GraphQL */ `
  subscription OnCreateDevices($id: String) {
    onCreateDevices(id: $id) {
      id
      account_id
      system
      manual
      custom
      timestamp
    }
  }
`;
export const onUpdateDevices = /* GraphQL */ `
  subscription OnUpdateDevices($id: String) {
    onUpdateDevices(id: $id) {
      id
      account_id
      system
      manual
      custom
      timestamp
    }
  }
`;
export const onDeleteDevices = /* GraphQL */ `
  subscription OnDeleteDevices($id: String) {
    onDeleteDevices(id: $id) {
      id
      account_id
      system
      manual
      custom
      timestamp
    }
  }
`;
export const onCreateReports = /* GraphQL */ `
  subscription OnCreateReports($id: String!) {
    onCreateReports(id: $id) {
      id
      account_id
      report_id
      results
      timestamp
      custom_columns
    }
  }
`;
export const onUpdateReports = /* GraphQL */ `
  subscription OnUpdateReports($id: String, $account_id: String) {
    onUpdateReports(id: $id, account_id: $account_id) {
      id
      account_id
      report_id
      results
      timestamp
      custom_columns
    }
  }
`;
export const onDeleteReports = /* GraphQL */ `
  subscription OnDeleteReports {
    onDeleteReports {
      id
      account_id
      report_id
      results
      timestamp
      custom_columns
    }
  }
`;
export const onCreateAutomation = /* GraphQL */ `
  subscription OnCreateAutomation {
    onCreateAutomation {
      id
      job
      timestamp
      status_code
      message
      user
      params
    }
  }
`;
export const onUpdateAutomation = /* GraphQL */ `
  subscription OnUpdateAutomation {
    onUpdateAutomation {
      id
      job
      timestamp
      status_code
      message
      user
      params
    }
  }
`;
export const onDeleteAutomation = /* GraphQL */ `
  subscription OnDeleteAutomation {
    onDeleteAutomation {
      id
      job
      timestamp
      status_code
      message
      user
      params
    }
  }
`;
export const onCreateBranding = /* GraphQL */ `
  subscription OnCreateBranding(
    $account_id: String
    $name: String
    $id: String
    $primary: String
    $secondary: String
  ) {
    onCreateBranding(
      account_id: $account_id
      name: $name
      id: $id
      primary: $primary
      secondary: $secondary
    ) {
      account_id
      name
      id
      themeColours
      logo_filename
      portal_logo_filename
      graphConfig
    }
  }
`;
export const onUpdateBranding = /* GraphQL */ `
  subscription OnUpdateBranding(
    $account_id: String
    $name: String
    $id: String
    $primary: String
    $secondary: String
  ) {
    onUpdateBranding(
      account_id: $account_id
      name: $name
      id: $id
      primary: $primary
      secondary: $secondary
    ) {
      account_id
      name
      id
      themeColours
      logo_filename
      portal_logo_filename
      graphConfig
    }
  }
`;
export const onDeleteBranding = /* GraphQL */ `
  subscription OnDeleteBranding(
    $account_id: String
    $name: String
    $id: String
    $primary: String
    $secondary: String
  ) {
    onDeleteBranding(
      account_id: $account_id
      name: $name
      id: $id
      primary: $primary
      secondary: $secondary
    ) {
      account_id
      name
      id
      themeColours
      logo_filename
      portal_logo_filename
      graphConfig
    }
  }
`;
export const onCreateMinimalMonitoring = /* GraphQL */ `
  subscription OnCreateMinimalMonitoring($account_id: String, $id: Int) {
    onCreateMinimalMonitoring(account_id: $account_id, id: $id) {
      account_id
      id
      billing_reference
      dns_name
      timestamp
      issue
    }
  }
`;
export const onUpdateMinimalMonitoring = /* GraphQL */ `
  subscription OnUpdateMinimalMonitoring($account_id: String, $id: Int) {
    onUpdateMinimalMonitoring(account_id: $account_id, id: $id) {
      account_id
      id
      billing_reference
      dns_name
      timestamp
      issue
    }
  }
`;
export const onDeleteMinimalMonitoring = /* GraphQL */ `
  subscription OnDeleteMinimalMonitoring($account_id: String, $id: Int) {
    onDeleteMinimalMonitoring(account_id: $account_id, id: $id) {
      account_id
      id
      billing_reference
      dns_name
      timestamp
      issue
    }
  }
`;
export const onCreateRole = /* GraphQL */ `
  subscription OnCreateRole(
    $group_id: String
    $id: String
    $role: String
    $Admin: AWSJSON
    $Alerts: AWSJSON
  ) {
    onCreateRole(
      group_id: $group_id
      id: $id
      role: $role
      Admin: $Admin
      Alerts: $Alerts
    ) {
      group_id
      id
      role
      Admin
      Alerts
      Automation
      Device
      Maintenance
      Masquerade
      Reports
      Services
      Settings
      Support
    }
  }
`;
export const onUpdateRole = /* GraphQL */ `
  subscription OnUpdateRole(
    $group_id: String
    $id: String
    $role: String
    $Admin: AWSJSON
    $Alerts: AWSJSON
  ) {
    onUpdateRole(
      group_id: $group_id
      id: $id
      role: $role
      Admin: $Admin
      Alerts: $Alerts
    ) {
      group_id
      id
      role
      Admin
      Alerts
      Automation
      Device
      Maintenance
      Masquerade
      Reports
      Services
      Settings
      Support
    }
  }
`;
export const onDeleteRole = /* GraphQL */ `
  subscription OnDeleteRole(
    $group_id: String
    $id: String
    $role: String
    $Admin: AWSJSON
    $Alerts: AWSJSON
  ) {
    onDeleteRole(
      group_id: $group_id
      id: $id
      role: $role
      Admin: $Admin
      Alerts: $Alerts
    ) {
      group_id
      id
      role
      Admin
      Alerts
      Automation
      Device
      Maintenance
      Masquerade
      Reports
      Services
      Settings
      Support
    }
  }
`;
