import { encode } from "base-64";
import { Auth } from "aws-amplify";
import API from "@aws-amplify/api";
import { apiQuery } from "../libs/auth-config";

import * as queries from "../graphql/queries";
import default_data from "../data/default_permissions";

export const SESSION_REQUEST = "SESSION_REQUEST";
export const SESSION_RECEIVE = "SESSION_RECEIVE";
export const SESSION_FAILURE = "SESSION_FAILURE";

export function initRequestSession(token) {
  const parsedToken = parseJwt(token);
  return {
    type: SESSION_RECEIVE,
    payload: parsedToken,
  };
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const JWT_REQUEST = "JWT_REQUEST";
export const JWT_RECEIVE = "JWT_RECEIVE";
export const JWT_FAILURE = "JWT_FAILURE";

export const fetchJWT =
  (username, password, account_id) => async (dispatch) => {
    if (!account_id) {
      return;
    }

    var string = encode(`${username}:${password}`);
    console.log(string);
    apiQuery(
      "GET",
      "/tokens",
      {
        account_id: account_id,
      },
      null,
      null,
      string
    )
      .then((data) => {
        console.log(data);
        dispatch({
          type: JWT_RECEIVE,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({ type: JWT_FAILURE });
        console.log(e);
      });

    // var url = APIURL + "/api/v1/token-jwt?account_id=" + account_id;
    // return {
    //   [RSAA]: {
    //     endpoint: url,
    //     method: "GET",
    //     types: [
    //       {
    //         type: JWT_REQUEST,
    //         // meta: { 'goods': goods }
    //       },
    //       JWT_RECEIVE,
    //       JWT_FAILURE,
    //     ],
    //     headers: {
    //       Authorization: "Basic " + string,
    //     },
    //   },
    // };
  };

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_RECEIVE = "LOGOUT_RECEIVE";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// this just logs out the user locally for now.
// It could be extended to perform logout on the server too.
export function logoutUser() {
  return {
    type: LOGOUT_RECEIVE,
  };
}

export const COGNITO_USER_RECEIVE = "COGNITO_USER_RECEIVE";

export function fetchCognitoUser() {
  return async function getCognitoUserThunk(dispatch, getState) {
    return (
      Auth.currentUserInfo()
        .then((usr) =>
          dispatch({
            type: COGNITO_USER_RECEIVE,
            payload: usr,
          })
        )
        // this.setState({ devPageAccess: parseInt(usr.attributes['custom:superuser'])}))
        .catch((e) => console.log(e))
    );
  };
}

export const getRbac = async (role_id, group) =>
  await API.graphql({
    query: queries.getRole,
    variables: {
      id: role_id,
      group_id: group,
    },
    authToken: localStorage.getItem("jwt"),
  })
    .then((data) => {
      console.log(role_id, group, data);
      let perms = data.data.getRole;
      Object.keys(perms).forEach((page) => {
        try {
          perms[page] = JSON.parse(perms[page]);
        } catch (err) {}
      });
      return perms;
    })
    .catch((e) => {
      console.log(e);
      return false;
    });

export const getPermission = async (role_id, account_id, path) =>
  await API.graphql({
    query: queries.getRole,
    variables: {
      id: role_id,
      account_id: account_id,
    },
    authToken: localStorage.getItem("jwt"),
  })
    .then((data) => {
      let perms = JSON.parse(data.data.getRole[path[0]]);
      if (path.length === 1) return perms;
      path = path.slice(1);
      let end_node = perms;
      for (let x = 0; x < path.length; x++) {
        end_node = end_node[path[x]];
      }
      return end_node;
    })
    .catch((e) => {
      console.log(e);
      return false;
    });

export const VIEW_ROLES_RECEIVE = "VIEW_ROLES_RECEIVE";

export function getViewRoleConfiguration() {
  return async function getViewRoleConfigurationThunk(dispatch, getState) {
    API.graphql({
      query: queries.listViewRoles,
      authToken: localStorage.getItem("jwt"),
    })
      .then((data) => {
        // console.log(
        //   convertArrayToObject(viewRoles.data.listViewRoles.items, "Component")
        // );
        dispatch({
          type: VIEW_ROLES_RECEIVE,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({
          type: VIEW_ROLES_RECEIVE,
          payload: default_data,
        });
        console.log(e);
      });
  };
}
