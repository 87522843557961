import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CAPABILITY_LEVELS, USER_POOL } from "../../constants";
//import API from "@aws-amplify/api";
import { apiQuery } from "../../libs/auth-config";
import { fetchRoles } from "../../actions/roles";
import PhoneNumberInput from "../PhoneNumberInput";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 600,
    minWidth: 500,
  },
  textfield: {
    marginBottom: theme.spacing(2),
    width: theme.spacing(15),
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  phonecontainer: {
    marginBottom: theme.spacing(2),
  },
}));

function AddUserForm({
  state,
  userRole,
  callback,
  account_id,
  updateCallback,
  roles,
  fetchRoles,
  permissions,
}) {
  const [open, setOpen] = React.useState(state);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhoneNumber] = React.useState("");
  const [account, setAccountId] = React.useState("");
  const [role, setRole] = React.useState("");
  const [customRoles, setCustomRoles] = React.useState("");
  const [customerList, setCustomerList] = React.useState([]);
  const [customer, setCustomer] = React.useState("");
  const [running, setRunning] = React.useState(false);
  const classes = useStyles();
  const accountList = useSelector((state) => state.deviceGroups.accountGroups);

  const user = useSelector((state) => state.userSession.email) || "";

  const fields = [
    {
      id: "firstName",
      label: "First Name",
      onChange: (value) => setFirstName(value),
    },
    {
      id: "lastName",
      label: "Last Name",
      onChange: (value) => setLastName(value),
    },
    {
      id: "email",
      label: "Email",
      onChange: (value) => setEmail(value),
    },
  ];

  useEffect(() => {
    if (state) {
      setOpen(state);
    }
  }, [state]);

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    callback();
    setOpen(false);
    setRunning(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setAccountId("");
    setRole("");
    setCustomer("");
    setCustomerList([]);
  };

  const checkValuesAreValid = () => {
    if (
      firstName &&
      lastName &&
      email.includes("@") &&
      email.includes(".") &&
      phone.length > 6 &&
      account
    ) {
      return true;
    }
    return false;
  };

  const getSubDeviceGroups = async (group_id) => {
    const data = {
      account_id: account_id,
      parentId: group_id,
    };
    const response = await apiQuery("GET", "/devicegroups/customers", data);
    setCustomerList(response.data);
  };

  const handleAccount = async (account) => {
    setAccountId(account);
    if (role === "customer" && account) await getSubDeviceGroups(account.id);
    if (!account) setCustomerList([]);
  };

  const addUser = async () => {
    setRunning(true);
    const myInit = {
      userPoolId: USER_POOL,
      email: email,
      given_name: firstName,
      family_name: lastName,
      account_id: account.account_id,
      phone_number: "+" + phone,
      customRoles: customRoles,
      customer: customer.id,
      user: user,
    };
    apiQuery("POST", "/users", myInit)
      .then((response) => {
        updateCallback(response.status_code, response.message);
      })
      .catch((response) => console.log(response))
      .finally(() => {
        handleClose();
      });
  };

  const getLabel = (option) => {
    if (typeof option === "string") return option;
    else if (typeof option.name !== "undefined") return option.name;
    else return "";
  };

  let canSeeAdmin = permissions.admin.roles <= CAPABILITY_LEVELS["manage"];
  let filteredRoles = roles;
  if (!canSeeAdmin) {
    filteredRoles = filteredRoles.filter((r) => r.name !== "Admin");
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">New User Form</DialogTitle>
        <DialogContent>
          <Typography color="primary" className={classes.section}>
            Please fill out the following fields to add a new user.
          </Typography>
          <Container>
            {fields.map((field) => {
              return (
                <TextField
                  key={field.id}
                  id={field.id}
                  label={field.label}
                  variant="outlined"
                  size="small"
                  placeholder={field.placeholder}
                  className={classes.textfield}
                  multiline
                  onChange={(e) => field.onChange(e.target.value)}
                />
              );
            })}
            <PhoneNumberInput
              handlePhoneChange={(value) => setPhoneNumber(value)}
              phone_number={phone}
              containerClass={classes.phonecontainer}
              inputStyle={{ width: "240px" }}
            />
            <Autocomplete
              multiple
              options={filteredRoles}
              getOptionLabel={(option) => option.name}
              className={classes.textfield}
              onChange={(_, v) =>
                setCustomRoles(v.map((role) => role.name).join(","))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role(s)"
                  placeholder=""
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              options={accountList}
              getOptionLabel={(option) => getLabel(option)}
              className={classes.textfield}
              onChange={(_, v) => handleAccount(v)}
              renderInput={(params) => (
                <TextField
                  key={params.id}
                  {...params}
                  label={"Default Account"}
                  variant="outlined"
                  size="small"
                />
              )}
            />
            {role === "customer" && customerList.length ? (
              <Autocomplete
                options={customerList}
                getOptionLabel={(option) => getLabel(option)}
                className={classes.textfield}
                onChange={(_, v) => setCustomer(v)}
                renderInput={(params) => (
                  <TextField
                    key={params.id}
                    {...params}
                    label={"Customer Account"}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            ) : null}
          </Container>
        </DialogContent>
        <DialogActions>
          {running ? <CircularProgress /> : null}
          <Button
            onClick={addUser}
            color="primary"
            variant="contained"
            disabled={!checkValuesAreValid() || running}
          >
            Submit
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    roles: state.roles.roles,
    permissions: state.roles.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoles: () => dispatch(fetchRoles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserForm);
