import React, { useEffect, useRef, useState, useMemo } from "react";
import SummaryTile from "./SummaryTile";
import {AutoSizer, Grid as VirtualGrid} from 'react-virtualized';
import {Card} from "@material-ui/core";

export default function SummaryDashboard({
  bySiteTiles,
  tileSize,
  textSize,
  details,
  alignTiles,
  dashboardSettings,
  groupBy,
}) {
  //const [width, setWidth] = useState(0);
  const convertTileSize = (ts, property) => {
    /*
      Centralised definition of how tiles should be sized as ghost
      elements must match this.
     */
    switch (property) {
      case "width":
        return ts * 40;
      case "height":
        return 20 + ts * 10;
      case "bodySize": {
        return 0.5 + ts * 0.1;
      }
      case "titleSize":
        return 0.5 + ts * 0.1;
      default:
        return 0;
    }
  };

  function getAlignmentStyles(alignTiles, width, totalCellWidth) {
    switch (alignTiles) {
      case 'flex-start':
        return { marginLeft: '0px' };
      case 'flex-end':
        return { marginLeft: `${width - totalCellWidth}px` };
      case 'center':
      default: 
        const marginLeft = Math.max(0, (width - totalCellWidth) / 2);
        return { marginLeft: `${marginLeft}px` };
    }
  }
  
  const tileWidth = convertTileSize(tileSize, "width");
  const cellGap = 10 ;
  function getColumnCount(containerWidth){
    return Math.floor(containerWidth / (tileWidth + cellGap));
  };

  
 const tiles = useMemo(() => {
    return Object.entries(bySiteTiles).map(([tile, tileData]) => ({
      title: tile.trim(),
      info: details ? {
        Devices: tileData["devices"].length,
        Alerts: tileData["AlertTotal"],
      } : [],
      link: "/services/connectivity?search_term=" + tile,
      status: tileData["Severity"],
      borderColor: tileData["boxColor"],
    }));
  }, [bySiteTiles, details]);

  useEffect(() => {
    if (tiles && tiles.length > 0) {
        setTrigger(prev => prev + 1);
    }
}, [tiles]);

  const [tileDimensions, setTileDimensions] = useState({
    width: convertTileSize(tileSize, "width"), height: convertTileSize(tileSize, "height"),
  });
  const firstTileRef = useRef(null);
  const [trigger, setTrigger] = useState(0);
  useEffect(() => {
      if (firstTileRef.current) {
          const observer = new ResizeObserver((entries) => {
              const { width, height } = entries[0].contentRect;
              setTileDimensions({ width, height });
          });
          observer.observe(firstTileRef.current);

          return () => observer.disconnect();
      }
  }, [trigger]);

  //setBySiteTiles(gatherStatusTiles(bySites,alerts))
  const cellRenderer = (width) => ({ columnIndex, rowIndex, key, parent, style }) => {
  const index = rowIndex * getColumnCount(width) + columnIndex;
  const tmpTile = tiles[index];
  const cellStyle = {
    ...style,
    padding: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  if (!tmpTile) return null;

  return (
      <div key={key} style={cellStyle}>
        <SummaryTile
    convertTileSize={convertTileSize}
    tile={tmpTile}
    tileSize={tileSize}
    textSize={textSize}
    details={details}
    groupBy={groupBy}
  />
      </div>
   
  );  
};
  return (
    <Card elevation={0} style={{ overflowY: "auto", maxHeight: "100vh" }}>
      {/* Invisible First Tile for Measurement */}
      {
  tiles && tiles.length > 0 ?   (
    <div ref={firstTileRef} style={{visibility: 'hidden', position: 'absolute'}}>
      <SummaryTile
          convertTileSize={convertTileSize}
          tile={tiles[0]}
          tileSize={tileSize}
          textSize={textSize}
          details={details}
          groupBy={groupBy}
      />
    </div>
  ) : null
  
} 
<div style={{
  height: "800px",
}}>
    <AutoSizer>
      {({ width, height }) => {
        const columnCount = getColumnCount(width);
        const totalCellWidth = columnCount * (tileDimensions.width + cellGap);
        const styles = getAlignmentStyles(alignTiles, width, totalCellWidth);      
        return (
          <VirtualGrid
                      width={width}
                      height={height}
                      //overscanRowCount={20}
                      rowCount={Math.ceil(tiles.length / columnCount)}
                      columnCount={columnCount}
                      columnWidth={tileDimensions.width + cellGap}
                      rowHeight={tileDimensions.height + cellGap}
                      cellRenderer={cellRenderer(width)}
                      style={styles}
                    />     
        );
      }}
    </AutoSizer>
  </div>
    </Card>
);
}