import { fromUnixTime, getUnixTime, format, sub } from "date-fns";

const convertArrayToObject = (array, key) =>
  // eslint-disable-next-line
  array.reduce((obj, item) => ((obj[[item[key]]] = item), obj), {});

export default convertArrayToObject;

export const formattedUptime = (seconds) => {
  var numdays = Math.floor(seconds / 86400);
  var numhours = Math.floor((seconds % 86400) / 3600);
  var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  // var numseconds = ((seconds % 86400) % 3600) % 60;
  return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
};

export const daysUptime = (seconds) => {
  var numdays = Math.floor(seconds / 86400);
  return numdays + " days ";
};

export const epochToTimestamp = (epoch) => {
  if (!epoch) return null;
  epoch = epoch.toString().substring(0, 10);
  var date = fromUnixTime(epoch);
  return format(date, "MMM do HH:mm");
};

export const epochToTimestampWithYears = (epoch) => {
  if (!epoch) return null;
  epoch = epoch.toString().substring(0, 10);
  var date = fromUnixTime(epoch);
  return format(date, "MMM do yyyy - HH:mm");
};

export const epochToTimestampWithoutTime = (epoch) => {
  if (!epoch) return null;
  epoch = epoch.toString().substring(0, 10);
  var date = fromUnixTime(epoch);
  return format(date, "MMM do yyyy");
};

export const timestampToEpoch = (timestamp) => {
  if (!timestamp) return null;
  return getUnixTime(timestamp);
};

export const removeNewlines = (s) => {
  if (s !== undefined) {
    s = s.replace(/\s{2,}/g, " ");
    s = s.replace(/\t/g, " ");
    s = s
      .toString()
      .trim()
      .replace(/(\r\n|\n|\r)/g, "");
  }
  return s;
};

export const checkBillingRef = (ref) =>
  typeof ref !== "undefined"
    ? ref.includes("DR-")
      ? ref.replace("DR-", "")
      : ref
    : null;

// Can use for finiding differences in arrays of objects
export const arrayComparison = (a, b) => {
  const isSameElement = (a, b) => {
    var aKeys = Object.keys(a),
      bKeys = Object.keys(b);
    for (let i = 0; i < aKeys.length; i++) {
      for (let j = 0; j < bKeys.length; j++) {
        if (aKeys[i] === bKeys[j] && a[aKeys[i]] !== b[bKeys[j]]) return true;
      }
    }
  };

  const onlyInLeft = (left, right, compareFunction) =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );

  const onlyInA = onlyInLeft(a, b, isSameElement);
  const onlyInB = onlyInLeft(a, b, isSameElement);

  const result = [...onlyInA, ...onlyInB];

  return result;
};

export const saveToLS = (key, value) => {
  if (global.localStorage) {
    global.localStorage.setItem(key, value);
  }
};

export const getFromLS = (key) => {
  if (global.localStorage) {
    try {
      return global.localStorage.getItem(key);
    } catch (e) {
      return "";
    }
  }
};

/*export const operatorFilter = (sites, devices) => {
  let firstFlag = true;
  let filteredSites = {};
  let filters = [
    {
      property: "Circuit Size",
      operator: "===",
      value: "100Mb",
    },
  ];
  for (let filter of filters) {
    let siteSource = firstFlag === true ? sites : filteredSites;
    filteredSites = {};
    let filterProperty = filter["property"];
    let filterOperator = filter["operator"];
    let filterValue = filter["value"];
    Object.entries(devices).forEach((device) => {
      if (device[1][filterProperty]) {
        let prop = device[1][filterProperty];
        if (prop === filterValue && prop !== null) {
          if (
            Function(
              `return "${prop
                .toString()
                .toLowerCase()}"${filterOperator}"${filterValue.toLowerCase()}"`
            )()
          ) {
            Object.entries(siteSource).forEach((site) => {
              if (site[1][0] === parseInt(device[0])) {
                filteredSites[site[0]] = site[1];
                return;
              }
            });
          }
        }
      }
    });
    if (firstFlag) {
      firstFlag = false;
    }
  }
  return filteredSites;
};*/

const operatorEvaluator = (filter, device) => {
  try {
    if (filter["property"] === "Status") {
      // Devices with alerts come as '-' separated strings with status in middle.
      let deviceStatus =
        device["Alert Status"] === "none"
          ? "live"
          : device["Alert Status"].split("-")[1];

      if (filter["operator"] === "equals") {
        return deviceStatus === filter["value"] ? device : null;
      } else if (filter["operator"] === "not equals") {
        return deviceStatus !== filter["value"] ? device : null;
      } else if (filter["operator"] === "contains") {
        return deviceStatus.includes(filter["value"]) ? device : null;
      } else if (filter["operator"] === "not contains") {
        return !deviceStatus.includes(filter["value"]) ? device : null;
      }
    }
    if (filter["operator"] === "before") {
      if (device.startEpoch < new Date(filter["value"]).getTime() / 1000) {
        return device;
      } else {
        return null;
      }
    }
    if (filter["operator"] === "after") {
      if (device.startEpoch > new Date(filter["value"]).getTime() / 1000) {
        return device;
      } else {
        return null;
      }
    }

    // Let filters work on devices with undefined for property value.
    let devicePropertyValue = device[filter["property"]]
      ? removeNewlines(device[filter["property"]])
      : "";
    let filtervalue = removeNewlines(filter["value"]);

    if (filter["operator"] === "equals") {
      if (devicePropertyValue.toLowerCase() === filtervalue.toLowerCase()) {
        return device;
      } else {
        return null;
      }
    } else if (filter["operator"] === "not equals") {
      if (devicePropertyValue.toLowerCase() !== filtervalue.toLowerCase()) {
        return device;
      } else {
        return null;
      }
    } else if (filter["operator"] === "contains") {
      if (
        devicePropertyValue.toLowerCase().includes(filtervalue.toLowerCase())
      ) {
        return device;
      } else {
        return null;
      }
    } else if (filter["operator"] === "not contains") {
      if (
        !devicePropertyValue.toLowerCase().includes(filtervalue.toLowerCase())
      ) {
        return device;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const filterChecker = (filter) => {
  var filterValid = true;
  var key;
  for (const item of filter) {
    if (Array.isArray(item)) {
      for (const obj of item) {
        for (key in obj) {
          if (obj[key] === null) {
            filterValid = false;
          }
        }
      }
    } else {
      for (key in item) {
        if (item[key] === null) {
          filterValid = false;
        }
      }
    }
    //this.gatherStatusTiles()
  }

  return filterValid;
};

export const operatorFilter = (devices, filters) => {
  var filterValid = filterChecker(filters);
  if (!filterValid) {
    return devices;
  }
  var returnedDevices = [];
  for (const device of devices) {
    var operatorBool = true;
    for (const filter of filters) {
      if (Array.isArray(filter)) {
        var orOperatorBool = false;
        for (const item of filter) {
          var operatorBoolOrEval = operatorEvaluator(item, device);
          if (operatorBoolOrEval !== null) {
            orOperatorBool = true;
          }
        }
        if (!orOperatorBool) {
          operatorBool = false;
        }
      } else {
        var operatorEval = operatorEvaluator(filter, device);
        if (operatorEval === null) {
          operatorBool = false;
        }
      }
    }
    //console.log(operatorBool)
    if (operatorBool) {
      returnedDevices.push(device);
    }
  }
  return returnedDevices;
};

export const filterEditor = (
  deviceFilter,
  item,
  value,
  position,
  arrayPosition
) => {
  var filtersCopy = JSON.parse(JSON.stringify(deviceFilter));
  if (arrayPosition === null) {
    filtersCopy[position][item] = value;
  } else {
    filtersCopy[position][arrayPosition][item] = value;
  }
  return filtersCopy;
};

export const filterAddOperator = (deviceFilter, verb, position) => {
  var filtersCopy = JSON.parse(JSON.stringify(deviceFilter));
  if (verb === "AND") {
    filtersCopy.splice(position + 1, 0, {
      property: "",
      operator: null,
      value: "",
    });
  } else {
    /*var currentPosition = filtersCopy[position];
    filtersCopy[position] = [
      currentPosition,
      { property: null, operator: null, value: null },
    ];*/
    if (Array.isArray(filtersCopy[position])) {
      filtersCopy[position].push({
        property: "",
        operator: null,
        value: "",
      });
    } else {
      filtersCopy[position] = [
        filtersCopy[position],
        { property: "", operator: null, value: "" },
      ];
    }
  }
  return filtersCopy;
};

export const deleteFilter = (deviceFilter, position, arrayPosition) => {
  var filtersCopy = JSON.parse(JSON.stringify(deviceFilter));
  if (arrayPosition === null) {
    filtersCopy.splice(position, 1);
  } else {
    if (arrayPosition === 0) {
      filtersCopy.splice(position, 1);
    } else {
      var currentStatement = filtersCopy[position];
      currentStatement.splice(arrayPosition, 1);

      if (currentStatement.length === 1) {
        currentStatement = currentStatement[0];
      }

      filtersCopy[position] = currentStatement;
    }
  }

  if (filtersCopy.length === 0) {
    filtersCopy.push({ property: "", operator: null, value: "" });
  }
  return filtersCopy;
};

export const filterPropertiesForAutocomplete = (deviceProperties) => {
  if (deviceProperties === null) {
    return [];
  }
  return deviceProperties
    .filter((prop) => !prop.name.match(/Broadband.|.*Dashboard.ID|Links/gm))
    .sort((a, b) => (a.group > b.group ? 1 : b.group > a.group ? -1 : 0));
};

export const emailChecker = (email) =>
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const setTime = (e) => {
  var startDate;
  var endDate;
  var toReturn = {};
  if (e === "24 Hours") {
    toReturn.start = sub(new Date(), { days: 1 });
    toReturn.end = new Date();
    toReturn.date_type = e;
  } else if (e === "Today Only") {
    startDate = new Date();
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    toReturn.start = startDate;
    toReturn.end = new Date();
    toReturn.date_type = e.replaceAll(" ", "_");
  } else if (e === "Week") {
    toReturn.start = sub(new Date(), { days: 7 });
    toReturn.end = new Date();
    toReturn.date_type = e;
  } else if (e === "30 Days") {
    toReturn.start = sub(new Date(), { days: 30 });
    toReturn.end = new Date();
    toReturn.date_type = e.replaceAll(" ", "_");
  } else if (e === "This Calendar Month") {
    startDate = new Date();
    startDate.setDate(1);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    toReturn.start = startDate;
    toReturn.end = new Date();
    toReturn.date_type = e.replaceAll(" ", "_");
  } else if (e === "Last Calendar Month") {
    endDate = new Date();
    endDate.setDate(0);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    startDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 1);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    toReturn.start = startDate;
    toReturn.end = endDate;
    toReturn.date_type = e.replaceAll(" ", "_");
  } else if (e === "Year") {
    toReturn.start = sub(new Date(), { days: 365 });
    toReturn.end = new Date();
    toReturn.date_type = e;
  }
  return toReturn;
};

export const checkOptionLabel = (option, prop) => {
  var to_display = option[prop];
  if (to_display !== undefined && to_display.trim().length)
    return to_display.replace("DR-", "");
  else return option["DNS Name"];
};
