import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import AddUsersByCSV from "../components/forms/AddUsersByCSV";
import AddNetworkDashboard from "../components/forms/AddNetworkDashboard";
import FullConfiguration from "../components/forms/FullConfiguration";
import SSLProperties from "../components/forms/SSLProperties";
import AddProperties from "../components/forms/AddProperties";
import Grid from "@material-ui/core/Grid";

import HistoryGraph from "../components/HistoryGraph";
import JobConsole from "../components/JobConsole";

const styles = (theme) => ({
  root: {
    width: 1000,
    height: 1000,
  },
});

class RunJobContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: "",
    };
    this.val = 0;
    this.title = this.props.match.params.id;
  }
  componentDidMount() {
    var uppercase = this.title.replace(/([A-Z])/g, " $1");
    uppercase = uppercase.charAt(0).toUpperCase() + uppercase.slice(1);
    this.props.setParentTitle("Run Job: " + uppercase);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.props.setParentTitle("Run Job: " + this.title);
    }
  }

  valueLabelFormat = (value) => {
    return this.marks.findIndex((mark) => mark.value === value) + 1;
  };

  render() {
    //const {classes} = this.props

    return (
      <Grid container spacing={1} alignItems="flex-start">
        <Grid item>
          {this.title === "addUsersByCSV" ? (
            <AddUsersByCSV
              callback={(resp) => this.setState({ response: resp })}
            />
          ) : this.title === "fullConfiguration" ? (
            <FullConfiguration
              callback={(resp) => this.setState({ response: resp })}
            />
          ) : this.title === "addNetworkDashboard" ? (
            <AddNetworkDashboard
              callback={(resp) => this.setState({ response: resp })}
            />
          ) : this.title === "sslProperties" ? (
            <SSLProperties
              callback={(resp) => this.setState({ response: resp })}
            />
          ) : this.title === "AddProperties" ? (
            <AddProperties
              callback={(resp) => this.setState({ response: resp })}
            />
          ) : null}
        </Grid>
        <Grid item>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <HistoryGraph />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <JobConsole response={this.state.response} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
function mapStateToProps(state) {
  return 1;
}

const enhance = compose(connect(mapStateToProps), withStyles(styles));
export default enhance(RunJobContainer);
