import { useSelector } from "react-redux";
import { CAPABILITY_LEVELS } from "../constants";
import useRelevantPermissions from "../libs/useRelevantPermissions";

const Visible = ({
  children,
  component,
  permissionNeeded,
  capabilityNeeded,
}) => {
  // Filter permissions for the device ID theyre trying to view if they are,
  // otherwise return all permissions.
  let allPermissions = useSelector((state) => state.roles.permissions);
  let relevantPermissions = useRelevantPermissions();

  let permissions = relevantPermissions ? relevantPermissions : allPermissions;

  let usersCapability;
  try {
    // Put '.'s in to separate nested permissions, e.g. 'sevices.circuit'.
    if (permissionNeeded.split(".").length > 1) {
      let permissionLevels = permissionNeeded.split(".");
      usersCapability = permissions[permissionLevels[0]][permissionLevels[1]];
    } else {
      usersCapability = permissions[permissionNeeded];
    }
  } catch (e) {
    usersCapability = 1;
  }

  let hasCapabilty = usersCapability <= CAPABILITY_LEVELS[capabilityNeeded];

  // Don't break legacy stuff for now.
  if (!permissionNeeded && !capabilityNeeded) {
    hasCapabilty = true;
  }

  return hasCapabilty && children;
};

export default Visible;
