import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress, FormControl, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { addProperty } from "../../actions/devices";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "400px",
  },
  formControl: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  field: {
    width: theme.spacing(20),
    height: 20,
  },
}));

export default function AddCustomProperty({
  customPropsOpen,
  closeDialog,
  id,
  customList,
}) {
  const [open, setOpen] = React.useState(customPropsOpen);
  const [newName, setNewName] = React.useState("");
  const [newValue, setNewValue] = React.useState("");
  const [running, setRunning] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [message, setMessage] = React.useState("");
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (customPropsOpen) {
      setOpen(customPropsOpen);
    }
  }, [customPropsOpen]);

  const handleClose = () => {
    setOpen(false);
    setNewValue("");
    setNewName("");
    setStatus("");
    setMessage("");
    setRunning(false);
    closeDialog();
  };

  const dispatchAddProperty = () => {
    setRunning(true);
    dispatch({ type: "REQUEST_ADD_CUSTOM_PROPERTY" });
    const payload = {
      id: id,
      name: newName,
      value: newValue,
    };
    addProperty(payload)
      .then((res) => {
        if (res.status_code === 200) {
          dispatch({
            type: "SUCCESS_ADD_CUSTOM_PROPERTY",
            meta: payload,
          });
          setNewName("");
          setNewValue("");
        }
        setStatus(res.status_code);
        setMessage(res.message);
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: "FAILURE_ADD_CUSTOM_PROPERTY" });
      })
      .finally(() => setRunning(false));
  };

  const checkPropName = () =>
    !newName ||
    (customList !== undefined &&
      customList.filter((custom) => custom.name === newName).length);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Custom Property</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl} variant="outlined">
          <TextField
            label="Property Name"
            variant="outlined"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className={classes.field}
            maxRows={3}
          />
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            label="Property Value"
            variant="outlined"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className={classes.field}
            maxRows={1}
          />
        </FormControl>
        <div>
          {status && message && (
            <Alert severity={status === 200 ? "success" : "error"}>
              {status}: {message}
            </Alert>
          )}
        </div>
      </DialogContent>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 24,
          paddingTop: 0,
          paddingBottom: 20,
        }}
      >
        {running ? <CircularProgress /> : null}
        <Button
          onClick={dispatchAddProperty}
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          disabled={checkPropName()}
        >
          Confirm
        </Button>
        <Button
          onClick={handleClose}
          style={{ marginLeft: 5 }}
          variant="contained"
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
}
