import { apiQuery } from "../libs/auth-config";

export const ALERTS_REQUEST = "ALERTS_REQUEST";
export const ALERTS_SUCCESS = "ALERTS_SUCCESS";
export const ALERTS_FAILURE = "ALERTS_FAILURE";

const updateAlerts = (alerts) => ({ type: ALERTS_SUCCESS, payload: alerts });

export const initRequestAlerts = (account_id) => async (dispatch) => {
  dispatch({ type: ALERTS_REQUEST });
  const response = await apiQuery("GET", "/alerts", { account_id: account_id });

  return dispatch(updateAlerts(response.data));
};

export const RECIPIENT_GROUPS_REQUEST = "RECIPIENT_GROUPS_REQUEST";
export const RECIPIENT_GROUPS_SUCCESS = "RECIPIENT_GROUPS_SUCCESS";
export const RECIPIENT_GROUPS_FAILURE = "RECIPIENT_GROUPS_FAILURE";

export const initRequestRecipientGroupById =
  (account_id) => async (dispatch) => {
    dispatch({ type: RECIPIENT_GROUPS_REQUEST });
    apiQuery("GET", "/recipientgroups/recipientgroup", {
      account_id: account_id,
    })
      .then((alerts) => {
        dispatch({
          type: RECIPIENT_GROUPS_SUCCESS,
          payload: alerts.data,
        });
      })
      .catch((e) => {
        dispatch({ type: RECIPIENT_GROUPS_FAILURE });
        console.log(e);
      });
  };

export const SAVE_RECIPIENTS_REQUEST = "SAVE_RECIPIENTS_REQUEST";
export const SAVE_RECIPIENTS_SUCCESS = "SAVE_RECIPIENTS_SUCCESS";
export const SAVE_RECIPIENTS_FAILURE = "SAVE_RECIPIENTS_FAILURE";

export const initSaveRecipients =
  (account_id, id, data, type) => async (dispatch) => {
    dispatch({ type: SAVE_RECIPIENTS_REQUEST });
    apiQuery("PUT", "/recipientgroups/recipientgroup", {
      account_id: account_id,
      id: id,
      data: data,
    })
      .then((alerts) => {
        dispatch({
          type: SAVE_RECIPIENTS_SUCCESS,
          payload: alerts.data,
          meta: { recipient: data, action: type },
        });
      })
      .catch((e) => {
        dispatch({ type: SAVE_RECIPIENTS_FAILURE });
        console.log(e);
      });
  };

export const ACKNOWLEDGE_REQUEST = "ACKNOWLEDGE_REQUEST";
export const ACKNOWLEDGE_SUCCESS = "ACKNOWLEDGE_SUCCESS";
export const ACKNOWLEDGE_FAILURE = "ACKNOWLEDGE_FAILURE";

export const acknowledgeAlert =
  (account_id, alertId, alertInfo, comment) => (dispatch) => {
    dispatch({ type: ACKNOWLEDGE_REQUEST });
    apiQuery("POST", "/alerts/alert/ack", {
      account_id: account_id,
      alert_info: alertInfo,
      alert_id: alertId,
      comment: comment,
    })
      .then((resp) => {
        dispatch({
          type: ACKNOWLEDGE_SUCCESS,
          payload: resp.message,
        });
        initRequestAlerts(account_id);
      })
      .catch((e) => {
        dispatch({ type: ACKNOWLEDGE_FAILURE });
        console.log(e);
      });
  };

export const IDV_ALERT_REQUEST = "IDV_ALERT_REQUEST";
export const IDV_ALERT_SUCCESS = "IDV_ALERT_SUCCESS";
export const IDV_ALERT_FAILURE = "IDV_ALERT_FAILURE";

export const getAlertInformation = (account_id, alertId) => (dispatch) => {
  dispatch({ type: IDV_ALERT_REQUEST });
  apiQuery("GET", "/alerts/alert", {
    account_id: account_id,
    alert_id: alertId,
  })
    .then((alert) => {
      dispatch({
        type: IDV_ALERT_SUCCESS,
        payload: alert.data[0],
      });
    })
    .catch((e) => {
      dispatch({ type: IDV_ALERT_FAILURE });
      console.log(e);
    });
};

const pathMap = {
  Reseller: "groups",
  Customer: "groups",
  Device: "devices",
};

export const getAlertThresholdConfig = (
  account_id,
  type,
  id,
  ds_id,
  inst_id
) => {
  return apiQuery("GET", "/datasources/thresholds", {
    account_id: account_id,
    type: type === "Device" ? "devices" : "groups",
    id: id,
    ds_id: ds_id,
    inst_id: inst_id,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getDatasources = (account_id, type, id) => {
  return apiQuery("GET", "/datasources", {
    account_id: account_id,
    type: pathMap[type],
    id: id,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getInstances = (account_id, type, id, ds_id) => {
  return apiQuery("GET", "/datasources/instances", {
    account_id: account_id,
    type: pathMap[type],
    id: id,
    ds_id: ds_id,
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
};

export const saveThresholdConfig = (data) => {
  return apiQuery("PUT", "/datasources/thresholds", {
    ...data,
    type: data.type === "Device" ? "devices" : "groups",
  })
    .then((data) => {
      return data.data;
    })
    .catch((e) => {
      console.log(e);
    });
};
