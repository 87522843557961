import { createTheme } from "@material-ui/core/styles";
import { daisyTheme, affinityTheme } from "@akj-dev/design-system";

import { THEME } from "../constants";

export default function theme(branding) {
  var themeColours;
  if (branding) {
    themeColours = JSON.parse(branding.themeColours);
  }
  var theme = daisyTheme;
  if (THEME === "affinity") {
    theme = createTheme({
      ...affinityTheme,
      palette: {
        type: (themeColours && themeColours.portal.type) || "light",
        primary: {
          main: (themeColours && themeColours.portal.primary) || "#67246A",
        },
        secondary: {
          main: (themeColours && themeColours.portal.secondary) || "#cb2b94",
        },
        sidebar: {
          main:
            (themeColours && themeColours.portal.sidebar) ||
            (themeColours && themeColours.portal.primary) ||
            "#cb2b94",
        },
      },
      props: {
        MuiAlert: {
          variant:
            themeColours && themeColours.portal.type === "light"
              ? "standard"
              : themeColours && themeColours.portal.type === "dark"
              ? "outlined"
              : "standard",
        },
        MuiTextField: {
          size: "small",
        },
      },
      overrides: {
        MUIDataTableSelectCell: {
          fixedLeft: {
            position: "static",
          },
        },
        MuiButton: {
          root: {
            padding: "8px 18px",
          },
          containedPrimary: {
            backgroundColor:
              (themeColours && themeColours.portal.secondary) || "#cb2b94",
          },
        },
      },
    });
  } else if (THEME === "dwp") {
    theme = createTheme({
      ...affinityTheme,
      palette: {
        type: (themeColours && themeColours.portal.type) || "light",
        primary: {
          main: (themeColours && themeColours.portal.primary) || "#67246A",
        },
        secondary: {
          main: (themeColours && themeColours.portal.secondary) || "#cb2b94",
        },
        sidebar: {
          main:
            (themeColours && themeColours.portal.sidebar) ||
            (themeColours && themeColours.portal.primary) ||
            "#67246A",
        },
      },
      props: {
        MuiAlert: {
          variant:
            themeColours && themeColours.portal.type === "light"
              ? "standard"
              : themeColours && themeColours.portal.type === "dark"
              ? "outlined"
              : "standard",
        },
        MuiTextField: {
          size: "small",
        },
      },
      overrides: {
        MuiButton: {
          containedPrimary: {
            backgroundColor:
              (themeColours && themeColours.portal.secondary) || "#EEAF03",
          },
        },
        MUIDataTableSelectCell: {
          fixedLeft: {
            position: "static",
          },
        },
        MuiContainer: {
          root: {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        },
      },
    });
  } else {
    theme = createTheme({
      ...daisyTheme,
      palette: {
        type: (themeColours && themeColours.portal.type) || "light",
        primary: {
          main: (themeColours && themeColours.portal.primary) || "#022f6b",
        },
        secondary: {
          main: (themeColours && themeColours.portal.secondary) || "#022f6b",
        },
        sidebar: {
          main:
            (themeColours && themeColours.portal.sidebar) ||
            (themeColours && themeColours.portal.primary) ||
            "#022f6b",
        },
      },
      props: {
        MuiAlert: {
          variant:
            themeColours && themeColours.portal.type === "light"
              ? "standard"
              : themeColours && themeColours.portal.type === "dark"
              ? "outlined"
              : "standard",
        },
        MuiTextField: {
          size: "small",
        },
      },
      overrides: {
        MUIDataTableSelectCell: {
          fixedLeft: {
            position: "static",
          },
        },
        MuiChip: {
          root: {
            backgroundColor:
              (themeColours && themeColours.portal.background) || "#fafafa",
          },
        },
        MuiButton: {
          containedPrimary: {
            backgroundColor:
              (themeColours && themeColours.portal.secondary) || "#022f6b",
          },
        },
      },
    });
  }
  return theme;
}
