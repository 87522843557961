import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import { Button, Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import RemoveUsers from "../components/dialogs/RemoveUsers";
import AddUserForm from "../components/dialogs/AddUserForm";
import UpdateUsers from "../components/dialogs/UpdateUsers";
import PasswordReset from "../components/dialogs/PasswordReset";
import { apiQuery } from "../libs/auth-config";
import CustomDatatable from "../components/CustomDatatable";
import ContainerHeader from "../components/ContainerHeader";
import { CAPABILITY_LEVELS } from "../constants";

const useStyles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    grid: {
      marginBottom: 10,
    },
  });

class UserAccessContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      percentage: 0,
      newUserState: false,
      updateUserState: false,
      removeUserState: false,
      passwordResetState: false,
      users: [],
      selected: [],
      initialSelected: [],
      status: "",
      message: "",
    };
    this.nextToken = 0;
    this.buttons = [
      {
        label: "Add User",
        onClick: () => this.setState({ newUserState: true }),
      },
      {
        label: "Update Users",
        onClick: () => this.setState({ updateUserState: true }),
      },
      {
        label: "Remove Users",
        onClick: () => this.setState({ removeUserState: true }),
      },
      {
        label: "Reset Password",
        onClick: () => this.setState({ passwordResetState: true }),
      },
    ];
    this.columns = {
      id: { display: "excluded", filter: false },
      email: {
        label: "Email",
      },
      given_name: {
        label: "First Name",
      },
      family_name: {
        label: "Last Name",
      },
      phone_number: {
        label: "Phone Number",
      },
      "custom:account_id": {
        label: "Account ID",
        customBodyRenderLite: (i) => {
          const user = this.state.users[i];
          if (!user) return null;
          try {
            if (user["custom:role"] === "customer")
              return (
                user["custom:account_id"].split("-")[1] +
                " - " +
                user["custom:customer"]
              );
            else return user["custom:account_id"];
          } catch {
            return user["custom:account_id"];
          }
        },
      },
      "custom:roles": {
        label: "Role(s)",
        customBodyRenderLite: (value, tableMeta, updateValue) => {
          // Stop it crashing when you update your own role.
          if (!this.state.users) {
            return <></>;
          } else {
            return !("custom:roles" in this.state.users[value]) ? (
              <></>
            ) : (
              <Typography variant="body2">
                {this.state.users[value]["custom:roles"]
                  .split(",")
                  .filter((r) => r && r !== ",")
                  .join(", ")}
              </Typography>
            );
          }
        },
      },
      status: {
        label: "Status",
      },
    };
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.props.setParentTitle("User Access");
    this.listUsers();
  }

  async listUsers() {
    this.setState({ loading: true });
    let parsedUsers = [];
    // let myInit = {
    //   queryStringParameters: {
    //     userpool: USER_POOL,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `${(await Auth.currentSession())
    //       .getAccessToken()
    //       .getJwtToken()}`,
    //   },
    // };

    // await API.get("Automation", "/users", myInit)
    apiQuery("GET", "/users")
      .then((response) => {
        let users = response.data.Users;
        for (var i = 0; i < users.length; i++) {
          let usr = {};
          usr.status = users[i].UserStatus;
          let atts = users[i].Attributes;
          for (var j = 0; j < atts.length; j++) {
            usr[atts[j].Name] = atts[j].Value;
          }
          parsedUsers.push(usr);
        }
      })
      .catch((e) => console.log(e))
      .finally(() =>
        this.setState({
          users: parsedUsers,
          cachedUsers: parsedUsers,
          loading: false,
          selected: [],
        })
      );
  }

  updateMessage(status, message) {
    this.listUsers();
    this.setState({ status: status, message: message, selected: [] });
  }

  render() {
    const { classes, role, properties } = this.props;
    const { users, loading, user_views } = this.state;
    const hasManagePermissions =
      this.props.permissions.admin.userAccess <= CAPABILITY_LEVELS["manage"];

    return (
      <>
        {hasManagePermissions && (
          <>
            <AddUserForm
              state={this.state.newUserState}
              userRole={role}
              callback={() => this.setState({ newUserState: false })}
              account_id={properties.account_id}
              updateCallback={(status, message) =>
                this.updateMessage(status, message)
              }
            />
            <RemoveUsers
              state={this.state.removeUserState}
              users={this.state.selected}
              callback={() => this.setState({ removeUserState: false })}
              account_id={properties.account_id}
              updateCallback={(status, message) =>
                this.listUsers() && this.updateMessage(status, message)
              }
            />
            <UpdateUsers
              state={this.state.updateUserState}
              userRole={role}
              users={this.state.selected}
              callback={() => {
                this.setState({
                  updateUserState: false,
                });
              }}
              updateCallback={(status, message) =>
                this.updateMessage(status, message)
              }
            />
            <PasswordReset
              state={this.state.passwordResetState}
              userRole={role}
              users={this.state.selected}
              callback={() => {
                this.setState({
                  passwordResetState: false,
                });
              }}
              updateCallback={(status, message) =>
                this.updateMessage(status, message)
              }
            />
          </>
        )}
        <ContainerHeader title={"User Accounts"} />
        <Grid container spacing={1} className={classes.grid}>
          {hasManagePermissions &&
            this.buttons.map((button, i) => {
              return (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={i ? !this.state.selected.length : 0}
                    onClick={() => button.onClick()}
                  >
                    {button.label}
                  </Button>
                </Grid>
              );
            })}
          <Grid item>
            <div>
              {this.state.status && this.state.message && (
                <Alert
                  severity={this.state.status === 200 ? "success" : "error"}
                >
                  {this.state.status}: {this.state.message}
                </Alert>
              )}
            </div>
          </Grid>
        </Grid>
        <CustomDatatable
          viewComponent={"UsersTable"}
          customPage
          propMap={this.columns}
          rows={users}
          user_views={user_views}
          loading={loading}
          selectableRows={hasManagePermissions && "custom"}
          rowSelectionCallback={(selected) =>
            this.setState({
              initialSelected: selected,
              selected: selected,
            })
          }
          isRowSelectable={(i) =>
            !(
              (
                "custom:roles" in users[i] &&
                users[i]["custom:roles"].includes("Admin") &&
                users[i]["email"] !== this.props.email
              ) // Let user edit themselves.
            )
          }
        />
      </>
    );
  }
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const { role, properties, email } = state.userSession;
  const { permissions } = state.roles;
  return { role, properties, permissions, email };
}

const enhance = compose(withStyles(useStyles), connect(mapStateToProps));
export default enhance(UserAccessContainer);
