import * as React from "react";
import { Bar } from "react-chartjs-2";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    height: "100%",
  },
});

// const legendStyles = () => ({
//   root: {
//     display: 'flex',
//     margin: 'auto',
//     flexDirection: 'row',
//   },
// });
//
// const legendRootBase = ({ classes, ...restProps }) => (
//   <Legend.Root {...restProps} className={classes.root} />
// );
//
// const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
// const legendLabelStyles = () => ({
//   label: {
//     whiteSpace: 'nowrap',
//   },
// });
//
// const legendLabelBase = ({ classes, ...restProps }) => (
//   <Legend.Label className={classes.label} {...restProps} />
// );
// const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);

class HistoryGraph extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data, classes } = this.props;

    // var plots = [
    //   {month: 'Jan', runs: 0, partials: 0, fails: 0},
    //   {month: 'Feb', runs: 0, partials: 0, fails: 0},
    //   {month: 'Mar', runs: 0, partials: 0, fails: 0},
    //   {month: 'Apr', runs: 0, partials: 0, fails: 0},
    //   {month: 'May', runs: 0, partials: 0, fails: 0},
    //   {month: 'Jun', runs: 0, partials: 0, fails: 0},
    //   {month: 'Jul', runs: 0, partials: 0, fails: 0},
    //   {month: 'Aug', runs: 0, partials: 0, fails: 0},
    //   {month: 'Sep', runs: 0, partials: 0, fails: 0},
    //   {month: 'Oct', runs: 0, partials: 0, fails: 0},
    //   {month: 'Nov', runs: 0, partials: 0, fails: 0},
    //   {month: 'Dec', runs: 0, partials: 0, fails: 0}
    // ]

    var plots = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Success",
          backgroundColor: "#5CD15C",
        },
        // {
        //     label: "Partials",
        //     backgroundColor: "#FDC703"
        // },
        {
          label: "Fails",
          backgroundColor: "#FF2D00",
        },
      ],
    };

    var runs = [],
      partials = [],
      errors = [];
    if (data) {
      for (const [key] of Object.entries(data)) {
        for (let i = 0; i < data[key].length; i++) {
          for (let j = 0; j < data[key][i].length; j++) {
            try {
              // let month = new Date(data[key][i][j].timestamp).getMonth()
              if (data[key][i][j].overall === 500) {
                errors[i] = (errors[i] || 0) + 1;
              } else if (data[key][i][j].overall === 400) {
                partials[i] = (partials[i] || 0) + 1;
              } else {
                runs[i] = (runs[i] || 0) + 1;
              }
            } catch {}
          }
          plots["datasets"][0]["data"] = runs;
          plots["datasets"][1]["data"] = errors;
          // plots['datasets'][2]['data'] = errors
        }
      }
    }

    return (
      <Paper className={classes.paper}>
        <Typography variant="h3" color="primary" align="center">
          Execution History
        </Typography>

        {
          <Bar
            data={plots}
            width={100}
            height={50}
            options={{
              maintainAspectRatio: true,
            }}
          />
        }
        {/*
        <Chart
          data={plots}
          width={'100%'}
          height={300}

        >
          <ArgumentAxis />
          <ValueAxis />

          <BarSeries
            name="Success"
            valueField="runs"
            argumentField="month"
            color="#5CD15C"
          />
          <BarSeries
            name="Partial"
            valueField="partials"
            argumentField="month"
            color="#FDC703"
          />
          <BarSeries
            name="Errors"
            valueField="fails"
            argumentField="month"
            color="#FF2D00"
          />
          <Stack />
          <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
          <Animation />
        </Chart>
        */}
      </Paper>
    );
  }
}

export default withStyles(styles)(HistoryGraph);
