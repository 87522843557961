import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Visible from "../Visible";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gap: {
    marginTop: theme.spacing(2),
  },
  nogap: {
    marginTop: theme.spacing(0),
  },
}));

export default function ReportThemeDialog({
  state,
  branding,
  callback,
  saveCallback,
  loading,
}) {
  const [open, setOpen] = useState(false);
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");
  const [img, setImg] = useState("");
  const classes = useStyles();

  useEffect(() => setOpen(state), [state]);

  useEffect(() => setBranding(branding), [branding]);

  const setBranding = (branding) => {
    setPrimary(branding.primary);
    setSecondary(branding.secondary);
    if (branding.img) setImg(branding.img);
  };

  const handleSave = () => {
    saveCallback(primary, secondary, img);
  };

  const handleClose = () => {
    callback();
    setOpen(false);
  };

  const checkValuesAreChanged = () =>
    primary === branding.primary &&
    secondary === branding.secondary &&
    typeof img !== "object";

  return (
    <Visible component={"AddMaintenanceButton"}>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Report Branding</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <Typography variant="body">
            Upload a logo and choose the primary and secondary colour of the
            company theme. {"\n"} Please note that the two colours will used for
            the background (primary) and text (secondary), therefore the colours
            should not be similar.
          </Typography>

          <center>
            <Box className={classes.gap}>
              <img
                style={{
                  border: "5px solid #000",
                }}
                src={typeof img === "string" ? img : URL.createObjectURL(img)}
                alt="Company Logo"
                width={300}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              component="label"
              className={classes.gap}
            >
              Upload Logo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => e.target.files[0] && setImg(e.target.files[0])}
              />
            </Button>
            <Grid
              container
              spacing={4}
              className={classes.nogap}
              justifyContent="center"
            >
              <Grid item lg={3}>
                <Typography variant="h5" gutterBottom>
                  Primary
                </Typography>
                <input
                  type="color"
                  id="head"
                  name="head"
                  value={primary}
                  onChange={(colour) => setPrimary(colour.target.value)}
                />
              </Grid>
              <Grid item lg={3}>
                <Typography variant="h5" gutterBottom>
                  Secondary
                </Typography>
                <input
                  type="color"
                  id="head"
                  name="head"
                  value={secondary}
                  onChange={(colour) => setSecondary(colour.target.value)}
                />
              </Grid>
            </Grid>
          </center>
        </DialogContent>
        <DialogActions className={classes.gap}>
          {loading && <CircularProgress />}
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={checkValuesAreChanged()}
          >
            Save
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Visible>
  );
}
