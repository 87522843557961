import React from "react";
import ReactDOM from "react-dom";

function copyStyles(sourceDoc, targetDoc) {
  /* Load stylesheets into the new window. */
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    if (styleSheet.cssRules) {
      const newStyleEl = sourceDoc.createElement("style");
      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });
      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      const newLinkEl = sourceDoc.createElement("link");
      newLinkEl.rel = "stylesheet";
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

export default class PopoutWindow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.containerEl = document.createElement("div");
    this.externalWindow = null;
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }

  componentDidMount() {
    this.externalWindow = window.open(
      "",
      "LogicOne",
      `width=${this.props.width}, height=${this.props.height}, left=${this.props.left}, top=${this.props.top}`
    );
    copyStyles(document, this.externalWindow.document);
    this.externalWindow.document.body.appendChild(this.containerEl);
  }

  componentWillUnmount() {
    this.externalWindow.close();
  }
}
