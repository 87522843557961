import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    // marginBottom: "20px",
    // display: 'flex',
    // alignItems: 'center'
    marginLeft: theme.spacing(1),
  },
  title: {
    textAlign: "center",
    //display: "flex"
    paddingTop: 15,
  },
}));

export default function OverviewStats({ lineData, type }) {
  const classes = useStyles();
  React.useEffect(() => {
    return () => {};
  }, []);

  function totalData(data) {
    try {
      return data.reduce((partialSum, a) => partialSum + a, 0).toFixed(2);
    } catch {
      return "-";
    }
  }

  var templateStructure = type ? type : "default";

  var overviewMetricsTemplate =
    useSelector(
      (state) =>
        state.ui_settings.portal_config.dashboard_config[templateStructure]
          .stats
    ) || {};
  var overviewMetrics = JSON.parse(JSON.stringify(overviewMetricsTemplate));
  lineData.forEach((entry) => {
    var value;
    var name;
    var measurement;
    if (entry.label.startsWith("Peak")) {
      try {
        value = entry.max.toFixed(2);
      } catch {
        value = "-";
      }
      name = entry.label.substring("Peak".length);
      measurement = "Peak";
      if (!overviewMetrics[measurement]) {
        overviewMetrics[measurement] = {};
      }
    } else if (entry.label.startsWith("Average")) {
      try {
        value = entry.avg.toFixed(2);
      } catch {
        value = "-";
      }
      name = entry.label.substring("Average".length);
      measurement = "Average";
      if (!overviewMetrics[measurement]) {
        overviewMetrics[measurement] = {};
      }
    } else if (entry.label.startsWith("Total")) {
      value = totalData(entry.data);
      name = entry.label.substring("Total".length);
      measurement = "Total";
      if (!overviewMetrics[measurement]) {
        overviewMetrics[measurement] = {};
      }
    } else {
      [measurement, name] = entry.legend.split("___");
      measurement = measurement.replaceAll("_", " ");
      try {
        value = entry.data[entry.data.length - 1].toFixed(2);
      } catch {
        value = "-";
      }
    }
    if (!overviewMetrics[measurement]) {
      overviewMetrics[measurement] = {};
    }
    overviewMetrics[measurement][name] = {};
    overviewMetrics[measurement][name]["value"] = value;
  });

  return (
    overviewMetrics && (
      <>
        <Grid item sm={8} md={9} lg={9} xl={9}>
          <Grid container justifyContent="space-between">
            {Object.keys(overviewMetrics).map((datapoint, i) => (
              <Grid item sm={6} md={6} lg={6} xl={6}>
                <Card
                  md={4}
                  lg={3}
                  xl={3}
                  className={i && classes.card}
                  elevation={1}
                >
                  <div className={classes.title}>
                    <Typography
                      color="textSecondary"
                      style={{ marginBottom: 5, fontSize: 18 }}
                      gutterBottom
                    >
                      {datapoint}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      {Object.keys(overviewMetrics[datapoint]).map((metric) => (
                        <div style={{ float: "right" }}>
                          <div style={{ display: "flex", alignItems: "end" }}>
                            <Typography variant="h2" component="h2">
                              {overviewMetrics[datapoint][metric]["value"]}
                            </Typography>
                            {overviewMetrics[datapoint][metric]["value"] !==
                            "-" ? (
                              <Typography
                                variant="h4"
                                component="h4"
                                style={{
                                  fontWeight: "normal",
                                  paddingBottom: "3px",
                                }}
                              >
                                {overviewMetrics[datapoint][metric]["Postfix"]}
                              </Typography>
                            ) : null}
                          </div>
                          <Typography
                            color="textSecondary"
                            style={{ marginBottom: 16, fontSize: 13 }}
                          >
                            {metric}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    )
  );
}
