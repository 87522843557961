import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDatatable from "../components/CustomDatatable";
import ReportSettings from "../components/dialogs/ReportSettings";
import { sub } from "date-fns";
import { CircularProgress, MenuItem, Snackbar } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import * as subscriptions from "../graphql/subscriptions";
import { v4 as uuidv4 } from "uuid";
import { Tooltip, IconButton, Typography, Button } from "@material-ui/core";
import { API } from "aws-amplify";
import ContainerHeader from "../components/ContainerHeader";
import AddIcon from "@material-ui/icons/Add";
import { Delete, Edit, PlayArrow } from "@material-ui/icons";
import { ExportToCsv } from "export-to-csv";
import { setTime } from "../libs/utils";
import { removeReport } from "../actions/reports";
import { Alert } from "@material-ui/lab";
import RemovalConfirmation from "../components/dialogs/RemovalConfirmation";
import Visible from "../components/Visible";

const menuItems = [
  "Today Only",
  "24 Hours",
  "Week",
  "30 Days",
  "This Calendar Month",
  "Last Calendar Month",
  "Year",
  "Custom",
];

const IconWithTooltip = ({ icon, title, onClick, disabled }) => (
  <div style={{ marginRight: 0 }}>
    {disabled ? (
      <IconButton disabled onClick={onClick} color="primary">
        {icon}
      </IconButton>
    ) : (
      <Tooltip title={title}>
        <IconButton onClick={onClick} color="primary">
          {icon}
        </IconButton>
      </Tooltip>
    )}
  </div>
);

const reportSelector = (reports, selected, setReportCallBack) => (
  <TextField
    label="Select Report"
    id="timeselection"
    value={selected}
    select
    variant="outlined"
    size="small"
    style={{ marginBottom: "20px" }}
    //className={classes.timeSelector}
    //maxWidth="xs"
    required
    onChange={(e) => setReportCallBack(e.target.value, selected)}
    fullWidth
  >
    {reports.map((item) => {
      var alertUrl = item.description.split("||")[4];
      var reportName = item.description.split("||")[5];
      return (
        <MenuItem key={reportName} value={alertUrl}>
          {reportName}
        </MenuItem>
      );
    })}
  </TextField>
);

export default function ReportsTable(props) {
  const [dialogState, setDialogState] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [dateType, setDateType] = useState("24_Hours");
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [rowData, setRowData] = useState(null);
  const [startDate, setStartDate] = useState(sub(new Date(), { days: 1 }));
  const [timeRangeDialogVisible, setTimeRangeDialogVisible] = useState(false);
  const dispatch = useDispatch();
  const { templateInfo, devices } = props;
  var rows = useSelector((state) => state.reports.results || []);
  var descriptionSplit = templateInfo
    ? templateInfo.description.split("||")
    : [];
  var type = descriptionSplit[4];
  var reportName = descriptionSplit[5];
  var reportTemplate = descriptionSplit[0];
  var title;
  // if (props.runButtonDisabled) {
  title = "Report - " + dateType.replaceAll("_", " ");
  if (reportTemplate === "Host Inventory") {
    title = reportName + " Report";
  } else {
    title = reportName + " Report - " + dateType.replaceAll("_", " ");
  }

  let subscription;
  useEffect(() => {
    return () => clearSubscription(subscription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setupSubscription(id) {
    subscription = API.graphql({
      query: subscriptions.onCreateReports,
      variables: { id: id },
      authToken: localStorage.getItem("jwt"),
    }).subscribe({
      next: ({ provider, value }) => {
        const rows = value.data.onCreateReports;
        dispatch({
          type: "REPORT_EXECUTION_RECEIVE",
          payload: rows.results,
          meta: {
            time_period: rows.report_id.split("__")[1],
            timestamp: rows.timestamp * 1000,
            custom_columns: rows.custom_columns,
            id: id,
          },
        });
      },
    });
  }

  const clearSubscription = (subscription) => {
    subscription && subscription.unsubscribe();
  };

  try {
    if (props.reportRunning) {
      rows = [];
    } else if (rows[type][dateType.replace(" ", "_")] !== "device") {
      var entry;
      if (reportTemplate === "Host Inventory") entry = rows[type]["24_Hours"];
      else entry = rows[type][dateType.replace(" ", "_")];

      var results = entry.results;

      // var id = entry.id;
      var cols = entry.custom_columns;

      if (!entry.downloaded) {
        var rowOutput = [];
        for (var rowIndex = 0; rowIndex < results.length; rowIndex++) {
          var currentRow = results[rowIndex];
          if (currentRow.Device.substring(0, 2) === "'-")
            currentRow.Device = "-";
          for (var devIndex = 0; devIndex < devices.length; devIndex++) {
            if (
              currentRow.Device ===
                devices[devIndex]["DNS Name"] +
                  " (" +
                  devices[devIndex]["Loopback IP"] +
                  ")" ||
              currentRow.Device === devices[devIndex]["Loopback IP"] ||
              currentRow.Device === devices[devIndex]["DNS Name"]
            ) {
              for (var colIndex = 0; colIndex < cols.length; colIndex++) {
                var col = cols[colIndex];
                try {
                  currentRow[col] =
                    devices[devIndex][col] !== undefined
                      ? devices[devIndex][col]
                      : "";
                } catch {
                  currentRow[col] = "";
                }
              }
            }
          }
          rowOutput.push(currentRow);
        }

        /* If everything is filtered out, download wont start if no row data. */
        rows = rowOutput;

        // Only add headers if results else hide.
        let useKeysAsHeaders = true;

        if (!rowOutput.length) {
          useKeysAsHeaders = false;
          rowOutput = [["No results found."]];
        }

        const options = {
          fieldSeparator: ",",
          decimalSeparator: ".",
          showLabels: true,
          showTitle: false,
          filename: title,
          useBom: true,
          useKeysAsHeaders,
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(rowOutput);
        dispatch({
          type: "REPORT_DOWNLOAD",
          meta: { type: type, time_range: dateType.replace(" ", "_") },
        });
        setLoadingIndex(null);
      }
    }
  } catch (err) {
    //console.log(err);
    rows = [];
  }

  const runReport = (id, date, start, end, name) => {
    setupSubscription(id);
    props.callBack(id, date, start, end, name);
  };

  const CustomToolbar = () => (
    <Button
      color="primary"
      variant="outlined"
      onClick={() => setTimeRangeDialogVisible(true)}
      style={{
        height: "36.5px",
        minWidth: "45px",
        padding: "0px",
        marginLeft: "10px",
      }}
    >
      <Tooltip
        disableFocusListener
        title={<Typography variant="body2">Add Report</Typography>}
      >
        <AddIcon />
      </Tooltip>
    </Button>
  );

  const convertScheduledReport = (row, index) => {
    var id = uuidv4();
    var times = setTime(row.time_range);
    setupSubscription(id);
    setStartDate(times.start);
    setEndDate(times.end);
    setDateType(times.date_type);
    setLoadingIndex(index);
    props.callBack(
      id,
      times.date_type.replace(" ", "_"),
      times.start,
      times.end,
      row.report_template,
      row.report_format,
      row.report_template,
      row.custom_columns,
      row.recipients,
      row.filters
    );
  };

  const removeScheduledReport = (row) => {
    props.updateLoading(true);
    removeReport(
      props.account_id,
      reportToDelete.report_name,
      !reportToDelete.scheduleReadable
    ).then(() => {
      props.updateLoading(false);
      props.listReportsCallback();
      setDialogState(false);
    });
  };

  const columns = {
    report_name: { label: "Report Name", filter: false },
    report_template: {
      label: "Template",
      filter: false,
    },
    time_range: { label: "Time Range", filter: false },
    recipients: {
      label: "Recipients",
      filter: false,
    },
    scheduleReadable: {
      label: "Schedule",
      filter: false,
    },
    report_format: {
      label: "Format",
    },
    Actions: {
      sort: false,
      customBodyRenderLite: (dataIndex) => (
        <div style={{ display: "flex" }}>
          <IconWithTooltip
            disabled={props.reportRunning}
            icon={
              props.reportRunning && loadingIndex === dataIndex ? (
                <CircularProgress size={24} />
              ) : (
                <PlayArrow />
              )
            }
            title="Run"
            onClick={() => {
              convertScheduledReport(
                props.scheduled_reports[dataIndex],
                dataIndex
              );
            }}
          />
          <Visible permissionNeeded="reports" capabilityNeeded="manage">
            <IconWithTooltip
              disabled={props.reportRunning}
              icon={<Edit />}
              title="Edit"
              onClick={() => handleRowClick(props.scheduled_reports[dataIndex])}
            />
            <IconWithTooltip
              disabled={props.reportRunning}
              icon={<Delete />}
              title="Delete"
              onClick={() =>
                handleDeleteClick(props.scheduled_reports[dataIndex])
              }
            />
          </Visible>
        </div>
      ),
    },
  };

  const handleDeleteClick = (report) => {
    setReportToDelete(report);
    setDialogState(true);
  };

  const handleRowClick = (row) => {
    const data = JSON.parse(row.data);
    setRowData({ ...row, data: data });
    props.setReportCallBack(data.description.split("||")[4]);
    setTimeRangeDialogVisible(true);
  };

  const handleExit = () => {
    props.handleConfigClose();
    setRowData(null);
    setTimeRangeDialogVisible(false);
    setDialogState(false);
  };

  return (
    <>
      <RemovalConfirmation
        open={dialogState}
        loading={props.loading}
        title={"Remove Report"}
        message={
          reportToDelete
            ? `You are about to remove report '${reportToDelete.report_name}'. This cannot be undone.`
            : null
        }
        callBack={removeScheduledReport}
        closesureCallback={handleExit}
      />
      <ReportSettings
        visible={timeRangeDialogVisible}
        loading={props.reportRunning}
        callbackClose={handleExit}
        setStartDate={(date) => setStartDate(date)}
        setEndDate={(date) => setEndDate(date)}
        setDateType={(date) => setDateType(date)}
        startDate={startDate}
        endDate={endDate}
        menuItems={menuItems}
        extraConfig={reportSelector(
          props.reports,
          props.selectedReport,
          props.setReportCallBack
        )}
        selectedReport={props.selectedReport}
        format={props.format}
        pdfRecipients={props.pdfRecipients}
        setPdfStates={props.setPdfStates}
        timeRangeDisabled={props.timeDisabled}
        callbackSaveReport={props.callbackSaveReport}
        account_id={props.account_id}
        scheduledLoading={props.scheduledLoading}
        scheduledError={props.scheduledError}
        updateColumns={props.updateColumns}
        customColumns={props.customColumns}
        updateReportFilters={props.updateReportFilters}
        reportFilters={props.reportFilters}
        runReport={() =>
          runReport(
            uuidv4(),
            dateType.replace(" ", "_"),
            startDate,
            endDate,
            reportName
          )
        }
        clearSchedulingError={props.clearSchedulingError}
        schedulingError={props.schedulingError}
        rowData={rowData}
      />
      <ContainerHeader title={"Reports"} />
      <CustomDatatable
        viewComponent={"ReportsTable"}
        customPage
        propMap={columns}
        rows={props.scheduled_reports}
        loading={props.loading}
        customToolbar={<CustomToolbar />}
        // selectableRows={"single"}
        rowSelectionCallback={(selected) =>
          this.setState({ selectedRow: selected[0] })
        }
        // rowClickCallback={handleRowClick}
      />
      <Snackbar open={props.reportRunning}>
        <Alert severity="info">
          The report is loading and will be downloaded shortly.
        </Alert>
      </Snackbar>
    </>
  );
}
