import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, Button, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import RolesForm from "../components/RoleForm";
import CustomDatatable from "../components/CustomDatatable";
import ContainerHeader from "../components/ContainerHeader";
import AddIcon from "@material-ui/icons/Add";
import RemovalConfirmation from "../components/dialogs/RemovalConfirmation";
import { deleteRole } from "../actions/roles";
import { connect } from "react-redux";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  editIcon: {
    marginRight: theme.spacing(1),
  },
}));

function RolesTable({
  roles,
  existingRoleNames,
  fetchRoles,
  loading,
  deleteRole,
}) {
  const classes = useStyles();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleState, setRoleState] = useState(null);
  const [removalState, setRemovalState] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const removeRole = async () => {
    setRemovalState(true);
    if (selectedRole) {
      if (selectedRole.userCount === 0) {
        await deleteRole(selectedRole.name).then(() => {
          setRemovalState(false);
          fetchRoles();
          setDeleteOpen(false);
        });
      } else {
        setShowAlert(true);
        setRemovalState(false);
      }
    }
  };

  const CustomToolbar = () => (
    <Button
      color="primary"
      variant="outlined"
      onClick={() => {
        setRoleState("Create");
        setEditOpen(true);
        setSelectedRole(null);
        //callback()
      }}
      style={{
        height: "36.5px",
        minWidth: "45px",
        padding: "0px",
        marginLeft: "10px",
      }}
    >
      <Tooltip
        disableFocusListener
        title={<Typography variant="body2">Add Role</Typography>}
      >
        <AddIcon />
      </Tooltip>
    </Button>
  );

  const columns = {
    name: { label: "Name", filter: false },
    description: { label: "Description", filter: false },
    userCount: { label: "User Count", filter: false },
    Actions: {
      sort: false,
      customBodyRenderLite: (dataIndex) => (
        <div style={{ display: "flex" }}>
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              color="primary"
              className={classes.editIcon}
              onClick={() => {
                setSelectedRole(roles[dataIndex]);
                setRoleState("Edit");
                setEditOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              disabled={false}
              aria-label="edit"
              color="primary"
              onClick={() => {
                setSelectedRole(roles[dataIndex]);
                setDeleteOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  };

  return (
    <>
      <RolesForm
        open={editOpen}
        onClose={() => {
          setSelectedRole(null);
          setEditOpen(false);
        }}
        role={selectedRole}
        existingRoleNames={existingRoleNames}
        fetchRoles={fetchRoles}
        action={roleState}
      />
      <RemovalConfirmation
        open={deleteOpen}
        loading={removalState}
        title={"Remove Role"}
        message={
          selectedRole
            ? `You are about to remove role '${selectedRole.name}'. This cannot be undone.`
            : null
        }
        callBack={removeRole}
        closesureCallback={() => {
          setDeleteOpen(false);
        }}
      >
        {showAlert && (
          <Alert
            style={{ width: "100%", height: "40px", alignItems: "center" }}
            severity="error"
            onClick={() => setShowAlert(false)}
          >
            <span>Roles with users cannot be deleted.</span>
          </Alert>
        )}
      </RemovalConfirmation>
      <ContainerHeader title={"Roles"} />
      <CustomDatatable
        viewComponent={"RolesTable"}
        customPage
        propMap={columns}
        rows={roles}
        loading={loading}
        customToolbar={<CustomToolbar />}
        // selectableRows={"single"}
        rowSelectionCallback={(selected) =>
          this.setState({ selectedRow: selected[0] })
        }
        // rowClickCallback={handleRowClick}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRole: (role) => dispatch(deleteRole(role)),
  };
};

export default connect(() => {}, mapDispatchToProps)(RolesTable);
