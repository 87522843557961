import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { makeStyles, Typography } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import MasqueradeDialog from "./dialogs/MasqueradeDialog";
import Visible from "../components/Visible";

import { logoutUser } from "../actions/session";

const useStyles = makeStyles((theme) => ({
  root: {
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  display_name: {
    display: "inline-block",
    // marginLeft: theme.spacing(1),
    color:
      theme.palette.type === "light"
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
  },
}));

export default function ProfileMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [signOutElement, setsignOutElement] = React.useState(null);
  const [masqueradeDialogVisible, setmasqueradeDialogVisible] =
    React.useState(false);
  const display_name =
    useSelector((state) => state.userSession.display_name) || "";

  let history = useHistory();

  const navigateToProfile = () => {
    history.push("/profile");
    setsignOutElement(null);
  };

  const openMasqueradeDialog = () => {
    setmasqueradeDialogVisible(true);
    setsignOutElement(null);
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    dispatch(logoutUser());
  };

  return (
    <React.Fragment>
      <div>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(event) => setsignOutElement(event.currentTarget)}
          classes={{ root: classes.root }}
        >
          <AccountCircle />
        </IconButton>
        <Typography classes={{ root: classes.display_name }}>
          {display_name}
        </Typography>
        <Menu
          id="menu-appbar"
          anchorEl={signOutElement}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(signOutElement)}
          onClose={(event) => setsignOutElement(null)}
        >
          <MenuItem onClick={() => navigateToProfile()}>My Account</MenuItem>
          <Visible
            component={"MasqueradeUserContainer"}
            permissionNeeded="admin.masquerade"
            capabilityNeeded="view"
          >
            <MenuItem
              onClick={() => openMasqueradeDialog()}
              // this.setState({
              // signOutElement: null,
              // masqueradeDialogVisible: true,
              // })
            >
              Switch Account
            </MenuItem>
          </Visible>
          <MenuItem onClick={() => signOut()}>Logout</MenuItem>
        </Menu>
      </div>
      <MasqueradeDialog
        callback={() => setmasqueradeDialogVisible(false)}
        visible={masqueradeDialogVisible}
      />
    </React.Fragment>
  );
}
