import API from "@aws-amplify/api";
import { RSAA } from "redux-api-middleware";
import { APIURL } from "../constants";
import * as queries from "../graphql/queries";
import { initRequestAlerts } from "./alerts";
import { apiQuery } from "../libs/auth-config";

export const REQUEST = "REQUEST_DEVICE";
export const RECEIVE = "RECEIVE_DEVICE";
export const FAILURE = "FAILURE_DEVICE";

export function initRequestDevice() {
  return {
    [RSAA]: {
      endpoint: APIURL + "/api/v1/device", // url,
      method: "GET",
      types: [
        {
          type: REQUEST,
          // meta: { 'goods': goods }
        },
        RECEIVE,
        FAILURE,
      ],
      headers: {},
      //credentials: 'include'
    },
  };
}

export function resetDeviceSearch() {
  return {
    type: "DEVICE_SEARCH_RESET",
  };
}

export const REQUEST_DEVICE_ALERTS = "REQUEST_DEVICE_ALERTS";
export const RECEIVE_DEVICE_ALERTS = "RECEIVE_DEVICE_ALERTS";
export const FAILURE_DEVICE_ALERTS = "FAILURE_DEVICE_ALERTS";

export function initRequestDeviceAlerts(id) {
  return {
    [RSAA]: {
      endpoint: APIURL + "/device/" + id + "/alerts", // url,
      method: "GET",
      types: [
        {
          type: REQUEST_DEVICE_ALERTS,
          // meta: { 'goods': goods }
        },
        RECEIVE_DEVICE_ALERTS,
        FAILURE_DEVICE_ALERTS,
      ],
      headers: {},
      // credentials: 'include'
    },
  };
}

export const DEVICES_REQUEST = "DEVICES_REQUEST";
export const DEVICES_SUCCESS = "DEVICES_SUCCESS";
export const DEVICES_FAILURE = "DEVICES_FAILURE";

const updateDevices = (devices) => ({
  type: DEVICES_SUCCESS,
  payload: devices,
});

export function initRequestAllDevices() {
  return async function getDevicesThunk(dispatch) {
    dispatch({
      type: DEVICES_REQUEST,
    });
    getAllPagedDevices()
      .then((data) => {
        dispatch({
          type: DEVICES_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        console.log(e);
        dispatch({ type: DEVICES_FAILURE });
      });
  };
}

const getAllPagedDevices = (customer) => async (dispatch) => {
  let token = null;
  let devices = [];

  do {
    // console.log("get all devices using token " + token);
    const response = await API.graphql({
      query: queries.listDevices,
      variables: { limit: 1000, nextToken: token },
      authToken: localStorage.getItem("jwt"),
    });
    // console.log(response);
    devices = devices.concat(response.data.listDevices.items);
    token = response.data.listDevices.nextToken;
  } while (token);

  if (customer)
    devices = devices.filter((dev) => dev.system.Customer === customer);

  return dispatch(updateDevices(devices));
};

export const DEVICE_SEARCH_REQUEST = "DEVICE_SEARCH_REQUEST";
export const DEVICE_SEARCH_SUCCESS = "DEVICE_SEARCH_SUCCESS";
export const DEVICE_SEARCH_FAILURE = "DEVICE_SEARCH_FAILURE";

const getDevicesByAccountID = (account_id, customer) => async (dispatch) => {
  if (account_id.includes("AUR") && customer) {
    account_id = account_id.split("-")[1];
    apiQuery("GET", "/devices/customer", {
      account_id: account_id,
      customer: customer,
    })
      .then((devices) => dispatch(updateDevices(devices.data)))
      .catch((e) => console.log(e));
  } else {
    dispatch({
      type: DEVICE_SEARCH_REQUEST,
    });
    const response = await apiQuery("GET", "/devices/customer", {
      account_id: account_id,
    }).then((res) => res.data);

    return dispatch(updateDevices(response));
  }
};

export function initDeviceSearch(account_id) {
  return async function getDevicesThunk(dispatch) {
    let customer = "";
    if (account_id.includes(" - ")) {
      customer = account_id.split(" - ")[1];
      account_id = account_id.split(" - ")[0];
    }
    console.log(account_id);
    dispatch({
      type: DEVICE_SEARCH_REQUEST,
    });
    API.graphql({
      query: queries.listDevicesByAccountId,
      variables: {
        account_id: account_id,
      },
      authToken: localStorage.getItem("jwt"),
    }).then((data) =>
      dispatch({
        type: DEVICES_SUCCESS,
        payload: customer
          ? data.data.listDevicesByAccountId.items.filter(
              (dev) => JSON.parse(dev.manual).Customer === customer
            )
          : data.data.listDevicesByAccountId.items,
      })
    );

    return Promise.resolve();
  };
}

export const saveSearch = (term) => (dispatch) =>
  dispatch({
    type: DEVICE_SEARCH_REQUEST,
    meta: { search_term: term },
  });

export const REQUEST_DEVICE_GRAPHS = "REQUEST_DEVICE_GRAPHS";
export const RECEIVE_DEVICE_GRAPHS = "RECEIVE_DEVICE_GRAPHS";
export const FAILURE_DEVICE_GRAPHS = "FAILURE_DEVICE_GRAPHS";

export function initRequestDeviceGraphs(id) {
  return {
    [RSAA]: {
      endpoint: APIURL + "/device/" + id + "/graphs", // url,
      method: "GET",
      types: [
        {
          type: REQUEST_DEVICE_GRAPHS,
          // meta: { 'goods': goods }
        },
        RECEIVE_DEVICE_GRAPHS,
        FAILURE_DEVICE_GRAPHS,
      ],
      headers: {},
      credentials: "include",
    },
  };
}

export const getDeviceUptime = (account_id, id) => (dispatch) => {
  dispatch({ type: "REQUEST_DEVICE_UPTIME" });
  apiQuery("GET", "/datasources/uptime", {
    account_id: account_id,
    id: id,
  })
    .then((data) =>
      dispatch({
        type: "RECEIVE_DEVICE_UPTIME",
        payload: data.data[0].uptime,
        meta: { id: id },
      })
    )
    .catch((e) => {
      console.log(e);
      dispatch({
        type: "FAILURE_DEVICE_UPTIME",
      });
    });
};

export const addProperty = async (payload) =>
  apiQuery("POST", "/devices/properties", payload)
    .then((res) => res)
    .catch((e) => console.log(e));

export const updateProperty = async (payload) =>
  apiQuery("PUT", "/devices/properties", payload)
    .then((res) => res)
    .catch((e) => console.log(e));

export const removeProperty = (payload) => (dispatch) => {
  dispatch({ type: "REQUEST_REMOVE_CUSTOM_PROPERTY" });
  apiQuery("DELETE", "/devices/properties", payload)
    .then((res) => {
      dispatch({
        type: "SUCCESS_REMOVE_CUSTOM_PROPERTY",
        meta: payload,
      });
      return res;
    })
    .catch((e) => {
      dispatch({ type: "FAILURE_REMOVE_CUSTOM_PROPERTY" });
      console.log(e);
    });
};

const updateDone = () => ({ type: "UPDATE_DONE" });

export const dispatchChaining = (account_id) => async (dispatch) => {
  await dispatch(getAllPagedDevices());
  await dispatch(initRequestAlerts(account_id));
  // await Promise.all([
  //   dispatch(getAllPagedDevices()),
  //   dispatch(initRequestAlerts(account_id)),
  // ]);
  return dispatch(updateDone());
};

export const listAllDevicesThenAlerts = (account_id, role) => (dispatch) => {
  return dispatch(getAllPagedDevices()).then(() => {
    return dispatch(initRequestAlerts(account_id));
  });
};

export const listDevicesThenAlerts =
  (account_id, customer) => async (dispatch) => {
    await dispatch(getDevicesByAccountID(account_id, customer));
    await dispatch(initRequestAlerts(account_id));

    return dispatch(updateDone());
    // });
  };

export const saveLinks = (payload) => (dispatch) =>
  apiQuery("PUT", "/devices/links", payload).then((res) =>
    dispatch({
      type: "UPDATE_DEVICE_LINKS",
      payload: payload,
    })
  );
