import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
// import { apiMiddleware } from "redux-api-middleware";
import reducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
// import jwtMiddleware from "./middleware/jwtMiddleware";

// const reducer = combineReducers(reducers);
const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(thunkMiddleware)
)(createStore);

export default function configureStore(initialState) {
  return createStoreWithMiddleware(reducers, initialState);
}
