import React from "react";
import {
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import LaunchIcon from "@material-ui/icons/Launch";
import Visible from "./Visible";

const details = [
  "Uptime",
  "OID",
  "Vendor",
  "Type",
  "Model",
  "Serial Number",
  "SNMP Version",
];

export default class SnmpInformationFields extends React.Component {
  renderTableData = () => {
    return details.map((detail) => {
      return (
        <>
          <TableRow style={{ margin: "10px" }}>
            <TableCell style={{ borderBottom: "none", padding: "3px" }}>
              <Typography
                variant="span"
                style={{
                  color: "#7a7a7a",
                  marginRight: "5px",
                }}
              >
                {detail}
              </Typography>
            </TableCell>
            <TableCell
              style={{
                borderBottom: "none",
                padding: "3px",
                textAlign: "center",
                overflowWrap: "break-word",
                maxWidth: "200px",
              }}
            >
              <Typography
                variant="span"
                style={{
                  width: "100%",
                  maxWidth: "200px",
                  display: "inline-block",
                }}
              >
                {this.props.errorFetchingData
                  ? "N/A"
                  : this.props.data[detail] !== undefined
                  ? this.props.data[detail]
                  : "..."}
              </Typography>
            </TableCell>
          </TableRow>
        </>
      );
    });
  };

  renderDeviceDescription = () => {
    return (
      <Typography
        variant="p"
        style={{
          margin: "0 auto",
          marginBottom: "5px",
          overflowWrap: "break-word",
        }}
      >
        {this.props.errorFetchingData
          ? "N/A"
          : this.props.data.Description !== undefined
          ? this.props.data.Description
          : "..."}
      </Typography>
    );
  };

  render() {
    var { classes } = this.props;
    return (
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="center"
        className="deviceSnmpInfoContainer"
      >
        <Grid
          item
          className={`${classes.refreshButtonWrapper} snmpRefreshButtonContainer`}
          style={{ paddingBottom: "8px", textAlign: "center" }}
        >
          <Grid
            container
            direction="column"
            spacing={1}
            style={{ marginBottom: "8px" }}
          >
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={this.props.refresh}
                color="primary"
                variant="contained"
                className={classes.snmpDiagnosticsButton}
              >
                Refresh <RefreshIcon />
              </Button>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
              <Visible
                permissionNeeded="services.diagnostics"
                capabilityNeeded="manage"
              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{ maxHeight: "40px" }}
                  className={classes.snmpDiagnosticsButton}
                  onClick={this.props.openAdvancedDiagnosticsWindow}
                >
                  Advanced Diagnostics{" "}
                  {<LaunchIcon size="small" style={{ marginLeft: "5px" }} />}
                </Button>
              </Visible>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.deviceDescriptionTitleWrapper}>
          <Typography variant="h4" style={{ marginBottom: "10px" }}>
            Description
          </Typography>
          <div className={classes.snmpDescriptionWrapper}>
            {this.renderDeviceDescription()}
          </div>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            align="center"
            style={{ marginBottom: "10px" }}
          >
            Details
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>{this.renderTableData()}</TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}
