import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CSVReader from "react-csv-reader";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import { apiQuery } from "../../libs/auth-config";

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
  },
  formControl: {
    minWidth: 300,
    margin: theme.spacing(1),
  },
  csvFileStyle: {
    textAlign: "center",
  },
}));

export default function AddUsersByCSV({ callback }) {
  const classes = styles();
  const [portal, setPortal] = React.useState("sandy");
  const [csv, setCSV] = React.useState([]);

  const handleChange = (e) => {
    setPortal(e.target.value);
  };

  const handleCSV = (data) => {
    setCSV(data);
  };

  const runJob = () => {
    const data = {
      account: portal,
      dataframe: csv,
    };

    apiQuery("POST", "addingusers", data)
      .then((res) => callback(res))
      .catch((e) => console.log(e));
  };

  const checkState = () => {
    if (portal && csv.length > 0) return false;
    else return true;
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent classes={{ root: classes.csvFileStyle }}>
          <Link href="https://masro-automation.s3.eu-west-2.amazonaws.com/example.csv">
            Example CSV
          </Link>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="panel">LogicMonitor Panel</InputLabel>
              <Select
                labelid="panel"
                value={portal}
                onChange={handleChange}
                labelWidth={125}
              >
                <MenuItem value={"sandy"}>Sandy</MenuItem>
                <MenuItem value={"dws"}>DWS</MenuItem>
                <MenuItem value={"splc"}>SPLC</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box textAlign="center">
            <CSVReader onFileLoaded={handleCSV} />
          </Box>
          <Box textAlign="center">
            <Button
              className={classes.formControl}
              color="primary"
              variant="contained"
              onClick={runJob}
              disabled={checkState()}
            >
              Run Job
            </Button>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

//<CSVReader onFileLoaded={(data, fileInfo) => console.log(data, fileInfo)}/>
