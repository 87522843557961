import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// import {Link} from 'react-router-dom';
import PropTypes from "prop-types";

import { Box, TextField, Select, MenuItem } from "@material-ui/core";
import { Tab, Paper, Tabs, Divider } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

// import { getSubDeviceGroups } from "../actions/devicegroup";
import CircularIntegration from "../components/CircularIntegration";

import { apiQuery } from "../libs/auth-config";

import { CAPABILITY_LEVELS, PREFIX } from "../constants";
import { Grid, FormControlLabel, Checkbox } from "@material-ui/core";
import { Button } from "@material-ui/core";

import OptionalProperties from "../components/dialogs/OptionalProperties";

const ProvisionContainer = ({ setParentTitle }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [pageTitle, setpageTitle] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!pageTitle) {
      setParentTitle("CPE Provisioning");
      setpageTitle(true);
    }
  }, [setParentTitle, setpageTitle, pageTitle]);

  const permissions = useSelector((state) => state.roles.permissions);
  const hasManagePermissions =
    permissions["admin"]["provisioning"] <= CAPABILITY_LEVELS["manage"];

  return (
    <div className={classes.root}>
      <Paper>
        <Tabs
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="provisioning_tabs"
        >
          {PREFIX === "dws" ? <Tab label="Reseller" {...a11yProps(0)} /> : null}
          {PREFIX === "dws" ? (
            <Tab label="Customer" {...a11yProps(1)} />
          ) : (
            <Tab label="Customer" {...a11yProps(0)} />
          )}
          {PREFIX === "dws" ? (
            <Tab label="Device" {...a11yProps(2)} />
          ) : (
            <Tab label="Device" {...a11yProps(1)} />
          )}
        </Tabs>
        <Divider />
        {PREFIX === "dws" ? (
          <TabPanel value={value} index={0}>
            <ProvisionResellerForm
              hasManagePermissions={hasManagePermissions}
            />
          </TabPanel>
        ) : null}
        <TabPanel value={value} index={PREFIX === "dws" ? 1 : 0}>
          <ProvisionCustomerForm hasManagePermissions={hasManagePermissions} />
        </TabPanel>
        <TabPanel value={value} index={PREFIX === "dws" ? 2 : 1}>
          <ProvisionDeviceForm hasManagePermissions={hasManagePermissions} />
        </TabPanel>
      </Paper>
    </div>
  );
};

export default ProvisionContainer;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

// function LinkTab(props) {
//   return (
//     <Tab
//       component="a"
//       onClick={(event) => {
//         event.preventDefault();
//       }}
//       {...props}
//     />
//   );
// }

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: "8px",
      width: "25ch",
    },
  },
  optionalButton: { margin: "8px", width: 195 },
  circuitsize: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
}));

const ProvisionResellerForm = ({ hasManagePermissions }) => {
  // const classes = useStyles();
  const [isProvisioning, setProvisioning] = React.useState(false);
  const [reseller, setReseller] = React.useState("");
  const [resellerID, setResellerID] = React.useState("");
  const [provision_error_message, setErrorMessage] = React.useState("");
  const [provision_success_message, setSuccessMessage] = React.useState("");
  const email = useSelector((state) => state.userSession.email) || "";
  const checkValues = () => {
    if (reseller && resellerID) return false;
    else return true;
  };

  const provisionReseller = async () => {
    setProvisioning(true);
    const response = await postReseller();
    if (response.status_code !== 200) {
      setErrorMessage(`${response.status_code}: ${response.message}`);
    } else {
      setSuccessMessage(response.message);
    }
    setProvisioning(false);
  };

  const postReseller = async () => {
    return apiQuery("POST", "/reseller", {
      reseller: reseller,
      resellerID: resellerID,
      user: email,
    }).then((data) => data);
  };

  return (
    <form
      onSubmit={(event) => this.performSearch(event)}
      id="searchForm"
      className="form"
    >
      <div>
        <TextField
          id="reseller_name_tf"
          size="small"
          label="Reseller"
          type="text"
          variant="outlined"
          onChange={(e) => setReseller(e.target.value)}
        />
        <TextField
          id="reseller_id_tf"
          size="small"
          label="Reseller ID"
          type="text"
          variant="outlined"
          onChange={(e) => setResellerID(e.target.value)}
        />
      </div>
      <div style={{ margin: "8px" }}>
        <CircularIntegration
          text="Start Provisioning"
          is_loading={isProvisioning}
          disabled={checkValues() || !hasManagePermissions}
          onClick={() => provisionReseller()}
          style={{ width: 195 }}
        />
        {provision_error_message && (
          <Alert severity="error">{provision_error_message}</Alert>
        )}
        {provision_success_message && (
          <Alert severity="success">{provision_success_message}</Alert>
        )}
      </div>
    </form>
  );
};

const ProvisionCustomerForm = ({ hasManagePermissions }) => {
  const classes = useStyles();
  const resellerList =
    useSelector((state) => state.deviceGroups.accountGroups) || [];
  // const [account, setAccount] = React.useState('');
  const [isProvisioning, setProvisioning] = React.useState(false);
  const [reseller, setReseller] = React.useState("");
  const [customer, setCustomer] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");
  const [provision_error_message, setErrorMessage] = React.useState("");
  const [provision_success_message, setSuccessMessage] = React.useState("");

  const email = useSelector((state) => state.userSession.email) || "";

  const checkValues = () => {
    if (reseller && customer && PREFIX === "dws") return false;
    else if (customer && PREFIX === "dc") return false;
    else return true;
  };

  const provisionCustomer = async () => {
    setProvisioning(true);
    const response = await postCustomer();
    if (response.status_code !== 200) {
      setErrorMessage(`${response.status_code}: ${response.message}`);
    } else {
      setSuccessMessage(response.message);
    }
    setProvisioning(false);
  };

  const postCustomer = async () => {
    return apiQuery("POST", "/customer", {
      customer: customer,
      rDevGroupId: reseller.id,
      user: email,
      ...(PREFIX !== "dws" && {
        customerID: customerId,
      }),
    }).then((data) => data);
  };

  const getLabel = (option) => {
    if (typeof option === "string") return option;
    else if (typeof option.name !== "undefined") return option.name;
    else return "";
  };

  return (
    <form className={classes.root}>
      <Grid container justifyContent="flex-start">
        {PREFIX === "dws" ? (
          <Autocomplete
            options={resellerList}
            getOptionLabel={(option) => getLabel(option)}
            value={reseller}
            freeSolo
            contentEditable={false}
            onChange={(_, v) => {
              setReseller(v);
            }}
            renderInput={(params) => (
              <TextField
                key={params.id}
                {...params}
                label={"Reseller"}
                variant="outlined"
                size="small"
              />
            )}
          />
        ) : null}
        <TextField
          id="customer_name_tf"
          label="Customer"
          type="text"
          variant="outlined"
          size="small"
          onChange={(e) => setCustomer(e.target.value)}
        />
        {PREFIX !== "dws" ? (
          <TextField
            id="account_id_tf"
            label="Account ID"
            type="text"
            size="small"
            variant="outlined"
            onChange={(e) => setCustomerId(e.target.value)}
          />
        ) : null}
      </Grid>
      <div style={{ margin: "8px" }}>
        <CircularIntegration
          text="Start Provisioning"
          is_loading={isProvisioning}
          disabled={checkValues() || !hasManagePermissions}
          onClick={() => provisionCustomer()}
          style={{ width: 195 }}
        />
        {provision_error_message && (
          <Alert severity="error">{provision_error_message}</Alert>
        )}
        {provision_success_message && (
          <Alert severity="success">{provision_success_message}</Alert>
        )}
      </div>
    </form>
  );
};

const ProvisionDeviceForm = ({ hasManagePermissions }) => {
  const classes = useStyles();

  const [isProvisioning, setProvisioning] = React.useState(false);
  const resellerList =
    useSelector((state) => state.deviceGroups.accountGroups) || [];
  const [customerList, setCustomerList] = React.useState([]);

  const [customer, setCustomer] = React.useState("");
  const [reseller, setReseller] = React.useState("");
  const [deviceName, setdeviceName] = React.useState("");
  const [postcode, setpostcode] = React.useState("");
  const [loopback, setloopback] = React.useState("");
  const [circuitref, setcircuitref] = React.useState("");
  const [circuitsize, setcircuitsize] = React.useState("");
  const [circuitunit, setcircuitunit] = React.useState("Mb");
  const [bearersize, setbearersize] = React.useState("");
  const [bearerunit, setbearerunit] = React.useState("Mb");
  const [requestid, setrequestid] = React.useState("");
  const [dialogstate, setdialogboxstate] = React.useState(false);
  const [optionalprops, setoptionalprops] = React.useState([]);
  const [prelive, setprelive] = React.useState(false);
  // const [account, setAccount] = React.useState('');
  const [provision_error_message, setErrorMessage] = React.useState("");
  const [provision_success_message, setSuccessMessage] = React.useState("");

  const email = useSelector((state) => state.userSession.email) || "";

  const provisioningConfig = useSelector(
    (state) => state.ui_settings.portal_config["provisioning"]
  );

  useEffect(() => {
    if (!optionalprops.length && provisioningConfig !== undefined) {
      var props = [];
      for (const config of Object.values(provisioningConfig)) {
        if ("default" in config) {
          props.push({
            name: "aur." + config["prop"],
            value: config["default"],
          });
        }
      }
      setoptionalprops(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provisioningConfig]);

  const checkValues = () => {
    if (
      PREFIX === "dws" &&
      reseller &&
      customer &&
      deviceName &&
      postcode &&
      loopback &&
      circuitref &&
      circuitsize &&
      bearersize &&
      requestid &&
      circuitunit
    )
      return false;
    else if (
      PREFIX === "dc" &&
      customer &&
      deviceName &&
      postcode &&
      loopback &&
      circuitref &&
      circuitsize &&
      bearersize &&
      requestid &&
      circuitunit
    )
      return false;
    else return true;
  };

  const getLabel = (option) => {
    if (typeof option === "string") return option;
    else if (typeof option.name !== "undefined") return option.name;
    else return "";
  };

  const provisionDevice = async () => {
    setProvisioning(true);
    const response = await postDevice();
    console.log(response);
    if (response.status_code !== 200) {
      setErrorMessage(response.status_code + ": " + response.message);
    } else {
      setSuccessMessage(response.message);
    }
    setProvisioning(false);
  };

  const getSubDeviceGroups = async (group_id) => {
    apiQuery("GET", "/devicegroups", {
      parentId: group_id,
    }).then((response) => setCustomerList(response.data));
  };

  const postDevice = async () => {
    return apiQuery("POST", "/device", {
      cDevGroupId: customer.id,
      device: deviceName,
      ip: loopback,
      "aur.circuit_reference": circuitref,
      "aur.postcode": postcode,
      "aur.circuit_size": circuitsize + circuitunit,
      "aur.bearer_size": bearersize + bearerunit,
      "aur.request_id": requestid,
      ...(prelive && { "aur.pre_live": "true" }),
      optional: optionalprops,
      user: email,
    }).then((data) => data);
  };

  const selectReseller = (v) => {
    setReseller(v);
    if (v) getSubDeviceGroups(parseInt(v.id));
  };

  const setOptionalProps = (optionalProps) => {
    var toSet = [];

    for (const [key, value] of Object.entries(optionalProps)) {
      let propName;
      if (key === "location") propName = "l1." + key;
      else propName = "aur." + key;
      toSet.push({ name: propName, value: value });
    }
    setoptionalprops(toSet);
  };

  return (
    <form className={classes.root}>
      <div>
        <OptionalProperties
          state={dialogstate}
          setProperties={(props) => setOptionalProps(props)}
          closeDialog={() => setdialogboxstate(false)}
          existingValues={optionalprops}
          initialValues={optionalprops}
        />
        <Grid container justifyContent="flex-start">
          {PREFIX === "dws" ? (
            <Grid item>
              <Autocomplete
                options={resellerList}
                getOptionLabel={(option) => getLabel(option)}
                value={reseller}
                freeSolo
                onChange={(_, v) => {
                  setCustomer(null);
                  selectReseller(v);
                  setCustomerList([]);
                }}
                renderInput={(params) => (
                  <TextField
                    key={params.id}
                    {...params}
                    label={"Reseller"}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item>
            <Autocomplete
              options={PREFIX === "dws" ? customerList : resellerList}
              getOptionLabel={(option) => getLabel(option)}
              value={customer}
              freeSolo
              onChange={(_, v) => {
                setCustomer(v);
              }}
              renderInput={(params) => (
                <TextField
                  key={params.id}
                  {...params}
                  label={"Customer"}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>

        {/*
        <TextField
          id="cust_device_group_id_tf"
          label="Customer Device Group ID"
          type="text"
          variant="outlined"
          size="small"
          onChange={(e) => setdeviceGroupID(e.target.value)}
        />
        */}
        <TextField
          id="device_name_tf"
          label="Device Name"
          variant="outlined"
          size="small"
          onChange={(e) => setdeviceName(e.target.value)}
        />
        <TextField
          id="postcode_tf"
          label="Postcode"
          type="text"
          variant="outlined"
          size="small"
          onChange={(e) => setpostcode(e.target.value)}
        />
        <TextField
          id="loopback_tf"
          label="Loopback IP"
          type="text"
          variant="outlined"
          size="small"
          onChange={(e) => setloopback(e.target.value)}
        />
        <TextField
          id="circuitref_tf"
          label="Circuit Reference"
          type="text"
          variant="outlined"
          size="small"
          onChange={(e) => setcircuitref(e.target.value)}
        />
        <TextField
          id="circuitsize_tf"
          label="Circuit Size"
          type="text"
          variant="outlined"
          size="small"
          onChange={(e) =>
            /^[0-9]*$/.test(e.target.value) || !e.target.value
              ? setcircuitsize(e.target.value)
              : null
          }
          className={classes.circuitsize}
          value={circuitsize}
          InputProps={{
            endAdornment: (
              <Select
                onChange={(e) => setcircuitunit(e.target.value)}
                value={circuitunit}
                style={{ WebkitTapHighlightColor: "transparent" }}
              >
                <MenuItem
                  style={{ WebkitTapHighlightColor: "transparent" }}
                  value="Mb"
                >
                  Mb
                </MenuItem>
                <MenuItem value="Gb">Gb</MenuItem>
              </Select>
            ),
          }}
        />
        <TextField
          id="bearersize_tf"
          label="Bearer Size"
          type="text"
          variant="outlined"
          size="small"
          onChange={(e) =>
            /^[0-9]*$/.test(e.target.value) || !e.target.value
              ? setbearersize(e.target.value)
              : null
          }
          className={classes.circuitsize}
          value={bearersize}
          InputProps={{
            endAdornment: (
              <Select
                onChange={(e) => setbearerunit(e.target.value)}
                value={bearerunit}
                style={{ WebkitTapHighlightColor: "transparent" }}
              >
                <MenuItem
                  style={{ WebkitTapHighlightColor: "transparent" }}
                  value="Mb"
                >
                  Mb
                </MenuItem>
                <MenuItem value="Gb">Gb</MenuItem>
              </Select>
            ),
          }}
        />
        <TextField
          id="requestid_tf"
          label="Request ID (DR-)"
          type="text"
          variant="outlined"
          size="small"
          onChange={(e) => setrequestid(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setdialogboxstate(true)}
          className={classes.optionalButton}
        >
          Optional Properties
        </Button>
      </div>
      <div style={{ margin: "8px" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <CircularIntegration
              text="Start Provisioning"
              is_loading={isProvisioning}
              disabled={checkValues() || !hasManagePermissions}
              onClick={() => provisionDevice()}
              style={{ width: 195 }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={prelive}
                  onChange={() => setprelive(!prelive)}
                  name="prelive"
                />
              }
              label="Pre-Live"
            />
          </Grid>
        </Grid>
        {provision_error_message && (
          <Alert severity="error">{provision_error_message}</Alert>
        )}
        {provision_success_message && (
          <Alert severity="success">{provision_success_message}</Alert>
        )}
      </div>
    </form>
  );
};
