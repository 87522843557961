import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { apiQuery } from "../../libs/auth-config";
import {
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";

export const styles = makeStyles((theme) => ({
  "@global": {
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  root: {
    marginBottom: theme.spacing(1),
  },
  div: {
    width: "100%",
    height: "68%",
  },
  grid: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    gap: theme.spacing(1),
    width: "100%",
  },
  item: {
    height: "100%",
    width: "100%",
  },
  field: {
    margin: "auto",
  },
  button: {
    width: "10%",
  },
  centered: {
    height: theme.spacing(14.2),
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    overflow: "auto",
    paddingRight: 8,
  },
  widgetToken: {
    height: "100%",
    width: "100%",
    marginBottom: -theme.spacing(1),
  },
}));

export default function DashboardForm({
  templates,
  callback,
  data,
  clearData,
  deviceGroupOptions,
  dashboardGroupOptions,
}) {
  const classes = styles();
  const [groups, setGroups] = React.useState([]);
  const [rootName, setRootName] = React.useState("");
  const [templateId, setTemplateId] = React.useState("")
  const [dashboardName, setDashboardName] = React.useState("")
  const [target, setTarget] = React.useState({
    dashboard: "",
    dashboardInfo: "",
    widgetTokens: [],
  });
  const [loading, setLoading] = React.useState(false);

  const rootGroupId =
    useSelector((state) => state.ui_settings.portal_config.rootDashboardId) ||
    "0";

  useEffect(() => {
    var currentGroups = [...groups];

    async function populateGroups(groupId) {
      // You can await here
      return await getSingleGroup(groupId);
      // ...
    }
    var indexToChange = null;
    for (var itemIndex in currentGroups) {
      if (
        !_.isEmpty(currentGroups[itemIndex].id) &&
        _.isEmpty(currentGroups[itemIndex].results)
      ) {
        indexToChange = itemIndex;
      }
    }
    if (indexToChange) {
      populateGroups(currentGroups[indexToChange]["id"]).then((data) => {
        currentGroups[indexToChange].results = data.subGroups;
        setGroups(currentGroups);
        if(currentGroups[indexToChange]["id"] === rootGroupId.toString()){
          setRootName(data.name)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  const getSingleGroup = async (group_id) => {
    setLoading(true);
    const data = {
      group_id: group_id,
      type: "Dashboard"
    };
    const response = await apiQuery(
      "GET",
      "/automation/groups",
      data
    );
    setLoading(false);
    return response.data;
  };

  const groupChecker = () => {
    if (groups.length === 0) {
      return false;
    }
    for (var group of groups) {
      if (group.type === "dynamic" && group.value === "") {
        return false;
      }
    }
    return true;
  };

  const fields = {
    Dashboard: {
      list: templates,
      value: target.dashboard,
      onChange: (option) => handleDashboardChange(option),
    },
  };

  const saveChecker = () => {
    var tokenChecker = true
    for(var token of target.widgetTokens){
      if(_.isEmpty(token.value)){
        tokenChecker = false
      }
    }
    if(tokenChecker && !_.isEmpty(target.dashboard) && groupChecker){
      return false
    }else{
      return true
    }
  }

  const handleFolderChange = (item, index) => {
    var currentGroups = [...groups];
    if (currentGroups[index + 1].type === "static") {
      currentGroups[index]["value"] = item["name"];
      setGroups(currentGroups);
      return false;
    }
    for (var i = index + 1; i < currentGroups.length; i++) {
      currentGroups[i].id = "";
      currentGroups[i].value = "";
      currentGroups[i].results = [];
    }
    currentGroups[index + 1]["id"] = item["id"].toString();
    currentGroups[index]["value"] = item["name"];

    setGroups(currentGroups);
  };

  const handleDashboardChange = (val) => {
    let newTarget = { ...target };
    val ? (newTarget.dashboard = val.name) : (newTarget.dashboard = "");
    var description = val.description.split("\n");
    for (var item of description) {
      if (item.startsWith("Info: ")) {
        newTarget.dashboardInfo = item.replace("Info: ", "");
      } else if (item.startsWith("Name: ")) {
        setDashboardName(item.replace("Name: ",""))
      } else if (item.startsWith("Path: ")) {
        var folders = [];
        var path = item.replace("Path: ", "");
        var paths = path.split("/");
        for (var folderIndex in paths) {
          if (
            paths[folderIndex].startsWith("{") &&
            paths[folderIndex].endsWith("}")
          ) {
            var id = "";
            if (folderIndex === "0") {
              id = rootGroupId.toString();
            }
            folders.push({
              name: paths[folderIndex].replace(/{|}/g, ""),
              type: "dynamic",
              value: "",
              results: [],
              id: id,
            });
          } else {
            folders.push({
              name: paths[folderIndex],
              type: "static",
              value: paths[folderIndex],
              id: "",
              results: [],
            });
          }
        }
        //newTarget.groups = folders
      }
    }
    var tokens = [];
    for (var token of val.widgetTokens) {
      if (token.type === "owned") {
        tokens.push({ name: token.name, value: "" });
      }
    }
    newTarget.widgetTokens = tokens;
    setTarget(newTarget);
    setGroups(folders);
  };

  const resetForm = () => {
    setTarget({
      dashboard: "",
      dashboardInfo: "",
      widgetTokens: [],
    });
    setGroups([]);
    setDashboardName("");
    clearData();
  };

  const saveConfig = () => {

    var path = ""
    if(rootGroupId !== "0"){
      path = rootName + "/"
    }
    var group;
    for(group of groups){
      path += group.value + "/"
    }
    path = path.slice(0, -1)
    var name = dashboardName
    const indexesOpening = [...dashboardName.matchAll(new RegExp("{", 'gi'))].map(a => a.index);
    const indexesClosing = [...dashboardName.matchAll(new RegExp("}", 'gi'))].map(a => a.index);
    for(var index in indexesOpening){
      var tokenNameFull = dashboardName.substring(indexesOpening[index], indexesClosing[index]+1)
      var tokenName = dashboardName.substring(indexesOpening[index]+1, indexesClosing[index])
      var replaceValue = ""
      for(group of groups){
        if(tokenName === group.name){
          replaceValue = group.value
        }
      }
      for(var token of target.widgetTokens){
        if(tokenName === token.name){
          replaceValue = token.value
        }
      }
      if(!_.isEmpty(replaceValue)){
        name = name.replaceAll(tokenNameFull,replaceValue)
      }
    }
    callback({"dashboard":target.dashboard, "widgetTokens":target.widgetTokens,"path":path,"templateId": templateId, "dashboardName":name});
  };

  return (
    <>
      <Typography variant="h4" className={classes.root}>
        Dashboard Provisioning
      </Typography>
      <Typography variant="body">
        Enter the device name and select a dashboard template from the list.
        Please ensure that the device is eligible for the chosen template.
      </Typography>
      <div className={classes.div}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item lg={5} className={classes.item}>
            {Object.keys(fields).map((label) => {
              return (
                <Autocomplete
                  options={fields[label].list}
                  getOptionLabel={(option) => option?.name || `${option}`}
                  freeSolo
                  disabled={false}
                  fullWidth
                  value={fields[label].value}
                  style={{ height: 40 }}
                  onChange={(_, v) => {
                    fields[label].onChange(v);
                    setTemplateId(v['id'].toString())
                  }}
                  renderInput={(params) => (
                    <TextField
                      key={params.id}
                      {...params}
                      fullWidth
                      value={fields[label]?.value || fields[label]}
                      label={label}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {label === "Dashboard" && target.dashboardInfo ? (
                              <Tooltip
                                title={
                                  <Typography fontSize={20}>
                                    {target.dashboardInfo}
                                  </Typography>
                                }
                              >
                                <InfoIcon color="inherit" size={20} />
                              </Tooltip>
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              );
            })}
          </Grid>
          {groups.map((item, index) =>
            item.type === "dynamic" ? (
              <Grid item lg={5} className={classes.item}>
                <Autocomplete
                  options={groups[index]["results"]}
                  getOptionLabel={(option) => option.name || option}
                  defaultValue={""}
                  value={item["value"]}
                  disabled={_.isEmpty(item["id"])}
                  onChange={(_, v) => {
                    handleFolderChange(v, index);
                  }}
                  renderInput={(params) => (
                    <TextField
                      key={params.id}
                      {...params}
                      label={item.name}
                      variant="outlined"
                      size="small"
                      style={{ margin: "0 auto" }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading &&
                            !_.isEmpty(item["id"]) &&
                            _.isEmpty(item["value"]) ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            ) : null
          )}
          {groupChecker() &&
            target.widgetTokens.map((item, index) => (
              <Grid item lg={5} className={classes.widgetToken}>
                <TextField
                  fullWidth
                  key={"token" + index}
                  label={item.name}
                  disabled={!!data}
                  variant="outlined"
                  value={item.value}
                  onChange={(e) => {
                    var newTarget = { ...target };
                    newTarget.widgetTokens[index]["value"] = e.target.value;
                    setTarget(newTarget);
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </div>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginTop: "auto" }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={resetForm}
          >
            Reset
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={saveChecker() || data}
            onClick={() => saveConfig()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
