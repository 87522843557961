import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { getGroupStructure } from "../../actions/automation";
import GroupSelect from "./GroupSelection";
import { useSelector } from "react-redux";
import _ from "lodash";

const styles = makeStyles((theme) => ({
  "@global": {
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  root: {
    marginBottom: theme.spacing(1),
  },
  div: {
    width: "100%",
    minHeight: theme.spacing(8.5),
  },
  grid: {
    height: "100%",
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  item: {
    height: "100%",
    maxHeight: "100%",
    width: "100%",
  },
  button: {
    width: "10%",
  },
  centered: {
    height: theme.spacing(15),
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    overflow: "auto",
    paddingRight: 8,
  },
}));

export default function GroupsForm({
  title,
  templates,
  callback,
  data,
  fullCustomer,
  deviceGroupOptions,
  dashboardGroupOptions,
  key,
}) {
  const classes = styles();
  const [customer, setCustomer] = React.useState("");
  const [folderId, setFolderId] = React.useState("");
  const [structure, setStructure] = React.useState("");
  const [structureData, setStructureData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const rootGroupId =
    useSelector(
      (state) => state.ui_settings.portal_config["root" + title + "Id"]
    ) || 0;

  const handleChange = (e) => {
    setCustomer(e.target.value);
    if (structure) setStructureData({ ...structureData, name: e.target.value });
  };

  useEffect(() => {
    /*if (data !== undefined) {
      if (title !== "Report") {
        setStructure(data.structure.name);
        setStructureData(data.structure.data);
      }
    }*/

    if (data?.customer) {
      setCustomer(data.customer);
    }

    /*if (data?.reseller) {
      setReseller(data.reseller);
    } else if (
      deviceGroupOptions?.reseller ||
      dashboardGroupOptions?.reseller
    ) {
      setReseller(
        deviceGroupOptions?.reseller || dashboardGroupOptions?.reseller
      );
    }

    if (data?.customer) {
      setCustomer(data.customer);
    } else if (
      deviceGroupOptions?.customer ||
      dashboardGroupOptions?.customer
    ) {
      setCustomer(
        deviceGroupOptions?.customer || dashboardGroupOptions?.customer
      );
    }*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  useEffect(() => {
    if (fullCustomer) setCustomer(fullCustomer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  const handleStructureChange = (val) => {
    if (!val) return setStructure("");
    setStructure(val.name);
    setLoading(true);
    getGroupStructure(title.toLowerCase(), val.name)
      .then((result) =>
        setStructureData({
          ...result,
          ...(customer && {
            id: result.id,
            name: customer,
            children: result.children,
          }),
        })
      )
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  const getStoredValue = () => ({
    ...(title !== "Report" && folderId),
    customer,
    type: title,
    ...(title !== "Report" && {
      structure: { name: structure, data: structureData },
    }),
  });

  const checkValues = () => {
    if (
      ((customer && title === "Report") ||
        (title !== "Report" && customer && !_.isEmpty(structure) && structureData)) &&
      data !== getStoredValue()
    ){
      return false;
    }
    else{
     return true
    }
  };

  const resetForm = () => {
    if (!fullCustomer) setCustomer("");
    setStructure("");
    setStructureData(null);
    //callback(null);
  };

  const saveConfig = () => callback(getStoredValue());

  return (
    <>
      <Typography variant="h4" className={classes.root}>
        {title} Groups
      </Typography>
      <Typography variant="body" className={classes.root}>
        Enter the customer name
        {title === "Report"
          ? "."
          : " and choose a grouping structure to provision."}
      </Typography>
      <div style={{ width: "100%", height: 10 }}>
        {loading && structure && <LinearProgress />}
      </div>
      <div className={classes.div}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.grid}
        >
          <Grid item lg={5}>
            <TextField
              variant="outlined"
              label="Name"
              onChange={handleChange}
              fullWidth
              disabled={fullCustomer}
              value={customer}
              size="small"
            />
            {title !== "Report" ? (
              <GroupSelect
                rootId={rootGroupId}
                endpoint={title}
                folderId={folderId}
                setFolderId={(id) => setFolderId(id)}
              />
            ) : null}
            {title !== "Report" ? (
              <Autocomplete
                options={templates}
                defaultValue={""}
                getOptionLabel={(option) => option.name || option}
                freeSolo
                fullWidth
                onChange={(_, v) => handleStructureChange(v)}
                renderInput={(params) => (
                  <TextField
                    key={params.id}
                    {...params}
                    defaultValue={structure}
                    value={structure}
                    label="Structure"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            ) : null}
          </Grid>

          {structure && structureData && title !== "Report" ? (
            <Grid
              item
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                overflow: "auto",
                minHeight: 0,
                maxHeight: 250,
                minWidth: 0,
                maxWidth: "50%",
              }}
            >
              <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpanded={[structureData.id]}
                defaultExpandIcon={<ChevronRight />}
                sx={{
                  height: 110,
                  maxWidth: 400,
                  maxHeight: 50,
                  overflowY: "auto",
                }}
              >
                {renderTree(structureData)}
              </TreeView>
            </Grid>
          ) : null}
        </Grid>
      </div>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginTop: "auto" }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={checkValues() && data}
            onClick={resetForm}
          >
            Reset
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={checkValues() || data}
            onClick={() => saveConfig()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
