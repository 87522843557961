import React, { Component } from "react";
import { connect } from "react-redux";
import { apiQuery } from "../libs/auth-config";
import ReportThemeDialog from "../components/dialogs/ReportThemeDialog";
import { Storage } from "aws-amplify";
import { getBranding, saveBranding } from "../actions/reports";
import { withTheme } from "@material-ui/styles";
import { PREFIX } from "../constants";
import CustomDatatable from "../components/CustomDatatable";
import DeleteIcon from "@material-ui/icons/Delete";
import { Tooltip, IconButton } from "@material-ui/core";
import ContainerHeader from "../components/ContainerHeader";

const columns = {
  report_name: { label: "Report Name", display: true, filter: false },
  report_template: {
    label: "Template",
    filter: false,
  },
  time_range: { label: "Time Range", display: true, filter: false },
  recipients: {
    label: "Recipients",
    filter: false,
  },
  scheduleReadable: {
    label: "Schedule",
    filter: false,
  },
  report_format: {
    label: "Format",
  },
};

const CustomToolbar = ({ openThemeCallback, deleteReports }) => (
  <>
    <div style={{ marginLeft: 10 }}>
      <Tooltip disableFocusListener title="Delete Report">
        <IconButton onClick={deleteReports} color="primary">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  </>
);

class ScheduleReportsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reports: [],
      selectedRow: [],
      dialogState: false,
      brandingLoading: false,
      branding: {
        primary: this.props.theme.palette.primary.main,
        secondary: this.props.theme.palette.secondary.main,
        img: `https://s3.eu-west-2.amazonaws.com/${PREFIX}.logic-one.io/brands/default`,
      },
    };
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.props.setParentTitle("Scheduled Reports");
    // this.getBranding();
    this.listReports();
  }

  // Try get colours from dynamo, then use ID to get logo from S3
  async getBranding() {
    getBranding(this.props.account_id)
      .then((data) => {
        Storage.get(data.account_id, { bucket: `${PREFIX}.logic-one.io` })
          .then((image) => this.setState({ branding: { ...data, img: image } }))
          .catch((e) => console.log(e));
      })
      .catch(() =>
        getBranding("default").then((data) => this.setState({ branding: data }))
      );
  }

  // Trigger mutation via appsync (if id is included it will update instead of create)
  // Then upload the image to S3 using dynamo ID
  async saveBranding(primary, secondary, img) {
    this.setState({ brandingLoading: true });
    saveBranding({
      account_id: this.props.account_id,
      name: this.props.name,
      primary: primary,
      secondary: secondary,
      ...(this.state.branding.id !== "default" && {
        id: this.state.branding.id,
      }),
    }).then((data) =>
      this.state.branding.img !== img
        ? Storage.put(data.account_id, img, {
            bucket: `${PREFIX}.logic-one.io`,
          })
            .then(() =>
              this.setState({
                branding: {
                  ...data,
                  img: URL.createObjectURL(img),
                },
                brandingLoading: false,
              })
            )
            .catch((e) => console.error(e))
        : this.setState({ brandingLoading: false })
    );
  }

  async listReports() {
    const { account_id } = this.props;
    this.setState({ loading: true });
    let reports = [];

    apiQuery("GET", "/reports/scheduled?account_id=" + account_id)
      .then((response) => {
        reports = response["data"];
      })
      .catch((e) => console.log(e))
      .finally(() =>
        this.setState({
          reports: reports,
          loading: false,
        })
      );
  }

  saveTheme = (theme) => {};

  deleteReports = async () => {
    //const { account_id } = this.props;

    apiQuery(
      "DELETE",
      "/reports/scheduled?account_id=" +
        this.state.selectedRow["account_id"] +
        "&report_name=" +
        this.state.selectedRow["report_name"]
    )
      .then((response) => {})
      .catch((e) => {})
      .finally(() => this.listReports());
  };

  render() {
    const { loading, reports, dialogState, branding, brandingLoading } =
      this.state;
    return (
      <>
        <ContainerHeader title={"Scheduled Reports"} />
        <ReportThemeDialog
          state={dialogState}
          branding={branding}
          callback={() => this.setState({ dialogState: false })}
          saveCallback={(pri, sec, img) => this.saveBranding(pri, sec, img)}
          loading={brandingLoading}
        />
        <CustomDatatable
          propMap={columns}
          rows={reports}
          loading={loading}
          customToolbar={
            <CustomToolbar
              openThemeCallback={() => this.setState({ dialogState: true })}
              deleteReports={this.deleteReports}
            />
          }
          selectColumns
          selectableRows={"single"}
          rowSelectionCallback={(selected) =>
            this.setState({ selectedRow: selected[0] })
          }
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { account_id } = state.userSession.properties;
  const { name } = state.deviceGroups.deviceGroupItems.parent;
  return { account_id, name };
}

export default connect(mapStateToProps)(withTheme(ScheduleReportsContainer));
