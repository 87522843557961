import _ from "lodash";

export default function roles(
  state = {
    roles: [],
    existingRoleNames: [],
  },
  action
) {
  switch (action.type) {
    case "FETCH_USER_PERMISSIONS": {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case "FETCH_ROLES": {
      return {
        ...state,
        roles: _.sortBy(action.payload, (r) => r.name),
        existingRoleNames: action.payload.map((r) => r.name),
      };
    }
    case "CREATE_ROLE": {
      return {
        ...state,
        roles: [...state.roles, action.payload],
        existingRoleNames: [...state.existingRoleNames, action.payload.name],
      };
    }
    case "UPDATE_ROLE": {
      return {
        ...state,
        roles: state.roles.map((role) => {
          return role.name === action.payload.name ? action.payload : role;
        }),
      };
    }
    case "DELETE_ROLE": {
      return {
        ...state,
        roles: state.roles.filter((role) => role.name !== action.payload.name),
        existingRoleNames: state.existingRoleNames.filter(
          (role) => role.name !== action.payload.name
        ),
      };
    }
    default:
      return state;
  }
}
