import React, { useEffect, useRef } from "react";

import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100%",
    height: "inherit",
    paddingRight: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

/*
  Params:
    bool: disabled - initial state of the switch
    refreshFunction: function - function to run every auto-refresh
    seconds: integer - How often to run refreshFunction
    receivedAt: epoch - Time of last refresh time
*/
export default function PageRefreshInput({
  disabled,
  refreshFunction,
  seconds,
  receivedAt,
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const checkedRef = useRef(checked);
  checkedRef.current = checked;

  useEffect(() => {
    if (disabled === true) {
      setChecked(false);
    } else setChecked(true);
    // setChecked(disabled ? !disabled : true)
    const timer = setInterval(
      () => {
        if (checkedRef.current === true) {
          if (refreshFunction) refreshFunction();
        }
      },
      seconds ? seconds * 1000 : 60 * 1000
    );
    return () => clearInterval(timer);
  }, [refreshFunction, seconds, disabled]);

  const handleChange = (name) => (event) => {
    setChecked(!checkedRef.current);
  };

  return (
    <div className={classes.root}>
      <Tooltip title="Auto Refresh">
        <Switch
          checked={checkedRef.current}
          onChange={handleChange()}
          value="checked"
          color="primary"
        />
      </Tooltip>
      {/*<Typography style={{ padding: "9px 0px" }} variant="body2">
          {receivedAt && "(Last updated: " + epochToTimestamp(receivedAt) + ")"}
        </Typography>*/}
    </div>
  );
}
