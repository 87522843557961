import convertArrayToObject from "../libs/utils";
import _ from "lodash";

export function ui_settings(
  state = {
    devices_search_table: { rows: 10, page: 0 },
    alerts_search_table: { rows: 10, page: 0 },
    roles_search_table: { rows: 10, page: 0 },
    users_search_table: { rows: 10, page: 0 },
    accountaccess_search_table: { rows: 10, page: 0 },
    maintenance_search_table: { rows: 10, page: 0 },
    reports_search_table: { rows: 10, page: 0 },
    auditlogs_search_table: { rows: 10, page: 0 },
    graph_search_table: { rows: 10, page: 0 },
    portal_config: {},
    view_permissions: {},
    user_views: {},
  },
  action
) {
  if (action.type.includes("_SEARCH_TABLE_PAGE_CHANGE")) {
    let tableName = action.type.split("_")[0].toLowerCase();
    return {
      ...state,
      [`${tableName}_search_table`]: {
        ...state[`${tableName}_search_table`],
        page: action.payload.page,
      },
    };
  }
  if (action.type.includes("_SEARCH_TABLE_ROWS_CHANGE")) {
    let tableName = action.type.split("_")[0].toLowerCase();
    return {
      ...state,
      [`${tableName}_search_table`]: {
        ...state[`${tableName}_search_table`],
        rows: action.payload.rowsPerPage,
      },
    };
  }

  switch (action.type) {
    case "PORTAL_CONFIGURATION":
      return {
        ...state,
        portal_config: action.payload,
      };
    case "FILTER_UPDATE":
      return {
        ...state,
        user_views: {
          ...state.user_views,
          deviceFilter: action.payload.filter,
        },
      };
    case "ALERTS_FILTER_UPDATE": {
      return {
        ...state,
        user_views: {
          ...state.user_views,
          alertsFilter: action.payload.filter,
        },
      };
    }
    case "VIEW_ROLES_RECEIVE":
      return {
        ...state,
        view_permissions: storePermissionsByComponentName(
          action.payload.data.listViewRoles
        ),
      };
    case "USER_SETTINGS_RECEIVE": {
      const default_view = action.meta.default_view,
        view = action.payload;
      let sv =
        view.cognito_user_id !== "default"
          ? JSON.parse(view.selected_views)
          : {
              DevicesTable: "All Services",
              AlertsTable: "default",
            };
      return {
        ...state,
        user_id: view.id,
        user_views: {
          defaultView: default_view,
          byViewName: storeViewByViewName(view.saved_views),
          byComponent: view.saved_views,
          deviceFilter: storeDefaultFilter(
            view.saved_views,
            sv.DevicesTable,
            "DevicesTable"
          ),
          alertsFilter: storeDefaultFilter(
            view.saved_views,
            sv.AlertsTable,
            "AlertsTable"
          ),
          selectedViews: sv,
        },
        saved_dashboard_settings: JSON.parse(view.dashboard_settings),
      };
    }
    case "USER_SETTINGS_UPDATE":
      return {
        ...state,
        user_settings: {
          byComponent: storePrefsByComponetName({
            items: [action.payload.data.updateUserViewSettings],
          }),
        },
      };
    case "USER_SETTINGS_CREATE":
      return {
        ...state,
        user_settings: {
          byComponent: storePrefsByComponetName({
            items: [action.payload.data.createUserViewSettings],
          }),
        },
      };
    case "USER_VIEW_UPDATED": {
      if (action.payload.table === "AlertsTable") {
        action.payload.name = "custom";
      }
      let newState = _.clone(state);
      newState.user_views.selectedViews = {
        ...newState.user_views.selectedViews,
        [action.payload.table]: action.payload.name,
      };
      return newState;
    }
    case "UPDATE_DASHBOARD_PREFERENCES": {
      return { ...state, dashboard_settings: action.payload.data };
    }
    case "SAVE_DASHBOARD_PREFERENCES": {
      return {
        ...state,
        saved_dashboard_settings: action.payload.data,
        user_id: action.payload.user_id,
      };
    }
    default:
      return state;
  }
}

function storeViewByViewName(user_views) {
  let by_view_name = {};
  // Extract info and store by name
  Object.keys(user_views).forEach((table) => {
    user_views[table].forEach((view) => {
      by_view_name[view.name] = {
        filters: view.filters,
        columns: view.columns,
        default: view.is_default,
      };
    });
  });
  return by_view_name;
}

function storeDefaultFilter(user_views, default_view, table) {
  var filter = [];
  try {
    user_views[table].forEach((view) => {
      if (view.name === default_view) {
        filter = view.filters;
      }
    });
  } catch (e) {
    filter = [];
  }

  return filter;
}

function storePermissionsByComponentName(roles) {
  return convertArrayToObject(roles.items, "Component");
}

function storePrefsByComponetName(settings) {
  return convertArrayToObject(settings.items, "component");
}
