import React from "react";
import { Paper } from "@material-ui/core";
import { apiQuery } from "../libs/auth-config";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import SnmpInterfaceTable from "../components/SnmpInterfaceTable";
import SnmpInformationFields from "../components/SnmpInformationFields";
import SnmpwalkTool from "../components/SnmpwalkTool";

const styles = createStyles((theme) => ({
  root: {
    "& .MuiTableContainer-root": {
      width: "100%",
    },
  },
  diagnosticsContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    flexWrap: "wrap",
    gap: "10px",
  },
  interfaceTableWrapper: {
    marginTop: "0px",
    paddingTop: "0px",
    flexBasis: "73%",
    flexGrow: "1",
  },
  refreshButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    height: "max-content",
    paddingBottom: "0px",
  },
  snmpDataWrapper: {
    flex: 1,
    minWidth: "350px",
  },
  snmpDataPaper: {
    margin: "0px",
    paddingTop: "15px",
    height: "max-content",
    marginBottom: "15px",
  },
  displayContainer: {
    marginLeft: "10px",
    marginRight: "10px",
    width: "100%",
  },
  snmpDiagnosticsButton: {
    paddingLeft: "8px",
    paddingRight: "5px",
    width: "100%",
    maxWidth: "215px",
    height: "40px",
    padding: "0px",
  },
  snmpTableCell: {
    width: "20%",
    maxWidth: "20%",
    whiteSpace: "normal",
    wordWrap: "break-word",
    textAlign: "left",
  },
  snmpTableHeader: {
    marginTop: "0px",
    width: "100%",
    marginBottom: "15px",
    borderRadius: "4px",
  },
  snmpTableHeaderCell: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "left",
    height: "50px",
    padding: "0px",
    paddingLeft: "14px",
  },
  deviceInfoContainer: {
    display: "flex",
    maxWidth: "100%",
    justifyContent: "center",
  },
  snmpWalkBox: {},
  deviceDescriptionTitleWrapper: {
    marginLeft: "5px",
    marginRight: "5px",
    textAlign: "center",
    minWidth: "200px",
  },
  snmpDescriptionWrapper: {
    display: "flex",
    maxWidth: "250px",
    margin: "auto",
    textAlign: "center",
  },
  snmpDetailsWrapper: {
    marginTop: "5px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    gap: "50px",
  },
  snmpInfoPaper: {
    display: "flex",
    padding: "48px",
    borderRadius: "4px",
    minWidth: "356px",
  },
  columnButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
  snmpDetailsContainer: {
    borderBottom: "none",
    padding: "3px",
    textAlign: "center",
    overflowWrap: "break-word",
    maxWidth: "200px",
  },
  interfaceTableErrorContainer: {
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "inline-block",
    textAlign: "center",
  },
}));

class SNMPStatusContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snmpStatus: "...",
      snmpUptime: "...",
      lastPoll: "...",
      snmpData: {},
      interfaceData: [],
      advancedDiagnosticsWindow: false,
      defaultSnmpVersion: "V2C",
    };
  }

  forceToUpdate = () => {
    this.forceUpdate();
  };

  setCurrentTime = () => {
    var lastPoll = new Date().toLocaleString();
    lastPoll = lastPoll.substring(lastPoll.length - 8);
    this.setState({ lastPoll: lastPoll });
  };

  refreshButton = () => {
    this.setState({ snmpNotConnected: false });
    this.props.setInterfaceData([]);
    this.fetchInterfaces();
    this.props.setSnmpData({});
    this.pollSnmpData();
  };

  pollSnmpData = () => {
    this.props.setUnableToFetchSNMPInfo(false);
    this.props.setSnmpData({});
    apiQuery("GET", "/devices/snmp/data", {
      dnsName: this.props.device["DNS Name"],
      account_id: this.props.accountId,
    })
      .then((res) => {
        // internal status codes dont trigger catch
        if (res.status_code !== 200 || !res.data) {
          this.props.setUnableToFetchSNMPInfo(true);
        }
        if (res.data["SNMP Version"] !== undefined) {
          this.setState({
            defaultSnmpVersion: res.data["SNMP Version"],
          });
        }
        return this.props.setSnmpData(res.data);
      })
      .then((newState) => {
        this.setState({ snmpStatus: "Connected" });
        this.setCurrentTime();
        this.forceUpdate();
      })
      .catch((err) => {
        this.props.setUnableToFetchSNMPInfo(true);
      });
  };

  fetchInterfaces = () => {
    // Clear the old data.
    this.props.setUnableToFetchSNMPInterfaces(false);
    this.setState({ interfaceData: [] });
    apiQuery("GET", "/devices/snmp/interface-data", {
      dnsName: this.props.device["DNS Name"],
      account_id: this.props.accountId,
    })
      .then((res) => {
        // internal status codes dont trigger catch
        if (res.status_code !== 200 || !res.data.length) {
          this.props.setUnableToFetchSNMPInterfaces(true);
        }
        return this.props.setInterfaceData(res.data);
      })
      .then(() => {
        this.setState({ snmpStatus: "Connected" });
      })
      .catch((err) => {
        this.props.setUnableToFetchSNMPInterfaces(true);
      });
  };

  componentDidMount() {
    // Parent already has data stored.
    if (
      !Object.keys(this.props.snmpData).length &&
      !this.props.interfaceData.length
    ) {
      this.fetchInterfaces();
      this.pollSnmpData();
    }
  }

  toggleAdvancedDiagnosticsWindow = () => {
    this.setState({
      advancedDiagnosticsWindow: !this.state.advancedDiagnosticsWindow,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div
          className={`${classes.diagnosticsContentContainer} colReverseSmallScreens`}
        >
          <div
            className={`${classes.interfaceTableWrapper} marginTopSmallScreens`}
          >
            <SnmpInterfaceTable
              interfaces={this.props.interfaceData}
              errorFetchingData={this.props.unableToFetchSNMPInterfaces}
              classes={classes}
            />
          </div>
          <div
            className="snmpContainer"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <Paper
              className={`snmpInfoPaper ${classes.snmpInfoPaper}`}
              elevation={2}
            >
              <SnmpInformationFields
                openAdvancedDiagnosticsWindow={
                  this.toggleAdvancedDiagnosticsWindow
                }
                errorFetchingData={this.props.unableToFetchSNMPInfo}
                refresh={this.refreshButton}
                classes={classes}
                data={this.props.snmpData}
              />
            </Paper>
            {this.state.advancedDiagnosticsWindow ? (
              <SnmpwalkTool
                defaultSnmpVersion={this.state.defaultSnmpVersion}
                classes={classes}
                device={this.props.device}
                accountId={this.props.accountId}
                onCloseFunction={this.toggleAdvancedDiagnosticsWindow}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}

const enhance = compose(withStyles(styles), connect());
export default enhance(SNMPStatusContainer);
