// Copied from aws-exports so we have a version we can dynamically update.
import { APPSYNC_URL } from "./constants";

const awsmobile = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "eu-west-2",
    identityPoolRegion: "eu-west-2",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "Automation",
        endpoint: process.env.REACT_APP_GATEWAY_URL,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: "logic-one",
      region: "eu-west-2",
      customPrefix: {
        public: "brands/",
      },
    },
  },
  aws_appsync_graphqlEndpoint: APPSYNC_URL,
  aws_appsync_region: "eu-west-2",
  aws_appsync_authenticationType: "AWS_LAMBDA",
  aws_cognito_region: "eu-west-2",
};
// const awsmobile = {
//     "aws_project_region": "eu-west-2",
//     "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITY_POOL_ID, // "eu-west-2:e4e936ab-5d89-4115-a581-578604b3fce8",
//     "aws_cognito_region": "eu-west-2",
//     "aws_user_pools_id": process.env.REACT_APP_USER_POOL_ID, //  "eu-west-2_DX5rbmOaO",
//     "aws_user_pools_web_client_id": process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, // "7a4852geom2e0pbvbm9u364luo",
//     "oauth": {},
//     "aws_appsync_graphqlEndpoint": "https://3rbgcy277zatzonup3vtop2zea.appsync-api.eu-west-2.amazonaws.com/graphql",
//     "aws_appsync_region": "eu-west-2",
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
//     // 'aws_appsync_authenticationType': 'API_KEY',
//     // "aws_appsync_apiKey": "da2-7nilzqqno5dfnaig5kb2nvdnye"
//
// };

export default awsmobile;
