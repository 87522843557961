import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { apiQuery } from "../../libs/auth-config";
import { CircularProgress, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Delete } from "@material-ui/icons";
import _ from "lodash";

const styles = makeStyles((theme) => ({
  "@global": {
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
      {
        backgroundColor: "white",
      },
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  grid: {
    height: "100%",
    width: "100%",
  },
  textField: {
    width: "100%",
    flexBases: "100%",
  },
  div: {
    width: "100%",
  },
}));

export default function GroupSelect({
  rootId,
  endpoint,
  folderId,
  setFolderId,
}) {
  const classes = styles();
  const [groups, setGroups] = React.useState([
    { id: rootId, results: [], selection: { id: rootId } },
  ]);
  //const [folderId, setFolderId] = React.useState(rootId);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setFolderId(rootId);

    async function populateGroups(groupId) {
      // You can await here
      return await getSingleDeviceGroup(groupId);
      // ...
    }
    var currentGroups = [...groups];
    populateGroups(currentGroups[0]["id"]).then((data) => {
      currentGroups[0]["results"] = data;
      setGroups(currentGroups);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSingleDeviceGroup = async (group_id) => {
    setLoading(true);
    const data = {
      group_id: group_id,
      type: endpoint
    };
    const response = await apiQuery("GET", "/automation/groups", data);
    setLoading(false);
    return response.data;
  };

  const addGroup = () => {
    var item = { selection: {}, id: folderId, results: [] };
    setGroups([...groups, item]);
    async function getGroups(groupId) {
      return await getSingleDeviceGroup(groupId);
    }

    getGroups(folderId).then((data) => {
      item["results"] = data["subGroups"];
      setGroups([...groups, item]);
    });
  };

  const removeGroup = () => {
    var currentGroups = [...groups];
    currentGroups = currentGroups.slice(0, -1);
    setFolderId(currentGroups[currentGroups.length - 1]["selection"]["id"]);
    setGroups(currentGroups);
  };

  const checkDisabled = (item, index) => {
    if (index === 0 && !_.isEmpty(item["results"])) {
      return false;
    }
    if (
      item["selection"]["numOfDirectSubGroups"] !== undefined &&
      item["selection"]["numOfDirectSubGroups"] !== 0 &&
      !_.isEmpty(item["selection"])
    ) {
      return false;
    }
    return true;
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={1}>
      {groups.map((item, index) => (
        <Grid item className={classes.textField}>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ flexWrap: "nowrap" }}
          >
            <Grid item style={{ flexBasis: "100%", justifyContent: "center" }}>
              {index === 0 && endpoint !== "Dashboard Single" ? (
                <TextField
                  label={"Group: " + (index + 1)}
                  value={item["results"]["name"] || "Loading..."}
                  disabled
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        {loading && index + 1 === groups.length ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                      </React.Fragment>
                    ),
                  }}
                />
              ) : (
                <Autocomplete
                  options={item["results"]}
                  getOptionLabel={(option) => option.name || option}
                  defaultValue={""}
                  value={item["selection"]}
                  disabled={false}
                  onChange={(_, v) => {
                    var currentGroups = [...groups];
                    currentGroups[index]["selection"] = v;
                    setGroups(currentGroups);
                    setFolderId(v["id"]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      key={params.id}
                      {...params}
                      label={"Group: " + (index + 1)}
                      variant="outlined"
                      size="small"
                      style={{ margin: "0 auto" }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading && index + 1 === groups.length ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            {index + 1 === groups.length &&
              index !== 0 &&
              endpoint !== "Dashboard Single" && (
                <Grid item>
                  <IconButton
                    id={"deleteGroup" + index}
                    onClick={() => removeGroup()}
                    color="primary"
                    style={{ margin: "0 auto", marginRight: "-50px" }}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                </Grid>
              )}
          </Grid>
          {index + 1 === groups.length && endpoint !== "Dashboard Single" && (
            <Grid item style={{ textAlign: "center" }}>
              <IconButton
                id={"addGroup" + index}
                disabled={checkDisabled(item, index)}
                onClick={() => {
                  addGroup();
                }}
                color="primary"
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}{" "}
    </Grid>
  );
}
