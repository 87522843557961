import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  alpha,
  Button,
  Card,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
//import AutomationHistoryTable from "./AutomationHistoryTable";
import {
  getGroupTemplates,
  getDashboardTemplates,
} from "../actions/automation";
import GroupsForm from "../components/forms/A_Groups";
import DashboardForm from "../components/forms/A_Dashboards";
import RolesForm from "../components/forms/A_Roles";
import UserForm from "../components/forms/A_Users";
import AutomationSummary from "../components/AutomationSummary";
import AutomationExecution from "../components/AutomationExecution";

import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import FullCustomerForm from "../components/forms/A_FullCustomer";
import { apiQuery } from "../libs/auth-config";
// Info about what each job needs has to go in.
export const jobsList = [
  "Device Groups",
  "Dashboard Groups",
  "Website Groups",
  "Report Groups",
  "Dashboards",
  "Users",
];

const steps = ["Selection", "Confirmation", "Execution"];

const styles = createStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
    "& .Mui-checked": { color: "#17c400" },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#17c400",
      "&:hover": {
        backgroundColor: alpha("#17c400", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#17c400",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: 7,
    },
    marginBottom: theme.spacing(1),
  },
  card: {
    width: "100%",
    minHeight: theme.spacing(25),
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    margin: "auto",
    "&$selected": {
      backgroundColor: "red",
    },
  },
  title: {
    paddingTop: theme.spacing(0.5),
  },
}));

class AutomationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      selectedJob: "",
      jobData: {},
      fullCustomer: false,
      dialogState: false,
      csvFile: null,
      customer: "",
      roles: [],
      dashboardGroupList: [],
      deviceGroupList: [],
    };
  }

  componentDidMount() {
    this.props.setParentTitle("Automation Jobs");
    this.retrieveTemplates();
    apiQuery("GET", "/automation/roles").then((res) => {
      this.setState({ roles: res.data });
    });
    apiQuery("GET", "/automation/dashboard-groups").then((res) => {
      this.setState({
        dashboardGroupList: res.data.map((group) => group.name),
      });
    });
    apiQuery("GET", "/automation/device-groups").then((res) => {
      this.setState({ deviceGroupList: res.data.map((group) => group.name) });
    });
  }

  retrieveTemplates() {
    this.props.getGroupTemplates("dashboard");
    this.props.getGroupTemplates("device");
    //this.props.getGroupTemplates("website");
    this.props.getDashboardTemplates();
  }

  findOutstandingJob = () => {
    for (let i = 0; i < jobsList.length; i++) {
      if (
        this.state.jobData[jobsList[i]] === undefined &&
        this.state.selectedJob !== jobsList[i]
      )
        return jobsList[i];
    }
    return this.state.selectedJob;
  };

  saveConfig = (data, callback) => {
    this.setState(
      {
        ...this.state,
        ...(this.state.fullCustomer && {
          selectedJob: this.findOutstandingJob(),
          customer: data.customer,
        }),
        jobData: {
          ...this.state.jobData,
          [this.state.selectedJob]: data,
        },
      },
      () => {
        /* 
         Need callback for when we are saving the CSV file
         otherwise the config save will get overwritten with the 
         old state.
        */
        if (callback) callback();
      }
    );
  };

  /*
    If user uploads a CSV, we want to save the name of the CSV
    so that it can be shown on the confirmation page.
  */
  saveFileName = (csvFile) => {
    this.setState({ ...this.state, csvFile });
  };

  checkListState = () => {
    if (Object.keys(this.state.jobData).length && !this.state.fullCustomer)
      return false;
    else if (
      Object.keys(this.state.jobData).length === Object.keys(jobsList).length &&
      this.state.fullCustomer
    )
      return false;
    else return true;
  };

  resetSteps = () => {
    this.setState({
      step: 0,
      jobData: {},
      selectedJob: "",
      fullCustomer: false,
    });
  };

  handleContinue = () => this.setState({ step: this.state.step + 1 });

  handleBack = () => this.setState({ step: this.state.step - 1 });

  handleSwitchChange = (e) =>
    this.setState({
      fullCustomer: e.target.checked,
      ...(!e.target.checked && { customer: "" }),
    });

  updateJobForm = (job) => this.setState({ selectedJob: job });

  updateFullCustomer = (val) =>
    this.setState({ customer: val, selectedJob: this.findOutstandingJob() });

  clearData = (job) =>
    this.setState({
      ...(delete this.state.jobData[job] && this.state.jobData),
    });

  render() {
    const { classes, device, dashboard } = this.props;
    const {
      step,
      jobData,
      selectedJob,
      fullCustomer,
      customer,
      roles,
      deviceGroupList,
      dashboardGroupList,
    } = this.state;

    return (
      <React.Fragment>
        <Stepper
          activeStep={step}
          style={{ width: "100%", backgroundColor: "transparent" }}
        >
          {steps.map((title) => {
            return (
              <Step key={title}>
                <StepLabel>{title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {!step ? (
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            className={classes.root}
          >
            <Grid item xl={4} lg={4} md={4}>
              <Card
                elevation={1}
                className={classes.card}
                style={{ paddingTop: 0 }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.title}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      style={{
                        display: "flex",
                        marginTop: 10,
                        marginLeft: 5,
                      }}
                    >
                      Job List
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Full Customer" placement="top">
                      <div>
                        <Switch onChange={this.handleSwitchChange} />
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
                <List dense>
                  {jobsList.map((job, i) => {
                    return (
                      <>
                        <Divider />
                        <ListItem
                          key={job}
                          button
                          className={classes.listItem}
                          disabled={fullCustomer && !customer}
                          selected={this.state.selectedJob === job}
                          onClick={(e) => this.updateJobForm(job)}
                        >
                          <ListItemText primary={job} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              disabled
                              edge="end"
                              checked={jobData[job] !== undefined}
                              style={fullCustomer ? { paddingRight: 18 } : null}
                              icon={
                                fullCustomer ? (
                                  <Typography>{i + 1}</Typography>
                                ) : (
                                  <CheckBoxOutlineBlank />
                                )
                              }
                              checkedIcon={
                                fullCustomer ? (
                                  <Typography style={{ color: "#17c400" }}>
                                    {i + 1}
                                  </Typography>
                                ) : (
                                  <CheckBox />
                                )
                              }
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      </>
                    );
                  })}
                  <Divider />
                </List>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: 10 }}
                  disabled={this.checkListState()}
                  onClick={this.handleContinue}
                >
                  Continue
                </Button>
              </Card>
            </Grid>
            <Grid item lg={8} xl={8} md={8}>
              <Card className={classes.card} elevation={1}>
                {fullCustomer && !customer ? (
                  <FullCustomerForm
                    callback={(val) => this.updateFullCustomer(val)}
                  />
                ) : selectedJob.includes("Groups") ? (
                  <GroupsForm
                    title={selectedJob.replace(" Groups", "")}
                    templates={device.groups}
                    callback={(data) => this.saveConfig(data)}
                    data={jobData[selectedJob]}
                    key={selectedJob}
                    deviceGroupOptions={jobData["Device Groups"]}
                    dashboardGroupOptions={jobData["Dashboard Groups"]}
                    fullCustomer={customer}
                  />
                ) : selectedJob === "Dashboards" ? (
                  <DashboardForm
                    title={"Dashboard"}
                    templates={dashboard.dashboards}
                    callback={(data) => this.saveConfig(data)}
                    clearData={() => this.clearData("Dashboards")}
                    data={jobData[selectedJob]}
                    deviceGroupOptions={jobData["Device Groups"]}
                    dashboardGroupOptions={jobData["Dashboard Groups"]}
                    fullCustomer={customer}
                  />
                ) : selectedJob === "Users" ? (
                  <UserForm
                    templates={[]}
                    callback={(data) => this.saveConfig([...data])}
                    data={jobData[selectedJob]}
                    saveFileName={this.saveFileName}
                    fullCustomer={customer}
                    roles={roles}
                  />
                ) : selectedJob === "Roles" ? (
                  <RolesForm
                    saveConfig={(data, callback) =>
                      this.saveConfig(data, callback)
                    }
                    data={jobData[selectedJob]}
                    deviceGroupOptions={jobData["Device Groups"]}
                    dashboardGroupOptions={jobData["Dashboard Groups"]}
                    fullCustomer={customer}
                    deviceGroupList={deviceGroupList}
                    dashboardGroupList={dashboardGroupList}
                  />
                ) : (
                  <Typography variant="h3" style={{ margin: "auto" }}>
                    Please select a job from the list.
                  </Typography>
                )}
              </Card>
            </Grid>
          </Grid>
        ) : step === 1 ? (
          <AutomationSummary
            jobs={jobData}
            backCallback={() => this.handleBack()}
            continueCallback={() => this.handleContinue()}
            csv={this.state.csvFile}
          />
        ) : step === 2 ? (
          <AutomationExecution
            jobs={jobData}
            callback={() => this.resetSteps()}
          />
        ) : null}
        {/*<AutomationHistoryTable />*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { device, dashboard } = state.automation.templates;
  return { device, dashboard };
};

const mapDispatchToProps = {
  getGroupTemplates,
  getDashboardTemplates,
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AutomationContainer);
