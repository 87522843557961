import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import AlertsIcon from "../AlertsIcon";

const styles = (theme) => ({
  root: {
    // padding: theme.spacing(3, 2),
    height: 90,
    overflow: "hidden",
    padding: theme.spacing(2),
    color: "white",
    cursor: "pointer",
  },
  paper: {
    padding: theme.spacing(2),
  },
  Critical: {
    backgroundColor: "red",
  },
  confirmedWarnAlert: {
    backgroundColor: "blue",
  },
  Warning: {
    backgroundColor: "orange",
  },
  confirmedErrorAlert: {
    backgroundColor: "yellow",
  },
  confirmedCriticalAlert: {
    backgroundColor: "blue",
  },
  noAlert: {
    backgroundColor: "green",
  },
  iconbar: {
    width: "fit-content",
  },
});

class WanViewWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group_selected: false,
    };
  }

  componentDidMount() {}

  compare(a, b) {
    // console.log(a)
    // console.log(b)
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  clickDeviceGroup = (id) => {
    // this.setState({group_selected: true})
    // this.props.dispatch(initRequestDeviceGroup(id))
    console.log("clicky for " + id);
    this.loadDashboard(id);
  };

  orderTiles(tiles) {
    let criticalGroups = [],
      warnGroups = [],
      ackWarnGroups = [],
      alertGroups = [],
      allGroups = [];
    tiles.map((tile, index) => {
      if (tile.criticalAlert === 1) {
        tile.alertStatus = "Critical";
        criticalGroups.push(tile);
      } else if (tile.warnAlert === 1) {
        tile.alertStatus = "Warning";
        warnGroups.push(tile);
        // } else if (deviceGroup.confirmedWarnAlert) {
        //   deviceGroup.alertStatus = 'noAlert'
        //   ackWarnGroups.push(deviceGroup)
      } else {
        tile.alertStatus = "noAlert";
        alertGroups.push(tile);
      }
      //   // console.log(deviceGroup)
      // return null
      //   // @todo add code to actually sort the groups (or do it api side?)
      return null;
    });
    criticalGroups.sort(this.compare);
    // console.log(criticalGroups)
    warnGroups.sort(this.compare);
    // console.log(warnGroups)
    alertGroups.sort(this.compare);
    // console.log(alertGroups)
    allGroups = ackWarnGroups.concat(
      criticalGroups,
      ackWarnGroups,
      warnGroups,
      alertGroups
    );
    // console.log(allGroups)

    return allGroups;
  }

  render() {
    var { widgets, classes, idMappings } = this.props;
    var widgetId;
    Object.keys(widgets).forEach((id) => {
      if (widgets[id].type === "deviceNOC") {
        widgetId = id;
      }
    });
    if (widgetId && widgets[widgetId]) {
      console.log(widgets[widgetId]);
      var orderedTiles = this.orderTiles(widgets[widgetId].items);
      return (
        <div>
          <Grid container spacing={1}>
            {orderedTiles.map((widget, index) => {
              var status = widget.alertStatus || "noAlert";
              var deviceId = idMappings[widget.entityId];
              return (
                <Grid key={widget.entityId} item xs={3} lg={2} xl={2}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={deviceId ? "/device/" + deviceId : "/devices"}
                  >
                    <Paper
                      onClick={() => this.props.handleClick(widget.id)}
                      className={`${classes.root} ${classes[status]}`}
                    >
                      <Typography variant="body1" noWrap={true}>
                        <AlertsIcon status={status} /> {widget.name}
                      </Typography>
                    </Paper>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect()(withStyles(styles)(WanViewWidget));
