import React, { cloneElement } from "react";
import { Route } from "react-router-dom";
// import { RbacContext } from "./LoadingContainer";

export const PrivateRoute = ({
  children,
  component: Component,
  keys,
  ...rest
}) => {
  // return (
  //   <RbacContext.Consumer>
  //     {(perms) => {
  //       let end_node = perms;
  //       keys.forEach((key) => (end_node = end_node[key]));
  return (
    <Route
      {...rest}
      render={
        (props) => cloneElement(children, props)
        // end_node.view ? (

        // ) : (
        //   <Redirect to="/" />
        // )
      }
    />
  );
  //   }}
  // </RbacContext.Consumer>
  // );
};
