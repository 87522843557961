import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import UpdateAccounts from "../components/dialogs/UpdateAccounts";
import { CAPABILITY_LEVELS, PREFIX } from "../constants";
import { apiQuery } from "../libs/auth-config";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withStyles, createStyles } from "@material-ui/core/styles";
import AddAccountIds from "../components/dialogs/AddAccountIds";
import CustomDatatable from "../components/CustomDatatable";
import ContainerHeader from "../components/ContainerHeader";
// import { RbacContext } from "./LoadingContainer";

const drawerWidth = 240;

const columns = {
  account_id: { label: "Account ID", display: true, filter: false },
  name: { label: "Name", display: true, filter: false },
  devs: { label: "Device Count", display: true, filter: false },
  status: { label: "Status", display: true, filter: false },
};

const useStyles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      overflow: "auto",
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  });

class AccountAccessContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      activeSelected: [],
      inactiveSelected: [],
      noIds: [],
      idsToUpdate: [],
      updateDialog: false,
      percentage: 0,
      accIdsLoading: false,
      accIdDialog: false,
      updateLoading: false,
    };
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.props.setParentTitle("Account Access");
    this.setState({ loading: true, data: [] });
    this.getL1Accounts();
  }

  async getL1Accounts() {
    apiQuery("GET", "/accounts")
      .then((res) =>
        this.setState({
          ...(res.status_code === 200 && { data: res.data }),
          loading: false,
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  dialogHandler() {
    if (this.state.noIds.length) this.setState({ accIdDialog: true });
    else this.setState({ updateDialog: true });
  }

  async updateAccountIds(idsToUpdate) {
    this.setState({ accIdsLoading: true });
    let progress = idsToUpdate.length;
    let toUpdate = idsToUpdate;
    let tmp = this.state.inactiveSelected;
    for (let i = 0; i < toUpdate.length; i++) {
      if (toUpdate[i].accId) {
        let data = {
          id: toUpdate[i].id,
          account_id: toUpdate[i].accId,
          name: toUpdate[i].name,
        };
        await apiQuery("POST", "/accountId", data)
          .then((res) => {
            if (res.status_code === 200) {
              for (let j = 0; j < this.state.inactiveSelected.length; j++) {
                if (this.state.inactiveSelected[j].id === toUpdate[i].id) {
                  tmp[j].account_id = toUpdate[i].accId;
                  break;
                }
              }
            }
          })
          .catch((e) => console.log(e));
        progress -= 1;
        if (!progress) {
          this.setState({
            inactiveSelected: tmp,
            accIdsLoading: false,
            idsToUpdate: [],
            accIdDialog: false,
            updateDialog: true,
          });
        }
      }
    }
  }

  async updateL1Accounts() {
    this.setState({ updateLoading: true });

    var dataToSendToAPI = [];
    var success = [];
    var error = [];
    var singularUpdateData;

    for (let i = 0; i < this.state.inactiveSelected.length; i++) {
      singularUpdateData = {
        name: this.state.inactiveSelected[i].name,
        account_id: this.state.inactiveSelected[i].account_id,
      };
      if (this.state.inactiveSelected[i].accountCreated) {
        singularUpdateData["updateData"] = {
          account_id: this.state.inactiveSelected[i].account_id,
          is_enabled: 1,
        };
      } else {
        singularUpdateData["updateData"] = "";
      }
      dataToSendToAPI.push(singularUpdateData);
    }

    for (let i = 0; i < this.state.activeSelected.length; i++) {
      singularUpdateData = {
        account: PREFIX,
        name: this.state.activeSelected[i].name,
        account_id: this.state.activeSelected[i].account_id,
      };
      if (this.state.activeSelected[i].accountCreated) {
        singularUpdateData["updateData"] = {
          account_id: this.state.activeSelected[i].account_id,
          is_enabled: 0,
        };
      }
      dataToSendToAPI.push(singularUpdateData);
    }
    var counter = 0;
    for (let i = 0; i < dataToSendToAPI.length; i++) {
      counter += 1;
      await apiQuery("PUT", "/accounts", dataToSendToAPI[i])
        .then((response) => {
          try {
            if (
              response === "Key updated successfully" ||
              response.error === 200
            ) {
              success.push(dataToSendToAPI[i]);
            } else {
              error.push(dataToSendToAPI[i]);
            }
          } catch {
            error.push(dataToSendToAPI[i]);
          }
        })
        .catch((e) => console.log("Unexpected Error: \n" + e))
        .finally(() => {});
      if (counter === dataToSendToAPI.length) {
        this.setState({
          updateDialog: false,
          updateLoading: false,
          data: [],
          loading: true,
        });
        this.getL1Accounts();
      }
    }
    this.setState({ updateDialog: false });
  }

  render() {
    const hasManagePermissions =
      this.props.permissions.admin["accountAccess"] <=
      CAPABILITY_LEVELS["manage"];

    return (
      <>
        <ContainerHeader title={"Account Access"} />
        {/* <RbacContext.Consumer>
          {(perms) => ( */}
        <CustomDatatable
          viewComponent={"AccountAccessTable"}
          customPage
          propMap={columns}
          rows={this.state.data}
          loading={this.state.loading}
          selectableRows={hasManagePermissions && "multiple"}
          rowSelectionCallback={(rows) => {
            let active = [],
              inactive = [],
              noIds = [];
            rows.forEach((row) => {
              if (!row.account_id) noIds.push(row);
              if (row.status === "active") active.push(row);
              else inactive.push(row);
            });
            this.setState({
              activeSelected: active,
              inactiveSelected: inactive,
              noIds: noIds,
            });
          }}
        />
        {/* )}
        </RbacContext.Consumer> */}
        {(this.state.activeSelected.length ||
          this.state.inactiveSelected.length) &&
        !this.state.loading &&
        hasManagePermissions ? (
          <Button
            style={{ marginTop: "10px" }}
            color="primary"
            variant="contained"
            onClick={() => this.dialogHandler()}
          >
            Update
          </Button>
        ) : null}
        {hasManagePermissions && (
          <UpdateAccounts
            open={this.state.updateDialog}
            circleState={this.state.updateLoading}
            active={this.state.activeSelected}
            inactive={this.state.inactiveSelected}
            callBack={() => this.setState({ updateDialog: false })}
            startUpdate={() => this.updateL1Accounts()}
          />
        )}
        {hasManagePermissions && (
          <AddAccountIds
            open={this.state.accIdDialog}
            circleState={this.state.accIdsLoading}
            accounts={this.state.noIds}
            startUpdate={(idsToUpdate) => this.updateAccountIds(idsToUpdate)}
            callBack={() =>
              this.setState({ accIdDialog: false, accIdsLoading: false })
            }
          />
        )}
      </>
    );
  }
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const { accountGroups } = state.deviceGroups;
  const { permissions } = state.roles;
  return { accountGroups, permissions };
}

const enhance = compose(withStyles(useStyles), connect(mapStateToProps));
export default enhance(AccountAccessContainer);
