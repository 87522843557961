import { apiQuery } from "../libs/auth-config";

export const SDT_REQUEST = "SDT_REQUEST";
export const SDT_SUCCESS = "SDT_SUCCESS";
export const SDT_FAILURE = "SDT_FAILURE";

export const requestMaintenance = (account_id) => (dispatch) => {
  dispatch({ type: SDT_REQUEST });
  apiQuery("GET", "/maintenances", { account_id: account_id })
    .then((sdts) => {
      dispatch({
        type: SDT_SUCCESS,
        payload: sdts.data,
      });
    })
    .catch((e) => {
      dispatch({ type: SDT_FAILURE });
      console.log(e);
    });
};

export const ADD_SDT_REQUEST = "ADD_SDT_REQUEST";
export const ADD_SDT_SUCCESS = "ADD_SDT_SUCCESS";
export const ADD_SDT_FAILURE = "ADD_SDT_FAILURE";

export const addMaintenance =
  (account_id, device_id, billing_ref, start, end, comment, type) =>
  async (dispatch) => {
    dispatch({ type: ADD_SDT_REQUEST });
    return apiQuery("POST", "/maintenances/maintenance", {
      account_id: account_id,
      device_id: device_id,
      billing_ref: billing_ref,
      start: start,
      type: type,
      end: end,
      comment: comment,
    })
      .then((sdts) =>
        dispatch({
          type: ADD_SDT_SUCCESS,
          payload: sdts.data,
        })
      )
      .catch((e) => {
        dispatch({ type: ADD_SDT_FAILURE });
        console.log(e);
      });
  };

export const UPDATE_SDT_REQUEST = "UPDATE_SDT_REQUEST";
export const UPDATE_SDT_SUCCESS = "UPDATE_SDT_SUCCESS";
export const UPDATE_SDT_FAILURE = "UPDATE_SDT_FAILURE";

export const updateMaintenance =
  (account_id, id, billing_ref, start, end, type) => async (dispatch) => {
    dispatch({ type: UPDATE_SDT_REQUEST });
    return apiQuery("PUT", "/maintenances/maintenance", {
      account_id: account_id,
      id: id,
      billing_ref: billing_ref,
      start: start,
      end: end,
      type: type,
    })
      .then((sdts) =>
        dispatch({
          type: UPDATE_SDT_SUCCESS,
          payload: sdts.data,
        })
      )
      .catch((e) => {
        dispatch({ type: UPDATE_SDT_FAILURE });
        console.log(e);
      });
  };

export const DELETE_SDT_REQUEST = "DELETE_SDT_REQUEST";
export const DELETE_SDT_SUCCESS = "DELETE_SDT_SUCCESS";
export const DELETE_SDT_FAILURE = "DELETE_SDT_FAILURE";

export const removeMaintenance =
  (account_id, id, billing_ref) => async (dispatch) => {
    dispatch({ type: DELETE_SDT_REQUEST });
    return apiQuery("DELETE", "/maintenances/maintenance", {
      account_id: account_id,
      id: id,
      billing_ref: billing_ref,
    })
      .then((sdts) =>
        dispatch({
          type: DELETE_SDT_SUCCESS,
          payload: sdts.data,
        })
      )
      .catch((e) => {
        dispatch({ type: DELETE_SDT_FAILURE });
        console.log(e);
      });
  };

export const addThenGetSDTs =
  (account_id, device_id, billing_ref, start, end, comment, type) =>
  (dispatch) => {
    return dispatch(
      addMaintenance(
        account_id,
        device_id,
        billing_ref,
        start,
        end,
        comment,
        type
      )
    ).then(() => {
      return dispatch(requestMaintenance(account_id));
    });
  };

export const updateThenGetSDTs =
  (account_id, id, billing_ref, start, end, type) => (dispatch) => {
    return dispatch(
      updateMaintenance(account_id, id, billing_ref, start, end, type)
    ).then(() => {
      return dispatch(requestMaintenance(account_id));
    });
  };

export const removeThenGetSDTs =
  (account_id, id, billing_ref) => (dispatch) => {
    return dispatch(removeMaintenance(account_id, id, billing_ref)).then(() => {
      return dispatch(requestMaintenance(account_id));
    });
  };
