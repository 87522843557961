import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, createStyles } from "@material-ui/core/styles";

import { getDashboardFromReportId } from "../actions/dashboard";
import { initRequestDeviceGroup } from "../actions/devicegroup";

import AlertOverview from "./AlertOverview";
// import { data } from '../data/graphs'

const styles = (theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
  });

class DeviceGroupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_hidden: true,
      loadingWidgets: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    console.log("id is " + id);
    this.props.initRequestDeviceGroup(id);
  }

  render() {
    const { currentDeviceGroup } = this.props;

    var body = <p>Stick a spinner in here prolly</p>;
    if (currentDeviceGroup) {
      body = (
        <React.Fragment>
          <AlertOverview />
          <p>{currentDeviceGroup["Customer Name"]}</p>
        </React.Fragment>
      );
    } else {
      body = <CircularProgress size={24} />;
    }

    return (
      <div className="container">
        <div className="row">
          <Grid container spacing={2} alignItems="flex-end">
            {body}
          </Grid>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { currentDeviceGroup } = state.deviceGroups;

  return { currentDeviceGroup };
}

const mapDispatchToProps = {
  initRequestDeviceGroup,
  getDashboardFromReportId,
};

const enhance = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DeviceGroupContainer);
