import React from "react";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import BuildIcon from "@material-ui/icons/Build";
import UpdateIcon from "@material-ui/icons/Update";

import {
  CRITICAL_COLOR,
  LIVE_COLOR,
  ERROR_COLOR,
  WARNING_COLOR,
} from "../constants";

export default function AlertsIcon({ status, needsColour }) {
  var icon, colour;
  if (status === "Critical") {
    colour = needsColour ? (colour = CRITICAL_COLOR) : (colour = "white");
    icon = <WhatshotIcon style={{ color: colour }} />;
  } else if (status === "Error") {
    colour = needsColour ? (colour = ERROR_COLOR) : (colour = "white");
    icon = <ErrorIcon style={{ color: colour }} />;
  } else if (status === "Warning") {
    colour = needsColour ? (colour = WARNING_COLOR) : (colour = "white");
    icon = <WarningIcon style={{ color: colour }} />;
  } else if (status === "SDT") {
    colour = needsColour ? (colour = "blue") : (colour = "white");
    icon = <UpdateIcon style={{ color: colour }} />;
  } else if (status === "pre_live") {
    colour = needsColour ? (colour = "black") : (colour = "white");
    icon = <BuildIcon style={{ color: colour }} />;
  } else if (status !== "") {
    colour = needsColour ? (colour = LIVE_COLOR) : (colour = "white");
    icon = <DoneOutlineIcon style={{ color: colour }} />;
  } else {
  }
  return icon;
}
