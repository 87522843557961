import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { STAGE } from "../../constants";
import { apiQuery } from "../../libs/auth-config";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
    width: 400,
    minWidth: 400,
  },
  textfield: {
    marginBottom: 20,
  },
}));

export default function SubmitTicket({
  account,
  device,
  state,
  callbackFunction,
  refreshTicketList,
}) {
  const [open, setOpen] = React.useState(state);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [name, setContactName] = React.useState("");
  const [number, setContactNumber] = React.useState("");
  const [times, setContactTimes] = React.useState("");
  const [caseNumber, setCaseNumber] = React.useState(0);
  const [status, setStatus] = React.useState("");
  const [error, setError] = React.useState("");
  const [running, setRunning] = React.useState(false);
  const [submissionState, setSubmissionState] = React.useState({
    title: "",
    description: "",
  });
  const identifier =
    useSelector(
      (state) =>
        state.ui_settings.portal_config.submit_ticket_device_identifier ||
        state.ui_settings.portal_config.device_identifier
    ) || "Billing Reference";
  const classes = useStyles();

  useEffect(() => {
    if (state) {
      setOpen(state);
    }
  }, [state]);

  const handleClose = () => {
    setOpen(false);
    callbackFunction();
    setSubmissionState({
      title: "",
      description: "",
    });
  };

  const variables = [
    {
      name: "Device",
      value: device[identifier] || device["DNS Name"],
      disabled: true,
    },
    {
      name: "Customer",
      value: device["Customer"],
      disabled: true,
    },
  ];

  const editable = [
    {
      name: "Title",
      onChange: (val) => setTitle(val),
      rows: 2,
    },
    {
      name: "Description",
      onChange: (val) => setDescription(val),
      rows: 6,
    },
    {
      name: "Contact Name",
      onChange: (val) => setContactName(val),
      rows: 1,
    },
    {
      name: "Contact Number",
      onChange: (val) => setContactNumber(val),
      rows: 1,
    },
    {
      name: "Contact Access Times",
      onChange: (val) => setContactTimes(val),
      rows: 1,
      placeholder:
        "Enter the hours you wish to be contacted, e.g. Mon-Fri 9:00-17:00",
    },
  ];

  const submitTicket = () => {
    setRunning(true);
    let formattedDescription = "Contact Name: " + name;
    formattedDescription += "\nContact Number: " + number;
    formattedDescription += "\nContact Access Times: " + times;
    formattedDescription += "\n\nCustomer: " + device["aur.customer"];
    formattedDescription +=
      "\nCircuit Reference: " + device["aur.circuit_reference"];
    formattedDescription += "\nBilling Reference: " + device["aur.request_id"];
    formattedDescription += "\nDescription: \n\n" + description;
    const payload = {
      account: account,
      title: title,
      description: formattedDescription,
      stage: STAGE,
    };
    apiQuery("POST", "/tickets", payload)
      .then((response) => {
        try {
          if (!response.data.length) setError(response.message);
          else {
            setCaseNumber(response.data[0].case);
            setStatus(response.status_code);
          }
        } catch {
          setError(response.message);
        }
      })
      .catch((e) => console.log("Unexpected Error: \n" + e))
      .finally(() => {
        //refreshTicketList();
        setRunning(false);
        setSubmissionState({
          title: title,
          description: description,
        });
      });
  };

  const checkValuesAreChanged = () => {
    if (
      submissionState.title === title &&
      submissionState.description === description
    )
      return true;
    else return false;
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Submit A Ticket</DialogTitle>
        <DialogContent>
          <Typography color="primary" className={classes.textfield}>
            Please fill out the following fields to submit a ticket.
          </Typography>

          <Grid container spacing={1} className={classes.textfield}>
            {variables.map((info) => {
              return (
                <React.Fragment key={info.name}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      id={info.name}
                      key={info.name}
                      label={info.name}
                      variant="outlined"
                      size="small"
                      value={info.value}
                      multiline
                      fullWidth
                      rows={info.rowHeight || 1}
                      disabled={info.disabled}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
          {editable.map((field) => {
            return (
              <TextField
                id={field.name}
                key={field.name}
                label={field.name}
                variant="outlined"
                size="small"
                onChange={(e) => field.onChange(e.target.value)}
                multiline
                fullWidth
                className={classes.textfield}
                rows={field.rows}
                placeholder={field.placeholder}
              />
            );
          })}

          <div>
            {error && <Alert severity="error">{error}</Alert>}
            {status && caseNumber && (
              <Alert severity="success">
                Message: {status}, Case ID: {caseNumber}
              </Alert>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {running ? <CircularProgress /> : null}
          <Button
            onClick={submitTicket}
            color="primary"
            variant="contained"
            disabled={running || checkValuesAreChanged()}
          >
            Submit
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
