import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
// import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import {
  initRequestRecipientGroupById,
  acknowledgeAlert,
} from "../actions/alerts";
// import { initRequestAllDeviceGroups } from '../actions/devicegroup'
import { requestMaintenance } from "../actions/maintenance";
import AddMaintenance from "../components/dialogs/AddMaintenance";
import { getAlertInformation } from "../actions/alerts";
import CustomDatatable from "../components/CustomDatatable";
import { epochToTimestampWithYears } from "../libs/utils";
import ContainerHeader from "../components/ContainerHeader";
import ConfigureMaintenance from "../components/dialogs/ConfigureMaintenance";
import Visible from "../components/Visible";
// import { RbacContext } from "./LoadingContainer";

// const propDict = {
//   DeviceSDT: "deviceDisplayName",
//   DeviceGroupSDT: "deviceGroupFullPath",
//   DeviceDataSourceSDT: "deviceDisplayName",
//   CollectorSDT: "collectorDescription",
// };

class MaintenanceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      loading: true,
      edit_dialog_state: false,
      applied_at: null,
      selected_row: {
        id: "-",
        start: "-",
        end: "-",
        duration: "-",
        type: "-",
        appliedAt: "-",
        admin: "-",
        timezone: "-",
      },
    };
    this.propMap = {
      startDateTime: {
        label: "Start",
        customBodyRender: (val) => epochToTimestampWithYears(val),
        filter: false,
      },
      endDateTime: {
        label: "End",
        customBodyRender: (val) => epochToTimestampWithYears(val),
        filter: false,
      },
      duration: {
        label: "Duration",
        customBodyRender: (val) =>
          val > 1440
            ? parseInt(val / 1440) + " Days"
            : (val / 60).toFixed(1) + " Hours",
        filter: true,
      },
      ...(!this.state.type && {
        type: { label: "Type" },
        // We need to do this properly
        deviceDisplayName: {
          label: "Applied At",
          // customBodyRender: (_, meta) => {
          //   console.log(rows[meta.rowIndex]);
          //   var this_type = propDict[rows[meta.rowIndex].type];
          //   return rows[meta.rowIndex][this_type];
          // },
          filter: false,
        },
      }),
      ...(this.state.type === "DeviceSDT" && {
        deviceDisplayName: {
          label: "Device",
          filter: true,
        },
      }),
      ...(this.state.type === "DeviceGroupSDT" && {
        deviceGroupFullPath: {
          label: "Full Path",
          filter: true,
        },
      }),
    };
  }

  componentDidMount() {
    // const { device_group_id } = this.props
    // this.props.setParentTitle("Maintenance");
    this.getMaintenance();
    // device groups not used for maintenace just yet.
    // this.props.dispatch(initRequestAllDeviceGroups(device_group_id))
  }

  getMaintenance() {
    this.props.dispatch(requestMaintenance(this.props.account_id));
    this.setState({ loading: false });
  }

  handleButtonClick() {
    this.props.dispatch(initRequestRecipientGroupById(this.props.account_id));
  }

  acknowledgeAlert() {
    this.props.dispatch(acknowledgeAlert(this.props.alert_id));
  }

  getAlertInfo(id) {
    this.props.dispatch(getAlertInformation(id));
  }

  filterAlerts(type) {
    this.setState({ type: type });
  }

  updateTable = () => {
    this.getMaintenance();
  };

  handleRowClick(row) {
    console.log(row, this.propMap[row.type]);
    this.setState({
      selected_row: row,
      applied_at: row.deviceDisplayName,
      edit_dialog_state: true,
    });
  }

  renderAddMaintenanceButton = () => {
    return (
      <Visible permissionNeeded="maintenance" capabilityNeeded="manage">
        <AddMaintenance
          devices={this.props.Devices}
          callback={() => this.updateTable()}
          account_id={this.props.account_id}
        />
      </Visible>
    );
  };

  render() {
    const { sdts, byType, account_id } = this.props;
    const { type, selected_row, applied_at, edit_dialog_state } = this.state;
    /*
    alerts.forEach((item) => {
      this.getAlertInfo(item.id)
    })
*/
    const rows = type ? byType[type] : sdts;

    return (
      <React.Fragment>
        {/* <RbacContext.Consumer>
          {(perms) =>
            perms.Maintenance.manage && ( */}
        <ConfigureMaintenance
          state={edit_dialog_state}
          row={selected_row}
          name={applied_at}
          closeDialog={() => this.setState({ edit_dialog_state: false })}
          callback={this.updateTable}
          account_id={account_id}
        />
        {/* )
          }
        </RbacContext.Consumer> */}

        <ContainerHeader title={"Maintenance"} />
        <Grid
          container
          justifyContent="space-between"
          style={{ marginBottom: 20 }}
        >
          <Grid item>
            {/*
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item>
                <Button
                  variant={!this.state.filter ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => this.filterAlerts(null)}
                >
                  ({sdts ? sdts.length : 0}) Show All
                </Button>
              </Grid>
              */}
            {/*
              <Grid item>
                <Button
                  variant={
                    this.state.filter === "DeviceGroupSDT"
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  onClick={() => this.filterAlerts("DeviceGroupSDT")}
                >
                  ({byType.DeviceGroupSDT ? byType.DeviceGroupSDT.length : 0})
                  Device Groups
                </Button>
              </Grid>*/}
            {/*
              <Grid item>
                <Button
                  variant={
                    this.state.filter === "DeviceSDT" ? "contained" : "outlined"
                  }
                  color="primary"
                  onClick={() => this.filterAlerts("DeviceSDT")}
                >
                  ({byType.DeviceSDT ? byType.DeviceSDT.length : 0}) Devices
                </Button>
              </Grid>
            </Grid>
             */}
          </Grid>
        </Grid>
        <CustomDatatable
          viewComponent={"MaintenanceTable"}
          customPage
          propMap={this.propMap}
          rows={rows}
          rowClickCallback={(row) => this.handleRowClick(row)}
          loading={this.state.loading}
          customColumns={false}
          renderAddMaintenanceButton={this.renderAddMaintenanceButton}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { sdts } = state.maintenance;
  const { byType } = state.maintenance;
  //const { items } = state.deviceGroups
  const { Devices } = state.deviceSearch;
  const { device_group_id, account_id } = state.userSession.properties;
  return { sdts, byType, Devices, device_group_id, account_id };
}
export default connect(mapStateToProps)(MaintenanceContainer);

/* TAKEN OUT AS NOT ESSENTIAL - HERE FOR FUTURE PROOFING
  <Grid item>
    <Button
      variant="outlined" color="primary"
      onClick={() => this.filterAlerts('DeviceDataSourceSDT')}
    >
      ({ byType.DeviceDataSourceSDT ? byType.DeviceDataSourceSDT.length : 0 }) DataSources
    </Button>
  </Grid>
  <Grid item>
    <Button
      variant="outlined" color="primary"
      onClick={() => this.filterAlerts('CollectorSDT')}
    >
      ({ byType.CollectorSDT ? byType.CollectorSDT.length : 0 }) Collectors
    </Button>
  </Grid>
 */
