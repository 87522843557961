import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Box, Grid, Typography, CircularProgress } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  text: {
    marginBottom: 20,
  },
}));

function generate(element, name) {
  return React.cloneElement(element, { key: name });
}

export default function UpdateAccounts({
  open,
  circleState,
  active,
  inactive,
  callBack,
  startUpdate,
}) {
  const [state, setOpen] = React.useState(open);
  const [confirm, setConfirm] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    callBack();
  };

  const checkValuesAreChanged = () => {
    if (confirm === "confirm") return false;
    else return true;
  };

  const submitRemoval = () => {
    setConfirm("");
    startUpdate();
  };

  return (
    <div>
      <Dialog
        open={state}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Update LogicOne Accounts
        </DialogTitle>
        <DialogContent>
          <Typography color="primary" variant="h4" className={classes.text}>
            You are about to enable/disable the accounts below.
          </Typography>
          <Typography color="primary" variant="h4" className={classes.text}>
            Please ensure both lists are correct.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                Accounts To Disable
              </Typography>
              <div className={classes.demo}>
                <List dense={true}>
                  {active.map((val) =>
                    generate(
                      <ListItem>
                        <ListItemText
                          primary={val.name}
                          secondary={val.accId}
                        />
                      </ListItem>,
                      val.name
                    )
                  )}
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                Accounts To Enable
              </Typography>
              <div className={classes.demo}>
                <List dense={true}>
                  {inactive.map((val) =>
                    generate(
                      <ListItem>
                        <ListItemText
                          primary={val.name}
                          secondary={val.accId}
                        />
                      </ListItem>,
                      val.name
                    )
                  )}
                </List>
              </div>
            </Grid>
          </Grid>

          <Box fontStyle="italic">
            <Typography
              color="secondary"
              variant="h5"
              className={classes.text}
              component="div"
            >
              Please type 'confirm' in the box below.
            </Typography>
          </Box>

          <TextField
            autoFocus
            margin="dense"
            label="Update Confirmation"
            fullWidth
            variant="outlined"
            onChange={(e) => setConfirm(e.target.value)}
          />
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <Button
                onClick={submitRemoval}
                variant="contained"
                color="primary"
                disabled={checkValuesAreChanged()}
              >
                Submit
              </Button>
            </Grid>
            {circleState ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
