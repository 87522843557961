import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

//import IdMapping from "../../data/IdMapping.json";
import { apiQuery } from "../../libs/auth-config";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
  },
  formControl: {
    minWidth: 300,
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  clone: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  diagRight: {
    width: "50%",
    borderLeft: "1px dotted black",
    padding: theme.spacing(1, 1),
    display: "flex",
    flexDirection: "row",
  },
  diagLeft: {
    width: "50%",
    padding: theme.spacing(1, 1),
    display: "flex",
    flexDirection: "row",
  },
  diaBox: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    position: "absolute",
    bottom: 20,
    right: "51%",
  },
  icon: {
    marginLeft: 5,
    marginTop: 15,
  },
}));

export default function FullConfiguration() {
  const classes = styles();

  const [open, setOpen] = React.useState(false);
  const [portal, setPortal] = React.useState("dws");
  //const [customer, setCustomer] = React.useState("");
  const [customerList, setCustomerList] = React.useState([]);
  //const [device, setDevice] = React.useState("");
  const [deviceList, setDeviceList] = React.useState([]);
  const [level, setLevel] = React.useState("");
  const [propName, setPropName] = React.useState("");
  const [propValue, setPropValue] = React.useState("");
  const [varName, setVarName] = React.useState("");
  //const [nameField, setNameField] = React.useState("");
  const [submission, setSubmission] = React.useState(false);
  const [outputData, setOutputData] = React.useState({});
  //const map = IdMapping;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const compileJSON = () => {
    var data = { setPropName: propName, setPropValue: propValue };
    setOutputData(data);
    onAdd();
  };

  const onAdd = () => {
    setSubmission(true);
    setPropName("");
    setPropValue("");
  };

  const onEdit = () => {
    setPropValue(outputData.propName);
    setPropName(outputData.propValue);
  };

  const onDelete = () => {
    setPropName("");
    setPropValue("");
    setSubmission(false);
  };

  // const test = (name) => {};

  const loadDevices = (folder) => {
    if (folder === null) return;
    const data = {
      id: 8713,
      account: portal,
    };
    apiQuery("GET", "/getcustomerdevices", data)
      .then((response) => setDeviceList(response))
      .catch((e) => console.log(e));
  };

  if (deviceList.length === 0) loadDevices();

  const loadFolders = (folder) => {
    if (folder === null) return;
    const data = {
      id: 2218,
      account: portal,
    };
    apiQuery("GET", "/getCustomers", data)
      .then((response) => setCustomerList(response))
      .catch((e) => console.log(e));
  };
  if (customerList.length === 0) loadFolders();
  /*
  const runJob = () => {
    var data = {
      account: portal,
      customer: customer,
    };
  };
*/
  return (
    <React.Fragment>
      <Card>
        <CardContent classes={{ root: classes.root }}>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="panel" focused>
                LogicMonitor Panel
              </InputLabel>
              <Select
                labelId="panel"
                id="panel"
                required
                value={portal}
                onChange={(e) => setPortal(e.target.value)}
                labelWidth={125}
              >
                <MenuItem value={"dc"}>Sandy</MenuItem>
                <MenuItem value={"dws"}>DWS</MenuItem>
                <MenuItem value={"splc"}>SPLC</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="panel" focused>
                Level
              </InputLabel>
              <Select
                labelId="panel"
                id="panel"
                required
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                labelWidth={125}
              >
                <MenuItem value={"customer level"}>Folder Level</MenuItem>
                <MenuItem value={"device level"}>Device Level</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {level === "customer level" ? (
            <React.Fragment>
              <Box textAlign="center">
                <FormControl className={classes.formControl} variant="outlined">
                  <Autocomplete
                    id="combo-box-demo"
                    options={customerList}
                    getOptionLabel={(option) => {
                      if (option) return option.name;
                      else return "";
                    }}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer list"
                        variant="outlined"
                      />
                    )}
                    onChange={(e, v) => {
                      if (v) setVarName(v.name);
                    }}
                  />
                </FormControl>
              </Box>
            </React.Fragment>
          ) : null}
          {level === "device level" ? (
            <Box textAlign="center">
              <FormControl className={classes.formControl} variant="outlined">
                <Autocomplete
                  id="combo-box-demo"
                  options={deviceList}
                  getOptionLabel={(option) => {
                    if (option) return option.displayName;
                    else return "";
                  }}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Device List"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, v) => {
                    if (v) setVarName(v.displayName);
                  }}
                />
              </FormControl>
            </Box>
          ) : null}
          <Box textAlign="center">
            <Button
              variant="contained"
              color="primary"
              // className={buttonClassname}
              // disabled={is_loading}
              onClick={handleClickOpen}
            >
              Add New Properties
            </Button>
          </Box>
        </CardContent>
      </Card>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth="true"
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">Adding a property</DialogTitle>
          <DialogContent>
            <div className={classes.diaBox}>
              <div className={classes.diagLeft}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="propName"
                    label="Property Name"
                    value={propName}
                    onChange={(e) => setPropName(e.target.value)}
                    fullWidth
                    disabled={submission}
                    variant="outlined"
                    style={{ paddingBottom: "10px", width: "500px" }}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="propValue"
                    label="Property Value"
                    onChange={(e) => setPropValue(e.target.value)}
                    value={propValue}
                    fullWidth
                    disabled={submission}
                    variant="outlined"
                    style={{ paddingBottom: "10px", width: "500px" }}
                  />
                </FormControl>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={compileJSON}
                >
                  Add
                </Button>
              </div>
              <div className={classes.diagRight}>
                <Grid
                  container
                  className={classes.clone}
                  alignItems="flex-start"
                >
                  <Grid item>
                    <TextField
                      autoFocus
                      margin="dense"
                      variant="outlined"
                      value={varName}
                      style={{ width: 400 }}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item className={classes.icon}>
                    <Tooltip
                      title="Edit"
                      placement="bottom"
                      style={{ cursor: "pointer" }}
                    >
                      <EditIcon onClick={onEdit} />
                    </Tooltip>
                  </Grid>
                  <Grid item className={classes.icon}>
                    <Tooltip
                      title="Remove"
                      placement="bottom"
                      style={{ cursor: "pointer" }}
                    >
                      <DeleteIcon onClick={onDelete} />
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => console.log("Button Pressed!")}
            >
              Save
            </Button>
            <Button onClick={handleClose} variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
