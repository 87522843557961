import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import IdMapping from "../../data/IdMapping.json";
import { apiQuery } from "../../libs/auth-config";

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
  },
  formControl: {
    minWidth: 300,
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function FullConfiguration() {
  const classes = styles();
  const [portal, setPortal] = React.useState("dc");
  const [customer, setCustomer] = React.useState("");
  const [customerList, setCustomerList] = React.useState([]);
  const [dashType, setDashType] = React.useState("");
  const [device, setDevice] = React.useState("");
  const [deviceList, setDeviceList] = React.useState([]);
  const [resellerList, setResellerList] = React.useState([]);
  const [secondDevice, setSecondary] = React.useState("");
  const [reseller, setReseller] = React.useState("");
  const map = IdMapping;

  const loadResellers = () => {
    const data = {
      id: map[portal].device,
      account: portal,
    };
    apiQuery("GET", "/getCustomers", data)
      .then((response) => setResellerList(response))
      .catch((e) => console.log(e));
  };

  if (portal === "dws" && !resellerList.length) loadResellers();
  const loadCustomers = (v) => {
    if (v === null) return;

    const data = {
      id: v,
      account: portal,
    };
    apiQuery("GET", "/getCustomers", data)
      .then((response) => setCustomerList(response))
      .catch((e) => console.log(e));
  };

  if (!customerList.length && portal !== "dws")
    loadCustomers(map[portal].device);
  const loadDevices = (folder) => {
    if (folder === null) return;
    const data = {
      id: folder,
      account: portal,
    };
    apiQuery("GET", "/getcustomerdevices", data)
      .then((response) => setDeviceList(response))
      .catch((e) => console.log(e));
  };

  const checkValuesAreChanged = () => {
    if (device && customer && reseller && dashType) {
      if (dashType === "firewall") {
        if (secondDevice) return false;
        else return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const runJob = () => {
    var data = {
      account: portal,
      customer: customer,
      dashType: dashType,
      device: device,
      second: secondDevice,
    };

    return data;
    // fire to api
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent classes={{ root: classes.root }}>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="panel" focused>
                LogicMonitor Panel
              </InputLabel>
              <Select
                labelId="panel"
                id="panel"
                required
                value={portal}
                onChange={(e) => setPortal(e.target.value)}
                labelWidth={125}
              >
                <MenuItem value={"dc"}>Sandy</MenuItem>
                <MenuItem value={"dws"}>DWS</MenuItem>
                <MenuItem value={"splc"}>SPLC</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {portal === "dws" ? (
            <Box textAlign="center">
              <FormControl className={classes.formControl} variant="outlined">
                <Autocomplete
                  id="combo-box-demo"
                  options={resellerList}
                  getOptionLabel={(option) => {
                    if (option) return option.name;
                    else return "";
                  }}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Reseller List"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, v) => {
                    if (v) {
                      loadCustomers(v.id);
                      setReseller(v.name);
                    } else {
                      setDevice("");
                      setCustomer("");
                      setDeviceList([]);
                      setCustomerList([]);
                    }
                  }}
                />
              </FormControl>
            </Box>
          ) : null}
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <Autocomplete
                id="combo-box-demo"
                options={customerList}
                getOptionLabel={(option) => {
                  console.log(option);
                  if (option) return option.name;
                  else return "";
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer List"
                    variant="outlined"
                  />
                )}
                onChange={(e, v) => {
                  if (v) {
                    loadDevices(v.id);
                    setCustomer(v.name);
                  } else {
                    setDevice("");
                    setDeviceList([]);
                  }
                }}
              />
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <Autocomplete
                id="combo-box-demo"
                options={deviceList}
                getOptionLabel={
                  deviceList.length ? (option) => option.displayName : ""
                }
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Device List"
                    variant="outlined"
                  />
                )}
                onChange={(e, v) => {
                  if (v) setDevice(v.id);
                }}
              />
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="type" focused>
                Choose a Dashboard Type
              </InputLabel>
              <Select
                labelId="type"
                value={dashType}
                onChange={(e) => setDashType(e.target.value)}
                labelWidth={170}
              >
                <MenuItem value={"wan"}>WAN</MenuItem>
                <MenuItem value={"firewall"}>Firewall</MenuItem>
                <MenuItem value={"netflow"}>Netflow</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {dashType === "firewall" ? (
            <React.Fragment>
              <Box textAlign="center">
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel id="second" focused>
                    Secondary Device
                  </InputLabel>
                  <OutlinedInput
                    labelId="second"
                    value={secondDevice}
                    required
                    onChange={(e) => setSecondary(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    labelWidth={110}
                    multiline
                  />
                </FormControl>
              </Box>
            </React.Fragment>
          ) : null}
          <Box textAlign="center">
            <Button
              className={classes.formControl}
              color="primary"
              variant="contained"
              disabled={checkValuesAreChanged()}
              onClick={runJob}
            >
              Run Job
            </Button>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
