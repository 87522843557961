import {
  Box,
  Checkbox,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import LoadingButton from "./LoadingButton";
import { fetchRoles, addRole, updateRole } from "../actions/roles";
import { CAPABILITY_LEVELS, DEFAULT_ROLES } from "../constants";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Visible from "./Visible";
import { apiQuery } from "../libs/auth-config";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: "auto",
    marginTop: 50,
    width: "60%",
    maxHeight: "90%",
    overflow: "scroll",
  },
  buttonContainer: {
    //padding: theme.spacing(1),
    float: "right",
  },
  textInput: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  permissionsTable: {
    marginBottom: theme.spacing(3),
  },
  expandedRow: {
    backgroundColor: theme.palette.action.hover,
    border: "1px solid",
    borderColor: theme.palette.action.selected,
    height: "50px",
  },
  roleDescription: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  tableRow: {
    height: "50px",
  },
  tableCell: {
    padding: "0px 16px",
  },
  pagination: {
    padding: theme.spacing(2),
    margin: "0 auto",
  },
}));

const capSplit = (str) => {
  return str.replace(
    /(^[a-z]+)|[0-9]+|[A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|[0-9])/g,
    function (match, first) {
      if (first) match = match[0].toUpperCase() + match.substr(1);
      return match + " ";
    }
  );
};

// Subfields to render.
const permissions = [
  "metrics",
  "system",
  "alerts",
  "diagnostics",
  "systemProperties",
  "customProperties",
  "raiseTicket",
];

const ServicesRow = ({
  groups,
  setGroups,
  root,
  parentPerms,
  level = 0,
  usersPerms,
  canViewParent,
}) => {
  const rootId = typeof root === "number" ? root : root.id;

  const hasParentPerms = canViewParent
    ? canViewParent
    : !!usersPerms.services.find((g) => g.id === rootId);

  // How many groups to show per page.
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const classes = useStyles();
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [deviceGroups, setDeviceGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  const noChildren =
    typeof root === "number" ? false : root.numOfDirectSubGroups === 0;

  const fetchData = async () => {
    setIsLoading(true);
    const res = await apiQuery("GET", "/devicegroups", {
      id: rootId,
      showSubgroups: "true",
    });
    setIsLoading(false);
    return res.data.subGroups;
  };

  const permissionForGroup = groups.find((g) => g.id === rootId);

  // If the group doesn't have any permission set then
  // it inherits the parent permissions, if these dont ecxist then we initialise
  // new ones with none set for everything which should cascade down.
  const perms = permissionForGroup
    ? { ...permissionForGroup } // Copy before we delete keys.
    : parentPerms
    ? { ...parentPerms }
    : permissions.reduce((acc, curr) => {
        acc[curr] = 4;
        return acc;
      }, {});

  // Remove ID for easy comparisons,
  // we have ID stored as a prop anyway.
  if ("id" in perms) {
    delete perms.id;
  }

  if ("parentId" in perms) {
    delete perms.parentId;
  }

  // Everything below a group inherits permissions so these are computed once
  // so they can be handed down to the children below.
  const hasPermissions = (capability) => {
    return Object.values(perms).every(
      (v) => v <= CAPABILITY_LEVELS[capability]
    );
  };

  // This may seem like a bug because it shows "-" when everything beneath
  // it is checked, but this is correct. If the top level group is checked
  // then any new groups which are added in future underneath it will have
  // permissions. If only the subgroups are all selected then any new ones
  //  will not be permissioned.

  // If anything in deviceGroups is in groups then that means this level
  // cannot be in groups too but the checkbox needs to be set to indeterminate.
  const hasIndeterminatePermissions = (capability) => {
    return !!groups.find(
      (g) =>
        (level === 0 || g.parentId === rootId) &&
        Object.entries(g)
          .filter(([k, v]) => !["id", "parentId"].includes(k))
          .some(([k, v]) => v <= CAPABILITY_LEVELS[capability]) &&
        !Object.entries(g)
          .filter(([k, v]) => !["id", "parentId"].includes(k))
          .every(([k, v]) => v <= CAPABILITY_LEVELS[capability])
    );
  };

  const giveGroupPermissions = (capability, checked, subPermission = false) => {
    let newGroups = [...groups];

    // Complicated bit: When a group is changed, we need to
    // remove its parents group from the permissions array,
    // and we also need to remove any children groups from the array.
    if (parentPerms?.id) {
      // Root wont have this.
      // Remove parent group.
      let parentIndex = newGroups.findIndex((g) => g.id === parentPerms.id);
      if (parentIndex !== -1) {
        newGroups.splice(parentIndex, 1);
      }
    }

    // Remove children groups.
    newGroups = newGroups.filter(
      (g) => !deviceGroups.find((dg) => dg.id === g.id)
    );

    let index = newGroups.findIndex((g) => g.id === rootId);
    if (index === -1) {
      newGroups.push({ ...perms });
      index = newGroups.length - 1; // We just added it to the end.
    }

    let editedGroup = newGroups.splice(index, 1)[0];

    if (!checked) {
      Object.keys(editedGroup).forEach((key) => {
        if (subPermission && key === subPermission) {
          editedGroup[key] = CAPABILITY_LEVELS[["none"]];
        } else if (!subPermission) {
          editedGroup[key] = CAPABILITY_LEVELS[["none"]];
        }
      });
    } else {
      Object.keys(editedGroup).forEach((key) => {
        if (subPermission && key === subPermission) {
          editedGroup[key] = CAPABILITY_LEVELS[capability];
        } else if (!subPermission) {
          editedGroup[key] = CAPABILITY_LEVELS[capability];
        }
      });
    }

    // If the group actually has permissions then you need to add
    // it back in otherwise its just redundant because a full none
    // group is no different permissions than no group at all.

    // This also removes the indeterminate state on the parent checkbox.
    if (Object.values(editedGroup).some((v) => v < CAPABILITY_LEVELS["none"])) {
      editedGroup.id = rootId;
      if (parentPerms) {
        editedGroup.parentId = parentPerms.id;
      }
      newGroups.push(editedGroup);
    }

    setGroups(newGroups);
  };

  useEffect(() => {
    /*
     We are refetching each time its expanded / closed,
     this prevents needed to refresh page to get rid of stale data,
     I'm not sure what the best way to handle this is, could set to 
     only fetch once but may not be ideal.
    */
    if (expanded) {
      const fetchDeviceGroups = async () => {
        setDeviceGroups(await fetchData());
      };
      fetchDeviceGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const backgroundColor = (lvl) => {
    // There is a limit to how deep this can go because theres
    // not that many shades but should be enough for practical purposes.
    if (theme.palette.type === "dark") {
      return theme.palette.grey[800 - lvl * 100];
    } else {
      return theme.palette.grey[lvl * 50];
    }
  };

  return (
    <>
      <TableRow
        className={classes.tableRow}
        style={{
          marginLeft: level * 10,
          background: backgroundColor(level),
        }}
      >
        <TableCell className={classes.tableCell}>
          {root.name || "Services"}
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ marginLeft: theme.spacing(0.5), marginBottom: "2px" }}
            onClick={() => {
              setShowOptions(!showOptions);
            }}
          >
            {!showOptions ? <AddBoxIcon /> : <IndeterminateCheckBoxIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Checkbox
            disabled={!hasParentPerms}
            checked={hasPermissions("view")}
            indeterminate={
              hasIndeterminatePermissions("view") ||
              (!hasPermissions("view") &&
                Object.values(perms).some(
                  (v) => v <= CAPABILITY_LEVELS["view"]
                ))
            }
            onChange={(e) => {
              giveGroupPermissions("view", e.target.checked);
            }}
          />
        </TableCell>
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell}>
          <Checkbox
            disabled={!hasParentPerms}
            checked={hasPermissions("manage")}
            indeterminate={
              hasIndeterminatePermissions("manage") ||
              (!hasPermissions("manage") &&
                Object.values(perms).some(
                  (v) => v <= CAPABILITY_LEVELS["manage"]
                ))
            }
            onChange={(e) => {
              giveGroupPermissions("manage", e.target.checked);
            }}
          />
          {expanded && isLoading && (
            <CircularProgress
              size={20}
              style={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                marginTop: "10px",
                float: "right",
              }}
            />
          )}
          {!noChildren && (
            <IconButton
              aria-label="expand row"
              size="small"
              style={{ float: "right", marginTop: "5px" }}
              onClick={() => {
                setExpanded(!expanded);
                if (!expanded) {
                  // Stop flash of old data when its expanded again.
                  setIsLoading(true);
                }
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {showOptions &&
        permissions
          .filter((p) => !["id", "parentId"].includes(p))
          .map((permission) => (
            <TableRow
              className={classes.tableRow}
              style={{
                marginTop: theme.spacing(3),
                background: backgroundColor(level),
              }}
            >
              <TableCell className={classes.tableCell}>
                <span style={{ marginLeft: "2em" }}>
                  {capSplit(permission)}
                </span>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Checkbox
                  checked={perms[permission] <= CAPABILITY_LEVELS["view"]}
                  onChange={(e) => {
                    giveGroupPermissions("view", e.target.checked, permission);
                  }}
                />
              </TableCell>
              <TableCell className={classes.tableCell} />
              <TableCell className={classes.tableCell}>
                <Checkbox
                  checked={perms[permission] <= CAPABILITY_LEVELS["manage"]}
                  onChange={(e) => {
                    giveGroupPermissions(
                      "manage",
                      e.target.checked,
                      permission
                    );
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
      {expanded && !isLoading && !noChildren && (
        <TableRow style={{ background: backgroundColor(level + 1) }}>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell>
            <TextField
              fullWidth
              size="small"
              label="Search"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPage(0);
              }}
              variant="outlined"
              style={{ float: "right" }}
            />
          </TableCell>
        </TableRow>
      )}
      {expanded && !isLoading && (
        <>
          {deviceGroups
            .filter((deviceGroup) =>
              deviceGroup.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((deviceGroup) => (
              <ServicesRow
                groups={groups}
                setGroups={setGroups}
                level={level + 1}
                key={deviceGroup.id}
                root={deviceGroup}
                parentPerms={{ ...perms, id: rootId }}
                usersPerms={usersPerms}
                canViewParent={hasParentPerms}
              />
            ))}
        </>
      )}
      {expanded && !isLoading && !noChildren && (
        <TablePagination
          component="div"
          page={page}
          onPageChange={(_, p) => setPage(p)}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
          count={deviceGroups.length}
          variant="outlined"
          color="secondary"
          className={classes.pagination}
        />
      )}
    </>
  );
};

function RolesForm({
  open,
  onClose,
  fetchRoles,
  addRole,
  updateRole,
  action,
  role = null,
  userPermissions,
}) {
  const rootDeviceGroupId = useSelector(
    (state) => state.ui_settings.portal_config.rootFolderId
  );

  const usersPerms = useSelector((state) => state.roles.permissions);

  useEffect(() => {
    setPermissions({
      dashboard: role ? role.dashboard : CAPABILITY_LEVELS["none"],
      services: role ? role.services : [],
      alerts: role ? role.alerts : CAPABILITY_LEVELS["none"],
      maintenance: role ? role.maintenance : CAPABILITY_LEVELS["none"],
      reports: role ? role.reports : CAPABILITY_LEVELS["none"],
      automation: role ? role.automation : CAPABILITY_LEVELS["none"],
      admin: {
        provisioning: role
          ? role.admin.provisioning
          : CAPABILITY_LEVELS["none"],
        userAccess: role ? role.admin.userAccess : CAPABILITY_LEVELS["none"],
        accountAccess: role
          ? role.admin.accountAccess
          : CAPABILITY_LEVELS["none"],
        roles: role ? role.admin.roles : CAPABILITY_LEVELS["none"],
        auditLogs: role ? role.admin.auditLogs : CAPABILITY_LEVELS["none"],
        masquerade: role ? role.admin.masquerade : CAPABILITY_LEVELS["none"],
      },
      settings: role ? role.settings : CAPABILITY_LEVELS["none"],
      support: role ? role.support : CAPABILITY_LEVELS["none"],
    });
    setName(role ? role.name : "");
    setDescription(role ? role.description : "");
  }, [role]);

  const classes = useStyles();

  const [name, setName] = useState(role ? role.name : "");
  const [description, setDescription] = useState(role ? role.description : "");

  const [permissions, setPermissions] = useState({
    dashboard: CAPABILITY_LEVELS["none"],
    services: [],
    alerts: CAPABILITY_LEVELS["none"],
    maintenance: CAPABILITY_LEVELS["none"],
    reports: CAPABILITY_LEVELS["none"],
    automation: CAPABILITY_LEVELS["none"],
    admin: {
      provisioning: CAPABILITY_LEVELS["none"],
      userAccess: CAPABILITY_LEVELS["none"],
      accountAccess: CAPABILITY_LEVELS["none"],
      roles: CAPABILITY_LEVELS["none"],
      auditLogs: CAPABILITY_LEVELS["none"],
      masquerade: CAPABILITY_LEVELS["none"],
    },
    settings: CAPABILITY_LEVELS["none"],
    support: CAPABILITY_LEVELS["none"],
  });

  // Derive setGroups function from setPermissions.
  const setGroups = (groups) => {
    setPermissions({
      ...permissions,
      services: groups,
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async () => {
    setIsLoading(true);
    if (action === "Create") {
      await addRole({
        ...permissions,
        name,
        description,
      }).then(() => fetchRoles());
    } else {
      await updateRole({
        ...permissions,
        name,
        description,
      }).then(() => fetchRoles());
    }
    onClose();
    setIsLoading(false);
  };

  const hasPermission = (permission, capability, sub = false) => {
    if (typeof permissions[permission] !== "number" && !sub) {
      return checkTopLevelPermissions(permission, capability);
    }
    if (sub) {
      return permissions[permission][sub] <= CAPABILITY_LEVELS[capability];
    } else {
      return permissions[permission] <= CAPABILITY_LEVELS[capability];
    }
  };

  const updatePermission = (permission, capability, checked, sub = false) => {
    var changedPerm = { ...permissions };
    var changeValue = 4;
    if (checked) {
      changeValue = CAPABILITY_LEVELS[capability];
    }

    if (sub) {
      changedPerm[permission][sub] = changeValue;
    } else {
      if (typeof permissions[permission] === "number") {
        changedPerm[permission] = changeValue;
      } else {
        Object.entries(permissions[permission]).forEach(([k, v]) => {
          changedPerm[permission][k] = changeValue;
        });
      }
    }

    setPermissions(changedPerm);
  };

  const checkTopLevelPermissions = (permission, capability) => {
    var isChecked = true;
    Object.entries(permissions[permission]).forEach(([k]) => {
      if (!(permissions[permission][k] <= CAPABILITY_LEVELS[capability])) {
        isChecked = false;
      }
    });
    return isChecked;
  };

  const checkboxEnabled = (permission, capability, sub) => {
    if (typeof permissions[permission] !== "number" && !sub) {
      var returnValue = false;
      Object.entries(userPermissions[permission]).forEach(([k]) => {
        if (userPermissions[permission][k] > CAPABILITY_LEVELS[capability]) {
          returnValue = true;
        }
      });
      return returnValue;
    }
    if (sub) {
      if (userPermissions[permission][sub] <= CAPABILITY_LEVELS[capability]) {
        return false;
      }
    } else {
      if (userPermissions[permission] <= CAPABILITY_LEVELS[capability]) {
        return false;
      }
    }
    return true;
  };

  const [isExpanded, setIsExpanded] = useState([]);

  const ExpandableTableRow = ({ permission, value }) => {
    return (
      <>
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            {capSplit(permission)}
            {typeof permissions[permission] !== "number" ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  var expandedChecker = [...isExpanded];
                  if (expandedChecker.includes(permission)) {
                    expandedChecker = expandedChecker.filter(
                      (item) => item !== permission
                    );
                  } else {
                    expandedChecker.push(permission);
                  }
                  setIsExpanded(expandedChecker);
                }}
              >
                {isExpanded.includes(permission) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            ) : null}
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Checkbox
              checked={hasPermission(permission, "view")}
              disabled={checkboxEnabled(permission, "view", false)}
              onChange={(e) =>
                updatePermission(permission, "view", e.target.checked)
              }
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            {permission === "alerts" ? (
              <Checkbox
                checked={hasPermission(permission, "acknowledge")}
                disabled={checkboxEnabled(permission, "acknowledge", false)}
                onChange={(e) =>
                  updatePermission(permission, "acknowledge", e.target.checked)
                }
              />
            ) : null}
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Checkbox
              checked={hasPermission(permission, "manage")}
              disabled={checkboxEnabled(permission, "manage", false)}
              onChange={(e) =>
                updatePermission(permission, "manage", e.target.checked)
              }
            />
          </TableCell>
        </TableRow>
        {isExpanded.includes(permission) &&
          Object.keys(permissions[permission]).map((subPermission) => (
            <TableRow className={classes.expandedRow}>
              <TableCell className={classes.tableCell} align="center">
                {capSplit(subPermission)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Checkbox
                  checked={hasPermission(permission, "view", subPermission)}
                  disabled={checkboxEnabled(permission, "view", subPermission)}
                  onChange={(e) =>
                    updatePermission(
                      permission,
                      "view",
                      e.target.checked,
                      subPermission
                    )
                  }
                />
              </TableCell>
              <TableCell className={classes.tableCell}></TableCell>
              <TableCell className={classes.tableCell}>
                <Checkbox
                  checked={hasPermission(permission, "manage", subPermission)}
                  disabled={checkboxEnabled(
                    permission,
                    "manage",
                    subPermission
                  )}
                  onChange={(e) =>
                    updatePermission(
                      permission,
                      "manage",
                      e.target.checked,
                      subPermission
                    )
                  }
                />
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setPermissions({
          dashboard: CAPABILITY_LEVELS["none"],
          services: {
            metrics: CAPABILITY_LEVELS["none"],
            system: CAPABILITY_LEVELS["none"],
            alerts: CAPABILITY_LEVELS["none"],
            diagnostics: CAPABILITY_LEVELS["none"],
            systemProperties: CAPABILITY_LEVELS["none"],
            customProperties: CAPABILITY_LEVELS["none"],
            raiseTicket: CAPABILITY_LEVELS["none"],
          },
          alerts: CAPABILITY_LEVELS["none"],
          maintenance: CAPABILITY_LEVELS["none"],
          reports: CAPABILITY_LEVELS["none"],
          admin: {
            provisioning: CAPABILITY_LEVELS["none"],
            userAccess: CAPABILITY_LEVELS["none"],
            accountAccess: CAPABILITY_LEVELS["none"],
            roles: CAPABILITY_LEVELS["none"],
            auditLogs: CAPABILITY_LEVELS["none"],
            masquerade: CAPABILITY_LEVELS["none"],
          },
          settings: CAPABILITY_LEVELS["none"],
          support: CAPABILITY_LEVELS["none"],
          automation: CAPABILITY_LEVELS["none"],
        });
        onClose();
        setIsExpanded([]);
      }}
      aria-labelledby="create-role"
      aria-describedby="create-role-form"
    >
      <Paper className={classes.container}>
        {action === "Create" ? (
          <Typography variant="h3">Add Role</Typography>
        ) : (
          <Typography variant="h3">Edit Role</Typography>
        )}
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={name}
          disabled={action === "Edit"}
          error={false}
          helperText={false}
          className={classes.textInput}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          label="Description"
          variant="outlined"
          className={classes.roleDescription}
          error={false}
          helperText={false}
          multiline
        />
        <Table className={classes.permissionsTable}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <b>View</b>
              </TableCell>
              <TableCell>
                <b>Ack</b>
              </TableCell>
              <TableCell>
                <b>Manage</b>
              </TableCell>
            </TableRow>
          </TableHead>
          {Object.entries(permissions).map(([key, value]) => {
            return key === "services" && name !== "Embedded" ? (
              <ServicesRow
                groups={permissions[key]}
                setGroups={(groups) => setGroups(groups)}
                root={rootDeviceGroupId}
                usersPerms={usersPerms}
              />
            ) : (
              <ExpandableTableRow permission={key} value={value} />
            );
          })}
        </Table>
        <Box className={classes.buttonContainer}>
          <Visible permissionNeeded="admin.roles" capabilityNeeded="manage">
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={submitForm}
              isLoading={isLoading}
              disabled={!name || DEFAULT_ROLES.includes(name)}
            >
              Save
            </LoadingButton>
          </Visible>
        </Box>
      </Paper>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    roles: state.roles.roles,
    existingRoleNames: state.roles.existingRoleNames,
    userPermissions: state.roles.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoles: () => dispatch(fetchRoles()),
    addRole: (role) => dispatch(addRole(role)),
    updateRole: (role) => dispatch(updateRole(role)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesForm);
