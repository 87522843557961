import React from "react";
import PhoneInput from "react-phone-input-2";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  countryList: {
    ...theme.typography.body1,
    background: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.text.primary} !important`,
    "& li": {
      background: `${theme.palette.background.paper} !important`,
    },
    "& li:hover": {
      background: `${theme.palette.action.selected} !important`,
    },
  },
  phoneInput: {
    background: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.text.primary} !important`,
    border: `1px solid ${theme.palette.action.disabled} !important`,
    "&:focus": {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: `2px !important`,
      boxShadow: `none !important`,
    },
  },
  labelUnfocused: {
    "& div.special-label": {
      color: `${theme.palette.text.secondary} !important`,
      background: `${theme.palette.background.paper} !important`,
    },
  },
  labelFocused: {
    "& div.special-label": {
      color: `${theme.palette.primary.main} !important`,
      background: `${theme.palette.background.paper} !important`,
    },
  },
}));

const PhoneNumberInput = ({
  containerClass,
  phone_number,
  handlePhoneChange,
  inputStyle,
}) => {
  const classes = useStyles();
  const [focused, setFocused] = useState(false);
  return (
    <div style={{ margin: focused ? 0 : "1px" }}>
      <PhoneInput
        name="phone_number"
        country="gb"
        value={phone_number}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        containerClass={
          containerClass +
          " " +
          `${focused ? classes.labelFocused : classes.labelUnfocused}`
        }
        // size="small"
        onChange={handlePhoneChange}
        // onChange={(value) => console.log(value)} // passed function receives the phone value
        component={TextField}
        dropdownClass={classes.countryList}
        isValid={(value, country) => {
          if (value.length > 2 && value.length < 11) return false;
          return true;
        }}
        inputProps={{
          style: {
            width: "100%",
            padding: "10.5px 14px 10.5px 58px",
            ...inputStyle,
          },
        }}
        inputClass={classes.phoneInput}
      />
    </div>
  );
};

export default PhoneNumberInput;
