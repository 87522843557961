import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { DoubleArrow as ArrowBackIcon } from "@material-ui/icons";
import { DoubleArrow as ArrowForwardIcon } from "@material-ui/icons";
import ProfileMenu from "../components/ProfileMenu";
import { useSelector } from "react-redux";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:
      theme.palette.type === "light"
        ? "#e9e9ec"
        : theme.palette.background.paper,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor:
      theme.palette.type === "light"
        ? "#e9e9ec"
        : theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  },
  menuDisplayed: {
    transform: "rotate(180deg)",
    color: theme.palette.primary.main,
  },
  menuHidden: {
    color: theme.palette.primary.main,
  },
}));

export default function TopNavContainer({
  logged_in,
  logoutUser,
  open,
  toggleDrawer,
}) {
  const classes = useStyles();
  const email = useSelector((state) => state.userSession.email) || undefined;

  return (
    <AppBar
      position="fixed"
      className={open ? classes.appBarShift : classes.appBar}
    >
      <Toolbar style={{ paddingLeft: "3px" }}>
        <Tooltip title={open ? "Close Menu" : "Open Menu"}>
          <IconButton
            size="small"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            className={open ? classes.menuDisplayed : classes.menuHidden}
            style={{
              // marginTop: "-36px",
              borderRadius: "0",
              // border: "2px solid",
              // padding: "px 0px 0px 0px",
            }}
          >
            {open ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </IconButton>
        </Tooltip>
        <div className={classes.grow} />
        {email && <ProfileMenu logoutUser={() => logoutUser()} />}
      </Toolbar>
    </AppBar>
  );
}
