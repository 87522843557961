import React from "react";
import { useDropzone } from "react-dropzone";

export default function DragAndDrop({ content, callback }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/svg": [".svg"],
      //Commented out as this gives an error
      //"image/jpeg": ["jpeg", "jpg"],
      "image/png": [".png"],
    },
    multiple: false,
    onDropAccepted: (files) => callback(files[0]),
  });

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <center>{content}</center>
      </div>
    </section>
  );
}
