import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Storage } from "aws-amplify";
import RemovalConfirmation from "../components/dialogs/RemovalConfirmation";
import { apiQuery } from "../libs/auth-config";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import EditDeviceProperty from "./dialogs/EditDeviceProperty";
import { useSelector } from "react-redux";
import AddCustomProperty from "./dialogs/AddCustomProperty";
import Visible from "./Visible";
import { PREFIX } from "../constants";

// import { RbacContext } from "../containers/LoadingContainer";

const useStyles = makeStyles((theme) => ({
  table: {
    marginBottom: theme.spacing(1),
  },
  titles: {
    // textAlign: "center",
    paddingBottom: theme.spacing(1),
    // paddingTop: theme.spacing(1),
  },
  prop: {
    paddingLeft: theme.spacing(1),
  },
  actionButtonRow: {
    display: "flex",
  },
  removeButton: {
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    // marginRight: theme.spacing(1),
  },
  fillSpace: { flexGrow: "1" },
  customPropertyButton: {
    position: "relative",
    float: "right",
    minWidth: 0,
    width: 20,
    height: 20,
    marginTop: 0,
    marginRight: 25,
    padding: 20,
    borderRadius: 100,
  },
  editIcon: {
    "& .MuiButton-iconSizeSmall > *:first-child": {
      fontSize: 15,
    },
  },
  editButton: {
    padding: "0px 8px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const excludedProps = [
  "Circuit Reference Provider",
  "Chassis Model",
  "Description",
  "Distribution",
  "Domain Controller",
];

const excludedEdits = ["Account ID", "Customer", "Date Added"];

export default function DeviceProperties({ device, allDevices, callback }) {
  const classes = useStyles();
  const [state, setDialogState] = useState(false);
  const [prop, setProp] = useState("");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [custom, setCustom] = useState(false);
  const [customPropsOpen, setCustomPropsOpen] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [open, setDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const email = useSelector((state) => state.userSession.email) || "";
  const propGroups =
    useSelector((state) => state.ui_settings.portal_config.device_properties) ||
    "";
  const identifier =
    useSelector(
      (state) =>
        state.ui_settings.portal_config.remove_device_identifier ||
        state.ui_settings.portal_config.device_identifier
    ) || "Billing Reference";

  try {
    device["Address"] = device["Address"].replace(device["Postcode"], "");
    device["Billing Reference"] = device["Billing Reference"].replace(
      "DR-",
      ""
    );
  } catch {}

  const provisioningConfig =
    useSelector((state) => state.ui_settings.portal_config["provisioning"]) ||
    {};

  useEffect(() => {
    const getProviderList = async () => {
      await Storage.get(`ProviderList.csv`, {
        download: true,
        bucket: `${PREFIX}.logic-one.io`,
      }).then((data) => {
        data.Body.text().then((text) => {
          const csvArray = text.replace(/(\r\n|\n|\r)/gm, ",").split(",");
          const csvMap = csvArray.map((provider) => ({ name: provider }));
          setProviderList(csvMap);
        });
      });
    };

    getProviderList();
  }, []);

  const handleClick = (prop, value, custom) => {
    setTitle(prop);
    setCustom(custom);
    setProp(prop);
    setValue(value);
    setDialogState(true);
  };

  const removeDevice = async () => {
    setLoading(true);

    const data = {
      id: device.id,
      name: device.label,
      user: email,
      billing_ref: device["Billing Reference"],
    };

    const response = await apiQuery("DELETE", "/devices", data);
    if (response.status_code === 200) window.location.reload();
    else callback(response.status_code, response.message);

    setDialogOpen(false);
    setLoading(false);
  };

  const closeRemoval = () => {
    setDialogOpen(false);
  };

  const customPropsAddition = () => {
    setCustomPropsOpen(true);
  };

  return (
    <React.Fragment>
      <EditDeviceProperty
        state={state}
        closeDialog={() => setDialogState(false)}
        id={device.id}
        billing_ref={device["Billing Reference"] || device["DNS Name"]}
        title={title}
        prop={prop}
        existing={value}
        custom={custom}
        providerList={providerList}
        config={provisioningConfig}
      />
      <RemovalConfirmation
        open={open}
        title={"Remove Device"}
        loading={loading}
        message={`You are about to remove the device '${
          device[identifier] || device["DNS Name"]
        }' and any associated dashboards. This cannot be undone.`}
        callBack={removeDevice}
        closesureCallback={closeRemoval}
      />
      <AddCustomProperty
        customPropsOpen={customPropsOpen}
        closeDialog={() => setCustomPropsOpen(false)}
        id={device.id}
        customList={device.Custom}
      />
      <span>
        <Button
          className={classes.customPropertyButton}
          onClick={() => customPropsAddition()}
          size="small"
          variant="contained"
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </Button>
      </span>
      <TableContainer>
        <Table>
          <TableBody>
            {Object.keys(propGroups).map(
              (key, i) => (
                // access[key].view && (
                <>
                  <Visible
                    permissionNeeded="services.systemProperties"
                    capabilityNeeded="view"
                  >
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: 0,
                          paddingTop: i ? 30 : 0,
                          paddingLeft: 0,
                        }}
                      >
                        <Typography color={"primary"} variant="h3">
                          {key}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {propGroups[key].map(
                      (prop) =>
                        !excludedProps.includes(prop) && (
                          <TableRow key={prop}>
                            <TableCell className={classes.prop}>
                              {prop}
                            </TableCell>
                            <TableCell className={classes.prop}>
                              {device[prop]}
                            </TableCell>
                            <TableCell align="right">
                              <Visible component={"TicketInfoButton"}>
                                {
                                  <Visible
                                    permissionNeeded="services.systemProperties"
                                    capabilityNeeded="manage"
                                  >
                                    {!excludedEdits.includes(prop) && (
                                      <Button
                                        size="small"
                                        color="primary"
                                        className={classes.editButton}
                                        startIcon={
                                          <EditIcon
                                            className={classes.editIcon}
                                          />
                                        }
                                        onClick={() =>
                                          handleClick(prop, device[prop], false)
                                        }
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  </Visible>
                                }
                              </Visible>
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </Visible>
                </>
              )
              // )
            )}
            {device.Custom !== undefined && device.Custom.length ? (
              //&& access.Custom.view
              <>
                <Visible
                  permissionNeeded="services.customProperties"
                  capabilityNeeded="view"
                >
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 0,
                        paddingTop: 30,
                        paddingLeft: 0,
                      }}
                    >
                      <Typography color={"primary"} variant="h3">
                        Custom
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {device.Custom.map((prop, i) => (
                    <TableRow>
                      <TableCell className={classes.prop}>
                        {prop.name}
                      </TableCell>
                      <TableCell className={classes.prop}>
                        {prop.value}
                      </TableCell>
                      <TableCell align="right">
                        <Visible
                          permissionNeeded="services.customProperties"
                          capabilityNeeded="manage"
                        >
                          <Button
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() =>
                              handleClick(prop.name, prop.value, true)
                            }
                          >
                            Edit
                          </Button>
                        </Visible>
                      </TableCell>
                    </TableRow>
                  ))}
                </Visible>
              </>
            ) : null}
          </TableBody>
        </Table>
        <Visible
          permissionNeeded="services.systemProperties"
          capabilityNeeded="manage"
        >
          <div className={classes.actions}>
            <Button
              variant="contained"
              color="primary"
              className={classes.removeButton}
              onClick={() => setDialogOpen(true)}
            >
              Delete
            </Button>
          </div>
        </Visible>
      </TableContainer>
    </React.Fragment>
  );
}
