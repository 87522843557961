import { useEffect, useState } from "react";
import { connect } from "react-redux";
import RolesTable from "./RolesTable";
import { fetchRoles } from "../actions/roles";

function RolesContainer({
  setParentTitle,
  roles,
  existingRoleNames,
  fetchRoles,
}) {
  // First mount we want this to load, otherwise just update in background.
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setParentTitle("Roles");
    const fetchData = async () => {
      return await fetchRoles();
    };
    fetchData().then(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RolesTable
        roles={roles}
        existingRoleNames={existingRoleNames}
        fetchRoles={fetchRoles}
        loading={isLoading}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    roles: state.roles.roles,
    existingRoleNames: state.roles.existingRoleNames,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRoles: () => dispatch(fetchRoles()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer);
