import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Delete, Link } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveLinks } from "../../actions/devices";
import { checkOptionLabel } from "../../libs/utils";
import LoadingButton from "../LoadingButton";

export const LinkDevices = ({ device }) => {
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [links, setLinks] = useState([]);
  const [link_id, setLinkId] = useState(0);
  const [link_tag, setLinkTag] = useState("");
  const [tag, setTag] = useState("");
  const devices = useSelector((state) => state.devices.byId);
  const identifier =
    useSelector(
      (state) =>
        state.ui_settings.portal_config.link_devices_identifier ||
        state.ui_settings.portal_config.device_identifier
    ) || "Billing Reference";
  const dispatch = useDispatch();

  useEffect(() => {
    if (device.Links) setLinks(device.Links);
    else setLinks([]);
    setUpdating(false);
  }, [device.Links, open]);

  const handleClose = () => {
    setOpen(false);
    setLinkId(0);
    setLinkTag("");
    setTag("");
  };

  const addLink = () => {
    setLinks([...links, { id: link_id, tag: link_tag }]);
    setLinkId("");
    setLinkTag("");
  };

  const removeLink = (link) => {
    let removal = [...links];
    removal.splice(links.indexOf(link), 1);
    setLinks(removal);
  };

  const handleSave = () => {
    setUpdating(true);
    let additions = [...links],
      current = device.Links,
      removals = [];

    // If its a new link, add the current device to the chain
    if (!device.Links) additions.unshift({ id: device.id, tag: tag });
    // If all links are removed, add existing links to removal array and disregard addition
    else if (additions.length === 1) {
      current.forEach((link) => removals.push(link.id));
      additions = [];
    }
    // Otherwise, look through current links to see which have been removed
    else {
      for (let i = 0; i < current.length; i++) {
        let exists = false;
        for (let j = 0; j < additions.length; j++) {
          if (current[i].id === additions[j].id) exists = true;
        }
        if (!exists) removals.push(current[i].id);
      }
    }
    dispatch(
      saveLinks({
        additions: additions,
        removals: removals,
      })
    );
  };

  const handleTagChange = (value, index) => {
    let updated = [...links];
    updated[index].tag = value;
    setLinks(updated);
  };

  return (
    <>
      <Tooltip disableFocusListener title="Link another device">
        <IconButton onClick={() => setOpen(true)}>
          <Link color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Link Devices</DialogTitle>
        <DialogContent>
          <Typography style={{ marginBottom: 12 }}>
            Link two or more devices together and add tags to identify them by.
          </Typography>
          <Grid container spacing={1}>
            {device.Links === undefined && (
              <>
                <Grid item lg={5}>
                  <TextField
                    value={device[identifier]}
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    fullWidth
                    placeholder="Tag"
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            {links.map((link, i) => (
              <>
                <Grid item lg={5}>
                  <TextField
                    value={devices[link.id][identifier]}
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    value={link.tag}
                    fullWidth
                    onChange={(e) => handleTagChange(e.target.value, i)}
                    variant="outlined"
                  />
                </Grid>
                {device.id !== link.id ? (
                  <Grid item>
                    <Tooltip title="Remove Link">
                      <IconButton onClick={() => removeLink(link)}>
                        <Delete color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
              </>
            ))}
            <Grid item lg={5}>
              <Autocomplete
                options={Object.values(devices).filter(({ id }) => {
                  if (
                    id === device.id ||
                    links.map(({ id }) => id).includes(id)
                  )
                    return false;
                  return true;
                })}
                onChange={(_, v) => (v ? setLinkId(v.id) : setLinkId(0))}
                filterSelectedOptions
                getOptionLabel={(option) =>
                  checkOptionLabel(option, identifier)
                }
                fullWidth
                value={link_id ? devices[link_id] : ""}
                renderInput={(params) => (
                  <TextField
                    key={params.id}
                    {...params}
                    fullWidth
                    label={"Service"}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                placeholder="Tag"
                value={link_tag}
                onChange={(e) => setLinkTag(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Tooltip title="Link Device">
                <IconButton disabled={!link_id || !link_tag} onClick={addLink}>
                  <Add color={!link_id || !link_tag ? "grey" : "primary"} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="primary"
            variant="contained"
            disabled={
              device.Links === links ||
              (device.Links === undefined && (!links.length || !tag)) ||
              updating
            }
            onClick={handleSave}
            isLoading={updating}
            spinnerStyle={{
              height: "25px",
              width: "25px",
              marginRight: "9px",
            }}
          >
            Save
          </LoadingButton>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
