import React from "react";
// import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { daysUptime } from "../libs/utils";
import { useSelector } from "react-redux";

//import DeviceConfiguraiton from "./dialogs/DeviceConfiguraiton";
//import SettingsIcon from "@mui/icons-material/Settings";
//import TicketList from "./dialogs/TicketList";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  noAccess: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoButton: {
    position: "relative",
    float: "right",
    marginTop: 10,
    marginRight: 20,
  },
}));

const DeviceSummary = ({
  account,
  Device,
  //tickets,
  //getting_tickets,
  callback,
  type,
}) => {
  const classes = useStyles();
  // const [configState, setConfigState] = React.useState(false);
  //const [listState, setListState] = React.useState(false);
  const properties = useSelector(
    (state) => state.ui_settings.portal_config.summary_fields
  );
  // const properties = getSummaryProps(type);

  if (Device) {
    var reqId;
    try {
      reqId = Device["Billing Reference"].replace("DR-", "");
    } catch {
      reqId = "-";
    }
    Device["Billing Reference"] = reqId;

    return (
      <React.Fragment>
        <Paper>
          <Grid container spacing={1} className={classes.root}>
            {properties.map((property) => {
              return (
                <React.Fragment key={property.name}>
                  <Grid item xs={4} sm={2}>
                    <Typography>{property.name}</Typography>
                  </Grid>
                  <Grid item xs={8} sm={4}>
                    <Typography
                      style={
                        property.is_bold === true && Device.Links !== undefined
                          ? { fontWeight: "bold" }
                          : null
                      }
                    >
                      {Device[property.value]}
                      {/*
                      {property.name === "Circuit Size:"
                        ? property.data
                          ? property.data.slice(-2) === "Mb"
                            ? property.data
                            : property.data + "Mb"
                          : "-"
                        : property.data || "-"}
                        */}
                    </Typography>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={4} sm={2}>
              <Typography>Device uptime</Typography>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Typography>
                {Device["Device Uptime"]
                  ? daysUptime(Device["Device Uptime"])
                  : "-"}
                {/*
                {property.name === "Circuit Size:"
                  ? property.data
                    ? property.data.slice(-2) === "Mb"
                      ? property.data
                      : property.data + "Mb"
                    : "-"
                  : property.data || "-"}
                  */}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default DeviceSummary;
