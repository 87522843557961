import React, { useEffect, useState } from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // version 1.x
import Grid from "@material-ui/core/Grid";
import { MenuItem, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Switch,
} from "@material-ui/core";
//import { common_columns } from "../CustomDatatable";
import TransferList from "../TransferList";
import TabPanel from "../TabPanel";
import { setTime } from "../../libs/utils";
import LoadingButton from "../LoadingButton";
import ReportFilterSettings from "../ReportFilterSettings";
import Visible from "../Visible";

const useStyles = makeStyles((theme) => ({
  dateTime: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  selector: {
    marginBottom: theme.spacing(1),
  },
  dialogActionButton: {
    width: "100px",
  },
}));

// All weekdays in order, with values we pass to schedule
const weekdayValues = {
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7,
  Sunday: 1,
};

export default function ReportSettings({
  visible,
  callbackClose,
  setStartDate,
  setEndDate,
  startDate,
  setDateType,
  endDate,
  menuItems,
  extraConfig,
  format,
  pdfRecipients,
  setPdfStates,
  selectedReport,
  loading,
  timeRangeDisabled,
  callbackSaveReport,
  account_id,
  scheduledLoading,
  scheduledError,
  updateColumns,
  customColumns,
  updateReportFilters,
  reportFilters,
  runReport,
  schedulingError,
  clearSchedulingError,
  rowData,
}) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [selected, setSelect] = useState("24 Hours");
  const [scheduleReport, setScheduleReport] = useState(false);
  const [scheduledReportName, setScheduledReportName] = useState("");
  const [scheduledReportEvery, setScheduledReportEvery] = useState("Day");
  const [pickerDisabled, setPickerDisabled] = useState(true);
  const [dailyPicker, setDailyPicker] = useState("00:00");
  const [weeklyPicker, setWeeklyPicker] = useState("2");
  const [monthlyPicker, setMonthlyPicker] = useState("");
  const [saveReportOpen, setSaveReportOpen] = useState(false);
  const [isSavingReport, setIsSavingReport] = useState(false);
  const [isCreatingReport, setIsCreatingReport] = useState(false);

  const common_columns_list = useSelector(
    (state) => state.devices.deviceProperties.perProp
  );
  var common_columns = [];

  common_columns_list.forEach((element) => {
    if (!element.name.match(/id|Postcode|Billing Reference|.*ID.*|Broadband\?/))
      common_columns.push(element.name);
  });

  common_columns.sort();
  const [validReceipientFormat, setValidReceipientFormat] = useState(false);
  const [page, setPage] = useState(0);
  //const [scheduledResponseLoading,setScheduledResponseLoading] = useState(false)
  //const [scheduledReportSubmitOutput, setScheduledReportSubmitOutput] = useState('')

  useEffect(() => {
    if (rowData) {
      const {
        scheduleReadable,
        recipients,
        report_format,
        report_name,
        custom_columns,
        filters,
      } = rowData;
      const schedule = scheduleReadable.split("-"); // i=0 -> reportEvery, i=1 -> time (daily) or day (weekly, monthly), i=2 -> time (weekly, monthly)
      setEditMode(true);
      setScheduledReportName(report_name);
      setSelect(rowData.time_range);
      handleReceipientValidation(recipients, report_format);
      if (filters) {
        updateReportFilters(filters);
      }
      if (custom_columns) updateColumns(custom_columns);
      if (scheduleReadable) {
        setScheduleReport(true);
        if (schedule[0].includes("Daily")) {
          setScheduledReportEvery("Day");
          setDailyPicker(schedule[1]);
        } else if (schedule[0].includes("Weekly")) {
          setScheduledReportEvery("Week");
          setWeeklyPicker(weekdayValues[schedule[1]]);
          setDailyPicker(schedule[2]);
        } else if (schedule[0].includes("Monthly")) {
          setScheduledReportEvery("Month");
          setMonthlyPicker(schedule[1]);
          setDailyPicker(schedule[2]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  const handleTimeChange = (e) => {
    setSelect(e);
    if (e === "Custom") {
      setPickerDisabled(false);
      setDateType(e);
    } else {
      var times = setTime(e);
      setPickerDisabled(true);
      setStartDate(times.start);
      setEndDate(times.end);
      setDateType(times.date_type);
    }
  };

  const handleClose = () => {
    updateReportFilters([{ property: "", operator: null, value: "" }]);
    setSaveReportOpen(false);
    setScheduledReportName("");
    setDailyPicker("00:00");
    setWeeklyPicker("2");
    setMonthlyPicker("");
    setScheduleReport(false);
    setEditMode(false);
    setScheduledReportName("");
    setSelect("24 Hours");
    setIsSavingReport(false);
    setPage(0);
    callbackClose();
  };

  const handleMonthValidation = (target) => {
    if ((target > 0 && target < 32) || target === "") {
      setMonthlyPicker(target);
    }
  };
  const handleReceipientValidation = (target, report_format) => {
    var valid = false;
    target.split(",").forEach((element) => {
      if (
        element
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        valid = true;
      } else {
        valid = false;
      }
    });

    setValidReceipientFormat(valid);
    setPdfStates(report_format, target, valid);

    return valid;
  };

  const filtersValid = () => {
    if (
      reportFilters.length === 1 &&
      reportFilters[0].property === "" &&
      reportFilters[0].value === "" &&
      reportFilters[0].operator === null
    ) {
      // Default filter is fine.
      return true;
    } else {
      let flattened = [].concat.apply([], reportFilters);
      if (flattened.find((f) => !f.property || !f.value || !f.operator)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (schedulingError === "Success.") {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulingError]);

  const handleScheduledReportSave = async (new_report) => {
    if (new_report) setIsCreatingReport(true);
    else setIsSavingReport(true);
    clearSchedulingError();
    var time_range;
    if (timeRangeDisabled) {
      time_range = "N/A";
    } else {
      time_range = selected;
    }
    try {
      await callbackSaveReport(
        time_range,
        scheduleReport ? cronBuilder() : "",
        scheduleReport ? cronHandler(cronBuilder()) : "",
        scheduledReportName,
        editMode,
        editMode ? rowData.scheduleReadable : false,
        reportFilters
      ).then(() => {
        if (new_report) setIsCreatingReport(false);
        else setIsSavingReport(false);
      });
    } catch (err) {
      setIsCreatingReport(false);
      setIsSavingReport(false);
    }
  };

  const cronBuilder = () => {
    var cron = "";
    if (scheduledReportEvery === "Day") {
      cron =
        dailyPicker.split(":")[1] +
        " " +
        dailyPicker.split(":")[0] +
        " * * ? *";
    } else if (scheduledReportEvery === "Week") {
      cron =
        dailyPicker.split(":")[1] +
        " " +
        dailyPicker.split(":")[0] +
        " ? * " +
        weeklyPicker +
        " *";
    } else if (scheduledReportEvery === "Month") {
      cron =
        dailyPicker.split(":")[1] +
        " " +
        dailyPicker.split(":")[0] +
        " " +
        monthlyPicker +
        " * ? *";
    }

    return cron;
  };

  const cronHandler = (val) => {
    var type;
    var values = val.split(" ");
    var hour = values[1];
    var minutes = values[0];
    if (values[2] !== "?" && values[2] !== "*") {
      type = "Monthly-" + values[2] + "-";
    } else if (values[4] !== "?" && values[4] !== "*") {
      var dayMap = {
        1: "Sunday",
        2: "Monday",
        3: "TuesDay",
        4: "Wednesday",
        5: "Thursday",
        6: "Friday",
        7: "Saturday",
      };
      type = "Weekly-" + dayMap[values[4]] + "-";
    } else {
      type = "Daily-";
    }
    type = type + hour + ":" + minutes;
    return type;
  };

  const addToColumnList = (cols) => {
    updateColumns(cols);
  };

  const checkReportName = (name) => {
    if (
      !name.match(/.*[,:;"\][\\/'@)([£$?+=*&^%$!~`€#¢∞§¶•ªº¡“‘«æ«…≥÷≤].*/gm) &&
      name.length <= 64
    )
      setScheduledReportName(name);
  };

  return (
    <React.Fragment>
      <Dialog
        open={visible}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <Dialog
          open={saveReportOpen}
          onClose={() => {
            setSaveReportOpen(false);
            setIsSavingReport(false);
            clearSchedulingError();
          }}
          size="xs"
        >
          <DialogTitle>Save Report</DialogTitle>
          <DialogContent>
            <div style={{ padding: "0px", marginBottom: "-7px" }}>
              <TextField
                label="Report Name"
                id="reportNameInput"
                value={scheduledReportName}
                variant="outlined"
                size="small"
                style={{ width: "300px" }}
                className={classes.selector}
                //maxWidth="xs"
                required
                onChange={(e) => checkReportName(e.target.value)}
                fullWidth
              />
              {schedulingError && schedulingError !== "Success." && (
                <Alert severity="error">{schedulingError}</Alert>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={() => handleScheduledReportSave(true)}
              disabled={!scheduledReportName}
              spinnerStyle={{
                height: "25px",
                width: "25px",
                marginRight: "7px",
              }}
              isLoading={isCreatingReport}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <div style={{ paddingTop: "0px" }}>
          <Tabs
            value={page}
            indicatorColor="primary"
            onChange={(_, v) => setPage(v)}
          >
            <Tab label="Configuration" />
            <Tab label="Properties" />
            <Tab label="Filters" />
          </Tabs>
        </div>
        <TabPanel value={page} index={0}>
          <div style={{ marginBottom: "-15px", maxWidth: "400px" }}>
            {editMode ? (
              <Typography variant="h6" style={{ marginBottom: 20 }}>
                {scheduledReportName}
              </Typography>
            ) : null}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {extraConfig ? extraConfig : null}
              <TextField
                label="Report Format"
                id="format"
                value={format}
                size="small"
                className={classes.selector}
                variant="outlined"
                select
                required
                disabled={selectedReport === "graphicalUtilisation"}
                onChange={(e) =>
                  setPdfStates(
                    e.target.value,
                    pdfRecipients,
                    validReceipientFormat
                  )
                }
                fullWidth
              >
                <MenuItem value={"CSV"}>CSV</MenuItem>
                <MenuItem value={"HTML"}>HTML</MenuItem>
              </TextField>
              <TextField
                label="Select Time"
                id="timeselection"
                value={selected}
                select
                variant="outlined"
                size="small"
                disabled={timeRangeDisabled}
                className={classes.selector}
                //maxWidth="xs"
                required
                onChange={(e) => handleTimeChange(e.target.value)}
                fullWidth
              >
                {menuItems.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
              {format === "HTML" && !scheduleReport && (
                <TextField
                  label="Recipients"
                  id="recipients"
                  value={pdfRecipients}
                  variant="outlined"
                  size="small"
                  className={classes.selector}
                  placeholder={"Comma Delimited List"}
                  required
                  error={!validReceipientFormat}
                  onChange={(e) =>
                    handleReceipientValidation(e.target.value, format)
                  }
                  fullWidth
                />
              )}
              <Grid
                container
                direction={"rows"}
                alignItems="center"
                //alignItems="flex-end"
                maxWidth="xs"
                justifyContent={"space-evenly"}
              >
                {!pickerDisabled && (
                  <>
                    <Grid
                      item
                      style={{ marginTop: "10px", marginBottom: "15px" }}
                    >
                      <DateTimePicker
                        value={startDate}
                        maxWidth="xs"
                        format="do MMM HH:mm"
                        disabled={pickerDisabled || timeRangeDisabled}
                        className={classes.dateTime}
                        label="Start"
                        //variant="static"
                        size="small"
                        onChange={(date) => {
                          setStartDate(date);
                          setDateType("Custom");
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <DateTimePicker
                        value={endDate}
                        maxWidth="xs"
                        format="do MMM HH:mm"
                        label="End"
                        disabled={pickerDisabled || timeRangeDisabled}
                        className={classes.dateTime}
                        //variant="static"
                        size="small"
                        onChange={(date) => {
                          setEndDate(date);
                          setDateType("Custom");
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid container direction="column">
                  {!(
                    selected === "Custom" || account_id.includes("_SUPER")
                  ) && (
                    <Grid item style={{ width: "200px" }}>
                      <FormControlLabel
                        className={classes.selector}
                        control={
                          <Switch
                            color="primary"
                            checked={scheduleReport && !(selected === "Custom")}
                            disabled={
                              selected === "Custom" ||
                              account_id.includes("_SUPER")
                            }
                            onChange={() => {
                              setScheduleReport(!scheduleReport);
                              if (!rowData)
                                handleReceipientValidation("", format);
                            }}
                            name="prelive"
                          />
                        }
                        label="Schedule Report"
                      />
                    </Grid>
                  )}

                  {scheduleReport && selected !== "Custom" ? (
                    <Grid container direction="column">
                      <Grid item>
                        <TextField
                          label="Recipients"
                          id="recipients"
                          value={pdfRecipients}
                          variant="outlined"
                          size="small"
                          className={classes.selector}
                          placeholder={"Comma Delimited List"}
                          //maxWidth="xs"
                          required
                          error={!validReceipientFormat}
                          onChange={(e) =>
                            handleReceipientValidation(e.target.value, format)
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Report Every"
                          id="scheduleSelector"
                          value={scheduledReportEvery}
                          select
                          variant="outlined"
                          size="small"
                          className={classes.selector}
                          //maxWidth="xs"
                          required
                          onChange={(e) =>
                            setScheduledReportEvery(e.target.value)
                          }
                          fullWidth
                        >
                          <MenuItem key={"scheduleDay"} value={"Day"}>
                            {"Daily"}
                          </MenuItem>
                          <MenuItem key={"scheduleWeek"} value={"Week"}>
                            {"Weekly"}
                          </MenuItem>
                          <MenuItem key={"scheduleMonth"} value={"Month"}>
                            {"Monthly"}
                          </MenuItem>
                        </TextField>
                      </Grid>
                      {scheduledReportEvery === "Week" ? (
                        <Grid container direction="rows">
                          <Grid item>
                            <TextField
                              label="Every"
                              id="weekdaySelector"
                              value={weeklyPicker}
                              select
                              variant="outlined"
                              size="small"
                              className={classes.selector}
                              //maxWidth="xs"
                              required
                              onChange={(e) => setWeeklyPicker(e.target.value)}
                            >
                              {Object.keys(weekdayValues).map((day) => (
                                <MenuItem key={day} value={weekdayValues[day]}>
                                  {day}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item style={{ paddingLeft: 15 }}>
                            <TextField
                              id="timeMonth"
                              //label="Select Time"
                              type="time"
                              color="primary"
                              value={dailyPicker}
                              style={{ width: 100 }}
                              onChange={(e) => setDailyPicker(e.target.value)}
                              //className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : scheduledReportEvery === "Day" ? (
                        <Grid item>
                          <TextField
                            id="timeDaily"
                            label="Select Time"
                            type="time"
                            color="primary"
                            value={dailyPicker}
                            style={{ width: 100 }}
                            onChange={(e) => setDailyPicker(e.target.value)}
                            //className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        </Grid>
                      ) : scheduledReportEvery === "Month" ? (
                        <Grid container direction="rows">
                          <Grid item>
                            <TextField
                              label="Day of Month"
                              id="monthlySelector"
                              value={monthlyPicker}
                              variant="outlined"
                              size="small"
                              placeholder="1-31"
                              type="number"
                              className={classes.selector}
                              //maxWidth="xs"
                              required
                              onChange={(e) =>
                                handleMonthValidation(e.target.value)
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item style={{ paddingLeft: 15 }}>
                            <TextField
                              id="timeMonth"
                              //label="Select Time"
                              type="time"
                              color="primary"
                              value={dailyPicker}
                              style={{ width: 100 }}
                              onChange={(e) => setDailyPicker(e.target.value)}
                              //className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </TabPanel>
        <TabPanel value={page} index={1}>
          <div style={{ margin: "-16px", marginRight: "0px" }}>
            <TransferList
              l={common_columns.filter((col) => !customColumns.includes(col))}
              r={customColumns}
              saveSelected={(cols) => addToColumnList(cols)}
            />
          </div>
        </TabPanel>
        <TabPanel value={page} index={2}>
          <ReportFilterSettings
            reportFilters={reportFilters}
            setReportFilters={updateReportFilters}
          />
        </TabPanel>
        <DialogActions style={{ paddingBottom: "12px", paddingRight: "15px" }}>
          {scheduleReport &&
          scheduledError !== "Success." &&
          !scheduledLoading &&
          scheduledError ? (
            <Alert severity="error">{scheduledError}</Alert>
          ) : null}
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={runReport}
            disabled={
              loading ||
              isSavingReport ||
              isCreatingReport ||
              (format === "HTML" && !validReceipientFormat) ||
              (format !== "HTML" && format !== "CSV") ||
              !filtersValid()
            }
            isLoading={loading}
            spinnerStyle={{
              height: "25px",
              width: "25px",
              marginRight: "9px",
            }}
          >
            Run
          </LoadingButton>
          <Visible permissionNeeded="reports" capabilityNeeded="manage">
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={() => {
                editMode && rowData
                  ? handleScheduledReportSave()
                  : setSaveReportOpen(true);
              }}
              isLoading={isSavingReport}
              disabled={
                selected === "Custom" ||
                loading ||
                isSavingReport ||
                isCreatingReport ||
                (scheduleReport &&
                  ((scheduledReportEvery === "Month" && monthlyPicker === "") ||
                    !validReceipientFormat)) ||
                (format === "HTML" && !validReceipientFormat) ||
                (format !== "HTML" && format !== "CSV") ||
                !filtersValid()
              }
              spinnerStyle={{
                height: "25px",
                width: "25px",
                marginRight: "9px",
              }}
            >
              Save
            </LoadingButton>
          </Visible>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
