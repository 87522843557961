import { combineReducers } from "redux";

import reports from "./reports";
import deviceGroups from "./devicegroups";
import dashboard from "./dashboard";
import alerts from "./alerts";
import deviceAlerts from "./deviceAlerts";
import maintenance from "./maintenance";
import automation from "./automation";
import roles from "./roles";
import { devices, deviceSearch } from "./devices";
import { ui_settings } from "./ui_settings";
// import deviceStorage from '../libs/deviceStorage'

function userSession(
  state = {
    logged_in: !!sessionStorage.jwt,
    properties: null,
    app_offline: false,
    jwt_token: null,
    ui_settings: { search_table: { rows: 10, page: 1 } },
  },
  action
) {
  if (action.payload && action.payload.status === 401) {
    return Object.assign({}, state, {
      logged_in: false,
      login_error_msg:
        action.payload.response && action.payload.response.error !== ""
          ? action.payload.response.error
          : action.payload.statusText,
    });
  }
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return Object.assign({}, state, {
        logged_in: true,
      });
    case "LOGOUT_RECEIVE":
      localStorage.removeItem("jwt");
      return Object.assign({}, state, {
        logged_in: false,
        jwt_token: null,
      });
    case "SESSION_RECEIVE":
      return Object.assign({}, state, {
        app_offline: false,
        logged_in: true,
        properties: action.payload,
        view_id: "All Services",
      });
    case "SESSION_FAILURE":
      return Object.assign({}, state, {
        app_offline: true,
        logged_in: false,
        login_error_msg:
          action.payload.response && action.payload.response.error !== ""
            ? action.payload.response.error
            : action.payload.statusText,
      });
    case "JWT_RECEIVE":
      console.log(action.payload);
      localStorage.setItem("jwt", action.payload.IdToken);
      // var token = deviceStorage.saveItem("jwt", action.payload.token);
      return Object.assign({}, state, {
        logged_in: true,
        jwt_token: action.payload.IdToken,
      });
    case "JWT_REQUEST":
      return Object.assign({}, state, {
        login_error_msg: null,
      });
    case "JWT_FAILURE":
      return Object.assign({}, state, {
        login_error_msg:
          action.payload.response && action.payload.response.error !== ""
            ? action.payload.response.error
            : action.payload.statusText,
      });
    case "COGNITO_USER_RECEIVE":
      return Object.assign({}, state, {
        username: action.payload.username,
        role: action.payload.attributes["custom:role"],
        properties: {
          account_id: action.payload.attributes["custom:account_id"],
          customer: action.payload.attributes["custom:customer"],
        },
        email: action.payload.attributes.email,
        display_name:
          action.payload.attributes.given_name +
          " " +
          action.payload.attributes.family_name,
        is_superuser: parseInt(action.payload.attributes["custom:superuser"]),
        view_id:
          action.payload.attributes["custom:active_view_id"] || "All Services",
        logged_in: true,
      });
    case "GET_BRANDING_SUCCESS":
      return Object.assign({}, state, {
        branding: action.payload,
      });
    case "SAVE_BRANDING_SUCCESS":
      return Object.assign({}, state, {
        branding: action.payload,
      });
    case "GET_LOGO_SUCCESS":
      return Object.assign({}, state, {
        logo_url: action.payload,
      });
    case "GET_PORTAL_LOGO_SUCCESS":
      return Object.assign({}, state, {
        portal_logo_url: action.payload,
      });
    case "GRAPH_CONFIG_RETRIEVED":
      return Object.assign({}, state, {
        branding: { ...state.branding, graphConfig: action.payload },
      });
    default:
      return state;
  }
}

const combinedReducer = combineReducers({
  devices,
  deviceSearch,
  deviceGroups,
  userSession,
  dashboard,
  alerts,
  deviceAlerts,
  reports,
  maintenance,
  automation,
  ui_settings,
  roles,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_RECEIVE") {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
