import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  IconButton,
  MenuItem,
  Popover,
  CircularProgress,
  Paper,
  MenuList,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Typography,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  Delete as DeleteIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Add,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { deleteUserSettings, switchUserView } from "../../actions/ui_settings";

const styles = makeStyles((theme) => ({
  root: {
    //padding: 12,
    maxWidth: 300,
  },
  saved_views_button: {
    "white-space": "nowrap",
  },
}));

export default function SavedViewsMenu({
  user_views,
  view_name,
  viewComponent,
  user_id,
  callback,
  saveViewCallback,
  allowMultipleViews,
}) {
  const [savedviewsAnchorEl, setSavedviewsAnchorEl] = React.useState(null);
  const [isLoadingView, setIsLoadingView] = React.useState(false);
  const views_open = Boolean(savedviewsAnchorEl);
  const dispatch = useDispatch();
  const classes = styles();

  const handleSwitchViewClick = async (view, views) => {
    // Display spinner instead of success icon while fetching view data.
    setIsLoadingView(view);
    var filters = [];
    for (const item of views) {
      if (item.name === view) {
        filters = item.filters;
      }
    }
    await dispatch(
      switchUserView({ name: view, table: viewComponent }, filters, user_id)
    ).then((res) => {
      callback && callback(filters);
      setIsLoadingView(false); // View has been loaded.
    });
  };

  const deleteUserView = async (view) => {
    await dispatch(
      deleteUserSettings({
        table: viewComponent,
        view_name: view,
        user_id: user_id,
      })
    ).then(() => {
      // Need to let delete finish because get user settings changes the view
      // name back to what you deleted.
      if (view_name === view) {
        // Needed to not swap to random views when > 2 custom views.
        let nextView;
        if (user_views - 1 === 1) {
          nextView = "All Services";
        } else {
          nextView = user_views.filter(
            (user_view) => user_view.name !== view
          )[0].name;
        }
        dispatch(
          switchUserView({ name: nextView, table: viewComponent }, [], user_id)
        );
      }
    });
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={true ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={true ? "true" : undefined}
        variant="outlined"
        color="primary"
        disableElevation
        endIcon={<KeyboardArrowDown />}
        className={classes.saved_views_button}
        onClick={(event) => setSavedviewsAnchorEl(event.currentTarget)}
      >
        Saved Views
      </Button>
      <Popover
        id={"views_popover"}
        open={views_open}
        anchorEl={savedviewsAnchorEl}
        onClose={() => setSavedviewsAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{ sx: { height: "100px" } }}
      >
        <Paper className={classes.root}>
          <MenuList>
            <MenuItem onClick={() => saveViewCallback()}>
              <Add style={{ marginRight: 15 }} />
              <ListItemText>Create</ListItemText>
            </MenuItem>
            <Divider component="li" sx={{ borderBottomWidth: 4 }} />
            {user_views &&
              user_views.map((view) => {
                return (
                  <MenuItem
                    onClick={() => handleSwitchViewClick(view.name, user_views)}
                  >
                    {view.name === view_name && !isLoadingView && (
                      <CheckCircleOutlineIcon
                        color="primary"
                        style={{ marginRight: 15 }}
                      />
                    )}
                    {((view.name !== view_name && isLoadingView === false) ||
                      (isLoadingView && isLoadingView !== view.name)) && (
                      <RadioButtonUncheckedIcon
                        color="primary"
                        style={{ marginRight: 15 }}
                      />
                    )}
                    {view.name === isLoadingView && (
                      <CircularProgress
                        style={{
                          height: "24px",
                          width: "24px",
                          marginRight: 15,
                        }}
                      />
                    )}
                    <Typography noWrap>{view.name}</Typography>
                    <ListItemSecondaryAction>
                      {view.name !== "All Services" &&
                        view.name !== view_name &&
                        !isLoadingView && (
                          <IconButton
                            size="small"
                            disabled={isLoadingView}
                            onClick={() => deleteUserView(view.name)}
                            style={{ marginRight: -5 }}
                          >
                            <DeleteIcon color="primary" />
                          </IconButton>
                        )}
                    </ListItemSecondaryAction>
                  </MenuItem>
                );
              })}
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}
