import React, { Component } from "react";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";

import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

import { fetchJWT } from "../actions/session";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://giacom.com/">
        {"Giacom (Platforms)"}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = (theme) => ({
  paper: {
    marginTop: "64px",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  signInHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: "24px 0px 16px",
  },
  copyright: {
    marginTop: theme.spacing(1),
  },
});

class AWSLoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      new_password: "",
      new_password_confirm: "",
      account_id: "",
      error_msg: "",
      forgot_password: false,
      verification_code_sent: false,
      verification_code: null,
      password_change_failed: false,
      password_change_msg: null,
      new_password_required: false,
    };
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  componentDidMount() {
    this.props.setParentTitle("Login Page");
  }

  setUsername(event) {
    // console.log(text)
    this.setState({ username: event.target.value });
  }

  setPassword(event) {
    // console.log(text)
    this.setState({ password: event.target.value });
  }

  setNewPassword(event) {
    // console.log(text)
    this.setState({ new_password: event.target.value });
  }

  setConfirmNewPassword(event) {
    // console.log(text)
    this.setState({ new_password_confirm: event.target.value });
  }

  setVerificationCode(event) {
    // console.log(text)
    this.setState({ verification_code: event.target.value });
  }

  setAccountID(event) {
    // console.log(text)
    this.setState({ account_id: event.target.value });
  }

  async handleButtonClick() {
    this.setState({ error_msg: null });
    try {
      await Auth.signIn(this.state.username, this.state.password).then(
        (res) => {
          if (res.challengeName === "NEW_PASSWORD_REQUIRED")
            this.setState({ new_password_required: true });
        }
      );
    } catch (error) {
      this.setState({ error_msg: error.message });
    }
  }

  startResetPassword() {
    Auth.forgotPassword(this.state.username)
      .then((data) => {
        this.setState({
          verification_code_sent: true,
          password_change_msg:
            "Reset code sent to " + data.CodeDeliveryDetails.Destination,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          password_change_failed: true,
          password_change_msg: err.message,
        });
      });
  }

  async resetPassword() {
    if (this.state.new_password !== this.state.new_password_confirm) {
      this.setState({
        password_change_msg: "New passwords do not match",
        password_change_failed: true,
      });
      return;
    }

    if (this.state.new_password_required) {
      await Auth.signIn(this.state.username, this.state.password)
        .then(
          async (usr) =>
            await Auth.completeNewPassword(usr, this.state.new_password).catch(
              (e) => {
                this.setState({
                  password_change_msg: e.message,
                  password_change_failed: true,
                });
                console.log(e);
                return;
              }
            )
        )
        .catch((err) => {
          this.setState({
            password_change_msg: err.message,
            password_change_failed: true,
          });
          console.log(err);
          return;
        });
      // .finally(
      //   async () =>
      //     await Auth.signIn(this.state.username, this.state.new_password)
      // );
    } else {
      if (this.state.new_password === this.state.new_password_confirm) {
        Auth.forgotPasswordSubmit(
          this.state.username,
          this.state.verification_code,
          this.state.new_password
        )
          .then((data) => {
            this.setState({
              password_change_msg: "Password changed successfully.",
              password_change_failed: false,
            });
            // console.log(data)
          })
          .catch((err) => {
            this.setState({
              password_change_msg: err.message,
              password_change_failed: true,
            });
            console.log(err);
          });
      } else {
        this.setState({
          password_change_msg: "New passwords do not match",
          password_change_failed: true,
        });
      }
    }
  }

  render() {
    const { classes } = this.props;

    // const { } = this.props
    if (this.state.forgot_password || this.state.new_password_required) {
      return (
        <Container className={classes.container} maxWidth="xs">
          <CssBaseline />
          <Paper className={classes.paper}>
            <div className={classes.signInHeader}>
              <img
                style={{ marginRight: "5%" }}
                src={
                  "https://logic-one.s3.eu-west-2.amazonaws.com/public/LogicOneBlack.svg"
                }
                alt="LogicOne"
                height="48"
              />
            </div>
            <form>
              <TextField
                autoFocus
                margin="normal"
                id="username"
                label="Username"
                type="text"
                fullWidth
                variant="outlined"
                autoComplete="username"
                onChange={(event) => this.setUsername(event)}
              />
              {!this.state.verification_code_sent &&
              !this.state.new_password_required ? (
                <Button
                  onClick={() => this.startResetPassword()}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Get verification code
                </Button>
              ) : (
                <React.Fragment>
                  {this.state.verification_code_sent &&
                  !this.state.new_password_required ? (
                    <TextField
                      autoFocus
                      margin="normal"
                      id="verification_code"
                      label="Verification code"
                      type="number"
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      onChange={(event) => this.setVerificationCode(event)}
                    />
                  ) : null}
                  <TextField
                    autoFocus
                    margin="normal"
                    id="new_password"
                    label="New Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    onChange={(event) => this.setNewPassword(event)}
                  />
                  <TextField
                    autoFocus
                    margin="normal"
                    id="new_password_confirm"
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    onChange={(event) => this.setConfirmNewPassword(event)}
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.resetPassword()}
                  >
                    Change password
                  </Button>
                </React.Fragment>
              )}
            </form>
            {this.state.password_change_failed && (
              <Alert severity="error">{this.state.password_change_msg}</Alert>
            )}
            {!this.state.password_change_failed &&
              this.state.password_change_msg && (
                <Alert severity="success">
                  {this.state.password_change_msg}
                </Alert>
              )}
            <Grid container>
              <Grid item xs>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => {
                    console.log("back to login..");
                    this.setState({
                      new_password_required: false,
                      password_change_msg: null,
                      password_change_failed: false,
                      forgot_password: false,
                    });
                  }}
                >
                  Back to login
                </Link>
              </Grid>
              <Grid item>
                {this.state.verification_code_sent && (
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => this.startResetPassword()}
                  >
                    Get new code
                  </Link>
                )}
              </Grid>
            </Grid>
          </Paper>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      );
    } else {
      return (
        <Container className={classes.container} maxWidth="xs">
          <CssBaseline />
          <Paper p={2} className={classes.paper}>
            <div className={classes.signInHeader}>
              <img
                style={{ marginRight: "5%" }}
                src={
                  "https://logic-one.s3.eu-west-2.amazonaws.com/public/LogicOneBlack.svg"
                }
                alt="LogicOne"
                height="48"
              />
            </div>
            <form>
              <TextField
                autoFocus
                margin="normal"
                id="username"
                label="Username"
                type="text"
                fullWidth
                variant="outlined"
                autoComplete="username"
                onChange={(event) => this.setUsername(event)}
              />
              <TextField
                autoFocus
                margin="normal"
                id="password"
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                autoComplete="current-password"
                onChange={(event) => this.setPassword(event)}
              />
              <Button
                onClick={() => this.handleButtonClick()}
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              {this.state.error_msg && (
                <Alert severity="error">{this.state.error_msg}</Alert>
              )}
              {this.props.login_error_msg && (
                <Alert severity="error">{this.props.login_error_msg}</Alert>
              )}
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => this.setState({ forgot_password: true })}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>{/* sign up link here possibly? */}</Grid>
              </Grid>
            </form>
          </Paper>
          <Box mt={8} className={classes.copyright}>
            <Copyright />
          </Box>
        </Container>
      );
    }
  }
}

function mapStateToProps(state) {
  const { login_error_msg } = state.userSession;
  return {
    login_error_msg,
  };
}

export default connect(mapStateToProps, { fetchJWT })(
  withStyles(styles)(AWSLoginContainer)
);
