import React from "react";
import { ArrowBackIos } from "@material-ui/icons";
import { Button, Typography, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function ContainerHeader({ title, custom }) {
  const history = useHistory();
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Tooltip title="Go Back">
          <Button
            onClick={() =>
              custom || !title ? history.goBack() : history.push("/")
            }
            color="primary"
          >
            <ArrowBackIos />
          </Button>
        </Tooltip>
        {title && <Typography variant="h3">{title}</Typography>}
      </div>
    </>
  );
}
