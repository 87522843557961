import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {},
};

class AlertsTableToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showCleared: false };
  }

  handleClick = () => {
    this.setState({ showCleared: !this.state.showCleared });
    this.props.toggleCleared();
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Toggle Cleared Alerts"}>
          <IconButton className={classes.iconButton} onClick={this.handleClick}>
            {this.state.showCleared ? (
              <CheckCircleIcon className={classes.deleteIcon} />
            ) : (
              <CheckCircleOutlineIcon className={classes.deleteIcon} />
            )}
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "AlertsTableToolbar" })(
  AlertsTableToolbar
);
