import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IS_EMBEDDED } from "../constants";
import DateFnsUtils from "@date-io/date-fns";
import { FilterList } from "@material-ui/icons/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Badge from "@material-ui/core/Badge";
import { Grid, Button, Popover } from "@material-ui/core";
import SaveUserView from "./dialogs/SaveUserView";
import { createUserSettings, updateUserSettings } from "../actions/ui_settings";
import {
  filterPropertiesForAutocomplete,
  filterAddOperator,
  deleteFilter,
  filterEditor,
} from "../libs/utils";
import FilterRow from "./FilterRow";

export default function FilterSettings({
  deviceProperties,
  deviceFilter,
  updateDeviceFilter,
  updateFilterOutput,
  savedFilters,
  filterValid,
  user_id,
  defaultView,
  byViewName,
  viewName,
  viewComponent,
}) {
  const [filterOn, setFilterOn] = React.useState(false);
  const [savedviewsAnchorEl, setSavedviewsAnchorEl] = React.useState(null);
  const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const dispatch = useDispatch();

  const saveUserView = async (save_name) => {
    var updateFilter = deviceFilter;
    if (deviceFilter.length === 1) {
      if (
        deviceFilter[0]["property"] === "" &&
        deviceFilter[0]["value"] === "" &&
        deviceFilter[0]["operator"] === null
      ) {
        updateFilter = [];
      }
    }
    const payload = {
      table: viewComponent,
      view_name: save_name,
      user_id: user_id,
      filters: updateFilter,
      //columns: columnOrder.map((i) => columns[i]),
    };
    if (save_name in byViewName) {
      payload["columns"] = byViewName[save_name]["columns"];
    }
    setSaveDialogOpen(false);
    if (!defaultView || user_id !== "default")
      dispatch(updateUserSettings(payload));
    else dispatch(createUserSettings(payload));
    //setChangeState(false);
  };

  const filterProps = filterPropertiesForAutocomplete(
    useSelector((state) => state.devices.deviceProperties.perProp)
  );

  // Don't want this in the column select.
  filterProps.push({
    group: "Circuit",
    name: "Status",
  });

  if (viewComponent === "AlertsTable") {
    filterProps.push({
      group: "Alerts",
      name: "Alert Began",
    });
  }

  const setFilters = (filter) => {
    updateDeviceFilter(filter);
  };

  const _filterEditor = (item, value, position, arrayPosition) => {
    setFilters(
      filterEditor(deviceFilter, item, value, position, arrayPosition)
    );
  };

  const _deleteFilter = (position, arrayPosition) => {
    setFilters(deleteFilter(deviceFilter, position, arrayPosition));
  };

  const _filterAddOperator = (verb, position) => {
    setFilters(filterAddOperator(deviceFilter, verb, position));
  };

  const setInitialDate = (position, arrayPosition) => {
    /* 
      When date/time filter is selected the current date has to fill
      the date field, set this as state before returnig the value.
      Otherwise, user has to click the date field for the apply 
      button to stop being disabled.
     */
    let now = new Date().getTime();
    _filterEditor("value", now, position, arrayPosition);
    return now;
  };

  let hasFilter = savedFilters.filter((filter) => {
    return !Array.isArray(filter)
      ? filter["property"] && filter["value"] && filter["operator"]
      : filter.every((f) => f["property"] && f["value"] && f["operator"]);
  }).length;

  if (hasFilter.length) {
    if (
      savedFilters[0]["property"] !== "" &&
      savedFilters[0]["value"] !== "" &&
      savedFilters[0]["operator"] !== null
    ) {
      hasFilter = "";
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item>
        <Badge
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          color="secondary"
          badgeContent={savedFilters.length}
          invisible={!hasFilter}
        >
          <Button
            variant="outlined"
            color="primary"
            //aria-describedby={"tmp"}
            startIcon={<FilterList />}
            //onClick={() => handleFilterCard()}
            onClick={(event) => {
              setSavedviewsAnchorEl(event.currentTarget);
              setFilterOn(true);
            }}
          >
            Filters
          </Button>
        </Badge>
        <Popover
          id={"tmp"}
          open={filterOn}
          //className={classes.popover}
          anchorEl={savedviewsAnchorEl}
          onClose={() => {
            setSavedviewsAnchorEl(null);
            setFilterOn(false);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: {
              padding: 20,
              display: "inline-block",
            },
          }}
        >
          <Grid
            container
            className={"test"}
            alignItems="center"
            direction="column"
            spacing={1}
          >
            {deviceFilter.map((filter, position) => {
              if (Array.isArray(filter)) {
                return (
                  <div class="mediator-container">
                    {filter.map((statement, arrayPosition) => {
                      return (
                        <Grid item>
                          <FilterRow
                            filterProps={filterProps}
                            filterEditor={_filterEditor}
                            info={statement}
                            position={position}
                            arrayPosition={arrayPosition}
                            setInitialDate={setInitialDate}
                            filterAddOperator={_filterAddOperator}
                            deleteFilter={_deleteFilter}
                          />
                        </Grid>
                      );
                    })}
                  </div>
                );
              } else {
                var renderReturn = (
                  <FilterRow
                    filterProps={filterProps}
                    filterEditor={_filterEditor}
                    info={filter}
                    position={position}
                    arrayPosition={null}
                    setInitialDate={setInitialDate}
                    filterAddOperator={_filterAddOperator}
                    deleteFilter={_deleteFilter}
                  />
                );
                return (
                  <Grid
                    item
                    container
                    spacing={1}
                    direction={"row"}
                    alignItems="center"
                    maxWidth="xs"
                    //justifyContent={"center"}
                  >
                    <Grid item>{renderReturn}</Grid>
                  </Grid>
                );
              }
            })}
            <Grid
              container
              justifyContent="flex-end"
              //spacing={1}
              alignItems="center"
              style={{
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 5,
                width: "100%",
              }}
            >
              <Grid item>
                <Button
                  id={"apply-filter"}
                  variant="contained"
                  color="primary"
                  disabled={!filterValid}
                  onClick={() => {
                    updateFilterOutput();
                    if (!IS_EMBEDDED && viewName !== "All Services") {
                      saveUserView(viewName);
                    }
                  }}
                >
                  {"Apply"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
        <SaveUserView
          viewName={viewName}
          state={saveDialogOpen}
          handleClose={() => setSaveDialogOpen(false)}
          handleSave={(save_name) => saveUserView(save_name)}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
