import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
//import IdMapping from "../../data/IdMapping.json";
import { apiQuery } from "../../libs/auth-config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
  },
  formControl: {
    minWidth: 300,
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function SSLProperties() {
  const classes = styles();

  const [open, setOpen] = React.useState(false);
  const [portal, setPortal] = React.useState("splc");
  //const [customer, setCustomer] = React.useState("");
  //const [dashType, setDashType] = React.useState("");
  //const [secondDevice, setSecondary] = React.useState("");
  const [serverList, setServerList] = React.useState([]);
  const [server, setServer] = React.useState(0);
  const [domain, storeDomain] = React.useState("");
  const [order, storeOrder] = React.useState("");
  const [expiration, storeExpiration] = React.useState("");
  const [owner, storeOwner] = React.useState("");
  const [duration, storeDuration] = React.useState("");
  const [productName, storeProductName] = React.useState("");
  //const [device, setDevice] = React.useState("");

  //const map = IdMapping;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const loadServers = (folder) => {
    if (folder === null) return;
    const data = {
      id: 2662,
      account: portal,
    };
    apiQuery("GET", "/getservers", data)
      .then((response) => setServerList(response))
      .catch((e) => console.log(e));
  };
  if (serverList.length === 0) loadServers();

  const runJob = () => {
    var data = {
      account: portal,
      domain_name: domain,
      duration: duration,
      expiry_date: expiration,
      order_number: order,
      owner: owner,
      product_name: productName,
      device_name: server,
    };
    return data;
    // fire to api
  };

  const checkValuesAreChanged = () => {
    if (
      !domain ||
      !duration ||
      !expiration ||
      !order ||
      !owner ||
      !productName ||
      !server
    )
      return true;
    else return false;
  };

  return (
    <React.Fragment>
      <Card>
        <CardContent classes={{ root: classes.root }}>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="panel" focused>
                LogicMonitor Panel
              </InputLabel>
              <Select
                labelId="panel"
                id="panel"
                open={open}
                required
                onClose={handleClose}
                onOpen={handleOpen}
                value={portal}
                onChange={(e) => setPortal(e.target.value)}
                labelWidth={125}
              >
                <MenuItem value={"sandy"}>Sandy</MenuItem>
                <MenuItem value={"dws"}>DWS</MenuItem>
                <MenuItem value={"splc"}>SPLC</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <Autocomplete
                id="combo-box-demo"
                options={serverList}
                getOptionLabel={
                  serverList.length ? (option) => option.displayName : ""
                }
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Server List"
                    variant="outlined"
                  />
                )}
                onChange={(e, v) => {
                  if (v) setServer(v.displayName);
                }}
              />
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="domain name" focused>
                SSL Domain Name
              </InputLabel>
              <OutlinedInput
                labelId="domain name"
                value={domain}
                onChange={(e) => storeDomain(e.target.value)}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
                labelWidth={98}
                multiline
              />
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="order number" focused>
                SSL Order Number
              </InputLabel>
              <OutlinedInput
                labelId="order number"
                value={order}
                onChange={(e) => storeOrder(e.target.value)}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
                labelWidth={98}
                multiline
              />
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="expiration date" focused>
                SSL Expiration Date
              </InputLabel>
              <OutlinedInput
                labelId="expiration date"
                value={expiration}
                onChange={(e) => storeExpiration(e.target.value)}
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
                labelWidth={98}
                multiline
              />
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="type" focused>
                SSL Owner
              </InputLabel>
              <Select
                labelId="type"
                value={owner}
                onChange={(e) => storeOwner(e.target.value)}
                labelWidth={170}
              >
                <MenuItem value={"daisy"}>Daisy</MenuItem>
                <MenuItem value={"customer"}>Customer</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="type" focused>
                SSL Duration
              </InputLabel>
              <Select
                labelId="type"
                value={duration}
                onChange={(e) => storeDuration(e.target.value)}
                labelWidth={170}
              >
                <MenuItem value={"1"}>1 Year</MenuItem>
                <MenuItem value={"2"}>2 Years</MenuItem>
                <MenuItem value={"3"}>3 Years</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box textAlign="center">
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="type" focused>
                SSL Product Name
              </InputLabel>
              <Select
                labelId="type"
                value={productName}
                onChange={(e) => storeProductName(e.target.value)}
                labelWidth={170}
              >
                <MenuItem value={"single"}>Single</MenuItem>
                <MenuItem value={"wildcard"}>Wildcard</MenuItem>
                <MenuItem value={"UCC certificate"}>UCC certificate</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box textAlign="center">
            <Button
              className={classes.formControl}
              color="primary"
              variant="contained"
              disabled={checkValuesAreChanged()}
              onClick={runJob}
            >
              Run Job
            </Button>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
